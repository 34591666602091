import { WenRouterOutlet } from '@portal/wen-components';
import { DataContext } from '../../../core/common/types/data-context';
import { userProfileLoadedGuardFn } from '../../../core/guards/user-profile-loaded-guard';
import { authGuardFn } from '../../../core/services/user-management/auth.guard';
import { WenRouteId, WenRoutes } from '../../../frame/routing/types';
import { AdaptiveBackgroundComponent } from '../../../shared/components/adaptive-background/adaptive-background.component';
import { chatDetailGuardFn } from '../../chat/chat-view/guards/chat-detail-guard';
import { chatCreationRoute } from '../../chat/routes/chat.routes';
import { chatViewIdentifier } from '../../chat/tokens';
import { inviteToChannelResolverFn } from '../../invite/invite-to-channel/guards/invite-to-channel.resolver';
import { inviteToChannel, inviteToChannelSelector } from '../../invite/routes/invite.routes';
import { singleOutletInviteRoutes } from '../../invite/routes/single-outlet-invite.routes';
import { userProfileIdentifier } from '../../user-profile/tokens';
import { ActionSelectorComponent } from '../action-selector/components/action-selector/action-selector.component';
import { ChatPreviewComponent } from '../chat-preview/components/chat-preview/chat-preview.component';
import { EmbeddedChatComponent } from '../chat/components/embedded-chat/embedded-chat.component';
import { InitiateChatComponent } from '../chat/components/initiate-chat/initiate-chat.component';
import { redirectToChatRoomGuardFn } from '../chat/guards/redirect-to-room.guards';
import { DistributeChatComponent } from '../distribute-chat/components/distribute-chat/distribute-chat.component';
import { ProfileReadOnlyViewComponent } from '../logout/components/profile-read-only-view.component';

export const singleOutletCrmRoutes: WenRoutes = [
  {
    path: 'home',
    component: AdaptiveBackgroundComponent,
  },
  {
    path: 'invite',
    children: [
      ...singleOutletInviteRoutes,
      inviteToChannelSelector,
      {
        ...inviteToChannel,
        resolve: {
          selectedChannels: inviteToChannelResolverFn,
        }
      },
    ],
    canActivateChild: [authGuardFn],
    outlet: WenRouterOutlet.PRIMARY,
  },
  {
    path: 'chat',
    children: [
      {
        ...chatCreationRoute,
      },
      {
        path: `:${chatViewIdentifier}`,
        component: EmbeddedChatComponent,
        canActivate: [chatDetailGuardFn],
        data: {
          routeId: WenRouteId.CRM_EMBEDDED_CHAT,
          dataContext: DataContext.CHAT
        }
      }
    ],
  },
  {
    path: 'logout',
    component: ProfileReadOnlyViewComponent,
    data: {
      routeId: WenRouteId.CRM_PROFILE_READ_ONLY_VIEW
    },
  },
  {
    path: 'action-selector',
    component: ActionSelectorComponent,
    canActivate: [userProfileLoadedGuardFn],
    data: {
      routeId: WenRouteId.CRM_ACTION_SELECTOR,
      dataContext: DataContext.PROFILE
    },
  },
  {
    path: 'distribute-chat',
    component: DistributeChatComponent,
    canActivate: [userProfileLoadedGuardFn],
    data: {
      routeId: WenRouteId.CRM_DISTRIBUTE_CHAT,
      dataContext: DataContext.PROFILE
    },
  },
  {
    path: 'chat-preview',
    component: ChatPreviewComponent,
    canActivate: [userProfileLoadedGuardFn],
    data: {
      routeId: WenRouteId.CRM_CHAT_PREVIEW
    },
  },
  {
    path: `embedded-chat/:${userProfileIdentifier}`,
    canActivate: [redirectToChatRoomGuardFn],
    component: InitiateChatComponent,
    data: {
      routeId: WenRouteId.CRM_INITIATE_CHAT
    },
  },
];
