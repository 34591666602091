@if (sectionData$ | async; as sectionData) {
  @switch (config?.context) {
    @case (DataContext.CHANNEL) {
      <wen-channel-section
        [dataSource]="sectionData.dataSource"
        [sectionName]="sectionData.name | translate"
        (showAll)="navigateToChannelDiscovery(sectionData.sectionProps)">
      </wen-channel-section>
    }
    @case (DataContext.APP) {
      <wen-app-section
        [dataSource]="sectionData.dataSource"
        [sectionName]="sectionData.name | translate"
        (showAll)="navigateToAppDiscovery(sectionData.sectionProps)">
      </wen-app-section>
    }
    @case (DataContext.EVENT) {
      <wen-event-section
        [dataSource]="sectionData.dataSource"
        [sectionName]="sectionData.name | translate"
        (showAll)="navigateToEventDiscovery(sectionData.sectionProps)">
      </wen-event-section>
    }
  }
}
