@if (loadResult$ | async; as loadResult) {
  @if (loadResult.data) {
    @if (loadResult.data.length) {
      <ng-container *ngTemplateOutlet="exploreChannelHeader">
      </ng-container>
      <wen-channel-list [channelList]="loadResult.data" [showAfterElement]="true" [wen-test-id]="'wen-subscribed-channel-list'" [enableContextMenu]="true"></wen-channel-list>
    } @else {
      @if (loadResult.mode === 'normal') {
        <wen-empty-list
          src="/assets/wen-widget/image/empty-channel-list-background.svg"
          titleLabel="CHANNEL_LIST_EMPTY_CHANNEL_TITLE"
          textLabel="CHANNEL_LIST_EMPTY_CHANNEL_TEXT"
          [wen-test-id]="'wen-user-empty-list'"
          >
          <ng-container wen-empty-list-header>
            <ng-container *ngTemplateOutlet="exploreChannelHeader"></ng-container>
          </ng-container>
        </wen-empty-list>
      } @else {
        <wen-empty-search-result></wen-empty-search-result>
      }
    }
  }
}

<ng-template #exploreChannelHeader>
  <div class="wen-explore-header">
    <h1 class="wen-explore-header-label">{{ 'CHANNELS_HEADER_TITLE' | translate }}</h1>
    <div class="wen-user-channel-list-explore-channel-button" portal-body-2 wen-primary-color
      [wen-test-id]="'channel-my-list-explore-channel-button'"
      (click)="navigateToChannelExplore()">
      <wen-icon [iconName]="'compass'" color="primary"></wen-icon>
      {{ "CHANNEL_DISCOVER" | translate }}
    </div>
  </div>
</ng-template>
