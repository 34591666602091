import { NgModule } from '@angular/core';
import { ListMassDataViewerEmptyTemplateDirective } from './directives/empty-list.directive';

@NgModule({
  declarations: [
    ListMassDataViewerEmptyTemplateDirective
  ],
  exports: [
    ListMassDataViewerEmptyTemplateDirective
  ],
})
export class ListMassDataCommonModule { }
