import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { SocketIoService } from '@portal/wen-backend-api';
import { WenBreakpointObserver } from '@portal/wen-components';
import { Observable, first, map, switchMap, tap, timer } from 'rxjs';
import { INVITATION_ID_QUERY_PARAM } from '../../../core/services/navigation/query-params';
import { WenNavigationHelper } from '../../../core/services/navigation/types';
import { PermissionLevel } from '../../../core/services/user-management/permission-level';
import { WenOAuthService } from '../../../core/services/user-management/wen-oauth.service';
import { selectOutletIds } from '../../../core/store/root/root.selectors';
import { SignInInviteDialogOpener } from '../services/sign-in-invite-dialog-opener';

export const signInInviteGuardFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(SignInInviteGuard).canActivate(route, state);
};

@Injectable()
export class SignInInviteGuard  {

  constructor(
    private readonly navigationHelper: WenNavigationHelper,
    private readonly store: Store,
    private readonly authService: WenOAuthService,
    private readonly socketIoService: SocketIoService,
    private readonly wenBreakpointObserver: WenBreakpointObserver,
    private readonly signInInviteDialogOpener: SignInInviteDialogOpener,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const inviteId = route.queryParamMap.get(INVITATION_ID_QUERY_PARAM);
    const userData = this.authService.getUserData();

    if (!Boolean(inviteId)) {
      this.navigationHelper.navigateToWelcomeScreen();
      return false;
    }

    if (userData?.permissionLevel === PermissionLevel.REGISTERED_USER) {
      return this.socketIoService.invites.confirmInvitation.acknowledgement$({ id: inviteId }).pipe(
        map((data) => {
          if(data?.success === true) {
            this.signInInviteDialogOpener.openInviteSuccessDialog();
          } else {
            this.signInInviteDialogOpener.openInviteFailureDialog();
          }
          this.navigationHelper.navigateToWelcomeScreen();
          return false;
        })
      );
    }

    if (!this.wenBreakpointObserver.isDesktopStyleDevice) {
      return true;
    }

    return timer(0).pipe(
      switchMap(() => this.store.pipe(
        select(selectOutletIds),
        first(),
        map(outletIds => Boolean(outletIds.dialogId)),
        tap(isDialog => {
          if (!isDialog) {
            this.navigationHelper.navigateToInviteSignIn(inviteId);
          }
        })
      ))
    );
  }
}
