import { Injectable } from '@angular/core';
import { MessageEvent, MessageEventResponses } from '@portal/wen-backend-api';
import { isEditEvent, isRealtimeEditEvent, isRedactEvent, isValidSendEvent } from '../message-event/message-event-helper';
import { asEncryptedEditEvent } from '../message-event/modifiers/edit';
import { asEncryptedRedactEvent } from '../message-event/modifiers/redact';

@Injectable()
export class EncryptedEventModifier {

  /**
   * @param event The message event from a given source
   * @returns The event which should be decrypted in order to be handled afterwards.
   * By default it is the SEND_MESSAGE event's content
   */
  transformEncryptedEvent(event: MessageEvent<MessageEventResponses>): MessageEvent<MessageEventResponses> {
    if (!Boolean(event)) {
      return null;
    }
    if (isRedactEvent(event)) {
      return asEncryptedRedactEvent(event);
    }
    if (isEditEvent(event) || isRealtimeEditEvent(event)) {
      return asEncryptedEditEvent(event);
    }
    if (isValidSendEvent(event)) {
      return event;
    }
    return null;
  }
}
