
import { Injectable } from '@angular/core';
import { AllFilters, ListEventRequestParameters } from '@portal/wen-backend-api';
import { SearchFilterId } from '../../header/models/HeaderData';
import { Filter, FilterType } from '../models/filter';
import { FilterId } from '../../../common/types/filter-id';
import { Filterable } from '../../../../shared/components/filter-view/models/filter-item';
import { DateTime } from 'luxon';

@Injectable({ providedIn: 'root' })
export class FilterHelperService {

  /**
   * @deprecated
   * Currently, on desktop it can happen that there are search fields available while the context still remains.
   * Therefore these values should be filtered out as well.
   */
  removeUserSearchFilters(activeFilters: Filter[]) {
    return activeFilters.filter(f => {
      return f.filterId !== SearchFilterId.ADMIN_SEARCH && f.filterId !== SearchFilterId.USER_SEARCH;
    });
  }

  /**
   * TODO: Improve typing and structure
   */
  convertFiltersToRequestParameters(activeFilters: Filter[]): ListEventRequestParameters | null {
    if (!activeFilters?.length) {
      return null;
    }

    return {
      filter: activeFilters.reduce((allFilters, {filterType, filterId, filterValue}) => {
        if (filterType === FilterType.SELECT) {
          if (this.isDateFilter(filterId)) {
            return { ...allFilters, date: this.convertDateFilter(filterValue) };
          }
          if (this.isGeoFilter(filterId)) {
            return { ...allFilters, geoLocation: { ...filterValue.filter } };
          }
          return { ...allFilters, ...filterValue.filter };
        }

        if (filterType === FilterType.SEARCH) {
          return { ...allFilters, search: filterValue };
        }

        return allFilters;
      }, {} as AllFilters)
    } as ListEventRequestParameters;
  }

  private isDateFilter(filterId: any): boolean {
    return filterId === FilterId.EVENT_DATES;
  }

  private isGeoFilter(filterId: any): boolean {
    return filterId === FilterId.EVENT_GEO_LOCATION || filterId === FilterId.CHANNEL_MESSAGE_GEO_LOCATION;
  }

  private convertDateFilter(filterValue: Filterable): { startDate: string; endDate: string } | null {
    return filterValue.filter ? {
      startDate: filterValue.filter.startDate instanceof DateTime
        ? filterValue.filter.startDate.toUTC()
        : filterValue.filter.startDate,
      endDate: filterValue.filter.endDate instanceof DateTime
        ? filterValue.filter.endDate.toUTC()
        : filterValue.filter.endDate
    } : null;
  }
}


