import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { combineLatest, distinctUntilChanged, exhaustMap, filter, map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { DateUtil } from '../../../../../core/common/date/date-util';
import { distinctUntilKeysChangedInArray } from '../../../../../core/common/operators/distinct-until-keys-changed';
import { LoadResultMode } from '../../../../../core/common/types/misc';
import { selectorWithParam } from '../../../../../core/common/util/selector-with-param';
import { selectAllRooms, selectUserChatListIsLoaded } from '../../../../../core/store/chat/chat.selectors';
import { ChatRoomEntity } from '../../../../../core/store/chat/chat.state';
import { selectSearchTerm } from '../../../../../core/store/filter/filter.selectors';
import { FilterEntityType } from '../../../../../core/store/filter/models/filter';
import { RootState } from '../../../../../core/store/root/public-api';
import { ChatListItemModel, ChatLoadResult } from '../../models/chat-list.models';
import { ChatListItemFactory } from '../chat-list-item/providers/chat-list-item-factory';

@Injectable()
export class UserChatListDataSource {

  private store: Store<RootState> = inject(Store);
  private itemFactory = inject(ChatListItemFactory);

  private chatRooms$: Observable<ChatRoomEntity[]> = this.store.pipe(
    select(selectUserChatListIsLoaded),
    filter(isLoaded => isLoaded),
    switchMap(() => this.store.pipe(
      select(selectAllRooms),
      smartDistinctUntilChanged(),
      shareReplay(1),
    ))
  );

  private searchTerm$ = this.store.pipe(
    selectorWithParam(selectSearchTerm, FilterEntityType.CHAT_LISTS),
    distinctUntilChanged(),
    shareReplay(1)
  );

  /**
   * Connect the chat list to the given observable
   *  - The items streamed in this observable are rendered
   */
  bindToSource(): Observable<ChatLoadResult> {
    const onLoadedContent$: Observable<ChatLoadResult> = this.chatRooms$.pipe(
      switchMap(chatRooms => {
        return this.searchTerm$.pipe(
          switchMap(searchTerm => {
            const filteredChats = this.filterChats(chatRooms, searchTerm);
            return of(filteredChats).pipe(
              distinctUntilKeysChangedInArray(['id']),
              switchMap(roomsToRender => {
                if (roomsToRender.length === 0) {
                  const emptyResult: ChatLoadResult = {
                    mode: searchTerm?.length ? LoadResultMode.SEARCH : LoadResultMode.NORMAL,
                    data: []
                  };
                  return of(emptyResult);
                }
                const listItems$: Observable<ChatListItemModel>[] = roomsToRender.map(room => {
                  return this.itemFactory.getRoomProperties(room).pipe(
                    map((properties) => this.itemFactory.createListItem(properties))
                  );
                });
                return combineLatest(listItems$).pipe(
                  map((listItems) => {
                    const sortedListItems = listItems.sort((chatA, chatB) => {
                      if (chatA?.pinTimestamp !== chatB?.pinTimestamp) {
                        return DateUtil.compareNullSafe(chatA.pinTimestamp, chatB.pinTimestamp);
                      }
                      return DateUtil.compareNullSafe(chatA.timestamp, chatB.timestamp);
                    });
                    return {
                      mode: searchTerm?.length ? LoadResultMode.SEARCH : LoadResultMode.NORMAL,
                      data: sortedListItems
                    };
                  })
                );
              })
            );
          })
        );
      }),
    );

    return this.store.pipe(
      select(selectUserChatListIsLoaded),
      // exhaustMap is used here as that will block the list from flashing should the WS reconnect
      exhaustMap((isLoaded) => {
        if (isLoaded) {
          return onLoadedContent$;
        }
        return of({
          mode: LoadResultMode.NORMAL,
          data: []
        });
      })
    );
  }

  private filterChats(chats: ChatRoomEntity[], searchTerm: string): ChatRoomEntity[] {
    if (!searchTerm) {
      return chats;
    }
    return chats.filter(chat => chat.details.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));
  }

}
