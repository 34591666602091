<wen-adaptive-background [desktopWidth]="375" wenIosSwipeSuppressor>
  <wen-dialog-layout wen-adaptive-background-content [headerGravity]="'top'">
    <div class="wen-error-page-content-wrapper" dialogLayoutContent>
      <div portal-body class="wen-error-page-body">
        <wen-error-page-reason-content [errorReason]="errorReason$ | async"></wen-error-page-reason-content>
        <!--
          TODO: Add error page link from design once we have the URL
          Translations for it are already added: "ERROR_PAGE_INFO_LINK_LABEL" and "ERROR_PAGE_INFO"
          -->
        <wen-action class="wen-error-page-reload-button wen-action-rounded" wenVariant="filled"
          (click)="reloadClicked()">
          <span portal-title>{{ "ERROR_PAGE_BUTTON_LABEL" | translate }}</span>
        </wen-action>
      </div>
    </div>
  </wen-dialog-layout>
</wen-adaptive-background>
