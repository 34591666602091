
@if (restrictionModel) {
  @if (restrictionModel?.layoutType === 'buttonLayout') {
    <div class="wen-channel-restriction-wrapper wen-button-layout">
      <img [src]="restrictionModel.imagePath" alt=""/>
      <div class="wen-restriction-content">
        <div class="wen-restriction-headline">
          @if (restrictionModel.premiumReadingChannel) {
            <wen-channel-indicator class="wen-premium-indicator wen-channel-indicator-large"
              [indicatorType]="IndicatorType.PREMIUM_READING_CHANNEL"
            ></wen-channel-indicator>
          }
          <div portal-headline>{{ restrictionModel.titleTranslationKey | translate }}</div>
        </div>
        <div portal-body-2 class="wen-channel-restriction-description">{{ restrictionModel.descriptionTranslationKey | translate }}</div>
        <wen-action class="wen-action-rounded" wenVariant="filled" (click)="restrictionModel?.action.handler()">
          {{ restrictionModel?.action.translationKey | translate }}
        </wen-action>
      </div>
    </div>
  } @else {
    @if (restrictionModel) {
      <div class="wen-channel-restriction-wrapper wen-info-layout">
        <div portal-body-2 class="wen-channel-restriction-title">{{ restrictionModel.titleTranslationKey | translate }}</div>
        <div portal-caption class="wen-channel-restriction-description">{{ restrictionModel.descriptionTranslationKey | translate }}</div>
        <img [src]="restrictionModel.imagePath" alt=""/>
      </div>
    }
  }
}


