import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { RootState } from '../../../../core/store/root/public-api';
import { map, Observable } from 'rxjs';
import { selectCommentsForMessage } from '../../../../core/store/comments/comments.selectors';
import { CommentEntity } from '../../../../core/store/comments/comments.state';
import { ActionComponent, WithVariantDirective } from '@portal/wen-components';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'wen-open-comment-button',
  standalone: true,
  templateUrl: './open-comments.component.html',
  imports: [ActionComponent, AsyncPipe, TranslateModule, WithVariantDirective],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenCommentsComponent {
  count$: Observable<number>;

  @Input() set messageId(id: string) {
    this.count$ = this.store.pipe(
      select(selectCommentsForMessage),
      map((projectorFn) => projectorFn(id)),
      map((commentEntity: CommentEntity) => commentEntity?.count)
    );
  }


  @Input() deactivated: boolean;
  @Input() disabled: boolean;

  constructor(private store: Store<RootState>) { }

}
