<wen-action (tap)="onMediaClick()"
            [altText]="'EMBED_FILE_DESCRIPTION' | translate: { filename: params.title, fileSize: fileSize | filesize }">
  <wen-icon class="wen-embed-file-file-icon wen-icon-big" [iconName]="fileIcon"></wen-icon>
  <div class="wen-embed-file-data-wrapper">
    <div portal-title class="wen-embed-file-title">
      <span [wen-middle-ellipsize]="7" [text]="params.title"></span>
    </div>
    <div class="wen-embed-file-second-line">
      <span portal-subheading-1 class="wen-embed-file-size">{{ fileSize | filesize }}</span>
      @if ((isDownloading$ | async) === false) {
        <wen-icon
          class="wen-embed-file-download-icon wen-icon-small"
          iconName="download_inactive"
        ></wen-icon>
      }
      @if (isDownloading$ | async) {
        <wen-loading-indicator
          class="wen-embed-file-download-icon"
          [size]="'icon-small'"
        ></wen-loading-indicator>
      }
    </div>
  </div>
</wen-action>
