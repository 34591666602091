<div class="wen-form">
  <div class="wen-form-group wen-single-group">
    <div class="wen-form-field-label" portal-caption>{{ 'CHANNEL_CONTENT_PROVIDER_LABEL' | translate }}</div>
    @if (canAddContentProviders$ | async) {
      <div class="wen-channel-content-provider-list-add-content-provider-button"  portal-body-2 wen-primary-color (click)="navigateToAddContentProvider()">
        <wen-icon class="wen-icon-big" [iconName]="'add_user'"></wen-icon>
        {{ "CHANNEL_ADD_CONTENT_PROVIDER_TITLE" | translate }}
      </div>
    }
    <div class="wen-form-field-scroller">
      @for (listItem of userList$ | async; track listItem) {
        <wen-user-list-item [user]="listItem.user" [userNavigable]="listItem.user.userId">
          @if (listItem.hasMenu) {
            <wen-action wenVariant="textSubtle" class="wen-action-no-padding" iconName="more" [altText]="'MORE_OPTION_LABEL' | translate" (click)="openContextMenu(listItem.user.userId)" wen-user-after></wen-action>
          }
        </wen-user-list-item>
      }
    </div>
    <div class="wen-form-group-hint" portal-subheading-2>{{ 'CHANNEL_CONTENT_PROVIDER_HINT_LABEL' | translate }}</div>
  </div>
</div>