import { inject, Injectable } from '@angular/core';
import { ChatMessageComposerExtras, ChatMessagePreprocessorResult, EditRelation, EncryptionType, isEditRelation, isQuoteRelation, PlainEditMessageEventDTO, PlainQuoteMessageEventDTO, PlainSendMessageEventDTO, QuoteRelation, RelationTypes, SendMessageContent, SendToRoomEventPayload, ToRoomEventType } from '@portal/wen-backend-api';
import { ChatMessageBatchLoader, ChatMessageComposerTransactionFactory } from '@portal/wen-chat-client';
import { of } from 'rxjs';
import { WenOAuthService } from '../../user-management/wen-oauth.service';

@Injectable()
export class PlainChatMessageSender {

  private transactionFactory = inject(ChatMessageComposerTransactionFactory);
  private oAuthService = inject(WenOAuthService);

  sendMessages(itemLoader: ChatMessageBatchLoader) {
    const extras: ChatMessageComposerExtras = {
      prepareMessage: (rawPayload) => {
        const { roomId, roomMessage, relation, scheduledFor } = rawPayload;
        const senderUserId = this.oAuthService.getUserData().userId;
        const preprocessorResult: ChatMessagePreprocessorResult = this.createPreprocessor(
          roomId,
          roomMessage,
          relation,
          senderUserId,
          scheduledFor
        );
        return of(preprocessorResult);
      },
      enableEncryption: false,
    };
    return this.transactionFactory.create(itemLoader, extras);
  }

  private createPreprocessor(
    roomId: string,
    roomMessage: SendMessageContent<false>,
    relation: RelationTypes,
    senderUserId: string,
    scheduledFor?: string
  ): ChatMessagePreprocessorResult {
    if (!relation) {
      return new PlainSendMessageEventResult(roomId, roomMessage, senderUserId);
    } else if (isEditRelation(relation)) {
      return new PlainEditMessageEventResult(roomId, roomMessage, relation, senderUserId, scheduledFor);
    } else if (isQuoteRelation(relation)) {
      return new PlainQuoteMessageEventResult(roomId, roomMessage, relation, senderUserId);
    }
  }

}


export class PlainEditMessageEventResult implements ChatMessagePreprocessorResult {

  constructor(
    private readonly roomId: string,
    private readonly content: SendMessageContent<false>,
    private readonly relation: EditRelation,
    private readonly senderUserId: string,
    private readonly scheduledFor: string
  ) { }

  getPayload(): SendToRoomEventPayload {
    return {
      roomId: this.roomId,
      payload: this.asSendToRoomPayload()
    };
  }

  private asSendToRoomPayload() {
    const sendMessageEventDTO: PlainSendMessageEventDTO = {
      roomId: this.roomId,
      eventType: ToRoomEventType.SEND_MESSAGE,
      senderUserId: this.senderUserId,
      content: this.content,
      type: EncryptionType.PLAIN,
    };
    const editMessageEventDTO: PlainEditMessageEventDTO = {
      roomId: this.roomId,
      eventType: ToRoomEventType.EDIT_MESSAGE,
      senderUserId: this.senderUserId,
      content: this.content,
      type: EncryptionType.PLAIN,
      relatesTo: this.relation
    };
    return this.scheduledFor ? sendMessageEventDTO : editMessageEventDTO;
  }
}

export class PlainQuoteMessageEventResult implements ChatMessagePreprocessorResult {

  constructor(
    private readonly roomId: string,
    private readonly content: SendMessageContent<false>,
    private readonly relation: QuoteRelation,
    private readonly senderUserId: string
  ) { }

  getPayload(): SendToRoomEventPayload {
    return {
      roomId: this.roomId,
      payload: this.asSendToRoomPayload()
    };
  }

  private asSendToRoomPayload() {
    const payload: PlainQuoteMessageEventDTO = {
      roomId: this.roomId,
      eventType: ToRoomEventType.QUOTE_MESSAGE,
      senderUserId: this.senderUserId,
      content: this.content,
      type: EncryptionType.PLAIN,
      relatesTo: this.relation
    };
    return payload;
  }
}

export class PlainSendMessageEventResult implements ChatMessagePreprocessorResult {

  constructor(
    private readonly roomId: string,
    private readonly content: SendMessageContent<false>,
    private readonly senderUserId: string
  ) { }

  getPayload(): SendToRoomEventPayload {
    return {
      roomId: this.roomId,
      payload: this.asSendToRoomPayload()
    };
  }

  private asSendToRoomPayload() {
    const payload: PlainSendMessageEventDTO = {
      roomId: this.roomId,
      eventType: ToRoomEventType.SEND_MESSAGE,
      senderUserId: this.senderUserId,
      content: this.content,
      type: EncryptionType.PLAIN,
    };
    return payload;
  }
}
