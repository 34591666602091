import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../../../../shared/shared.module';
import { ChatSendScheduledMessageComponent } from '../../../common/components/chat-send-scheduled-message/chat-send-scheduled-message.component';
import { ActionComponent, CoreComponentsModule, WithVariantDirective } from '@portal/wen-components';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatIconModule,
    MatDatepickerModule,
    MatInputModule,
    CoreComponentsModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMatTimepickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMatDatetimePickerModule,
    TranslateModule.forChild(),
    ActionComponent,
    WithVariantDirective
  ],
  exports: [
    ChatSendScheduledMessageComponent,
  ],
  declarations: [
    ChatSendScheduledMessageComponent,
  ],
})
export class ChatSendScheduledMessageModule { }
