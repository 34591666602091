<div class="wen-confirmation-dialog-wrapper" [wen-test-id]="'wen-confirmation-dialog'">
  <mat-dialog-content class="wen-confirmation-dialog-content">
    <h2 id="dialogHeaderLabel" class="wen-confirmation-dialog-header" [wen-test-id]="'wen-confirmation-dialog-header'">
      @if (headerImage) {
        <img [src]="headerImage" alt="">
      }
      @if (header) {
        {{ header }}
      }
    </h2>
    <div class="wen-confirmation-dialog-description" [wen-test-id]="'wen-confirmation-dialog-description'">
      @if (content) {
        <wen-markdown-text [content]="content"></wen-markdown-text>
      } @else {
        <ng-content
        select="wen-confirmation-dialog-custom-content, [wen-confirmation-dialog-custom-content]"></ng-content>
      }
    </div>
    @if (reasonFieldLabel) {
      <form (ngSubmit)="onAccept()" [formGroup]="confirmationFormGroup">
        @if (!largeReasonField) {
          <mat-form-field>
            <mat-label>{{ reasonFieldLabel }}</mat-label>
            <input matInput autocomplete="off" formControlName="reason" [wen-test-id]="'confirmation-dialog-reason'">
          </mat-form-field>
        } @else {
          <mat-form-field>
            <mat-label>{{ reasonFieldLabel }}</mat-label>
            <textarea matInput formControlName="reason" rows="4" maxlength="300" [wen-test-id]="'confirmation-dialog-reason'"></textarea>
          </mat-form-field>
        }
      </form>
    }
  </mat-dialog-content>
  <mat-dialog-actions class="wen-confirmation-dialog-actions">
    <ng-content select="wen-confirmation-dialog-ok-action, [wen-confirmation-dialog-ok-action]"></ng-content>
    @if (okLabel) {
      <button class="wen-confirmation-dialog-accept-button" wen-primary-color (click)="onAccept()"
      [wen-test-id]="'wen-confirmation-dialog-accept-button'">{{ okLabel }}</button>
    }
    @if (neutralLabel) {
      <button class="wen-confirmation-dialog-accept-button" wen-primary-color (click)="onNeutral()"
      [wen-test-id]="'wen-confirmation-dialog-neutral-button'">{{ neutralLabel }}</button>
    }
    @if (dismissLabel) {
      <button class="wen-confirmation-dialog-cancel-button" wen-primary-color (click)="onDismiss()"
      [wen-test-id]="'wen-confirmation-dialog-cancel-button'">{{ dismissLabel }}</button>
    }
  </mat-dialog-actions>
</div>


