import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { SimpleUserDTO } from '@portal/wen-backend-api';
import { Observable, Subject, map, takeUntil, withLatestFrom } from 'rxjs';
import { ActionMenuComponent, OverlayManager } from '@portal/wen-components';
import { equalsIgnoreCase } from '../../../core/common/operators/null-check-util';
import { selectorWithParam } from '../../../core/common/util/selector-with-param';
import { WenNavigationHelper } from '../../../core/services/navigation/types';
import { ActionMenuItemProvider } from '../../../core/services/util/action-menu-item.provider';
import { selectCurrentUserData } from '../../../core/store/auth/auth.selectors';
import { selectChannelPermission, selectCurrentChannelContentProviders } from '../../../core/store/channel/channel.selectors';
import { RootState } from '../../../core/store/root/public-api';
import { ColorPalette } from '../../../shared/directives/colorize/palette-provider';
import { channelViewIdentifier } from '../tokens';
import { channelContentProviderMenuId } from './providers/action-menu/constants';
import { HeaderDataHelper } from '../../../core/services/util/header-data-helper';

@Component({
  selector: 'wen-channel-content-provider-list',
  templateUrl: './channel-content-provider-list.component.html',
  styleUrls: ['./channel-content-provider-list.component.scss'],
  providers: [ActionMenuItemProvider]
})
export class ChannelContentProviderListComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  userList$: Observable<{ user: SimpleUserDTO; hasMenu: boolean }[]>;
  actions$ = this.actionMenuItemProvider.selectActions(channelContentProviderMenuId);
  canAddContentProviders$: Observable<boolean>;

  private currentChannelId = this.activatedRoute.snapshot.params[channelViewIdentifier];

  get contextMenuOpenerFillColor(): ColorPalette {
    return ColorPalette.GREY;
  }

  constructor(
    private navigationHelper: WenNavigationHelper,
    private activatedRoute: ActivatedRoute,
    private store: Store<RootState>,
    private actionMenuItemProvider: ActionMenuItemProvider,
    private overlayManager: OverlayManager,
    private headerDataHelper: HeaderDataHelper
  ) { }

  ngOnInit() {
    this.headerDataHelper.initializeContextTitleWithChannelTitle(true);

    const currentUserId$ = this.store.pipe(
      select(selectCurrentUserData),
      map((userData) => userData.userId.toLowerCase())
    );
    this.userList$ = this.store.pipe(
      select(selectCurrentChannelContentProviders),
      withLatestFrom(currentUserId$, this.actions$),
      map(([users, currentUserId, actions]) => {
        if (!users?.length) {
          return [];
        }
        return users.map(user => ({
          user,
          hasMenu: !equalsIgnoreCase(user.userId, currentUserId) && Boolean(actions?.length)
        }));
      }),
      map(users => users.sort(({ user: user1 }, { user: user2 }) => user1.displayName.localeCompare(user2.displayName))),
    );
    this.canAddContentProviders$ = this.store.pipe(
      selectorWithParam(selectChannelPermission, this.currentChannelId),
      map(permission => permission?.canAddAdmins),
      takeUntil(this.onDestroy$)
    );
  }

  navigateToAddContentProvider() {
    this.navigationHelper.navigateToAddContentProviderPage(this.currentChannelId);
  }

  openContextMenu(userId: string) {
    this.overlayManager.menu.open(ActionMenuComponent, {actions: this.actions$, data: userId});
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
