import { Component, OnDestroy, OnInit } from '@angular/core';
import { DocumentUtils } from '@portal/wen-common';
import { Subject } from 'rxjs';

@Component({
  selector: 'wen-refresh-banner',
  templateUrl: './refresh-banner.component.html',
  styleUrls: ['./refresh-banner.component.scss']
})
export class RefreshBannerComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();

  ngOnInit(): void { }

  reloadBrowser(){
    DocumentUtils.reload();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
