
import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs';
import { firstExisty } from '../../../core/common/operators/first-existy';
import { selectorWithParam } from '../../../core/common/util/selector-with-param';
import { selectChannelById, selectMessageDetailById } from '../../../core/store/channel/channel.selectors';
import { ChannelEntity } from '../../../core/store/channel/channel.state';
import { upsertHeaderData } from '../../../core/store/header/header.actions';
import { HeaderData } from '../../../core/store/header/models/HeaderData';
import { RootState } from '../../../core/store/root/public-api';
import { selectRouteParam } from '../../../core/store/root/root.selectors';
import { channelMessageDetailIdentifier } from '../../../views/channel/tokens';

@Injectable()
export class ChannelMessageDetailHeaderDataUpder {
  private store: Store<RootState> = inject(Store);
  private translateService = inject(TranslateService);
  private destroyRef = inject(DestroyRef);

  private headerData$ = this.store.pipe(
    select(selectRouteParam(channelMessageDetailIdentifier)),
    distinctUntilChanged(),
    switchMap((messageId) => this.store.pipe(
      selectorWithParam(selectMessageDetailById, messageId),
      filter(message => Boolean(message)),
      firstExisty(),
      switchMap((message) => this.store.pipe(
        selectorWithParam(selectChannelById, message.channelId)
      ))
    )),
    map((channelEntity: ChannelEntity) => {
      return {
        title: channelEntity.title,
        subTitle: this.translateService.instant('CHANNEL_COMMENTS_TITLE')
      } satisfies HeaderData;
    })
  );

  initialize() {
    this.headerData$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(headerData => {
      this.store.dispatch(upsertHeaderData({ headerData }));
    });
  }
}
