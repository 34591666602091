<div class="wen-weather-wrapper">
  <img class="wen-weather-background-icon" [src]="weatherViewValue.background.iconUrl" alt="" />
  @if (weatherViewValue) {
    <div class="wen-weather-header">
      <img class="wen-weather-icon" [src]="weatherViewValue.icon" [alt]="weatherViewValue.descriptionKey | translate" />
      <div class="wen-weather-summary-header">
        <div class="wen-header-title">
          <div class="wen-weather-temperature">
            <ng-container *ngTemplateOutlet="weatherViewValue?.temperature?.max ? multiTemperature : singleTemperature; context: { $implicit: weatherViewValue.temperature }"></ng-container>
          </div>
          <div class="wen-weather-location">
            <wen-icon iconName="location_top_right" class="wen-icon-medium"></wen-icon>
            <span>{{ weatherViewValue.location }}</span>
          </div>
        </div>
        <div class="wen-header-description">
          {{ weatherViewValue.descriptionKey | translate }}
        </div>
      </div>
    </div>
  }
  @if (weatherViewValue.forecasts; as forecasts) {
    <div class="wen-weather-content">
      @for (forecast of forecasts; track forecast) {
        <wen-hourly-forecast [data]="forecast"></wen-hourly-forecast>
      }
    </div>
  }
</div>

<ng-template #singleTemperature let-temperature>
  <span portal-headline>{{ temperature | number: '1.0-0' }}°</span>
</ng-template>

<ng-template #multiTemperature let-temperature>
  <span portal-headline>{{temperature.max | number: '1.0-0' }}°</span>
  <span portal-headline>{{temperature.min | number: '1.0-0' }}°</span>
</ng-template>