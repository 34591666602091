<h1 *isDesktopView class="wen-explore-header-label">{{ 'CHANNEL_DISCOVER' | translate }}</h1>

<div class="quick-filters">
  <wen-action class="wen-action-rounded"
              wenVariant="outlined"
              [isNavigating]="true"
              (click)="navigateToChannelDiscovery('clearFilter')">
    {{ 'CHANNEL_SHOW_ALL_BUTTON' | translate }}
  </wen-action>
</div>

<cdk-virtual-scroll-viewport class="wen-section-wrapper" itemSize="375" scrollStateHandler>
  @for (sectionData of sectionsData$ | async; track sectionData) {
    <wen-channel-section
      [dataSource]="sectionData.dataSource"
      [sectionName]="sectionData.name | translate"
      (showAll)="navigateToChannelDiscovery(sectionData.sectionProps)">
    </wen-channel-section>
  }
</cdk-virtual-scroll-viewport>
