import { WenNativeApi } from '@portal/wen-native-api';
import type * as SentryTypes from '@sentry/angular';
import { WenTracerConfig } from '../config-types';
import { GET_LAZY_MODULE } from '../util/module-loaders';
import { isRelevantBreadCrumb, isRelevantException } from './sentry-filters';
import { OfflineTransportOpts, sentryDefaultParams, SentryModuleType } from './sentry-utils';

const forceReRender = () => {
  requestAnimationFrame(() => {
    document.body.style.opacity = '0.9';
    requestAnimationFrame(() => {
      document.body.style.opacity = '1';
    });
  });
};

const setupAndroidWhiteScreenFix = (
  getReplay: () => ReturnType<SentryModuleType['getReplay']>,
  nativeApi: WenNativeApi
) => {
  let replyTimeoutId: number;
  document.addEventListener('visibilitychange', () => {
    if (nativeApi.isAndroid()) {
      const replayIntegration = getReplay();
      if (replayIntegration) {
        const isVisible = document.visibilityState === 'visible';
        clearTimeout(replyTimeoutId);
        if (isVisible) {
          forceReRender();
          replyTimeoutId = window.setTimeout(() => {
            replayIntegration.flush();
            replayIntegration.start();
          }, 5000);
        } else {
          replayIntegration.flush();
          replayIntegration.stop();
        }
      }
    }
  });
};

const createConfigWithDefaults = (
  sentryModule: SentryModuleType,
  config: WenTracerConfig
) => {
  if (!config) {
    return {};
  }
  const baseParams: SentryTypes.BrowserOptions = {
    release: config.version,
    environment: config.appEnv,
    dsn: config.dsn,
    sendDefaultPii: config.enableSensitiveDataSending
  };
  const integrations = [];
  const enableAll = config.sentryIntegrations === 'all';
  const integrationConfig = config.sentryIntegrations !== 'all' ? config.sentryIntegrations : {};
  if (enableAll || integrationConfig?.replayIntegration) {
    integrations.push(sentryModule.replayIntegration());
  }
  if (enableAll || integrationConfig?.browserTracingIntegration) {
    integrations.push(sentryModule.browserTracingIntegration());
  }
  if (enableAll || integrationConfig?.browserApiErrorsIntegration) {
    integrations.push(sentryModule.browserApiErrorsIntegration());
  }
  if (enableAll || integrationConfig?.browserProfilingIntegration) {
    integrations.push(sentryModule.browserProfilingIntegration());
  }
  const initParams = config.sentryParams || {};
  const cfg: SentryTypes.BrowserOptions = {
    ...baseParams,
    ...sentryDefaultParams,
    ...initParams,
    integrations
  };
  return cfg;
};

export const initSentry = async (
  config: WenTracerConfig,
  nativeApi: WenNativeApi,
) => {
  const SentryModule: SentryModuleType = await GET_LAZY_MODULE.sentry();
  const appConfig = createConfigWithDefaults(SentryModule, config);
  const isDisabled = () => {
    return SentryModule.getClient()?.getOptions()?.enabled === false;
  };

  const transport = SentryModule.makeBrowserOfflineTransport(SentryModule.makeFetchTransport);
  const transportOptions: Partial<OfflineTransportOpts> = {
    dbName: 'wen-sentry-offline',
    storeName: 'queue',
    maxQueueSize: 50,
    flushAtStartup: true,
    shouldStore: (envelope: any, error: Error, retryDelay: number) => {
      return true;
    }
  };
  SentryModule.init({
    ...appConfig,
    beforeSend: (event) => {
      if (isDisabled()) {
        return null;
      }
      if (!isRelevantException(event)) {
        return null;
      }
      return event;
    },
    beforeBreadcrumb(breadcrumb) {
      if (!isRelevantBreadCrumb(breadcrumb)) {
        return null;
      }
      return breadcrumb;
    },
    tracesSampler: () => {
      if (isDisabled()) {
        return 0.0;
      }
      return appConfig.tracesSampleRate;
    },
    transport,
    transportOptions
  });
  setupAndroidWhiteScreenFix(() => SentryModule.getReplay(), nativeApi);
  return Promise.resolve(SentryModule);
};
