import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WithVariantDirective } from '../../directives/public-api';
import { WenIconModule } from '../wen-icon/wen-icon.module';
import { LocationPreviewComponent } from './location-preview.component';
import { ActionComponent } from '../core/action/action.component';

@NgModule({
  imports: [
    CommonModule,
    WenIconModule,
    ActionComponent,
    WithVariantDirective
  ],
  declarations: [
    LocationPreviewComponent
  ],
  exports: [
    LocationPreviewComponent
  ],
})
export class LocationPreviewModule { }
