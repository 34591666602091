import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, first, map, Observable, of } from 'rxjs';
import { Filterable } from '../../../shared/components/filter-view/models/filter-item';
import { isNullOrUndefined } from '../../common/operators/null-check-util';
import { selectorWithParam } from '../../common/util/selector-with-param';
import { AllFilterIds, FilterEntityType, FilterType, SelectFilter } from '../filter/models/filter';
import { FilterHelperService } from '../filter/services/filter-helper.service';
import { RootState } from '../root/public-api';
import { fetchSectionContent } from './sections.actions';
import {
  selectSectionById,
  selectSectionContentById,
  selectSectionsByFilterEntityType
} from './sections.selectors';
import type { DataContext } from '../../common/types/data-context';
import { SectionEntity } from './sections.state';

export const SECTION_LIMIT = 10;

@Injectable({ providedIn: 'root' })
export class SectionService {

  constructor(
    private store: Store<RootState>,
    private filterHelper: FilterHelperService,
  ) { }

  getAvailableSectionsBy(filterEntityType: FilterEntityType) {
    return this.store.pipe(
      selectorWithParam(selectSectionsByFilterEntityType, filterEntityType),
      filter(value => !isNullOrUndefined(value) && value.length > 0),
      first()
    );
  }

  getSectionById(id: string): Observable<SectionEntity> {
    return this.store.pipe(
      selectorWithParam(selectSectionById, id),
      filter(value => !isNullOrUndefined(value)),
      first()
    );
  }

  getSectionWithDifferentTitle(translationKey: string, id?: string): Observable<SectionEntity | null> {
    if (!id) {
      return of(null);
    }

    return this.getSectionById(id).pipe(
      map((section: SectionEntity): SectionEntity => ({
        ...section,
        name: translationKey
      })),
    );
  }

  loadSectionContent(sectionId: string, filterRef: Filterable, dataContext: DataContext) {
    const filterElement = filterRef;
    const requestParams = this.toRequestParameters(filterElement, sectionId);
    this.store.dispatch(fetchSectionContent({ params: requestParams, sectionId, dataContext }));
    return this.store.pipe(
      selectorWithParam(selectSectionContentById, sectionId)
    );
  }

  private toRequestParameters(filterable: Filterable, sectionId) {
    const filterItem: SelectFilter = { filterId: sectionId as AllFilterIds, filterValue: filterable, filterType: FilterType.SELECT };
    const requestParams = this.filterHelper.convertFiltersToRequestParameters([filterItem]);
    requestParams.size = SECTION_LIMIT;
    return requestParams;
  }

}
