import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ReactionHandler } from '../providers/reaction-handler';

@Component({
  selector: 'wen-emoji',
  templateUrl: './emoji.component.html',
  styleUrls: ['./emoji.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmojiComponent {

  @Input() set id(value: number) {
    this.src$ = this.getEmoji(value);
  }

  @Input() selected: boolean;

  src$: Observable<SafeResourceUrl>;

  constructor(private reactionHandler: ReactionHandler) { }

  getEmoji(id: number): Observable<SafeResourceUrl> {
    return this.reactionHandler.getEmoji(id);
  }
}
