import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { ActionComponent, WenIconModule, WithVariantDirective } from '@portal/wen-components';
import { SearchInputComponent } from './search-input.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    WenIconModule,
    TranslateModule.forChild(),
    ActionComponent,
    WithVariantDirective
  ],
  declarations: [
    SearchInputComponent
  ],
  exports: [
    SearchInputComponent
  ]
})
export class SearchInputModule { }
