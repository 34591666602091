import { Injectable, inject } from '@angular/core';
import { RoomMemberData, SendToUsersEventPayload, SocketIoService, arrContainsAll, arrDiffAB } from '@portal/wen-backend-api';
import { Tracer } from '@portal/wen-tracer';
import { BehaviorSubject, Observable, filter, first, forkJoin, map, of, shareReplay, switchMap } from 'rxjs';
import { ChatGenericError } from '../../../error-types';
import { ChatTracer } from '../../../util/logging/chat-tracer';
import { WenChatClient } from '../../../wen-chat-client';
import { ShareKeyEncryptor } from './share-key-encryptor';

export abstract class SessionKeyShareServiceBase {

  abstract ensureSessionKeysAreShared(roomMemberDatas: RoomMemberData[]): Observable<boolean>;
}

@Injectable()
export class NonEncryptedSessionKeyShareService extends SessionKeyShareServiceBase {

  ensureSessionKeysAreShared(roomMemberDatas: RoomMemberData[]) {
    return of(true);
  }
}

@Injectable()
export class EncryptedSessionKeyShareService extends SessionKeyShareServiceBase {

  private sharedRoomSessionKeys = new BehaviorSubject<RoomMemberData[]>([]);
  private sharedRoomSessionKeys$ = this.sharedRoomSessionKeys.asObservable();

  private chatClient = inject(WenChatClient);
  private shareKeyEncryptor = inject(ShareKeyEncryptor);
  private socketIoService = inject(SocketIoService);
  private tracer = inject(Tracer);
  private chatTracer = inject(ChatTracer);

  ensureSessionKeysAreShared(roomMemberDatas: RoomMemberData[]) {
    const shareSessions$ = this.sharedRoomSessionKeys$.pipe(
      map((sharedOutboundGroupSessions) => {
        const sharedSessions = sharedOutboundGroupSessions.map(session => session.roomId);
        const targetRoomIds = roomMemberDatas.map(roomMemberData => roomMemberData.roomId);
        const toBeSharedRoomIds = arrDiffAB(sharedSessions, targetRoomIds);
        return toBeSharedRoomIds;
      }),
      first(),
      switchMap((toBeSharedRoomIds) => {
        const toBeSharedRooms = roomMemberDatas.filter(roomMemberData => toBeSharedRoomIds.includes(roomMemberData.roomId));
        this.chatTracer.addSessionKeyOutgoingHint('share_outbound_stats', {
          allNrOfRooms: roomMemberDatas.length,
          newNrOfRooms: toBeSharedRooms.length,
        });
        return this.shareSessionKeysForRooms(toBeSharedRooms);
      }),
      first(),
      shareReplay(1),
      switchMap(() => this.sharedRoomSessionKeys$.pipe(
        filter((sharedInboundGroupSessions) => {
          const sharedSessions = sharedInboundGroupSessions.map(session => session.roomId);
          const targetRoomIds = roomMemberDatas.map(roomMemberData => roomMemberData.roomId);
          return arrContainsAll(sharedSessions, targetRoomIds);
        }),
        first()
      ))
    );
    return shareSessions$.pipe(
      map(() => true)
    );
  }

  private shareSessionKeysForRooms(roomMemberDatas: RoomMemberData[]): Observable<RoomMemberData[]> {
    if (!roomMemberDatas.length) {
      return of([]);
    }
    const shares$ = roomMemberDatas.map((roomMemberData) => {
      const { roomId, memberUserIds } = roomMemberData;
      const validMemberIds = memberUserIds.filter(userId => Boolean(userId));
      if (!validMemberIds?.length) {
        this.tracer.captureException(new ChatGenericError(`Unable to share keys for a room ${roomId}: no members defined!`));
      }
      const outboundSession = this.chatClient.ensureOutboundGroupSession(roomId);
      this.chatTracer.addSessionKeyOutgoingHint('share_outbound_created', { roomId, memberUserIds: validMemberIds });
      return this.shareKeyEncryptor.encryptShareKeyEvent(outboundSession, roomId, validMemberIds).pipe(
        map((encryptionResult) => {
          const shareKeyPayloads = encryptionResult.asShareKeyPayload();
          return shareKeyPayloads;
        })
      );
    });
    return forkJoin(shares$).pipe(
      switchMap((encryptionResult) => {
        const shareKeyPayloads = encryptionResult.flat();
        const payload: SendToUsersEventPayload = {
          payloads: shareKeyPayloads
        };
        this.chatTracer.addSessionKeyOutgoingHint('share_outbound_sent', { nrOfPayloads: shareKeyPayloads.length });
        return this.socketIoService.chat.room.sendToUsers.acknowledgement$(payload).pipe(
          map(() => {
            const currentKeys = this.sharedRoomSessionKeys.getValue();
            this.sharedRoomSessionKeys.next([...currentKeys, ...roomMemberDatas]);
            return roomMemberDatas;
          })
        );
      })
    );
  }

}

@Injectable()
export class SessionKeyShareServiceProvider {

  private encryptedSessionKeyShareService = inject(EncryptedSessionKeyShareService);
  private nonEncryptedSessionKeyShareService = inject(NonEncryptedSessionKeyShareService);

  get(encryptedSendingEnabled: boolean) {
    if (encryptedSendingEnabled) {
      return this.encryptedSessionKeyShareService;
    }
    return this.nonEncryptedSessionKeyShareService;
  }

}
