import { Injectable } from '@angular/core';
import { ObjectFit, ThumbnailConfig, ThumbnailCustomizations } from '@portal/wen-components';

@Injectable()
export class ChatThumbnailCustomizations extends ThumbnailCustomizations {
  getConfig(): ThumbnailConfig {
    return {
      objectFit: ObjectFit.CONTAIN,
      maxHeight: 400,
      applyMaxWidth: true,
    };
  }
}
