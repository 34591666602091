import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, inject, Input, ViewEncapsulation } from '@angular/core';
import { EmbeddedLinkDTO, EmbeddedMediaDTO, EmbeddedPollDTO, EmbedDTOType, EmbedDTOTypes, QuoteData } from '@portal/wen-backend-api';
import { TranslateModule } from '@ngx-translate/core';
import { ColorizeModule } from '../../directives/colorize/colorize.module';
import { PaletteProvider } from '../../directives/colorize/palette-provider';
import { RemoveMarkdownModule } from '../../pipes/remove-markdown/remove-markdown.module';
import { EmbedDescriptionComponent } from '../embed-description/embed-description.component';
import { DeletedIndicatorComponent } from '../deleted-indicator/deleted-indicator.component';
import { MediaPreviewModule } from '../media-preview/media-preview.module';
import { ThumbnailImageModule } from '../thumbnail-image/thumbnail-image.module';
import { WenIconModule } from '../wen-icon/wen-icon.module';

@Component({
  selector: 'wen-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgStyle,
    RemoveMarkdownModule,
    ColorizeModule,
    EmbedDescriptionComponent,
    TranslateModule,
    DeletedIndicatorComponent,
    MediaPreviewModule,
    ThumbnailImageModule,
    WenIconModule
  ]
})
export class QuoteComponent {

  private palette = inject(PaletteProvider);
  private quoteData: QuoteData;
  borderColor: string;
  quoteMedia: EmbeddedMediaDTO;
  quoteLink: EmbeddedLinkDTO;
  quotePoll: EmbeddedPollDTO;

  get quote() {
    return this.quoteData;
  }

  @Input() set quote(value: QuoteData) {
    this.quoteData = value;
    this.evaluateEmbeds(value?.embeds);
    this.borderColor = this.setBorderColor(value);
  }

  @HostBinding('class.wen-quote') className = true;

  private setBorderColor(quoteData: QuoteData) {
    return this.palette.getColorFor(quoteData.author);
  }

  // TODO (for later): Handle thumbnails in a common way
  private evaluateEmbeds(embeds: EmbedDTOTypes[]) {
    if (!embeds || embeds?.length === 0) {
      this.quoteLink = null;
      this.quoteMedia = null;
      return;
    }
    const firstEmbed = embeds[0];
    switch (firstEmbed.type) {
      case EmbedDTOType.LINK:
        this.quoteLink = firstEmbed;
        return;
      case EmbedDTOType.MEDIA:
        this.quoteMedia = firstEmbed;
        return;
      case EmbedDTOType.POLL:
        this.quotePoll = firstEmbed;
        return;
      default:
        return;
    }
  }

}
