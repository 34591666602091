<img [src]="imageUrl" alt="" />

<h3 class="wen-create-welcome-title" [innerHTML]="translationKey + '.0' | translate" wen-header-huge></h3>

<wen-create-welcome-markdown class="wen-create-welcome-content" [content]="translationKey + '.1' | translate "></wen-create-welcome-markdown>
<wen-create-welcome-markdown class="wen-create-welcome-content" [content]="translationKey + '.2' | translate "></wen-create-welcome-markdown>
@if (!isPartnerApp) {
  <wen-create-welcome-markdown class="wen-create-welcome-content" [content]="translationKey + '.3' | translate "></wen-create-welcome-markdown>
}

<wen-action class="wen-create-welcome-button wen-action-rounded"
            wenVariant="filled"
            wen-test-id="wen-create-welcome-button"
            [isNavigating]="true"
            (click)="navigate()">
  {{ translationKey + '.4' | translate }}
</wen-action>
