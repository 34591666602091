@if (count$ | async; as count) {
  <wen-action iconName="comment_active" [isDisabled]="disabled"
      [attr.aria-label]="'CHANNEL_COMMENT_LABEL_WITH_COUNT' | translate: {count}"
      [isNavigating]="true"
      wenVariant="textSubtle"
      class="wen-action-no-padding">
    <span>{{ count }}</span>
  </wen-action>
} @else {
  <wen-action [isDisabled]="disabled || deactivated"
              [isNavigating]="true"
              wenVariant="textSubtle"
              class="wen-action-no-padding"
              iconName="comment_inactive">
    <span>{{ (deactivated ? "ADD_COMMENTS_DEACTIVATED_LABEL" : "ADD_COMMENTS_LABEL") | translate }}</span>
  </wen-action>
}

