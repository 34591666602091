import { inject } from '@angular/core';
import { TracerInitializer } from '@portal/wen-tracer';
import { catchError, firstValueFrom, map, of } from 'rxjs';
import { WenStorageService } from '../../../core/services/storage/wen-storage.service';

export function tracerAppInitializer() {
  const storageService = inject(WenStorageService);
  const isTracingEnabled = storageService.isTracingEnabled();
  if (!isTracingEnabled) {
    return () => Promise.resolve();
  }
  const tracerInitializer = inject(TracerInitializer);
  return () => {
    return firstValueFrom(tracerInitializer.enableSentry().pipe(
      map(() => true),
      catchError(() => of(true))
    ));
  };
}
