import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { SocketIoService } from '@portal/wen-backend-api';
import { first, map } from 'rxjs';
import { userProfileIdentifier } from '../../../user-profile/tokens';

export const redirectToChatRoomGuardFn: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const socketIoService = inject(SocketIoService);
  const router = inject(Router);
  const userId = route.paramMap.get(userProfileIdentifier);

  return socketIoService.chat.room.getDialogId.acknowledgement$({ userId }).pipe(
    first(),
    map(({ dialogId }) => {
      if (dialogId) {
        return router.createUrlTree(['/crm/chat', dialogId]);
      }
      return true;
    })
  );
};

