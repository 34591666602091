<div class="wen-profile-scroller" cdkScrollable>
  @if (headerData$ | async; as headerData) {
    <div class="wen-profile-header">
      <wen-detail-header [model]="headerData" [coverImageStyle]="'circle'" [allowLoadingIndicator]="true"
        [shouldAnimate]="false">
        <h2 wen-detail-header-title>{{ headerData?.title }}</h2>
        @if (showHint$ | async) {
          <div wen-detail-header-hint>
            {{ "CONTACTS_ACCEPT_RECEIVED_HINT_LABEL" | translate: {displayName: headerData?.title} }}
          </div>
        }
        @if (showActions$ | async) {
          <div wen-detail-header-additional-content>
            <ng-container *ngTemplateOutlet="connectionActions; context: { $implicit: profileData.profile$ | async }"></ng-container>
          </div>
        }
      </wen-detail-header>
    </div>
  }
  <div class="wen-profile-information-content-group">
    @if (additionalInformation$ | async; as additionalInformation) {
      <div class="wen-profile-information-field">
        <div portal-caption>{{ "PROFILE_FORM_ABOUT_ME_LABEL" | translate }}</div>
        <wen-expandable-container maxHeight="100">
          <wen-markdown-text portal-body [content]="additionalInformation" [hideDynamicContent]="true"></wen-markdown-text>
        </wen-expandable-container>
      </div>
    }
    @if (company$ | async; as company) {
      <div class="wen-profile-information-field">
        <div portal-caption>{{ "PROFILE_FORM_COMPANY_LABEL" | translate }}</div>
        <div portal-body>{{ company }}</div>
      </div>
    }
    @if (address$ | async; as address) {
      <div class="wen-profile-information-field">
        <div portal-caption>{{ "PROFILE_FORM_ADDRESS_LABEL" | translate }}</div>
        <div portal-body>{{ address }}</div>
      </div>
    }
    @if (sectionData$ | async; as sectionData) {
      <div class="wen-profile-information-field">
        <wen-channel-section
          [dataSource]="sectionData.dataSource"
          [sectionName]="'USER_PROFILE_CHANNEL_RECOMMENDATIONS_SECTION_LABEL' | translate: { userName: sectionData.name }"
          (showAll)="navigateToShowAllRecommendations(sectionData.userId)">
        </wen-channel-section>
      </div>
    }
  </div>
</div>

<ng-template #connectionActions let-profile>
  <div class="wen-other-profile-connection-additional-actions-wrapper">
    @if (enableContacts$ | async) {
      <wen-contact-response-action [profileData]="responseActionData$ | async"></wen-contact-response-action>
    }
    @if (conditions.canInitiateChat$ | async) {
      <wen-action class="wen-action-rounded" wenVariant="outlined"
        iconName="message_inactive" iconSize="big" (click)="actions.initiateChatWithUser(profile)">
        <span>{{ "CONTACTS_ACTION_SEND_MESSAGE" | translate }}</span>
      </wen-action>
    }
  </div>
</ng-template>
