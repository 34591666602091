import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { MessageSendFieldDataSource } from '../../providers/message-send-field-data-source';
import { Observable, map, shareReplay } from 'rxjs';
import { EmbeddedPollDTO } from '@portal/wen-backend-api';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'wen-poll-preview',
  templateUrl: './poll-preview.component.html',
  styleUrls: ['./poll-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PollPreviewComponent implements OnInit {

  @Input() previewSize: 'mini' | 'fit' = 'fit';

  @HostBinding('class.wen-poll-preview-mini') get miniStyle() { return this.previewSize === 'mini'; }
  @HostBinding('class.wen-poll-preview-fit') get fitStyle() { return this.previewSize === 'fit'; }

  pollEmbed$: Observable<EmbeddedPollDTO>;
  pollPreviewAltText = '';

  constructor(
    private readonly dataSource: MessageSendFieldDataSource,
    private readonly translateService: TranslateService
  ){}

  ngOnInit() {
    this.pollEmbed$ = this.dataSource.draftMessage$.pipe(
      map((message) => message?.pollEmbed),
      shareReplay(1)
    );
    const pollTranslation = this.translateService.instant('CONTEXT_MENU_ACTION_POLL_LABEL');
    this.pollPreviewAltText = this.translateService.instant('REMOVE_LABEL', { itemName: pollTranslation });
  }

  @Output() dismiss = new EventEmitter();

  emitDismiss() {
    this.dataSource.updateDraftMessage({ pollEmbed: null });
  }
}
