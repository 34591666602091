<div class="channel-container">
  <div class="channel-messages">
    @for (id of skeletonElements; track id) {
      <div class="channel-message skeleton">
        <div class="channel-message-author">
          <div class="circle"></div>
          <div class="line"></div>
        </div>
        <div class="channel-message-text">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
        <div class="channel-message-embed"></div>
        <div class="channel-message-interaction"> 
          <div class="channel-comments line"></div>
          <div class="channel-buttons">
            <div class="circle"></div>
            <div class="circle"></div>
          </div> 
        </div> 
      </div>
    }
  </div>
  <div class="channel-bottom-controls"></div>
</div>