import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MessageModificationState, PagingReplayDirection, SocketIoService } from '@portal/wen-backend-api';
import { uniqueArrayByKey } from '@portal/wen-common';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { combineLatest, distinctUntilChanged, exhaustMap, filter, first, map, mergeMap, Observable, of, Subject, switchMap, takeUntil, withLatestFrom } from 'rxjs';
import { ChatMessageEmbedResolver } from '../../../../core/services/chat/chat-message-embed-resolver';
import { DecryptedEventModifier } from '../../../../core/services/chat/decryption/decrypted-event-modifier';
import { MessageEventDecryptor } from '../../../../core/services/chat/decryption/message-event-decryptor';
import { isRealtimeUpdate } from '../../../../core/services/chat/message-event/message-event-helper';
import { WenOAuthService } from '../../../../core/services/user-management/wen-oauth.service';
import { UserData } from '../../../../core/store/auth/models/UserData';
import { fetchHistoricalMessagesForRoom } from '../../../../core/store/chat/actions/chat-history.actions';
import { selectCurrentAutoReadAcknowledgeRoomId, selectCurrentRoom, selectCurrentRoomMessagesHistoryFlags } from '../../../../core/store/chat/chat.selectors';
import { ChatMessageEntity, ChatMessageEntityContent, ChatRoomEntity } from '../../../../core/store/chat/chat.state';
import { ChatMessageOperations } from '../../../../core/store/chat/operations/chat-message-operations';
import { filterRoomEvents, isGroupChatRoom } from '../../../../core/store/chat/utils/chat-room-utils';
import { RootState } from '../../../../core/store/root/public-api';
import { PageRequestEvent, WeFeedDataset, WeFeedItemType, WeFeedMessageItem } from '../../../../shared/components/feed/components/we-feed/we-feed-model';
import { FeedDatasource } from '../../../../shared/components/feed/providers/feed-datasource';
import { calculateChatMessageStatus } from '../../../../shared/components/message-status-indicator/util/chat-message-status-calculator';
import { mapReplayDirectionToStateFlags } from '../../../../shared/components/paginated-scrollview/replay-direction-mapper';
import { MessagesDatasource } from '../../../../shared/services/messages.datasource';
import { ChatViewDatasource } from './chat-view-datasource';


@Injectable()
export class ChatFeedDatasource extends FeedDatasource implements OnDestroy {

  private onDestroy$ = new Subject<void>();

  private readonly chatMessagesHistory$ = this.socketIoService.chat.room.messagesReplay.listen.pipe(
    mergeMap((response) => {
      const { events } = response;
      const [encryptedEvents, plainEvents] = filterRoomEvents(events);
      return this.messageEventDecryptor.decryptMessageEvents(encryptedEvents).pipe(
        map(decryptedResults => {
          const decryptedStoreEntities = this.decryptedEventModifier.toMessageEntities(decryptedResults);
          const filtereddecryptedStoreEntities = uniqueArrayByKey(decryptedStoreEntities, 'eventId');
          const filteredPlainEvents = plainEvents.filter(event => !isRealtimeUpdate(event));
          const plainStoreEntities = this.decryptedEventModifier.toMessageEntities(filteredPlainEvents);
          const entities = filtereddecryptedStoreEntities.concat(plainStoreEntities);
          return {
            entities,
            originalResponse: response
          };
        })
      );
    })
  );

  constructor(
    protected store: Store<RootState>,
    private socketIoService: SocketIoService,
    private oAuthService: WenOAuthService,
    private chatViewDatasource: ChatViewDatasource,
    private messageDatasource: MessagesDatasource<ChatMessageEntity>,
    private messageEventDecryptor: MessageEventDecryptor,
    private chatMessageOperations: ChatMessageOperations,
    private decryptedEventModifier: DecryptedEventModifier,
    private chatMessageEmbedResolver: ChatMessageEmbedResolver,
  ) {
    super(store);
  }

  bindToSource() {
    return combineLatest([
      this.messageDatasource.messages$,
      this.chatViewDatasource.currentRoom$,
      this.messageDatasource.lastUnreadMessage$,
      this.store.pipe(select(selectCurrentRoomMessagesHistoryFlags)).pipe(
        smartDistinctUntilChanged()
      ),
      this.currentUser$,
      this.store.pipe(select(selectCurrentAutoReadAcknowledgeRoomId)).pipe(
        distinctUntilChanged()
      ),
    ]).pipe(
      switchMap(([messages, currentRoom, lastUnreadMessage, flags, currentUser, autoReadAckRoomId]) => {
        if (!messages?.length) {
          return of({ messages, currentRoom, lastUnreadMessage, flags, currentUser, autoReadAckRoomId });
        }
        const updatedMessages$: Observable<ChatMessageEntity>[] = this.chatMessageEmbedResolver.resolveEmbeds(messages);
        return combineLatest(updatedMessages$).pipe(
          map(updatedMessages => ({ messages: updatedMessages, currentRoom, lastUnreadMessage, flags, currentUser, autoReadAckRoomId }))
        );
      }),
      map(({ messages, currentRoom, lastUnreadMessage, flags, currentUser, autoReadAckRoomId }) => {
        if (!messages?.length) {
          return {
            items: []
          };
        }
        const items = messages.map((message) => {
          const feedItem = this.convertToFeedItem(message, currentRoom, currentUser);
          return feedItem;
        });
        const newMessageLineItemId = autoReadAckRoomId ? null : lastUnreadMessage?.eventId;
        const dataSet: WeFeedDataset = {
          newMessageLineItemId,
          items,
          hasMoreOlder: flags?.hasMoreOlder,
        };
        return dataSet;
      }),
      takeUntil(this.onDestroy$)
    );
  }

  loadNextPage(event: PageRequestEvent): Observable<any> {
    return combineLatest([
      this.messageDatasource.messages$,
      this.store.pipe(select(selectCurrentRoomMessagesHistoryFlags)),
    ]).pipe(
      first(),
      filter(([_, historyFlags]) => {
        const { direction } = event;
        if (direction === PagingReplayDirection.Up && historyFlags.hasMoreOlder === false) {
          return false;
        }
        if (direction === PagingReplayDirection.Down && !historyFlags.hasMoreNewer) {
          return false;
        }
        return true;
      }),
      filter(([currentMessages]) => {
        return currentMessages?.length > 0;
      }),
      exhaustMap(([currentMessages]) => {
        const { direction } = event;
        let firstMessage: ChatMessageEntity;
        if (direction === PagingReplayDirection.Up) {
          firstMessage = currentMessages[0];
        } else {
          firstMessage = currentMessages[currentMessages.length - 1];
        }

        this.store.dispatch(fetchHistoricalMessagesForRoom({
          roomId: firstMessage.encryptionData.roomId,
          timestamp: firstMessage.insertTimestamp,
          direction
        }));
        return this.waitForNextPage().pipe(
          map((response) => ({ response })),
          first()
        );
      }),
      map(({ response }) => {
        return {
          hasResult: Boolean(response?.hasResult)
        };
      })
    );
  }

  loadFirstPage() {
    return this.store.pipe(
      select(selectCurrentRoom),
      first(),
      switchMap((currentRoom) => {
        const { history } = currentRoom;
        if (!history || history.hasMoreNewer) {
          this.store.dispatch(fetchHistoricalMessagesForRoom({
            roomId: currentRoom.id,
            timestamp: null,
            direction: PagingReplayDirection.Down
          }));
          return this.waitForNextPage();
        }
        return of({
          hasResult: false
        });
      })
    );
  }

  private waitForNextPage() {
    return this.chatMessagesHistory$.pipe(
      withLatestFrom(this.store.pipe(
        select(selectCurrentRoom)
      )),
      map(([res, currentRoom]) => {
        const { entities, originalResponse } = res;
        const flags = mapReplayDirectionToStateFlags(originalResponse.direction, originalResponse.more);
        const action = this.chatMessageOperations.upsertMany(currentRoom.id, entities, flags);
        this.store.dispatch(action);
        return {
          hasResult: entities?.length > 0
        };
      })
    );
  }

  private convertToFeedItem(message: ChatMessageEntity, currentRoom: ChatRoomEntity, currentUser: UserData) {
    const {
      eventId,
      messageContent,
      insertUser,
      decryptionError,
      state
    } = message;

    const messageContentOrDeletedContent: ChatMessageEntityContent = state !== MessageModificationState.DELETED ? messageContent : {
      userId: insertUser.id,
      content: null,
      embeds: [],
    };
    const { userId, content, embeds } = messageContentOrDeletedContent;
    const isOwn = this.oAuthService.isCurrentUserId(userId);
    const isGroupChat = isGroupChatRoom(currentRoom);
    const status = messageContent ? calculateChatMessageStatus(message, currentRoom) : null;
    const feedItem: WeFeedMessageItem = {
      key: eventId,
      type: WeFeedItemType.MESSAGEBOX,
      value: {
        state,
        referenceId: message.eventId,
        messageId: eventId,
        authorId: userId,
        sender: insertUser?.name,
        senderVisible: isGroupChat,
        currentUserUpdater: currentUser.userId === insertUser.id,
        content,
        scheduled: false,
        embeds,
        timestamp: message.insertTimestamp,
        shareVisible: false,
        commentsVisible: false,
        commentsEnabled: false,
        highlighted: isOwn,
        wide: false,
        disableEmojiReaction: state === MessageModificationState.DELETED,
        hideEmojiReactionPlaceholder: true,
        status,
        modificationState: state,
        contentTemplateData: decryptionError,
        textToSpeechEnabled: false,
        eventType: message.encryptionData?.encryptedMessage?.eventType ?? message?.messageContent?.eventType,
        challengeEnabled: false,
        disableFooterFunctions: true,
      },
    };
    return feedItem;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
