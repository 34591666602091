import { ACTIVE_ITEM_IMAGE_COMMAND_TOKEN } from '../../../../shared/components/active-item-image-view/active-item-image-view-providers';
import { DataObjectType } from '../../common/data-objects';
import { CHAT_MENU_COMPONENT } from '../menu/constants';
import { HeaderCommand, HeaderCommandType, SaveHeaderCommand } from './HeaderCommand';
import { ActionVariant } from '@portal/wen-components';

export const createBackNavigationCommand = (): HeaderCommand => {
  return {
    type: HeaderCommandType.CANCEL_BACK_NAVIGATION,
    icon: 'chevron_left',
    title: 'BUTTON_BACK_LABEL',
    isNavigating: true
  };
};

export const createExitEmbeddedAppNavigationCommand = (): HeaderCommand => {
  return {
    type: HeaderCommandType.EXIT_EMBEDDED_APP_BACK_NAVIGATION,
    icon: null
  };
};

export const createCloseDialogCommand = (): HeaderCommand => {
  return {
    type: HeaderCommandType.CLOSE_DIALOG,
    icon: 'chevron_left',
    title: 'BUTTON_BACK_LABEL'
  };
};

export const createCancelCommand = (): HeaderCommand => {
  return {
    type: HeaderCommandType.CANCEL_BACK_NAVIGATION,
    title: 'CANCEL_BUTTON_LABEL'
  };
};

export const createSaveCommand = (dataObjectType: DataObjectType): HeaderCommand => {
  let title: string;
  switch (dataObjectType) {
    case DataObjectType.CHANNEL_POST:
      title = 'CHANNEL_POST_EDITOR_SAVE_BUTTON_LABEL';
      break;
    case DataObjectType.CHAT_MEMBERS:
      title = 'GROUP_CHAT_ADD_MEMBERS_HEADER_BUTTON';
      break;
    case DataObjectType.USER_PASSWORD:
      title = 'USER_PROFILE_PASSWORD_EDIT_FORM_CONTINUE_HEADER_LABEL';
      break;
    default:
      title = 'SAVE_BUTTON_LABEL';
  }
  return {
    type: HeaderCommandType.SAVE_BACK_NAVIGATION,
    title,
    dataObjectType
  };
};

export const createCloseProfileNavigationCommand = (): HeaderCommand => {
  return {
    type: HeaderCommandType.CLOSE_PROFILE_NAVIGATION,
    icon: 'close',
    altTextKey: 'NAVIGATION_CLOSE_MENU_LABEL',
    isNavigating: true,
    variant: ActionVariant.textSubtle
  };
};

export const createUserProfileNavigationCommand = (): HeaderCommand => {
  return {
    type: HeaderCommandType.OPEN_USER_PROFILE,
    icon: 'menu',
    altTextKey: 'NAVIGATION_OPEN_MENU_LABEL',
    isNavigating: true,
    variant: ActionVariant.textSubtle
  };
};

export const createChannelCreateCommand = (): HeaderCommand => {
  return {
    type: HeaderCommandType.CHANNEL_ADD_WELCOME,
    icon: 'add',
    altTextKey: 'CREATE_CHANNEL_WELCOME',
    isNavigating: true
  };
};

export const createAppCreateCommand = (): HeaderCommand => {
  return {
    type: HeaderCommandType.APP_ADD_WELCOME,
    icon: 'add',
    altTextKey: 'CREATE_APP_WIZARD_FORM_TITLE',
    isNavigating: true
  };
};

export const createAddNewChatCommand = (): HeaderCommand => {
  return {
    type: HeaderCommandType.CHAT_ADD_NEW,
    icon: 'add',
    altTextKey: 'CREATE_CHAT_OR_GROUP_LABEL'
  };
};

export const createForwardInWizardCommand = (type: HeaderCommandType): HeaderCommand => {
  return {
    type,
    title: 'NEXT_IN_WIZARD_BUTTON_LABEL',
  };
};

export const createFinishWizardCommand = (dataObjectType: DataObjectType): SaveHeaderCommand<HeaderCommandType.FINISH_CREATE> => {
  let title: string;
  switch (dataObjectType) {
    case DataObjectType.INVITE_TO_CHANNEL:
      title = 'INVITE_COMMAND_LABEL';
      break;
    case DataObjectType.USER_PASSWORD:
      title = 'FINISH_WIZARD_BUTTON_LABEL_DONE';
      break;
    default:
      title = 'FINISH_WIZARD_BUTTON_LABEL';
  }
  return {
    type: HeaderCommandType.FINISH_CREATE,
    title,
    dataObjectType
  };
};

export const createInviteToNetworkCommand = (): HeaderCommand => {
  return {
    type: HeaderCommandType.INVITE,
    title: 'INVITE_COMMAND_LABEL',
    dataObjectType: DataObjectType.INVITE_TO_NETWORK
  };
};

export const createNewChatMenu = (): HeaderCommand => {
  return {
    type: HeaderCommandType.CHAT_MENU,
    componentToken: CHAT_MENU_COMPONENT,
    icon: 'add',
    altTextKey: 'CREATE_CHAT_OR_GROUP_LABEL'
  };
};

export const createCrmProfileReadOnlyViewCommand = (): HeaderCommand => {
  return {
    type: HeaderCommandType.CRM_PROFILE_READ_ONLY_VIEW,
    componentToken: ACTIVE_ITEM_IMAGE_COMMAND_TOKEN
  };
};

export const createCrmPauseMassChatSendingCommand = (): HeaderCommand => {
  return {
    type: HeaderCommandType.CRM_PAUSE_MASS_CHAT_SENDING,
    title: 'CANCEL_BUTTON_LABEL'
  };
};
