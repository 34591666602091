import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { UserProfileResponse } from '@portal/wen-backend-api';
import { merge, Observable, Subject, of } from 'rxjs';
import { filter, first, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { FeatureEnablementService } from '../../../../../core/services/configuration/feature-enablement';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { logout } from '../../../../../core/store/auth/auth.actions';
import { RootState } from '../../../../../core/store/root/public-api';
import { selectProfileImageState, selectUserProfile } from '../../../../../core/store/user/user.selectors';
import { ProfileImageState } from '../../../../../core/store/user/user.state';
import { DetailHeaderModel } from '../../../../../shared/components/detail-header/detail-header.component';
import { EmailVerificationParamWatcher } from '../../../../../shared/feature-specific/email-verification/verification-param-watcher';
import { ConfirmationDialogComponent, OverlayManager } from '@portal/wen-components';
import { TranslateService } from '@ngx-translate/core';
import { ColorPalette } from '../../../../../shared/directives/colorize/palette-provider';
import { AppNavigator } from '../../../../../core/services/navigation/app-navigator';
import { WenRouteId } from '../../../../../frame/routing/types';
import { LocationFormatterPipe } from '../../../../../shared/pipes/location-formatter.pipe';

@Component({
  selector: 'wen-profile-read-view',
  templateUrl: './profile-read-view.component.html',
  styleUrls: ['./profile-read-view.component.scss'],
  providers: [
    EmailVerificationParamWatcher,
    LocationFormatterPipe
  ]
})
export class ProfileReadViewComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();

  @HostBinding('class.wen-profile-read-view') className = true;
  lottieOptions = {
    path: '/assets/wen-widget/lottie/PhotoLoading.json',
  };
  headerData$: Observable<DetailHeaderModel>;
  userProfile$: Observable<UserProfileResponse>;
  pendingProfileImageText$: Observable<string>;
  isAboutYouFieldEnabled$: Observable<boolean>;
  isProfileExtensionsEnabled$: Observable<boolean>;
  isChannelRecommendationsEnabled$: Observable<boolean>;
  isWalletEnabled$: Observable<boolean>;
  isAutoReplyEnabled$: Observable<boolean>;

  userProfileSettingsIcon = 'user_settings';
  userProfileSettingsFillColor = ColorPalette.LIGHT_BLUE2;
  userSubscriptionsIcon = 'user_subscriptions';
  userSubscriptionsFillColor = ColorPalette.GREEN;
  userAutoReplyIcon = 'auto_reply';
  userAutoReplyFillColor = ColorPalette.ORANGE;

  constructor(
    private store: Store<RootState>,
    private readonly navigationHelper: WenNavigationHelper,
    private readonly appNavigator: AppNavigator,
    private readonly emailVerificationParamWatcher: EmailVerificationParamWatcher,
    public readonly featureEnablementService: FeatureEnablementService,
    private overlayManager: OverlayManager,
    private translateService: TranslateService,
    private locationFormatterPipe: LocationFormatterPipe
  ) { }

  ngOnInit(): void {
    this.userProfile$ = this.store.pipe(
      select(selectUserProfile),
      shareReplay(1),
    );
    const profileImageState$ = this.store.pipe(
      select(selectProfileImageState),
      shareReplay(1),
    );

    this.isAutoReplyEnabled$ = this.featureEnablementService.featureConfigObs.enableChatAutoReply$;
    this.isWalletEnabled$ = this.featureEnablementService.featureConfigObs.enableWallet$.pipe(first());

    const headerDataOnProfileChange$ = this.userProfile$.pipe(
      switchMap((profile) => profileImageState$.pipe(
        first(),
        map(profileImageState => {
          return {
            title: profile?.displayname,
            imageUrl: profile?.avatarUrl,
            pendingImage: profileImageState === ProfileImageState.PENDING_SAFE || profileImageState === ProfileImageState.PENDING_UNSAFE
          };
        })
      )),
      takeUntil(this.onDestroy$)
    );

    const headerDataOnProfileImageError$ = profileImageState$.pipe(
      filter(profileImageState => profileImageState === ProfileImageState.ERROR),
      switchMap(() => this.userProfile$.pipe(
        first(),
        map(profile => {
          return {
            title: profile?.displayname,
            imageUrl: profile?.avatarUrl,
            pendingImage: false
          };
        })
      )),
      takeUntil(this.onDestroy$)
    );

    this.headerData$ = merge(
      headerDataOnProfileChange$,
      headerDataOnProfileImageError$
    );

    this.pendingProfileImageText$ = profileImageState$.pipe(
      map(profileImageState => {
        return profileImageState === ProfileImageState.PENDING_SAFE ? 'SAFE_PENDING_MEDIA_UPLOAD_TEXT' : 'UNSAFE_PENDING_MEDIA_UPLOAD_TEXT';
      })
    );

    this.isAboutYouFieldEnabled$ = of(this.featureEnablementService.featureFlagMethods.isEnableAboutMeField());
    this.isProfileExtensionsEnabled$ = of(this.featureEnablementService.featureFlagMethods.isEnableProfileExtensions());
    this.isChannelRecommendationsEnabled$ = of(this.featureEnablementService.featureFlagMethods.isEnableChannelRecommendations());
    this.emailVerificationParamWatcher.initialize();
  }

  navigateToProfileEdit() {
    this.navigationHelper.navigateToUserProfileEdit();
  }


  navigateToChannelRecommendations() {
    this.navigationHelper.navigateToUserChannelRecommendations();
  }

  navigateToAutoReply() {
    this.navigationHelper.navigateToAutoReplyEdit();
  }

  logout() {
    if(this.featureEnablementService.isChatEnabled()) {
      this.overlayManager.dialog.openConfirmationDialog(ConfirmationDialogComponent, {
        header: this.translateService.instant('LOGOUT_DIALOG_HEADER'),
        content: this.translateService.instant('LOGOUT_DIALOG_CONTENT'),
        okLabel: this.translateService.instant('BUTTON_LOGOUT_LABEL'),
        dismissLabel: this.translateService.instant('CANCEL_BUTTON_LABEL')
      }).afterClosed().pipe(
        first(),
        map(result => {
          return result?.result === 'ok';
        }),
        filter(isAccepted => isAccepted)
      ).subscribe(() => {
        this.store.dispatch(logout({ isByUserInteraction: true }));
      });
    } else {
      this.store.dispatch(logout({ isByUserInteraction: true }));
    }
  }

  navigateToUserProfileSettings() {
    this.navigationHelper.navigateToUserProfileSettings();
  }

  navigateToUserProfilePersonalAccountSettings() {
    this.appNavigator.navigateToRoute(WenRouteId.USERPROFILE_PERSONAL_ACCOUNT_SETTINGS);
  }

  navigateToWalletDetails() {
    this.navigationHelper.navigateToUserCredentialsMenu();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
