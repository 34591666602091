import { Injectable } from '@angular/core';
import { NavigatorUtils } from '@portal/wen-common';
import { WenNativeApi } from '@portal/wen-native-api';
import { distinctUntilChanged, filter, map, shareReplay, startWith } from 'rxjs';
import { ConnectionMonitor, ConnectionScope } from './types';

@Injectable()
export class NativeConnectionMonitor implements ConnectionMonitor {

  public readonly connectionState$ = this.nativeApi.connectivityChange$.pipe(
    filter(value => value !== null),
    startWith(NavigatorUtils.isOnline()),
    distinctUntilChanged(),
    shareReplay(1),
    map(isOnline => {
      return {
        isOnline, scope: ConnectionScope.INTERNET_CONNECTION
      };
    })
  );

  constructor(private nativeApi: WenNativeApi) { }

}
