import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { PagingReplayDirection } from '@portal/wen-backend-api';
import { Observable, combineLatest, exhaustMap, filter, first, map, of, switchMap } from 'rxjs';
import { DateUtil } from '../../../../core/common/date/date-util';
import { ChatMessageEmbedResolver } from '../../../../core/services/chat/chat-message-embed-resolver';
import { UserData } from '../../../../core/store/auth/models/UserData';
import { fetchScheduledChatMessages, fetchScheduledChatMessagesComplete } from '../../../../core/store/chat/actions/chat-scheduled-messages.actions';
import { selectCurrentRoom } from '../../../../core/store/chat/chat.selectors';
import { ChatRoomEntity, ChatScheduledMessageEntity } from '../../../../core/store/chat/chat.state';
import { selectCurrentRoomScheduledChatMessages, selectScheduledChatMessageCollectionExtrasForCurrentRoom } from '../../../../core/store/chat/selectors/chat-scheduled-selectors';
import { RootState } from '../../../../core/store/root/public-api';
import { PageRequestEvent, WeFeedDataset, WeFeedItemType, WeFeedMessageItem } from '../../../../shared/components/feed/components/we-feed/we-feed-model';
import { FeedDatasource } from '../../../../shared/components/feed/providers/feed-datasource';

@Injectable()
export class ChatScheduledMessagesFeedDatasource extends FeedDatasource {

  constructor(
    protected store: Store<RootState>,
    private chatMessageEmbedResolver: ChatMessageEmbedResolver,
    private actions$: Actions,
  ) {
    super(store);
  }

  bindToSource(): Observable<WeFeedDataset> {
    const scheduledMessages$ = this.store.pipe(
      select(selectCurrentRoomScheduledChatMessages),
      switchMap(messages => {
        const updatedMessages$ = this.chatMessageEmbedResolver.resolveEmbeds(messages);
        return combineLatest(updatedMessages$);
      }),
    );
    return combineLatest([
      scheduledMessages$,
      this.store.pipe(select(selectCurrentRoom)),
      this.currentUser$,
    ]).pipe(
      map(([messages, currentRoom, currentUser]) => {
        const sortedMessages = messages.sort((message1, message2) => DateUtil.compare(message2.scheduledFor, message1.scheduledFor));
        const feedItems = sortedMessages.map(message => {
          return this.convertToFeedItem(message, currentRoom, currentUser);
        });
        const dataSet: WeFeedDataset = {
          items: feedItems
        };
        return dataSet;
      })
    );
  }

  loadNextPage(event: PageRequestEvent): Observable<{ hasResult: boolean }> {
    return combineLatest([
      this.store.pipe(select(selectScheduledChatMessageCollectionExtrasForCurrentRoom)),
      this.store.pipe(select(selectCurrentRoomScheduledChatMessages)),
    ]).pipe(
      filter(([flags, scheduledMessages]) => {
        return flags.hasMoreNewer && scheduledMessages?.length > 0;
      }),
      first(),
      exhaustMap(([_, scheduledMessages]) => {
        const { direction } = event;
        const firstMessage = (direction === PagingReplayDirection.Up) ?
          scheduledMessages[0] : scheduledMessages[scheduledMessages.length - 1];
        this.store.dispatch(fetchScheduledChatMessages({
          payload: {
            roomId: firstMessage.encryptionData.roomId,
            scheduledFor: firstMessage.scheduledFor,
            direction: PagingReplayDirection.Down
          }
        }));
        return this.actions$.pipe(
          ofType(fetchScheduledChatMessagesComplete),
          first()
        );
      }),
      map(({ hasResult }) => {
        return {
          hasResult
        };
      })
    );
  }

  loadFirstPage(): Observable<{ hasResult: boolean }> {
    return of({ hasResult: false });
  }

  private convertToFeedItem(message: ChatScheduledMessageEntity, currentRoom: ChatRoomEntity, currentUser: UserData) {
    const {
      eventId,
      messageContent,
      insertUser,
      scheduledFor,
      decryptionError
    } = message;

    const { userId, content, embeds } = messageContent;
    const feedItem: WeFeedMessageItem = {
      key: eventId,
      type: WeFeedItemType.MESSAGEBOX,
      value: {
        state: message.state,
        referenceId: eventId,
        messageId: eventId,
        authorId: userId,
        sender: insertUser?.name || '',
        senderVisible: true,
        currentUserUpdater: currentUser.userId === insertUser.id,
        content,
        scheduled: true,
        embeds,
        timestamp: scheduledFor,
        shareVisible: false,
        commentsVisible: false,
        commentsEnabled: false,
        highlighted: true,
        wide: false,
        disableEmojiReaction: true,
        hideEmojiReactionPlaceholder: true,
        status: null,
        modificationState: null,
        contentTemplateData: decryptionError,
        textToSpeechEnabled: false,
        disableFooterFunctions: true,
      },
    };
    return feedItem;
  }

}
