@if (thumbnailParams) {
  <img alt="" class="wen-thumbnail-image"
    [ngClass]="{'wen-rounded-thumbnail-image': roundedEdges}"
    [wenLazyload]="thumbnailParams"
    [wenLazyloadFixWidth]="fixWidth"
    [wenLazyloadFixHeight]="fixHeight"
    [wenLazyloadDisableScaling]="disableScaling"
    (click)="onClick()"
    wenImgFallback
  />
  }
