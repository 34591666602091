import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SocketIoService } from '@portal/wen-backend-api';
import { Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { reloadDiscoverAppList } from '../apps.actions';
import { Filterable } from '../../../../shared/components/filter-view/models/filter-item';

export const createReloadDiscoverAppListEffect = (
  actions$: Actions,
  currentFilter: Observable<Filterable>,
  socketIoService: Pick<SocketIoService, 'app'>
) => {
  return createEffect(() => actions$.pipe(
    ofType(reloadDiscoverAppList),
    switchMap(() => currentFilter.pipe(first())),
    map(filter => {
      socketIoService.app.listForDiscover.emit({ ...filter });
    })
  ), { dispatch: false });
};

export const createUpdateDiscoverAppListOnFilterUpdateEffect = (
  currentFilter: Observable<Filterable>,
  socketIoService: Pick<SocketIoService, 'app'>
) => {
  return createEffect(() => currentFilter.pipe(
    map((filter) => {
      socketIoService.app.listForDiscover.emit({ ...filter });
    })
  ), { dispatch: false });
};
