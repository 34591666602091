import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnDestroy, OnInit, Optional, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SelectionOption, SelectionOptionGroup } from '@portal/wen-components';
import { Observable, ReplaySubject, Subject, combineLatest, first, map, takeUntil } from 'rxjs';
import { WenTranslationKeys } from '@portal/wen-translation';
import { firstExisty } from '../../../../../core/common/operators/first-existy';
import { groupBy } from '../../../../../core/common/util/group-by';
import { Categorized, FilterCategory, Filterable, hasCategory } from '../../models/filter-item';
import { CUSTOM_FILTER_VIEW, CustomFilterView } from './custom-filter-view';
import { FilterSelectorResponse } from './filter-selector-response';

export const toSelectionOptions = (items: Filterable[]): SelectionOption<string>[] => {
  return items.map(item => {
    return {
      id: item?.id,
      text: item.name,
      value: item.name
    };
  });
};

export type FilterSelectorData = {
  visibleFilters: Categorized<Filterable>[];
  activeFilterIds: string[];
  headerTitle?: WenTranslationKeys;
  isMultiselect?: boolean;
  multiSelectorLabelTranslationKey?: WenTranslationKeys;
};

@Component({
  selector: 'wen-filter-selector',
  templateUrl: './filter-selector.component.html',
  styleUrls: ['./filter-selector.component.scss']
})
export class FilterSelectorComponent implements OnInit, OnDestroy {

  private userSelection$ = new Subject<string[]>();
  private onDestroy$ = new Subject<void>();

  filterSelectorHeader: WenTranslationKeys;

  private possibleFilterItems$ = new ReplaySubject<(SelectionOption<string> | SelectionOptionGroup<string>)[]>(1);
  private filterSelectionModel$ = new ReplaySubject<SelectionModel<Partial<SelectionOption<string>>>>(1);
  filterSelectionListData$ = new Observable<{
    items: typeof this.possibleCategoryItems$;
    model: typeof this.categorySelectionModel$;
  }>();

  @ViewChild('default', { read: TemplateRef, static: true }) defaultForm: TemplateRef<any>;
  @ViewChild('containerRef', { read: ViewContainerRef, static: true }) containerRef: ViewContainerRef;

  isMultiSelection: boolean;
  selectedIds: string[];

  get hasSelectedFilters(): boolean {
    return Array.isArray(this.selectedIds) && this.selectedIds?.some(Boolean);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: FilterSelectorData,
    private dialogRef: MatDialogRef<FilterSelectorComponent, FilterSelectorResponse>,
    @Optional() @Inject(CUSTOM_FILTER_VIEW) private customFilterViews: CustomFilterView[]
  ) {
    this.filterSelectorHeader = dialogData?.headerTitle || 'CATEGORY_FILTER_HEADER';
  }

  ngOnInit(): void {
    this.initialize();
  }

  onSelection(selectedIds: string[]) {
    this.userSelection$.next(selectedIds);
  }

  onApply() {
    this.dialogRef.close({ selectedIds: this.selectedIds });
  }

  onReset() {
    this.selectedIds = [];

    this.filterSelectionModel$.pipe(first()).subscribe((model) => {
      model.clear();
      this.filterSelectionModel$.next(model);
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  private showDefaultForm() {
    this.containerRef.clear();
    this.containerRef.createEmbeddedView(this.defaultForm);
  }

  private showCustomFilterView(customView: CustomFilterView, selectedIds: string[]) {
    this.containerRef.clear();
    const compRef = this.containerRef.createComponent(customView.view);
    compRef.instance.cancel.pipe(
      first()
    ).subscribe(() => this.initialize());
    compRef.instance.apply.pipe(
      first()
    ).subscribe((customData) => {
      const response = {
        selectedIds,
        ...Boolean(customData) && { customData }
      };
      this.dialogRef.close(response);
    });
  }

  private initialize() {
    this.isMultiSelection = this.dialogData.isMultiselect ?? false;
    this.showDefaultForm();
    this.createSelectionListData();
    this.listenToUserSelection();
  }

  private listenToUserSelection() {
    this.userSelection$.pipe(takeUntil(this.onDestroy$)).subscribe((selectedIds) => {
      this.selectedIds = selectedIds;
      if (this.dialogData.isMultiselect) {
        /**
         * In case of multi dialog we want to wait until there is a selection.
         * The dialog should be closed via action button
         */
        return;
      }

      const matchingSelection = this.dialogData.visibleFilters.find(vF => vF.id === selectedIds[0]);
      if (!matchingSelection?.viewType) {
        return this.dialogRef.close({ selectedIds });
      }

      const targetFilter = this.customFilterViews.find(filterView => filterView.type === matchingSelection.viewType);
      this.showCustomFilterView(targetFilter, selectedIds);
    });
  }

  private createSelectionListData() {
    const shouldGroupItems = this.dialogData.visibleFilters.every(item => hasCategory(item));
    const selectionOptions = toSelectionOptions(this.dialogData.visibleFilters);
    if (shouldGroupItems) {
      const groupedFilters = groupBy(this.dialogData.visibleFilters, (filterElement) => filterElement.category);
      const mappedGroups: SelectionOptionGroup<string>[] = Array.from(groupedFilters).map(([category, visibleFilters]) => {
        const matchingSelections = selectionOptions.filter(option => visibleFilters.find(vF => vF.id === option.id));
        return {
          label: this.getCategoryLabelTranslation(category),
          items: matchingSelections
        };
      });
      this.possibleFilterItems$.next(mappedGroups);
    } else {
      this.possibleFilterItems$.next(selectionOptions);
    }
    const isMultiSelect = this.dialogData.isMultiselect ?? false;
    const activeIds = new Set(this.dialogData.activeFilterIds.flat());
    this.selectedIds = Array.from(activeIds);
    const filteredItems = selectionOptions.filter(option =>
      activeIds.has(option.id)
    );

    const filterSelectionModel = new SelectionModel(isMultiSelect, filteredItems);

    this.filterSelectionModel$.next(filterSelectionModel);

    this.filterSelectionListData$ = combineLatest([
      this.possibleFilterItems$,
      this.filterSelectionModel$
    ]).pipe(
      firstExisty(),
      map(([items, model]) => {
        return {
          items,
          model
        };
      })
    );
  }

  private getCategoryLabelTranslation(category: FilterCategory) {
    switch (category) {
      case FilterCategory.QUICKSELECTION: {
        return 'SELECTION_CATEGORY_QUICKSELECTION';
      }
      default:
        return '';
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
