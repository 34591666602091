import { SelectionModel } from '@angular/cdk/collections';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { AfterViewInit, ChangeDetectionStrategy, Component, ContentChild, forwardRef, inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { animationFrameScheduler, Observable, observeOn, Subject, takeUntil } from 'rxjs';
import { onScrollBottom } from '../common/utils/on-scroll-bottom';
import { ListMassDataViewerDatasource } from '../list-mass-data-viewer/providers/list-mass-data-viewer-datasource';
import { SelectionListMassDataViewerItemTemplateDirective } from './directives/selection-list-mass-data-viewer.directives';
import { SelectionListMassDataViewerControl } from './providers/md-selection-control';
import { SELECTION_MODEL } from './providers/md-selection-model';

@Component({
  selector: 'wen-selection-list-mass-data-viewer',
  templateUrl: './selection-list-mass-data-viewer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectionListMassDataViewerComponent),
      multi: true,
    },
    {
      provide: SelectionListMassDataViewerControl,
      useExisting: forwardRef(() => SelectionListMassDataViewerComponent),
    },
    {
      provide: SELECTION_MODEL,
      useFactory: () => {
        const fromParent = inject(SELECTION_MODEL, { optional: true, skipSelf: true });
        if (fromParent) {
          return fromParent;
        }
        return new SelectionModel<any>(true);
      }
    }
  ]
})
export class SelectionListMassDataViewerComponent<T>
  implements AfterViewInit, OnDestroy, ControlValueAccessor, SelectionListMassDataViewerControl {

  private onDestroy$ = new Subject<void>();

  @ContentChild(SelectionListMassDataViewerItemTemplateDirective) itemTemplate: SelectionListMassDataViewerItemTemplateDirective;
  @ViewChild(CdkScrollable) private scrollable: CdkScrollable;

  selectionModel = inject(SELECTION_MODEL);

  trackByFn = this.trackByFnImpl.bind(this);

  private currentDatasource: ListMassDataViewerDatasource<T>;

  @Input() set datasource(newDatasource: ListMassDataViewerDatasource<T>) {
    this.currentDatasource = newDatasource;
    this.reWireDatasource();
  }
  get datasource() { return this.currentDatasource; }

  @Input() itemSize = 56;

  isEmpty$: Observable<boolean>;

  reWireDatasource(): void {
    this.isEmpty$ = this.datasource.isEmpty().pipe(
      observeOn(animationFrameScheduler)
    );
  }

  trackByFnImpl(index: number, item: T) {
    return this.datasource.selectItemId(item, index);
  }

  ngAfterViewInit(): void {
    onScrollBottom(this.scrollable, this.datasource).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.datasource.fetchNextPage();
    });
  }

  emitChange: (value: T[]) => void = (_: T[]) => { };
  emitTouched: () => void = () => { };

  isSelected(item: T) {
    return this.selectionModel.isSelected(item);
  }

  writeValue(item: T | T[]): void {
    if (!item) {
      return;
    }
    const itemsArr = Array.isArray(item) ? item : [item];
    this.selectionModel.select(...itemsArr);
  }

  syncSelection() {
    const currentlySelected = this.selectionModel.selected;
    this.emitChange(currentlySelected);
  }

  registerOnChange(fn: (value: any) => void): void { this.emitChange = fn; }
  registerOnTouched(fn: () => void): void { this.emitTouched = fn; }

  setDisabledState?(isDisabled: boolean): void {
    // Unsupported
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
