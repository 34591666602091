import { Component } from '@angular/core';
import { ListMassDataViewerModule } from '@portal/wen-components';
import { ListMassDataViewerContainerConnector, provideSimpleMassDataContainerConnector } from '../../../../../../core/services/mass-data/list-mass-data-viewer-container-connector';
import { OtherProfileRecommendationsMassDataContainer } from '../../../../../../core/store/channel/mass-data/other-profile-recommendations-mass-data-config/other-profile-recommendations-mass-data.module';
import { AsyncPipe, JsonPipe } from '@angular/common';
import { OtherProfileData } from '../providers/profile-data.service';
import { ChannelListModule } from '../../../../../channel/channel-list/channel-list.module';
import { WenPipesModule } from '../../../../../../shared/pipes/wen-pipes.module';
import { ChannelDTO } from '@portal/wen-backend-api';
import { WenNavigationHelper } from '../../../../../../core/services/navigation/types';

@Component({
  selector: 'wen-other-profile-recommendations-list',
  standalone: true,
  imports: [
    ListMassDataViewerModule,
    JsonPipe,
    ChannelListModule,
    WenPipesModule,
    AsyncPipe
  ],
  templateUrl: './other-profile-recommendations-list.component.html',
  providers: [
    provideSimpleMassDataContainerConnector(OtherProfileRecommendationsMassDataContainer),
    OtherProfileData
  ]
})
export class OtherProfileRecommendationsListComponent {

  constructor(
    protected datasource: ListMassDataViewerContainerConnector<OtherProfileRecommendationsMassDataContainer>,
    private navigationHelper: WenNavigationHelper
  ) {
    this.datasource.fetchInitialPage();
  }

  navigateToChannel(channel: ChannelDTO) {
    this.navigationHelper.navigateToChannelView(channel.id);
  }
}
