import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { from, map, mergeMap, scan, startWith } from 'rxjs';
import { LegacyContextMenuOverlayService } from './openers/context-menu-overlay.service';
import { DialogHandler } from './openers/dialog-opener';
import { OverlayContainer } from '@angular/cdk/overlay';
import { observeOnMutation } from '../../util/mutation-observer';
import { ContextMenuHandler } from './openers/context-menu-opener';

@Injectable()
export class OverlayManager {

  hasOpenOverlay$ = observeOnMutation(this.overlayContainer.getContainerElement(), { childList: true })
    .pipe(
      mergeMap(mutationRecords => from(mutationRecords)),
      scan((acc, mutationRecord) => {
        return acc + mutationRecord.addedNodes.length - mutationRecord.removedNodes.length;
      }, 0),
      map(count => count > 0),
      startWith(false)
    );

  constructor(
    public readonly menu: ContextMenuHandler,
    public readonly contextMenu: LegacyContextMenuOverlayService,
    public readonly dialog: DialogHandler,
    private matDialog: MatDialog,
    private overlayContainer: OverlayContainer,
  ) { }

  closeAllDialogs() {
    this.matDialog.openDialogs
      .filter(dialog => !dialog.disableClose)
      .forEach(dialog => dialog.close());

    this.contextMenu.close();
    this.menu.close();
  }

  hasOpenDialogs() {
    const openDialogs = this.matDialog.openDialogs?.length > 0 || false;
    if (openDialogs) {
      return openDialogs;
    }
    return this.contextMenu.isOpen() || this.menu.isOpen();
  }

}
