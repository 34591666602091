@if (hasControls) {
  <div class="wen-embed-preview-container">
    <wen-message-discard-edit-button></wen-message-discard-edit-button>
    <wen-link-embed-preview [previewContent]="linkPreviewContent$ | async" [disabled]="hasMediaEmbed$ | async"
    (previewChanged)="updateDraftMessageLinkEmbed($event)"></wen-link-embed-preview>
    <wen-media-embed-preview></wen-media-embed-preview>
    <wen-poll-preview></wen-poll-preview>
  </div>
}
<wen-message-send-field [readonly]="!hasControls" [inputPlaceholder]="inputPlaceholder"
  [contextId]="channelId$ | async" (messageSent)="onMessageSent()" (focused)="onFocused($event)">
  @if (hasControls) {
    <wen-message-type-selector wen-message-type-selector [menuId]="messageSelectorId"
      [disabled]="hasEmbed$ | async">
    </wen-message-type-selector>
  }
  @if (hasControls) {
    <wen-message-scheduler wen-message-bottom-action
    [contextId]="channelId$ | async"></wen-message-scheduler>
  }
</wen-message-send-field>