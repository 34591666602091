import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, distinctUntilChanged, filter, first, map, Observable, ReplaySubject, shareReplay, switchMap, tap } from 'rxjs';
import { selectorWithParam } from '../../../../core/common/util/selector-with-param';
import { WenNavigationHelper } from '../../../../core/services/navigation/types';
import { loadRoomDetails } from '../../../../core/store/chat/chat.actions';
import { selectCurrentRoom, selectRoomById, selectUserChatListIsLoaded } from '../../../../core/store/chat/chat.selectors';
import { ChatRoomEntity } from '../../../../core/store/chat/chat.state';
import { RootState } from '../../../../core/store/root/public-api';
import { selectRouteParam } from '../../../../core/store/root/root.selectors';
import { chatViewIdentifier } from '../../tokens';
import { LoadingState } from '../../../../core/common/types/store-loading-state';

@Injectable()
export class ChatViewPreloaderGuard {

  private store = inject(Store<RootState>);
  private navigationHelper = inject(WenNavigationHelper);

  isChatHistoryLoaded$ = this.store.pipe(
    select(selectRouteParam(chatViewIdentifier)),
    distinctUntilChanged(),
    switchMap((roomId: string) => {
      return this.store.pipe(
        selectorWithParam(selectRoomById, roomId),
        map((roomEntity) => Boolean(roomEntity?.history)),
        filter(hasHistory => hasHistory),
      );
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  isChatDetailsLoaded$ = this.store.pipe(
    select(selectCurrentRoom),
    map((room) => {
      return Boolean(room?.id);
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  ensureCurrentChatDetailsLoaded(): Observable<ChatRoomEntity> {
    const details$ = new ReplaySubject<ChatRoomEntity>(1);
    combineLatest([
      this.store.pipe(select(selectUserChatListIsLoaded)),
      this.store.pipe(select(selectRouteParam(chatViewIdentifier)))
    ]).pipe(
      filter(([isLoaded, roomId]) => isLoaded && !!roomId),
      tap(([_, roomId]) => {
        this.store.dispatch(loadRoomDetails({ roomId }));
      }),
      switchMap(([_, roomId]) =>
        this.store.pipe(
          selectorWithParam(selectRoomById, roomId),
          tap(room => {
            if (!room) {
              this.navigationHelper.navigateToChatList();
            }
          }),
          filter(room => !!room && room.loadingState === LoadingState.LOADED),
          first()
        )
      ),
      first()
    ).subscribe((room: ChatRoomEntity) => {
      details$.next(room);
    });
    return details$.pipe(first());
  }
}
