<div class="wen-app-header-wrapper">
  @if (hasCommand) {
    <div class="wen-app-header-left-part" [wen-test-id]="'wen-app-header-left-command'">
      @if (leftCommand) {
        <wen-header-command [commandData]="leftCommand" [isCommandDisabled]="leftCommandDisabled"></wen-header-command>
      }
    </div>
  }
  <div class="wen-app-header-center-part">
    @if (showLogo) {
      <div
        class="wen-app-header-app-logo"
        [ngStyle]="{'backgroundImage': logoIcon ? 'url(' + logoIcon + ')' : 'var(--wen-logo-url)'}"
        [wen-test-id]="'wen-app-header-app-logo'"
        nativeBridgeDebugTarget
        (click)="centerClicked()">
      </div>
    }
    @if (contextTitle) {
      <h1 class="cdk-visually-hidden">{{ contextTitle | translate }}</h1>
    }

    @if (title) {
      <div class="wen-app-header-title"
        [class.wen-app-header-title-text-spacing]="titleIcon || prefixTitleIcon || !hasCommand" (click)="centerClicked()">
        <h2 portal-title class="wen-app-header-title-text" [wen-test-id]="'wen-app-header-title-text'">
          {{ title | translate }}
          @if (prefixTitleIcon) {
            <wen-icon class="wen-app-header-title-prefix-icon wen-icon-small" [iconName]="prefixTitleIcon.name"
                      [altText]="prefixTitleIcon.altText"></wen-icon>
          }
          @if (titleIcon) {
            <wen-icon class="wen-app-header-title-icon wen-icon-small" [iconName]="titleIcon.name"
                      [altText]="titleIcon.altText"></wen-icon>
          }
        </h2>
      </div>
    }
    @if (subTitle) {
      <h3 class="wen-app-header-subtitle" portal-caption>
        {{ subTitle | translate }}
      </h3>
    }
  </div>
  @if (hasCommand) {
    <div class="wen-app-header-right-part" [wen-test-id]="'wen-app-header-right-command'">
      @if (rightCommand) {
        <wen-header-command *wenComingSoonFeature="rightCommand.comingSoonFeature" [commandData]="rightCommand" [isCommandDisabled]="rightCommandDisabled"></wen-header-command>
      }
    </div>
  }
</div>
