<div class="wen-chat-selection-list-wrapper">
  <wen-list-mass-data-viewer [datasource]="listDatasource">
    <ng-container wen-list-mass-data-empty>
      <wen-empty-list src="/assets/wen-widget/image/empty-user-list.svg"
      [titleLabel]="'SEARCH_USER_LIST_EMPTY_TEXT'"></wen-empty-list>
    </ng-container>
    <ng-template wen-list-mass-data-item let-listItemData let-index="index">
      @if (listService.separatorPositions$ | async; as separators) {
        @if (separators.existingIndex === index) {
          <h3 portal-subheading-1 class="wen-chat-selection-list-group-label">
            {{ "SEARCH_USER_LIST_EXISTING_HEADER" | translate }}
          </h3>
        }
        @if (separators.groupIndex === index) {
          <h3 portal-subheading-1 class="wen-chat-selection-list-group-label">
            {{ "SEARCH_USER_LIST_GROUPS_HEADER" | translate }}
          </h3>
        }
        @if (separators.furtherIndex === index) {
          <h3 portal-subheading-1 class="wen-chat-selection-list-group-label">
            {{ "SEARCH_USER_LIST_FURTHER_HEADER" | translate }}
          </h3>
        }
      }
      <wen-user-list-item [user]="listItemData | toUserListItem" [disabled]="isItemDisabled(listItemData)"
        (click)="handleListItemClick(listItemData)">
        @if (!isItemDisabled(listItemData)) {
          <mat-checkbox wen-user-after [disableRipple]="'true'"
          [checked]="listItemData.selected"></mat-checkbox>
        }
      </wen-user-list-item>
    </ng-template>
  </wen-list-mass-data-viewer>
</div>