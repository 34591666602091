import { createAction, props } from '@ngrx/store';
import { WenLanguage } from '@portal/wen-backend-api';
import { AppPermission, ChannelPermission } from './models/Permission';
import { PrivilegeDTO } from './models/Privilege';
import { UserProfile } from './models/UserProfile';
import { ProfileImageState, UserMetaDataSettings } from './user.state';

export const subscribeToProfileUpdates = createAction(
  '[User] Subscribe to profile updates'
);

export const updateUserProfile = createAction(
  '[User] Update user profile in the store',
  props<{ userProfile: UserProfile }>()
);

export const deleteUserProfile = createAction(
  '[User] Delete user'
);

export const updatePrivileges = createAction(
  '[User] Update user privileges in the store',
  props<{ privileges: PrivilegeDTO[] }>()
);

export const fetchSystemPrivileges = createAction(
  '[User] Fetch system privileges'
);

export const subscribeToPermissionUpdates = createAction(
  '[User] Subscribe to permission updates'
);

export const updateUserChannelPermission = createAction(
  '[User] Update user permission for channels in the store',
  props<{ permission: ChannelPermission }>()
);

export const updateUserAppPermission = createAction(
  '[User] Update user permission for apps in the store',
  props<{ permission: AppPermission }>()
);

export const updateProfileImageState = createAction(
  '[User] Update user profile load state',
  props<{ state: ProfileImageState }>()
);

export const navigateToUserProfile = createAction(
  '[User] Navigate to a user profile',
  props<{ userId: string }>()
);

export const updateUserPrimaryLanguage = createAction(
  '[User] Update user primary language',
  props<{ languageCode: WenLanguage }>()
);

export const initiateLanguageChangeEmits = createAction(
  `[User] Notify the backend about the user's language changes`
);

export const upsertUserMetaDataSettings = createAction(
  '[User] Upsert the user meta data settings',
  props<{ newSettings: UserMetaDataSettings }>()
);
