import { ConfirmationDialogResult } from '@portal/wen-components';

export enum Recur {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED'
}

export interface EventICalEventPickerResultData extends ConfirmationDialogResult {
  recur?: boolean;
}

export interface ICalQueryData {
  eventId: string;
  currentEventStartDate: string;
  possibleReccuring: boolean;
  caption: string;
}
