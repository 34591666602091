<wen-form-progress [hasProgress]="hasProgress$ | async"></wen-form-progress>
<div class="sign-in-login-content">
  <div class="sign-in-login-logo-wrapper">
    <h1 class="cdk-visually-hidden">{{'HOME_PAGE_WELCOME_TITLE' | translate }} {{'HOME_PAGE_WELCOME_TITLE_APP' | translate }}</h1>
    <img class="sign-in-login-logo" [alt]="'APP_LOGO_DESCRIPTION' | translate" />
  </div>
  <div class="sign-in-login-controls-wrapper">
    @if (config.showLoginPrompt) {
      <div portal-title class="sign-in-login-prompt">{{'ONBOARDING_LOGIN_PROMPT' | translate}}</div>
    }
    <div class="sign-in-login-controls-buttons">
      @if (config.showRegisterButton) {
        <wen-action class="wen-action-rounded"
                    wenVariant="filled"
                    [isNavigating]="true"
                    (click)="goToRegister()">
          <span portal-title>{{ 'ONBOARDING_LOGIN_REGISTER' | translate }}</span>
        </wen-action>
      }
      @if (config.showAnonLoginButton) {
        <wen-action class="wen-action-rounded"
                    wenVariant="outlined"
                    [isNavigating]="true"
                    (click)="goToAnonymousLogin()">
          {{ 'ONBOARDING_LOGIN_LOGIN_ANONYMOUS' | translate }}
        </wen-action>
      }
      @if (config.showLoginButton) {
        <wen-action wenVariant="text" [isNavigating]="true" (click)="goToLogin()">
          <span [innerHtml]="'ONBOARDING_LOGIN_LOGIN' | translate"></span>
        </wen-action>
      }
    </div>
    @if (config.termsAndConditions) {
      <div class="sign-in-login-data-policy" portal-subheading-1>
        {{ 'ONBOARDING_LOGIN_DATA_POLICY_1' | translate }}
        <a portal-subheading-1 [href]="'PRIVACY_POLICY_URL' | translate" target="_blank" (click)="openPrivacyPage()">{{ 'ONBOARDING_LOGIN_DATA_POLICY_2' | translate }}</a>
        {{ 'ONBOARDING_LOGIN_DATA_POLICY_3' | translate }}
        <a portal-subheading-1 [href]="'TERMS_AND_CONDITIONS_URL' | translate" target="_blank" (click)="openTermsAndConditionsPage()">{{ 'ONBOARDING_LOGIN_DATA_POLICY_4' | translate }}.</a>
      </div>
    }
  </div>
</div>
<wen-silent-logout></wen-silent-logout>
