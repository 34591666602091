@if (isNumeric) {
  <div [@scaleAnimation] portal-subheading-1 class="wen-notification-count-content wen-notification-count-circle" [attr.aria-label]="altText">
    {{ value < 100 ? value : '99+' }}
  </div>
} @else {
  @if (value) {
    <div portal-subheading-1 class="wen-notification-count-content wen-notification-count-freetext">
      {{ value }}
    </div>
  }
}
