import { NgModule } from '@angular/core';
import { ThumbnailSizeHelperDirective } from './thumbnail-size-helper.directive';

@NgModule({
  declarations: [
    ThumbnailSizeHelperDirective,
  ],
  exports: [
    ThumbnailSizeHelperDirective
  ]
})
export class ThumbnailSizeHelperModule { }
