import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SelectionListMassDataViewerModule } from '@portal/wen-components';
import { ListMassDataViewerContainerConnector, provideSimpleMassDataContainerConnector } from '../../../../../core/services/mass-data/list-mass-data-viewer-container-connector';
import { UserChannelRecommendationsMassDataContainer } from '../../../../../core/store/user/mass-data/user-channel-recommendations-mass-data-config/user-channel-recommendations-mass-data-config';
import { SharedModule } from '../../../../../shared/shared.module';
import { Subject } from 'rxjs';
import { FormStoreService } from '../../../../../shared/form-store/form-store.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'wen-user-channel-recommendations',
  templateUrl: './user-channel-recommendations.component.html',
  styleUrls: ['./user-channel-recommendations.component.scss'],
  imports: [
    SharedModule,
    SelectionListMassDataViewerModule,
    MatCheckboxModule,
  ],
  providers: [
    provideSimpleMassDataContainerConnector(UserChannelRecommendationsMassDataContainer),
  ],
  standalone: true
})

export class UserChannelRecommendationsComponent implements OnInit, OnDestroy {

  private onDestroy$: Subject<void> = new Subject<void>();

  datasource = inject(ListMassDataViewerContainerConnector<UserChannelRecommendationsMassDataContainer>);
  formStoreService = inject(FormStoreService);

  selectedRecommendedChannels = new FormControl([]);

  userChannelRecommendationsFormGroup = new FormGroup({
    selectedRecommendedChannels: this.selectedRecommendedChannels
  });

  ngOnInit(): void {
    let itemsLoaded = 0;
    this.formStoreService.clearFormData();
    this.datasource.dataStream$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(data => {
      this.formStoreService.initializeForm(data.filter(item => item.isRecommended));
      this.selectedRecommendedChannels.setValue([
          ...this.selectedRecommendedChannels.value,
          ...data.filter((item, index) => item.isRecommended && index >= itemsLoaded)
      ]);
      itemsLoaded = data.length;
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }


}
