import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map } from 'rxjs/operators';
import { EmailVerificationService } from '../../shared/feature-specific/email-verification/email-verification.service';
import { EMAIL_VERIFICATION_CODE_QUERY_PARAM } from '../services/navigation/query-params';
import { AuthGuard } from '../services/user-management/auth.guard';

export const userProfileActivatorGuardFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(UserProfileActivatorGuard).canActivate(route, state);
};

@Injectable()
export class UserProfileActivatorGuard {

  constructor(
    private router: Router,
    private authGuard: AuthGuard,
    private emailVerificationService: EmailVerificationService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authGuard.canActivateChild(route, state).pipe(
      map((result) => {
        if (result instanceof UrlTree) {
          return result;
        } else if (result) {
          const verificationCode = route.queryParamMap.get(EMAIL_VERIFICATION_CODE_QUERY_PARAM);
          if (verificationCode) {
            this.emailVerificationService.verifyCode(verificationCode);
          }
          return this.router.parseUrl('');
        }
        return result;
      })
    );
  }
}
