import { Injectable } from '@angular/core';
import { PollManager } from '@portal/wen-components';
import { ChosenOptionRequest, GetPollRequest, PollModel, SocketIoService } from '@portal/wen-backend-api';
import { map, merge, Observable, tap } from 'rxjs';
import { PollViewState, PollViewStateHandler } from './poll-view-state-handler';

@Injectable()
export class PollService extends PollManager {

  pollUpdates$: Observable<Partial<PollModel>>;

  constructor(
    private pollViewStateHandler: PollViewStateHandler,
    private socketIOService: SocketIoService
  ) {
    super();
    this.setupSocketConnections();
  }

  getPoll(pollRequest: GetPollRequest): Observable<PollModel> {
    return this.socketIOService.messageEmbed.poll.get.acknowledgement$(pollRequest).pipe(
      tap(pollModel => this.pollViewStateHandler.add(pollModel))
    );
  }

  chooseOption(selectedOption: ChosenOptionRequest) {
    this.socketIOService.messageEmbed.poll.chooseOption.emit(selectedOption);
  }

  getPollViewUpdatesFor(id: string): Observable<PollViewState> {
    return this.pollViewStateHandler.getUpdatesFor(id);
  }

  private setupSocketConnections() {
    this.pollUpdates$ = merge(
      this.socketIOService.messageEmbed.poll.update.listen.pipe(map(partialPoll => this.pollViewStateHandler.upsertPoll(partialPoll))),
      this.socketIOService.messageEmbed.poll.chooseOption.listen,
    );
  }
}
