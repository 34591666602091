import { Injectable } from '@angular/core';
import { ObjectFit } from './thumbnail-size-helper.directive';

export type ThumbnailConfig = {
  objectFit: ObjectFit;
  minAspectRatio?: number;
  maxHeight?: number;
  applyMaxWidth: boolean;
};

@Injectable()
export class ThumbnailCustomizations {
  getConfig(): ThumbnailConfig {
    return {
      objectFit: ObjectFit.COVER,
      applyMaxWidth: false,
    };
  }
}
