import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { FAQViewComponent } from './components/faq-view/faq-view.component';
import { ContentBoundaryModule, IsDesktopViewDirective } from '@portal/wen-components';

@NgModule({
  imports: [
    SharedModule,
    ScrollingModule,
    ContentBoundaryModule,
    IsDesktopViewDirective
  ],
  declarations: [
    FAQViewComponent
  ],
  exports: [
    FAQViewComponent
  ]
})
export class FAQViewModule { }
