@if (error$ | async; as error) {
  @switch (error) {
    @case ('NO_KEY_FOUND') {
      <div class="wen-chat-message-error-share-key" (click)="errorShareKeyClicked($event)">
        <span class="wen-chat-message-error-share-key-icon-wrapper">
          <wen-icon class="wen-icon-flex" iconName="encryption_key"></wen-icon>
        </span>
        <div portal-subheading-1>{{ "CHAT_MESSAGE_DECRYPTION_ERROR" | translate }} </div>
        <div class="wen-chat-message-error-action" portal-subheading-1>{{ "CHAT_MESSAGE_DECRYPTION_ERROR_ACTION" | translate }}</div>
      </div>
    }
    @case ('UNKNOWN') {
      <div portal-subheading-1>{{ "CHAT_MESSAGE_DECRYPTION_ERROR" | translate }}</div>
    }
  }
}