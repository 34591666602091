import { Component, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { selectUserProfile } from '../../../../../core/store/user/user.selectors';

@Component({
  selector: 'wen-current-user-info',
  templateUrl: './current-user-info.component.html',
  styleUrls: ['./current-user-info.component.scss'],
})
export class CurrentUserInfoComponent {

  private readonly store = inject(Store);
  private readonly navigationHelper = inject(WenNavigationHelper);

  public readonly userProfile$ = this.store.pipe(
    select(selectUserProfile)
  );

  navigateToProfile() {
    this.navigationHelper.navigateToCrmProfileReadOnlyView();
  }
}
