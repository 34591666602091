import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LazyLoadModule } from '../../directives/lazy-load/lazy-load.module';
import { ThumbnailImageComponent } from './thumbnail-image.component';
import { ImgFallbackModule } from '../../directives/image-fallback/image-fallback.module';

@NgModule({
  imports: [
    CommonModule,
    LazyLoadModule,
    ImgFallbackModule
  ],
  declarations: [
    ThumbnailImageComponent
  ],
  exports: [
    ThumbnailImageComponent
  ]
})
export class ThumbnailImageModule { }
