import { TranslateService } from '@ngx-translate/core';
import { MediaType } from '@portal/wen-backend-api';
import { getMediaTypeData } from '@portal/wen-components';

export const getMediaType = (file: File) => {
  let type: MediaType;
  if (file.type.includes('image/')) {
    type = MediaType.PICTURE;
  } else if (file.type.includes('video/')) {
    type = MediaType.VIDEO;
  } else if (file.type.includes('audio/')) {
    type = MediaType.AUDIO;
  } else {
    type = MediaType.DOCUMENT;
  }
  return type;
};


export const getMediaAltText = (
  translateService: TranslateService,
  mediaType: MediaType,
  translationKey: string = ''
) => {
  if (!translationKey) {
    return;
  }
  const mediaTypeAsTitle = getMediaTypeData(mediaType).title;
  const translatedMediaType = translateService.instant(mediaTypeAsTitle);
  return translateService.instant(translationKey, { mediaType: translatedMediaType });
};
