import { Directive } from '@angular/core';

@Directive({
  selector: 'wen-skeleton-content, [wen-skeleton-content]'
})
export class SkeletonContentDirective {
}

@Directive({
  selector: 'wen-loaded-content, [wen-loaded-content]'
})
export class LoadedContentDirective {
}
