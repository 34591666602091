import { DraftMessageEntity } from '../../core/store/common/models';

export function checkDraftMessageState(draftMessage: DraftMessageEntity): boolean {
    return Boolean(draftMessage) &&
      (Boolean(draftMessage?.content)
        || Boolean(draftMessage?.fileEmbed)
        || Boolean(draftMessage?.linkEmbed)
        || Boolean(draftMessage?.pollEmbed)
      );
  }

export const canSchedule = (draftMessage: DraftMessageEntity): boolean => {
  return !draftMessage?.timestamp || Boolean(draftMessage.scheduled);
};
