import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppFilter, SocketIoService } from '@portal/wen-backend-api';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { filter, map, share, withLatestFrom } from 'rxjs/operators';
import { WenRouteId } from '../../../frame/routing/types';
import { selectorWithParam } from '../../common/util/selector-with-param';
import { selectActiveFiltersByEntityType } from '../filter/filter.selectors';
import { FilterEntityType, FilterType } from '../filter/models/filter';
import { SearchFilterId } from '../header/models/HeaderData';
import { RootState } from '../root/public-api';
import { selectOutletIds } from '../root/root.selectors';
import { createAppsListForUserEffect } from './effects/app-list-for-user.effect';
import { createAppDeleteAcknowledgeEffect, createAppUpdateEffect } from './effects/app-updates.effect';
import { createAppListForDiscoverEffect } from './effects/apps-list-for-discover.effect';
import { createReloadDiscoverAppListEffect, createUpdateDiscoverAppListOnFilterUpdateEffect } from './effects/reload-discover-app-list.effect';
import { createSetAppSubscriptionEffect, createSetAppSubscriptionWithIdEffect } from './effects/set-app-subscription.effect';
import { Filterable } from '../../../shared/components/filter-view/models/filter-item';

@Injectable()
export class AppsEffects {

  private readonly appDetails$ = this.socketIoService.app.details.listen.pipe(
    share()
  );
  private readonly listForUser$ = this.socketIoService.app.listForUser.listen.pipe(
    share()
  );
  private readonly listForDiscover$ = this.socketIoService.app.listForDiscover.listen.pipe(
    share()
  );
  private readonly appUpdate$ = this.socketIoService.app.update.listen;
  private readonly currentFilter$ = this.store.pipe(
    selectorWithParam(selectActiveFiltersByEntityType, FilterEntityType.APP_LISTS),
    smartDistinctUntilChanged(),
    withLatestFrom(this.store.pipe(select(selectOutletIds))),
    filter(([_, outletIds]) => {
      return WenRouteId.APP_DISCOVERY === (outletIds.sidebarId ?? outletIds.primaryId);
    }),
    map(([newFilters, _]) => {
      newFilters = newFilters.filter(f => f.filterType === FilterType.SELECT ||
        (f.filterType === FilterType.SEARCH && f.filterId === SearchFilterId.LIST_SEARCH));
      return newFilters.reduce((allFilters, currFil) => {
        let aF;
        const filterValue = currFil.filterValue as Filterable;
        switch (currFil.filterType) {
          case FilterType.SELECT:
            aF = filterValue.filter;
            break;
          case FilterType.SEARCH:
            aF = { search: filterValue };
            break;
          default:
            break;
        }
        return { ...allFilters, ...aF };
      }, {} as AppFilter);
    })
  );

  appUpdates$ = createAppUpdateEffect(this.actions$, this.appUpdate$, this.appDetails$);
  appsUserUpdates$ = createAppsListForUserEffect(this.store, this.actions$, this.listForUser$, this.socketIoService);
  appsDiscoveryUpdates$ = createAppListForDiscoverEffect(this.actions$, this.listForDiscover$);
  reloadDiscoverAppListEffect$ = createReloadDiscoverAppListEffect(this.actions$, this.currentFilter$, this.socketIoService);
  updateDiscoverListOnFilterUpdateEffect$ = createUpdateDiscoverAppListOnFilterUpdateEffect(this.currentFilter$, this.socketIoService);
  setAppSubscriptionEffect$ = createSetAppSubscriptionEffect(this.store, this.actions$, this.socketIoService);
  setAppSubscriptionWithIdEffect$ = createSetAppSubscriptionWithIdEffect(this.actions$, this.socketIoService);
  appDeleteAcknowledgeEffect$ = createAppDeleteAcknowledgeEffect(this.actions$, this.store, this.socketIoService);

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly socketIoService: SocketIoService
  ) {
  }

}
