import { createAction, props } from '@ngrx/store';
import { WeatherState } from './weather.state';
import { CoordinatesPayload } from '@portal/wen-backend-api';

export const fetchCurrentWeatherData = createAction(
  '[Weather] Get current weather data from API',
  props<CoordinatesPayload>()
);

export const weatherDataRetrieved = createAction(
  '[weather] Retrieved weather data',
  props<{ newState: Partial<WeatherState> }>()
);
