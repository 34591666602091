import { Injectable } from '@angular/core';
import * as Olm from '@matrix-org/olm';
import { OlmEncryptedMessage } from '@portal/wen-backend-api';
import { first, map, of, shareReplay, switchMap } from 'rxjs';
import { MegOlmDecryptionResult, MegOlmEncryptionResult, OlmDecryptionResult } from '../decryption/crypto-results';
import { CryptoStorage } from '../persistence/crypto-storage';
import { OlmAccountModel } from '../persistence/data-models';
import { AccountIdentityKeys, MegOlmDecryptedMessage } from '../types';

type OneTimeKeys = {
  curve25519: { [id: string]: string };
};

type AccountData = {
  account: Olm.Account;
  e2eKeys: AccountIdentityKeys;
};

@Injectable()
export class OlmDevice {

  private PICKLE_KEY = '🥒🥒🥒';

  private accountData: AccountData;

  constructor(
    private dataStore: CryptoStorage,
  ) { }

  initialize() {
    const account$ = this.initializeAccount().pipe(
      shareReplay(1)
    );
    account$.subscribe((account) => {
      const e2eKeys = JSON.parse(account.identity_keys()) as { curve25519: string; ed25519: string };
      this.accountData = {
        account, e2eKeys
      };
    });
    return account$;
  }

  getOneTimeKeyApi() {
    return {
      getMaxNumberOfOneTimeKeys: () => this.accountData.account.max_number_of_one_time_keys(),
      generateOneTimeKeys: (keyCount: number) => this.generateOneTimeKeys(keyCount),
    };
  }

  getAccountModel(): OlmAccountModel {
    return {
      account: this.accountData.account.pickle(this.PICKLE_KEY)
    };
  }

  getIdentityKeys() {
    return this.accountData.e2eKeys;
  }

  private generateOneTimeKeys(keyCount: number) {
    const { account } = this.accountData;
    account.generate_one_time_keys(keyCount);
    const oneTimekeys: OneTimeKeys = JSON.parse(account.one_time_keys());
    account.mark_keys_as_published();
    return this.dataStore.storeAccount({
      account: account.pickle(this.PICKLE_KEY)
    }).pipe(
      map(() => oneTimekeys)
    );
  }

  private initializeAccount() {
    return this.dataStore.getAccount().pipe(
      switchMap((accountModel) => {
        const account = new Olm.Account();
        if (accountModel?.account) {
          account.unpickle(this.PICKLE_KEY, accountModel.account);
          return of(account);
        } else {
          account.create();
          return this.dataStore.storeAccount({ account: account.pickle(this.PICKLE_KEY) }).pipe(
            map(() => account)
          );
        }
      }),
      first()
    );
  }

  createOutboundSession(theirCurvecurve25519: string, theirOneTimeKey: string) {
    const session = new Olm.Session();
    session.create_outbound(this.accountData.account, theirCurvecurve25519, theirOneTimeKey);
    return {
      sessionId: session.session_id(),
      session: session.pickle(this.PICKLE_KEY)
    };
  }

  createInboundSession(theirCurve25519: string, message: OlmEncryptedMessage) {
    const { account } = this.accountData;
    const session = new Olm.Session();
    session.create_inbound_from(this.accountData.account, theirCurve25519, message.body);
    account.remove_one_time_keys(session);
    return this.dataStore.storeAccount(this.getAccountModel()).pipe(
      map(() => {
        return {
          sessionId: session.session_id(),
          session: session.pickle(this.PICKLE_KEY)
        };
      })
    );
  }

  createOutboundGroupSession() {
    const outboundSession = new Olm.OutboundGroupSession();
    outboundSession.create();
    const sessionKey = outboundSession.session_key();
    const sessionId = outboundSession.session_id();
    return {
      sessionKey,
      sessionId,
      session: outboundSession.pickle(this.PICKLE_KEY)
    };
  }

  createInboundGroupSession(sessionKey: string) {
    const inboundSession = new Olm.InboundGroupSession();
    inboundSession.create(sessionKey);
    const sessionId = inboundSession.session_id();
    return {
      sessionKey,
      sessionId,
      session: inboundSession.pickle(this.PICKLE_KEY)
    };
  }

  getInboundGroupSessionExport(sessionPickle: string) {
    const inboundSession = new Olm.InboundGroupSession();
    inboundSession.unpickle(this.PICKLE_KEY, sessionPickle);
    return inboundSession.export_session(inboundSession.first_known_index());
  }

  importInboundGroupSession(sessionExport: string) {
    const inboundSession = new Olm.InboundGroupSession();
    inboundSession.import_session(sessionExport);
    return inboundSession.pickle(this.PICKLE_KEY);
  }

  matchesSession(sessionPickle: string, message: string) {
    const session = new Olm.Session();
    session.unpickle(this.PICKLE_KEY, sessionPickle);
    return session.matches_inbound(message);
  }

  encryptMessage(sessionPickle: string, message: string) {
    const session = new Olm.Session();
    session.unpickle(this.PICKLE_KEY, sessionPickle);
    const result = {
      encrypted: session.encrypt(message) as OlmEncryptedMessage,
      usedSession: {
        sessionId: session.session_id(),
        session: session.pickle(this.PICKLE_KEY)
      }
    };
    return result;
  }

  decryptMessage(sessionPickle: string, message: OlmEncryptedMessage) {
    const session = new Olm.Session();
    session.unpickle(this.PICKLE_KEY, sessionPickle);
    const result: OlmDecryptionResult = {
      decrypted: session.decrypt(message.type, message.body),
      usedSession: {
        sessionId: session.session_id(),
        session: session.pickle(this.PICKLE_KEY)
      }
    };
    return result;
  }

  encryptGroupMessage(sessionPickle: string, message: string) {
    const outboundSession = new Olm.OutboundGroupSession();
    outboundSession.unpickle(this.PICKLE_KEY, sessionPickle);
    const result: MegOlmEncryptionResult = {
      encrypted: outboundSession.encrypt(message),
      usedSession: {
        sessionId: outboundSession.session_id(),
        session: outboundSession.pickle(this.PICKLE_KEY)
      }
    };
    return result;
  }

  decryptGroupMessage(sessionPickle: string, message: string) {
    const inboundSession = new Olm.InboundGroupSession();
    inboundSession.unpickle(this.PICKLE_KEY, sessionPickle);
    const result: MegOlmDecryptionResult = {
      decrypted: inboundSession.decrypt(message) as MegOlmDecryptedMessage,
      usedSession: {
        sessionId: inboundSession.session_id(),
        session: inboundSession.pickle(this.PICKLE_KEY)
      }
    };
    return result;
  }

}
