<wen-form-progress [hasProgress]="progressState$ | async" [showCurtain]="false"></wen-form-progress>
<div class="wen-distribute-chat-container">
  <wen-active-item-image-view class="wen-active-item-image-view" [style.height.px]="imageSize" [style.width.px]="imageSize"
    (click)="navigateToCrmProfileReadOnlyView()" [fontSize]="22" [imageSize]="{ height: imageSize }">
  </wen-active-item-image-view>
  @if ((recipientCount$ | async) < 1) {
    <h1 class="wen-invite-counter-count">{{ "CRM_DISTRIBUTE_CHAT_FORM_TITLE" | translate }}</h1>
    <div portal-body-1>{{ "CRM_DISTRIBUTE_CHAT_FORM_DESCRIPTION" | translate }}</div>
  } @else {
    <h1 class="wen-invite-counter-count">
      {{ "CRM_DISTRIBUTE_CHAT_FORM_INPROGRESS_TITLE" | translate: { currentCount: processedRecipients$ | async, maxCount: recipientCount$ | async }  }}
    </h1>
    <div portal-body-1>{{ "CRM_DISTRIBUTE_CHAT_FORM_INPROGRESS_DESCRIPTION" | translate }}</div>
  }
  <wen-action class="wen-action-rounded" wenVariant="filled"
              (click)="sendMessage()"
              [isDisabled]="(progressState$ | async).hasProgress">
    {{ "CRM_DISTRIBUTE_CHAT_PROCESS_MESSAGES_BUTTON_LABEL" | translate }}
  </wen-action>
</div>
