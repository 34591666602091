import { Injectable, Optional } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WenSeparatorModel } from '@portal/wen-components';
import { DateTime } from 'luxon';
import { DateUtil } from '../../../core/common/date/date-util';
import { MessageSeparatorDateFormatter } from './message-separator-formatters';
import { SeparatorCustomizations } from './separator-customizations';

export interface MessageSeparatorData {
  current?: string;
  previous?: string;
  newMessageLine?: boolean;
  customText?: string;
}

@Injectable()
export class SeparatorFactory {

  constructor(
    private translateService: TranslateService,
    private messageSeparatorDateFormatter: MessageSeparatorDateFormatter,
    @Optional() readonly separatorCustomizations: SeparatorCustomizations,
  ) { }

  getSeparatorData(data: MessageSeparatorData): WenSeparatorModel {
    if (!data) {
      return null;
    }

    const hideLine = !data.newMessageLine && !!this.separatorCustomizations?.hideLine();

    const { current, previous, customText } = data;
    let needsGroupSeparator = false;
    const currentDate = DateUtil.fromIsoString(current);
    if (customText && !current) {
      return {
        centerText: customText,
        coloring: 'background',
        hideLine,
      };
    }
    if (current && previous) {
      const previousDate = DateUtil.fromIsoString(previous);
      const isSameDay = DateUtil.isInSameDay(currentDate, previousDate);
      needsGroupSeparator = !isSameDay;
    } else if (current && !previous) {
      needsGroupSeparator = true;
    }
    if (needsGroupSeparator || data.newMessageLine) {
      return {
        centerText: !needsGroupSeparator
          ? null
          : this.getCenterText(currentDate),
        rightText: data.newMessageLine ? this.translateService.instant('NEW_MESSAGE_INDICATOR_TEXT') : null,
        coloring: data.newMessageLine ? 'primary' : 'background',
        hideLine,
      };
    }
    return null;
  }

  private getCenterText(dateTime: DateTime): string {
    return this.messageSeparatorDateFormatter.formatSeparatorDate(dateTime);
  }

}
