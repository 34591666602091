import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { PassFilterData, WenNavigationHelper } from '../../../core/services/navigation/types';
import { removeFilter } from '../../../core/store/filter/filter.actions';
import { FilterEntityType } from '../../../core/store/filter/models/filter';
import { RootState } from '../../../core/store/root/public-api';
import { SectionService } from '../../../core/store/sections/sections.facade';
import { SharedModule } from '../../../shared/shared.module';
import { ChannelSectionComponent } from './components/channel-section/channel-section.component';
import { ScrollStateModule } from '../../../shared/directives/scroll-state/scroll-state.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ActionComponent, IsDesktopViewDirective, WithVariantDirective } from '@portal/wen-components';
import { map, Observable } from 'rxjs';
import { FilteredSectionDataSource } from '../../../core/store/sections/datasources/filtered-section-datasource';
import { DataContext } from '../../../core/common/types/data-context';
import { SectionProps } from '../../../core/common/util/section.model';
import { ChannelDTO } from '@portal/wen-backend-api';

@Component({
  selector: 'wen-channel-explore',
  templateUrl: './channel-explore.component.html',
  styleUrls: ['./channel-explore.component.scss'],
  imports: [
    TranslateModule,
    SharedModule,
    ChannelSectionComponent,
    ScrollingModule,
    ScrollStateModule,
    IsDesktopViewDirective,
    ActionComponent,
    WithVariantDirective
  ],
  standalone: true
})
export class ChannelExploreComponent implements OnInit {

  sectionsData$: Observable<{ dataSource: FilteredSectionDataSource<ChannelDTO>; name: string; sectionProps: SectionProps }[]> =
    this.sectionService.getAvailableSectionsBy(FilterEntityType.CHANNEL_LISTS).pipe(
    map(sections => sections.map(section => ({
      dataSource: new FilteredSectionDataSource<ChannelDTO>(section, DataContext.CHANNEL, this.sectionService),
      name: section.name,
      sectionProps: {
        filter: section.filter,
        filterEntityType: section.filterType,
        filterId: section.filterId
      }
    })))
  );

  constructor(
    private navigationHelper: WenNavigationHelper,
    private sectionService: SectionService,
    private store: Store<RootState>,
  ){}

  ngOnInit(): void {
    this.store.dispatch(removeFilter({ filterEntityType: FilterEntityType.CHANNEL_LISTS }));
  }

  navigateToChannelDiscovery(filterData?: PassFilterData){
    this.navigationHelper.navigateToChannelDiscovery(filterData);
  }

}
