import { inject, Injectable } from '@angular/core';
import { distinctUntilChanged, filter, first, map, repeat, shareReplay, switchMap, timer } from 'rxjs';
import { ConnectionMonitorService } from '../../../../core/services/connection-monitors/connection-monitor.service';
import { ConnectionScope } from '../../../../core/services/connection-monitors/types';
import { ErrorReasons } from './error-page-reason-content/error-page-reason-content.component';

@Injectable()
export class ErrorPageDatasource {

  /**
   * An array which defines an order of scopes most important for the user based on eg.:
   *  - If there is no internet it makes no sense to show the socket error
   *  - If no auth server it makes no sense to show the health status
   */
  private readonly scopeOrder: ConnectionScope[] = [
    ConnectionScope.INTERNET_CONNECTION,
    ConnectionScope.AUTH_SERVER,
    ConnectionScope.WEBSOCKET,
    ConnectionScope.HEALTH_STATUS,
  ];

  private readonly IDLE_TIMEOUT = 3000;
  private connectionMonitorService = inject(ConnectionMonitorService);

  attach() {
    const { isOnline$, isClientError$, offlineScopes$ } = this.connectionMonitorService.attach();
    const onOnline$ = isOnline$.pipe(
      filter(isOnline => isOnline),
      first(),
      shareReplay(1),
    );
    const onOffline$ = isOnline$.pipe(
      filter(isOnline => !isOnline),
      distinctUntilChanged(),
      shareReplay(1),
    );
    const onIdle$ = onOffline$.pipe(
      switchMap(() => timer(this.IDLE_TIMEOUT).pipe(
        repeat(),
        map(() => null)
      )),
    );
    const mostImportantScope$ = offlineScopes$.pipe(
      map(offlineScopes => this.calculateReason(offlineScopes)),
      distinctUntilChanged()
    );
    return {
      isClientError$,
      /**
       * Emit once if the client is online
       */
      onOnline$,
      /**
       * Repeatedly emit in an interval if the client is offline
       */
      onIdle$,
      /**
       * Repeatedly emit in an interval if the client is offline
       */
      mostImportantScope$,
    };
  }

  private calculateReason(offlineScopes: ConnectionScope[]): ErrorReasons {
    const scopeOrderVals = Object.values(this.scopeOrder);
    const sortedOrder = offlineScopes.sort((a, b) => scopeOrderVals.indexOf(a) - scopeOrderVals.indexOf(b));
    if (!sortedOrder.length) {
      return null;
    }
    const scope = sortedOrder[0];
    switch (scope) {
      case ConnectionScope.INTERNET_CONNECTION:
        return 'noInternet';
      case ConnectionScope.AUTH_SERVER:
        return 'auth';
      case ConnectionScope.HEALTH_STATUS:
        return 'healthStatus';
      case ConnectionScope.WEBSOCKET:
        return 'websocket';
      default:
        return 'unknown';
    }
  }

  forceRetry() {
    this.connectionMonitorService.forceRetry();
  }

}
