import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoadingSkeletonComponent } from './loading-skeleton.component';
import { LoadedContentDirective, SkeletonContentDirective } from './loading-skeleton.directives';

@NgModule({
  imports: [CommonModule],
  declarations: [
    LoadingSkeletonComponent,
    SkeletonContentDirective,
    LoadedContentDirective
  ],
  exports: [
    LoadingSkeletonComponent,
    SkeletonContentDirective,
    LoadedContentDirective
  ],
})
export class LoadingSkeletonModule { }
