import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ACTION_MENU_ITEMS, ActionComponent, WithVariantDirective } from '@portal/wen-components';
import { ActionMenuItemProvider } from '../../../core/services/util/action-menu-item.provider';
import { headerFeatureKey } from '../../../core/store/header/constants';
import { HeaderEffects } from '../../../core/store/header/header.effects';
import { headerReducerFactory } from '../../../core/store/header/header.reducers';
import { CHAT_MENU_COMPONENT, NEW_CHAT_ACTION_MENU } from '../../../core/store/header/menu/constants';
import { SearchBarModule } from '../../../shared/components/search-bar/search-bar.module';
import { FilterElementModule } from '../../../shared/directives/filter-element/filter-element.module';
import { NativeBridgeDebugModule } from '../../../shared/directives/native-bridge-debug/native-bridge-debug.module';
import { SharedModule } from '../../../shared/shared.module';
import { ChatCreationMenuComponent } from '../../../views/chat/chat-creation/chat-creation-menu/chat-creation-menu.component';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { DesktopHeaderComponent } from './components/desktop-header/desktop-header.component';
import { DesktopSubHeaderComponent } from './components/desktop-sub-header/desktop-sub-header.component';
import { HeaderCommandComponent } from './components/header-command/header-command.component';
import { MobileHeaderComponent } from './components/mobile-header/mobile-header.component';
import { HeaderComponent } from './components/wen-header/header.component';
import { GeoChannelDirectivesModule } from '../../../shared/directives/geo-channel/geo-channel-directives.module';

@NgModule({
  imports: [
    SharedModule,
    SearchBarModule,
    StoreModule.forFeature(headerFeatureKey, headerReducerFactory),
    EffectsModule.forFeature([HeaderEffects]),
    FilterElementModule,
    NativeBridgeDebugModule,
    GeoChannelDirectivesModule,
    ActionComponent,
    WithVariantDirective
  ],
  declarations: [
    HeaderComponent,
    AppHeaderComponent,
    MobileHeaderComponent,
    DesktopHeaderComponent,
    HeaderCommandComponent,
    DesktopSubHeaderComponent
  ],
  exports: [
    HeaderComponent,
    AppHeaderComponent,
    HeaderCommandComponent,
    DesktopSubHeaderComponent
  ],
  providers: [
    ActionMenuItemProvider,
    { provide: ACTION_MENU_ITEMS, useValue: NEW_CHAT_ACTION_MENU, multi: true },
    { provide: CHAT_MENU_COMPONENT, useValue: ChatCreationMenuComponent}
  ]
})
export class HeaderModule { }
