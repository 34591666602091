
<h2 class="cdk-visually-hidden" id="dialogHeaderLabel">{{ 'LIGHTBOX_HEADER_LABEL' | translate }}</h2>
<div id="lightbox-wrapper" [photoSwipe]="images" (pswpDestroy)="close()">
  @if (headerVisible$ | async) {
    <wen-lightbox-header
      @fadeInOut
      [isDownloading]="isDownloading$"
      [isFileDownloadEnabled]="isFileDownloadEnabled"
      (closeEvent)="close()"
      (downloadEvent)="onDownload()"
    ></wen-lightbox-header>
  }
  <wen-loading-indicator
    [style.visibility]="(isDownloading$ | async) ? 'visible' : 'hidden'"
    [size]="'icon-medium'"
    [color]="'light'"
  ></wen-loading-indicator>
</div>
