import { isGuidOnlyRoute } from '../../../core/common/util/routing-helpers';
import { channelDetailsLoadedGuardFn } from '../../../core/guards/channel-details-loaded.guard';
import { channelRestrictionsLoadedGuardFn } from '../../../core/guards/channel-restrictions-loaded-guard';
import { systemPrivilegesLoadedGuard } from '../../../core/guards/system-privileges-loaded.guard';
import { AlwaysHomeBackNavigation } from '../../../core/services/navigation/back-navigation/back-navigation-behaviors/always-home-back-navigation';
import { createDeepLinkedBackNavigation } from '../../../core/services/navigation/back-navigation/back-navigation-behaviors/deep-linked-back-navigation-behavior';
import { FormEditLeaveConfirmationBackNavigation } from '../../../core/services/navigation/back-navigation/back-navigation-behaviors/form-edit-leave-confirmation-behavior';
import { paramBasedRouteReuseCheck } from '../../../core/services/navigation/route-reuse-strategy/wen-route-reuse-strategy';
import { WenRoute, WenRouteId } from '../../../frame/routing/types';
import { commentListDataResolverFn } from '../../../shared/components/comments/resolvers/comment-list-data.resolver';
import { CreateWelcomeComponent } from '../../../shared/components/create-welcome/create-welcome.component';
import { CustomFormStoreKey } from '../../../shared/form-store/form-store-types';
import { ChannelAddAdminComponent } from '../channel-add-admin/channel-add-admin.component';
import { ChannelAdminListComponent } from '../channel-admin-list/components/channel-admin-list/channel-admin-list.component';
import { ChannelContentProviderListComponent } from '../channel-content-provider/channel-content-provider-list.component';
import { AddContentProviderComponent } from '../channel-content-provider/components/add-content-provider/add-content-provider.component';
import { ChannelDetailComponent } from '../channel-detail/components/channel-detail/channel-detail.component';
import { ChannelSettingsComponent } from '../channel-detail/components/channel-settings/channel-settings.component';
import { channelConfigurationResolver } from '../channel-detail/components/channel-settings/resolvers/channel-configuration.resolver';
import { ChannelInfoEditComponent } from '../channel-edit/components/channel-info-edit/channel-info-edit.component';
import { ChannelInteractionSettingsComponent } from '../channel-edit/components/channel-interaction-settings/channel-interaction-settings.component';
import { ChannelRestrictionSettingsComponent } from '../channel-edit/components/channel-restriction-settings/channel-restriction-settings.component';
import { ChannelVisibilitySettingsComponent } from '../channel-edit/components/channel-visibility-settings/channel-visibility-settings.component';
import { ChannelExploreComponent } from '../channel-explore/channel-explore.component';
import { ChannelFeaturedViewComponent } from '../channel-featured-view/channel-featured-view.component';
import { DiscoverChannelListComponent } from '../channel-list/components/discover-channel-list/discover-channel-list.component';
import { UserChannelListComponent } from '../channel-list/components/user-channel-list/user-channel-list.component';
import { discoverChannelListDataResolverFn } from '../channel-list/providers/discovery-channel-list-data.resolver';
import { ChannelMessageDetailComponent } from '../channel-message-detail/channel-message-detail.component';
import { ChannelMessageDetailDesktopComponent } from '../channel-message-detail/components/channel-message-detail-desktop/channel-message-detail-desktop.component';
import { channelMessageDetailsLoadedGuardFn } from '../channel-message-detail/guards/channel-message-detail.guard';
import { ChannelPostEditorComponent } from '../channel-post-editor/components/channel-post-editor/channel-post-editor.component';
import { ChannelPostLocationSelectorComponent } from '../channel-post-editor/components/channel-post-location-selector/channel-post-location-selector.component';
import { channelPostEditorDataResolverFn } from '../channel-post-editor/guards/channel-post-editor-edit.resolver';
import { ChannelSchedulesComponent } from '../channel-schedules//components/channel-schedules/channel-schedules.component';
import { ChannelSchedulesDesktopComponent } from '../channel-schedules/components/channel-schedules-desktop/channel-schedules-desktop.component';
import { scheduledMessageResolver } from '../channel-schedules/providers/scheduled-messages.resolver';
import { ChannelSubscriberListComponent } from '../channel-subscriber-list/channel-subscriber-list.component';
import { channelSubscriberListGuard } from '../channel-subscriber-list/guards/channel-subscriber-list.guard';
import { ChannelViewDesktopComponent } from '../channel-view/components/channel-view-desktop/channel-view-desktop.component';
import { ChannelViewComponent } from '../channel-view/components/channel-view/channel-view.component';
import { channelAddAdminsGuardFn } from '../channel-view/components/channel-view/guards/channel-add-admins.guard';
import { channelCreateGuardFn } from '../channel-view/components/channel-view/guards/channel-create.guard';
import { channelDeletedGuardFn } from '../channel-view/components/channel-view/guards/channel-deleted.guard';
import { channelListAdminsGuard } from '../channel-view/components/channel-view/guards/channel-list-admins.guard';
import { channelListContentProvidersGuard } from '../channel-view/components/channel-view/guards/channel-list-content-providers.guard';
import { channelEditPermissionGuardFn, channelSendPermissionGuardFn } from '../channel-view/components/channel-view/guards/channel-permission.guard';
import { ChannelSettingGuardFn } from '../channel-view/components/channel-view/guards/channel-setting.guard';
import { channelViewGuardFn } from '../channel-view/components/channel-view/guards/channel-view.guard';
import { joinRequestDeniedGuardFn } from '../channel-view/components/channel-view/guards/join-request-denied.guard';
import { joinRequestHandlingGuardFn } from '../channel-view/components/channel-view/guards/join-request-handling.guard';
import { channelElevatedPrivilegeMemberListResolver } from '../common/providers/channel-elevated-privilege-member-list-data.resolver';
import { channelSubscriberListResolver } from '../common/providers/channel-subscriber-list-data.resolver';
import { PendingChannelJoinRequestComponent } from '../pending-channel-join-request/pending-channel-join-request.component';
import { channelMessageDetailIdentifier, channelPostEditorMessageIdentifier, channelViewIdentifier } from '../tokens';

export const channelListRoute: WenRoute = {
  path: 'list',
  component: UserChannelListComponent,
  data: {
    routeId: WenRouteId.CHANNEL_LIST,
    routeTransitionEnabled: true,
    backNavigationBehavior: AlwaysHomeBackNavigation
  }
};

export const channelDiscoveryRoute: WenRoute = {
  path: 'discovery',
  component: DiscoverChannelListComponent,
  data: {
    routeId: WenRouteId.CHANNEL_DISCOVERY,
    routeTransitionEnabled: true
  },
  resolve: {
    DiscoverChannelListDataResolver: discoverChannelListDataResolverFn,
  }
};

export const channelExploreRoute: WenRoute = {
  path: 'explore',
  component: ChannelExploreComponent,
  data: {
    routeId: WenRouteId.CHANNEL_EXPLORE,
    routeTransitionEnabled: true
  }
};

export const channelDetailRoute: WenRoute = {
  path: `:${channelViewIdentifier}/detail`,
  canActivate: [channelRestrictionsLoadedGuardFn, channelDetailsLoadedGuardFn],
  component: ChannelDetailComponent,
  data: {
    routeId: WenRouteId.CHANNEL_DETAIL,
    backNavigationBehavior: createDeepLinkedBackNavigation({
      mobileTransition: {
        primary: WenRouteId.CHANNEL_VIEW,
      },
      desktopTransition: {
        dialog: null,
        primary: WenRouteId.CHANNEL_VIEW
      }
    })
  },
  resolve: {
    channelElevatedPrivilegeMemberListResolver,
    channelSubscriberListResolver
  }
};

export const channelSettingsRoute: WenRoute = {
  path: `:${channelViewIdentifier}/settings`,
  canActivate: [ChannelSettingGuardFn],
  component: ChannelSettingsComponent,
  data: {
    routeId: WenRouteId.CHANNEL_SETTING,
    backNavigationBehavior: createDeepLinkedBackNavigation({
      mobileTransition: {
        primary: WenRouteId.CHANNEL_DETAIL,
      },
      desktopTransition: {
        dialog: null,
        primary: WenRouteId.CHANNEL_DETAIL
      }
    })
  },
  resolve: {
    channelConfigurationResolver
  }
};

export const channelSchedulesRoute: WenRoute = {
  path: `:${channelViewIdentifier}/schedules`,
  component: ChannelSchedulesComponent,
  canActivate: [channelSendPermissionGuardFn],
  resolve: {
    scheduledMessageResolver
  },
  data: {
    routeId: WenRouteId.CHANNEL_SCHEDULES
  }
};

export const channelSchedulesDesktopRoute: WenRoute = {
  path: `:${channelViewIdentifier}/schedules`,
  component: ChannelSchedulesDesktopComponent,
  canActivate: [channelSendPermissionGuardFn],
  resolve: {
    scheduledMessageResolver
  },
  data: {
    routeId: WenRouteId.CHANNEL_SCHEDULES
  }
};

export const channelEditRoute: WenRoute = {
  path: `:${channelViewIdentifier}/edit`,
  component: ChannelInfoEditComponent,
  canActivate: [channelEditPermissionGuardFn],
  data: {
    routeId: WenRouteId.CHANNEL_INFO_EDIT,
    backNavigationBehavior: FormEditLeaveConfirmationBackNavigation
  }
};

export const channelVisibilitySettingsRoute: WenRoute = {
  path: `:${channelViewIdentifier}/visibility-settings`,
  component: ChannelVisibilitySettingsComponent,
  canActivate: [channelEditPermissionGuardFn],
  data: {
    routeId: WenRouteId.CHANNEL_VISIBILITY_SETTINGS,
    backNavigationBehavior: FormEditLeaveConfirmationBackNavigation,
  }
};

export const channelInteractionSettingsRoute: WenRoute = {
  path: `:${channelViewIdentifier}/interaction-settings`,
  component: ChannelInteractionSettingsComponent,
  canActivate: [channelEditPermissionGuardFn],
  data: {
    routeId: WenRouteId.CHANNEL_INTERACTION_SETTINGS,
    backNavigationBehavior: FormEditLeaveConfirmationBackNavigation,
  }
};

export const channelRestrictionSettingsRoute: WenRoute = {
  path: `:${channelViewIdentifier}/restriction-settings`,
  component: ChannelRestrictionSettingsComponent,
  canActivate: [
    channelRestrictionsLoadedGuardFn,
    channelEditPermissionGuardFn,
    systemPrivilegesLoadedGuard
  ],
  data: {
    routeId: WenRouteId.CHANNEL_RESTRICTION_SETTINGS,
    backNavigationBehavior: FormEditLeaveConfirmationBackNavigation,
  }
};

export const pendingChannelJoinRequestsRoute: WenRoute = {
  path: `:${channelViewIdentifier}/pending-join-requests`,
  canActivate: [joinRequestHandlingGuardFn],
  component: PendingChannelJoinRequestComponent,
  data: {
    routeId: WenRouteId.CHANNEL_PENDING_JOIN_REQUESTS,
    backNavigationBehavior: createDeepLinkedBackNavigation({
      mobileTransition: {
        primary: WenRouteId.CHANNEL_DETAIL,
      },
      desktopTransition: {
        dialog: WenRouteId.CHANNEL_DETAIL
      }
    })
  }
};

export const channelAdminListRoute: WenRoute = {
  path: `:${channelViewIdentifier}/admin-list`,
  component: ChannelAdminListComponent,
  canActivate: [channelListAdminsGuard],
  data: {
    routeId: WenRouteId.CHANNEL_ADMIN_LIST
  }
};

export const channelAddAdminRoute: WenRoute = {
  path: `:${channelViewIdentifier}/add-admin`,
  component: ChannelAddAdminComponent,
  canActivate: [channelAddAdminsGuardFn],
  data: {
    routeId: WenRouteId.CHANNEL_ADD_ADMIN
  }
};

export const channelContentProviderListRoute: WenRoute = {
  path: `:${channelViewIdentifier}/content-providers`,
  component: ChannelContentProviderListComponent,
  canActivate: [channelListContentProvidersGuard],
  data: {
    routeId: WenRouteId.CHANNEL_CONTENT_PROVIDERS
  }
};

export const channelAddContentProviderRoute: WenRoute = {
  path: `:${channelViewIdentifier}/content-providers/add`,
  component: AddContentProviderComponent,
  data: {
    routeId: WenRouteId.CHANNEL_ADD_CONTENT_PROVIDER
  }
};

export const channelSubscriberListRoute: WenRoute = {
  path: `:${channelViewIdentifier}/subscribers`,
  component: ChannelSubscriberListComponent,
  canActivate: [channelSubscriberListGuard],
  resolve: {
    channelSubscriberListResolver
  },
  data: {
    routeId: WenRouteId.CHANNEL_SUBSCRIBERS,
  }
};

export const channelViewRoute: WenRoute = {
  path: `:${channelViewIdentifier}`,
  canMatch: [isGuidOnlyRoute],
  component: ChannelViewComponent,
  canActivate: [
    channelViewGuardFn, channelDeletedGuardFn, joinRequestDeniedGuardFn
  ],
  data: {
    routeId: WenRouteId.CHANNEL_VIEW,
    routeTransitionEnabled: true,
    backNavigationBehavior: createDeepLinkedBackNavigation({
      mobileTransition: {
        primary: WenRouteId.CHANNEL_LIST,
      }
    })
  }
};

export const channelViewDesktopRoute: WenRoute = {
  path: `:${channelViewIdentifier}`,
  canMatch: [isGuidOnlyRoute],
  component: ChannelViewDesktopComponent,
  canActivate: [
    channelViewGuardFn, channelDeletedGuardFn, joinRequestDeniedGuardFn
  ],
  data: {
    routeId: WenRouteId.CHANNEL_VIEW,
    customRouteReuseCheck: paramBasedRouteReuseCheck(channelViewIdentifier),
    backNavigationBehavior: createDeepLinkedBackNavigation({
      desktopTransition: {
        primary: WenRouteId.DASHBOARD,
      }
    })
  }
};

export const channelPostEditorRoute: WenRoute = {
  path: `:${channelViewIdentifier}/post-editor`,
  canActivate: [channelSendPermissionGuardFn],
  children: [
    {
      path: `:${channelPostEditorMessageIdentifier}`,
      component: ChannelPostEditorComponent,
      resolve: {
        initialValue: channelPostEditorDataResolverFn,
      }
    },
    {
      path: '',
      component: ChannelPostEditorComponent,
    },
  ],
  data: {
    routeId: WenRouteId.CHANNEL_POST_EDITOR,
    formStoreStoreKey: CustomFormStoreKey.CHANNEL_POST_EDITOR_FORM_KEY,
    backNavigationBehavior: FormEditLeaveConfirmationBackNavigation,
  }
};

export const channelPostLocationSelectorRoute: WenRoute = {
  path: `:${channelViewIdentifier}/post-location-selector`,
  canActivate: [channelSendPermissionGuardFn],
  component: ChannelPostLocationSelectorComponent,
  data: {
    routeId: WenRouteId.CHANNEL_POST_LOCATION_SELECTOR,
    formStoreStoreKey: CustomFormStoreKey.CHANNEL_POST_EDITOR_FORM_KEY
  }
};

export const channelAddWelcomeRoute: WenRoute = {
  path: 'add-welcome',
  component: CreateWelcomeComponent,
  canActivate: [channelCreateGuardFn],
  data: {
    routeId: WenRouteId.CHANNEL_ADD_WELCOME
  }
};

export const addChannelInfoRoute: WenRoute = {
  path: 'add-channel-info',
  component: ChannelInfoEditComponent,
  canActivate: [channelCreateGuardFn],
  data: {
    routeId: WenRouteId.ADD_CHANNEL_INFO,
    backNavigationBehavior: FormEditLeaveConfirmationBackNavigation,
  }
};

export const addChannelVisibilityRoute: WenRoute = {
  path: 'add-channel-visibility',
  component: ChannelVisibilitySettingsComponent,
  canActivate: [channelCreateGuardFn],
  data: {
    routeId: WenRouteId.ADD_CHANNEL_VISIBILITY
  }
};

export const addChannelRestrictionsRoute: WenRoute = {
  path: 'add-channel-restrictions',
  component: ChannelRestrictionSettingsComponent,
  canActivate: [
    channelCreateGuardFn,
    systemPrivilegesLoadedGuard
  ],
  data: {
    routeId: WenRouteId.ADD_CHANNEL_RESTRICTIONS
  }
};

export const addChannelInteractionsRoute: WenRoute = {
  path: 'add-channel-interactions',
  component: ChannelInteractionSettingsComponent,
  canActivate: [channelCreateGuardFn],
  data: {
    routeId: WenRouteId.ADD_CHANNEL_INTERACTIONS
  }
};

export const channelFeaturedViewRoute: WenRoute = {
  path: 'featured',
  component: ChannelFeaturedViewComponent,
  data: {
    routeId: WenRouteId.CHANNEL_FEATURED_VIEW,
    routeTransitionEnabled: true
  },
  resolve: {
    parentInformation: commentListDataResolverFn,
  }
};

export const channelMessageDetailRoute: WenRoute = {
  path: `:${channelMessageDetailIdentifier}`,
  canMatch: [isGuidOnlyRoute],
  canActivate: [channelMessageDetailsLoadedGuardFn],
  component: ChannelMessageDetailComponent,
  data: {
    routeId: WenRouteId.CHANNEL_MESSAGE_DETAIL,
    backNavigationBehavior: createDeepLinkedBackNavigation({
      mobileTransition: {
        primary: WenRouteId.HOME,
      }
    })
  },
  resolve: {
    parentInformation: commentListDataResolverFn,
  }
};

export const channelMessageDetailDesktopRoute: WenRoute = {
  path: `:${channelMessageDetailIdentifier}`,
  canMatch: [isGuidOnlyRoute],
  canActivate: [channelMessageDetailsLoadedGuardFn],
  component: ChannelMessageDetailDesktopComponent,
  data: {
    routeId: WenRouteId.CHANNEL_MESSAGE_DETAIL,
    backNavigationBehavior: createDeepLinkedBackNavigation({
      desktopTransition: {
        primary: WenRouteId.HOME
      }
    })
  },
  resolve: {
    parentInformation: commentListDataResolverFn,
  }
};
