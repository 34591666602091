<wen-loading-skeleton class="wen-chat-view-content-slot-wrapper" [isLoaded]="isChatDataReady$ | async">
  <ng-container wen-loaded-content>
    <div class="wen-chat-view-content" portalDragOverlay [overlayContent]="overlayContent"
    (fileDropped)="onFilesDropped($event)">
      <wen-chat-messages-view [isGroupChat]="isGroupChat$ | async" [disableScrollToBottomButton]="false"
        [disableEmojiReaction]="false"></wen-chat-messages-view>
    </div>
    <div class="wen-chat-view-bottom-controls">
      <wen-chat-message-send-field [keepInputFocused]="keepInputFocused$ | async" (focused)="onFocusSendField()"
        (messageSent)="onMessageSent()">
      </wen-chat-message-send-field>
    </div>
  </ng-container>
  <ng-container wen-skeleton-content>
    <wen-chat-skeleton></wen-chat-skeleton>
  </ng-container>
</wen-loading-skeleton>