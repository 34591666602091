import { Component } from '@angular/core';
import { ConfirmationDialogModule, OverlayManager } from '@portal/wen-components';
import { WenStorageService } from '../../../../../core/services/storage/wen-storage.service';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AppConfigurationProvider } from '../../../../../core/services/configuration/app-confguration';
import { TranslateModule } from '@ngx-translate/core';
import { MarkdownTextModule } from '@portal/wen-components';

@Component({
  standalone: true,
  imports: [
    TranslateModule,
    MatCheckboxModule,
    FormsModule,
    ConfirmationDialogModule,
    MarkdownTextModule
],
  selector: 'wen-unencrypted-chat-dialog',
  templateUrl: './unencrypted-chat-dialog.component.html',
  styleUrls: ['./unencrypted-chat-dialog.component.scss']
})
export class UnencryptedChatDialogComponent {

  public doNotShowAgainSelected = false;
  public supportMail = this.appConfig.contactInfoConfig.supportEmail;

  constructor(
    public readonly overlayManager: OverlayManager,
    private readonly storageService: WenStorageService,
    private readonly appConfig: AppConfigurationProvider
  ) { }

  onAccept() {
    this.overlayManager.dialog.closeConfirmationDialog('ok');
    this.storageService.setUnencryptedChatDialogDismissedForSession();
    if (this.doNotShowAgainSelected) {
      this.storageService.setUnencryptedChatDialogDismissedForDevice();
    }
  }
}
