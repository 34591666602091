import type * as SentryTypes from '@sentry/angular';

export type SentryModuleType = typeof SentryTypes;

export type OfflineTransportOpts = Parameters<ReturnType<SentryModuleType['makeBrowserOfflineTransport']>>[0];

export const sentryDefaultParams: SentryTypes.BrowserOptions = {
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  profilesSampleRate: 1.0,
};
