<div class="wen-home-container">
  <div class="wen-home-cover" [wen-test-id]="'wen-home-cover'">
    <div class="wen-welcome-text" [wen-test-id]="'wen-home-welcome-title'">
      <h1 class="cdk-visually-hidden">{{'HOME_PAGE_WELCOME_TITLE' | translate }} {{'HOME_PAGE_WELCOME_TITLE_APP' | translate }}</h1>
      <div portal-display-1>{{ 'HOME_PAGE_WELCOME_TITLE' | translate }}</div>
      <div portal-display-2>{{ 'HOME_PAGE_WELCOME_TITLE_APP' | translate }}</div>
    </div>
    <img class="wen-home-background" [src]="homeCoverImage$ | async" [alt]="'HOME_COVER_IMAGE_ALT_TEXT' | translate" />
  </div>
  @if (featuredData$ | async; as featuredData) {
    <div class="wen-home-featured">
      <wen-message-preview-card (click)="featuredCardClicked()" [context]="featuredData" [wen-test-id]="'wen-home-featured-message-card'"></wen-message-preview-card>
    </div>
  }

  @for (section of featuredSectionConfigs  | slice:0:2; track section; let i = $index) {
    <wen-generic-section [config]="section" class="wen-home-section" [wen-test-id]="'wen-generic-section-' + i"></wen-generic-section>
  }

  @if (weatherData$ | async; as weatherData) {
    <div class="wen-home-weather" [wen-test-id]="'wen-home-weather'">
      <wen-weather
        [model]="weatherData"
        [ngClass]="{ 'wen-interactive': canNavigateOnWeatherWidget }"
        (click)="weatherClicked()">
      </wen-weather>
    </div>
  }

  @if (buttonSectionLink) {
    <div class="wen-home-button-section">
      <div class="wen-home-text wen-home-button-caption">{{ 'HOME_BUTTON_SECTION_DESCRIPTION' | translate }}</div>
      <wen-action class="wen-action-rounded" wenVariant="filled" [isNavigating]="true" (click)="buttonSectionClicked()">
        {{ 'HOME_BUTTON_SECTION_LABEL' | translate }}
      </wen-action>
    </div>
  }

  @for (section of featuredSectionConfigs | slice:2; track section; let i = $index) {
    <wen-generic-section [config]="section" class="wen-home-section" [wen-test-id]="'wen-generic-section-' + (i + 2)"></wen-generic-section>
  }
</div>
