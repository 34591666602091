import { Action, createReducer, on } from '@ngrx/store';
import { LoadingState } from '../../common/types/store-loading-state';
import { updateApp, updateAppCategories, updateAppDetail, updateAppPermission, updateApps, updateDiscoverAppsList, updateDiscoverAppsLoadingState, updateUserApps, updateUserAppsLoadingState, upsertAppMeta } from './apps.actions';
import { AppEntity, AppsState, appMetaAdapter, appsAdapter } from './apps.state';
import { appsHelper } from './state-adapter-helpers/app-state-adapter';

export const appsInitialState: AppsState = {
  apps: appsAdapter.getInitialState(),
  appMetas: appMetaAdapter.getInitialState(),
  discoverAppIds: [],
  userAppIds: [],
  userAppsLoading: LoadingState.NOT_LOADED,
  discoverAppsLoading: LoadingState.NOT_LOADED,
  categories: [],
  regions: []
};

const appsReducer = createReducer(
  appsInitialState,
  on(updateApp, updateAppDetail, (state, { app }): AppsState => {
    let newApps = state.apps;
    newApps = appsAdapter.upsertOne({ ...app } as AppEntity, newApps);
    return {
      ...state,
      apps: newApps
    };
  }),
  on(updateApps, (state, { apps }): AppsState => {
    let newApps = state.apps;
    newApps = appsAdapter.upsertMany(apps, newApps);
    return {
      ...state,
      apps: newApps
    };
  }),
  on(updateUserApps, (state, { apps }): AppsState => {
    let newApps = state.apps;
    apps.forEach((app) => {
      newApps = appsAdapter.upsertOne(app, newApps);
    });
    return {
      ...state,
      apps: newApps,
      userAppIds: [...apps.map(c => c.id)]
    };
  }),
  on(upsertAppMeta, (state, { appMetas }): AppsState => {
    const newAppMetas = appMetaAdapter.upsertMany(appMetas, state.appMetas);
    return {
      ...state,
      appMetas: newAppMetas
    };
  }),
  on(updateDiscoverAppsList, (state, { apps }): AppsState => {
    let newApps = state.apps;
    apps.forEach((app) => {
      newApps = appsAdapter.upsertOne(app, newApps);
    });
    return {
      ...state,
      apps: newApps,
      discoverAppIds: [...apps.map(c => c.id)]
    };
  }),
  on(updateUserAppsLoadingState, (state, { loadingState }) => {
    return { ...state, userAppsLoading: loadingState };
  }),
  on(updateDiscoverAppsLoadingState, (state, { loadingState }): AppsState => {
    return { ...state, discoverAppsLoading: loadingState };
  }),
  on(updateAppPermission, (state, { appId, permission }): AppsState => {
    return {
      ...state,
      apps: appsHelper.appPermission.updatePermissionFor(appId, permission, state.apps)
    };
  }),
  on(updateAppCategories, (state, { categories }) => {
    return {
      ...state,
      categories
    };
  })
);

export function appsReducerFactory(state: AppsState | undefined, action: Action) {
  return appsReducer(state, action);
}
