@if (previewData$ | async; as previewData) {
  <wen-horizontal-card>
    @if (previewData.thumbnail; as thumbnail) {
      <ng-container
        ngProjectAs="[wen-horizontal-card-image]">
        <wen-thumbnail-image wen-horizontal-card-image [thumbnail]="thumbnail" [fixWidth]="112" [fixHeight]="112">
        </wen-thumbnail-image>
      </ng-container>
    } @else {
      <wen-circle-image-view
        wen-horizontal-card-image shape="square"
        [imageUrl]="previewData.image"
        [placeholderText]="previewData.imagePlaceholder"
        [fontSize]="44"
      ></wen-circle-image-view>
    }
    <div wen-horizontal-card-title portal-subheading-1 [innerHTML]="previewData.title | removeMarkdown"></div>
    <div wen-horizontal-card-description portal-title [innerHTML]="previewData.description | removeMarkdown"></div>
    <div wen-horizontal-card-link portal-subheading-1>{{ 'HOME_CARD_OPEN_FEATURED' | translate }}</div>
  </wen-horizontal-card>
}

