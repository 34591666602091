import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PortalFieldModule } from '@portal/ui-kit/field';
import { ActionComponent, CoreComponentsModule, MarkdownTextModule, WithVariantDirective } from '@portal/wen-components';
import player from 'lottie-web';
import { provideLottieOptions  } from 'ngx-lottie';
import { HeaderModule } from '../../../frame/components/header/header.module';
import { SharedModule } from '../../../shared/shared.module';
import { OtherProfileViewComponent } from './components/other-profile/other-profile-view.component';
import { ProfileEditViewComponent } from './components/profile-edit/profile-edit-view.component';
import { ProfileReadViewComponent } from './components/profile-read/profile-read-view.component';
import { ContactResponseActionModule } from '../../../shared/components/contact-response-action/contact-response-action.module';
import { ProfileEditLocationSelectorComponent } from './components/profile-edit/profile-edit-location-selector/profile-edit-location-selector.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { LocationSelectorModule } from '../../../shared/components/location-selector/location-selector.module';
import { ChannelSectionComponent } from '../../channel/channel-explore/components/channel-section/channel-section.component';

export function playerFactory() {
  return player;
}

@NgModule({
    imports: [
        SharedModule,
        CoreComponentsModule,
        ScrollingModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        HeaderModule,
        PortalFieldModule,
        MarkdownTextModule,
        ContactResponseActionModule,
        MatCheckbox,
        LocationSelectorModule,
        ChannelSectionComponent,
        ActionComponent,
        WithVariantDirective
    ],
  providers: [
    provideLottieOptions({
      player: playerFactory
    })
  ],
  declarations: [
    ProfileReadViewComponent,
    ProfileEditViewComponent,
    OtherProfileViewComponent,
    ProfileEditLocationSelectorComponent
  ],
  exports: [
    ProfileReadViewComponent,
    ProfileEditViewComponent
  ]
})
export class ProfileFormViewModule { }
