<h1 *isDesktopView class="wen-user-event-header">{{ 'USER_EVENTS_LABEL' | translate }}</h1>

@if (loadResult$ | async; as loadResult) {
  <ng-container *ngTemplateOutlet="loadResult.data.length ?
    eventList : emptyListView; context: { data: loadResult.data, mode: loadResult.mode }">
  </ng-container>
}

<ng-template #eventList let-data="data">
  <wen-event-list (endReached)="endReached()" [events]="data" [listItemAfterRef]="subscriptionWithCount">
    <ng-template #subscriptionWithCount let-event="event">
      <wen-user-event-list-item-after [event]="event"></wen-user-event-list-item-after>
    </ng-template>
  </wen-event-list>
</ng-template>

<ng-template #emptyListView let-mode="mode">
  @if (mode === 'normal') {
    <wen-empty-list
      src="/assets/wen-widget/image/empty-event-list-background.svg" titleLabel="EMPTY_EVENT_LIST_TITLE"
    textLabel="EMPTY_EVENT_LIST_TEXT" [wen-test-id]="'wen-user-empty-list'"></wen-empty-list>
  } @else {
    <wen-empty-search-result></wen-empty-search-result>
  }
</ng-template>