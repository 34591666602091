<wen-widget>
  <wen-header></wen-header>

  <wen-navigation-bar>
    <wen-nav-bar-item forRoute="home"></wen-nav-bar-item>
    <wen-nav-bar-item forRoute="channel"></wen-nav-bar-item>
    <wen-nav-bar-item forRoute="apps"></wen-nav-bar-item>
    @if (enableEvents) {
      <wen-nav-bar-item forRoute="event"></wen-nav-bar-item>
    }
    @if (enableChat) {
      <wen-nav-bar-item forRoute="chat"></wen-nav-bar-item>
    }
  </wen-navigation-bar>
</wen-widget>
