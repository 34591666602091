<wen-dialog-layout [headerGravity]="headerGravity">
  <wen-dialog-layout-header>
    <ng-container ngProjectAs="[header]">
      @if (headerGravity === 'top') {
        <ng-container *ngTemplateOutlet="titleWithButtons" ngProjectAs="[header]"></ng-container>
      } @else {
        <ng-container *ngTemplateOutlet="cancelButton"></ng-container>
        <ng-container *ngTemplateOutlet="publishButton"></ng-container>
      }
    </ng-container>
  </wen-dialog-layout-header>
  @if (headerGravity === 'bottom') {
    <wen-dialog-layout-footer>
      <ng-container ngProjectAs="[header]">
        <ng-container *ngTemplateOutlet="title" ngProjectAs="[header]"></ng-container>
      </ng-container>
    </wen-dialog-layout-footer>
  }
  <div class="wen-poll-creation-dialog-content" dialogLayoutContent>
    <form [formGroup]="pollFormGroup" class="wen-form" wenFocusFirstInput>
      <div class="wen-form-group">
        <div class="wen-form-field-wrapper">
          <mat-form-field>
            <mat-label>{{'POLL_QUESTION_LABEL' | translate}}</mat-label>
            <textarea
              cdkTextareaAutosize
              matInput
              formControlName="question"
              [placeholder]="'POLL_QUESTION_PLACEHOLDER' | translate"
              autofocus="false"
              [maxlength]="CHARACTER_LIMIT"
            ></textarea>
            @if (characterCount$ | async; as characterCount) {
              <mat-hint
                align="end"
              >{{ characterCount }}/{{ CHARACTER_LIMIT }}</mat-hint>
            }
          </mat-form-field>
        </div>
        <div class="wen-form-field-wrapper">
          <div portal-caption class="wen-form-field-label">
            {{'POLL_OPTION_LABEL' | translate: {count: (pollOptions$ | async)} }}
          </div>
          <div formArrayName="options">
            @for (option of getOptionArrayControls(); track option; let index = $index) {
              <wen-poll-new-option
                [control]="option"
                [index]="index"
                [removable]="optionRemovable$ | async"
                (createNewOption)="createNewOption()"
                (remove)="removeOption($event)"
              ></wen-poll-new-option>
            }
          </div>
          <button
            portal-button
            class="wen-poll-creation-button"
            inlined
            [disabled]="addingNewOptionDisabled$ | async"
            (click)="createNewOption()"
            >
            <wen-icon iconName="add"></wen-icon>
            <span class="wen-add-poll-option-label">{{ 'POLL_ADD_OPTION_LABEL' | translate }}</span>
          </button>
          <div class="wen-form-group-hint" portal-subheading-2>{{ 'POLL_MAX_OPTION_LABEL' | translate: { maxOptions: MAX_OPTIONS } }}</div>
        </div>
      </div>
      <div class="wen-form-group">
        <mat-slide-toggle color="primary" class="wen-poll-toggle" formControlName="isMultiSelect">
          {{ 'POLL_MULTI_SELECT_LABEL' | translate }}
        </mat-slide-toggle>
      </div>
    </form>
  </div>
</wen-dialog-layout>

<ng-template #publishButton>
  <button headerRight portal-button inlined (click)="publish()" [disabled]="savingPollDisabled$ | async">
    <span>{{ 'PUBLISH_BUTTON_LABEL' | translate }}</span>
  </button>
</ng-template>

<ng-template #cancelButton>
  <button headerLeft portal-button inlined (click)="cancel()">
    <span>{{ 'CANCEL_BUTTON_LABEL' | translate }}</span>
  </button>
</ng-template>

<ng-template #title>
  <h2 id="dialogHeaderLabel" headerTitle class="wen-poll-creation-header-title">
    {{ 'POLL_CREATION_TITLE' | translate }}
  </h2>
</ng-template>

<ng-template #titleWithButtons>
  <ng-container *ngTemplateOutlet="cancelButton"></ng-container>
  <ng-container *ngTemplateOutlet="title"></ng-container>
  <ng-container *ngTemplateOutlet="publishButton"></ng-container>
</ng-template>

