import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { VariantConfig } from '../../../directives/styling/variant-config';
import { WenIconModule } from '../../wen-icon/wen-icon.module';
import { ActionOrientation, ActionVariantConfig, IconSize } from './types';

@Component({
  selector: 'wen-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss'],
  standalone: true,
  providers: [
    { provide: VariantConfig, useClass: ActionVariantConfig }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    WenIconModule,
    NgClass,
    NgTemplateOutlet
  ]
})
/*
* The action component is represented either by a <button> or a <a> if the action triggers a navigation.
* If the action contains only an icon and no text, then the iconName and iconSize can be defined directly on <wen-action>.
* The click handler should be defined on the <wen-action> itself.
* The altText is required for icon only actions.
* Examples:
*   Icon only:
*     <wen-action wenVariant="filled" iconName="save" [altText]="'SAVE_ACTION' | translate" (click)="save()">
*     </wen-action>
*   Icon and text:
*     <wen-action wenVariant="filled" iconName="save" (click)="save()">
*          <!-- The span is necessary here so that a margin-right can be applied between the icon and the text --!>
*          <span>{{ 'SAVE_ACTION' | translate }}
*     </wen-action>
*   Text only:
*     <wen-action wenVariant="filled" (click)="save()">
*          <span>{{ 'SAVE_ACTION' | translate }}
*     </wen-action>
* */
export class ActionComponent {

  @Input() iconName: string;
  @Input() iconSize: IconSize = 'large';
  @Input() altText: string;
  @Input() isDisabled: boolean;
  @Input() orientation: ActionOrientation = 'horizontal';

  /* If the action triggers a navigation, then isNavigating should be true. */
  @Input() isNavigating: boolean;

  @HostBinding('class.wen-action-disabled') get disabledClass() {
    return this.isDisabled === true;
  }

}
