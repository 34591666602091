import { inject, Injectable } from '@angular/core';
import { ChatNotificationEventEntity } from '../../../../core/store/notification/notification.state';
import { TranslateService } from '@ngx-translate/core';
import { lastItem } from '../../../../core/common/operators/array-utils';
import { ListItemDescription } from '../../../../shared/components/list-item/list-item.models';
import { EmbedService } from '../../../../core/services/util/embed-service';
import { isQuoteEmbed } from '@portal/wen-backend-api';

export interface MessageListItemDescriptor {
  getListDescription(message: ChatNotificationEventEntity): ListItemDescription;
}

@Injectable()
export class ChatMessageService implements MessageListItemDescriptor {

  private embedService = inject(EmbedService);
  private translationService = inject(TranslateService);

  getListDescription(message: ChatNotificationEventEntity): ListItemDescription {
    if (!message) {
      return;
    }
    if (message.redacted) {
      return { icon: 'delete', text: this.translationService.instant('CHAT_MESSAGE_DELETED') };
    }
    if (message.decryptionError) {
      return { text: this.translationService.instant('CHAT_MESSAGE_DECRYPTION_ERROR') };
    }
    const decryptedMessage = message.decryptedEvent.message;
    if (!decryptedMessage?.content && decryptedMessage?.embeds?.length) {
      const quoteLessEmbeds = decryptedMessage.embeds.filter(embed => !isQuoteEmbed(embed));
      const lastEmbed = lastItem(quoteLessEmbeds);
      return this.embedService.getEmbedDescription(lastEmbed);
    }
    return { text: decryptedMessage.content };
  }

}
