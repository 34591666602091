import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatListModule } from '@angular/material/list';
import { ListMassDataCommonModule } from '../common/list-mass-data-common.module';
import { SelectionListMassDataViewerItemTemplateDirective, SelectionListMassDataViewerSelectableDirective } from './directives/selection-list-mass-data-viewer.directives';
import { SelectionListMassDataViewerComponent } from './selection-list-mass-data-viewer.component';

@NgModule({
  imports: [
    CommonModule,
    ScrollingModule,
    MatListModule,
    ReactiveFormsModule,
    ListMassDataCommonModule,
  ],
  declarations: [
    SelectionListMassDataViewerComponent,
    SelectionListMassDataViewerItemTemplateDirective,
    SelectionListMassDataViewerSelectableDirective,
  ],
  exports: [
    SelectionListMassDataViewerComponent,
    SelectionListMassDataViewerItemTemplateDirective,
    SelectionListMassDataViewerSelectableDirective,
  ]
})
export class SelectionListMassDataViewerModule { }
