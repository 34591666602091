import { inject, Injectable, NgModule, StaticProvider } from '@angular/core';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { RecommendationDTO, SocketIoService } from '@portal/wen-backend-api';
import { MASS_DATA_CONFIG, MassDataConfigEntry, MassDataContainer, MassDataModule, MassDataRepository, MassDataStateAdapter, OffsetBasedMassDataPagingAdapter, PageRequestDescriptor } from '@portal/wen-data-core';
import { map } from 'rxjs';
import { userChannelRecommendationsIdentifier } from '../../constants';

type RecommendationEntity = RecommendationDTO;

@Injectable()
export class UserChannelRecommendationsMassDataContainer extends MassDataContainer<RecommendationEntity> {

  getIdentifier() {
    return userChannelRecommendationsIdentifier;
  }

}

@Injectable()
export class UserChannelRecommendationsMassDataAdapter extends MassDataStateAdapter<RecommendationEntity> {

  private userChannelRecommendationsEntityAdapter: EntityAdapter<RecommendationEntity>;

  getEntityAdapter() {
    if (!this.userChannelRecommendationsEntityAdapter) {
      this.userChannelRecommendationsEntityAdapter = createEntityAdapter<RecommendationEntity>({
        selectId: (recommendation) => recommendation.channelId
      });
    }
    return this.userChannelRecommendationsEntityAdapter;
  }

}

@Injectable()
export class UserChannelRecommendationsMassDataRepository extends MassDataRepository<UserChannelRecommendationsMassDataContainer> {

  private socketIoService = inject(SocketIoService);

  fetchPage(pagingParams: PageRequestDescriptor) {
    return this.socketIoService.channel.recommendationsListMy.acknowledgement$(pagingParams).pipe(
      map((response) => {
        return {
          data: response.recommendations.content
        };
      })
    );
  }

}

const UserChannelRecommendationsConfig: MassDataConfigEntry = {
  identifier: userChannelRecommendationsIdentifier,
  container: UserChannelRecommendationsMassDataContainer,
  adapter: UserChannelRecommendationsMassDataAdapter,
  repository: UserChannelRecommendationsMassDataRepository,
  pagingAdapter: OffsetBasedMassDataPagingAdapter,
};

const USER_CHANNEL_RECOMMENDATIONS_CONTAINER_CONFIG: StaticProvider = {
  provide: MASS_DATA_CONFIG,
  multi: true,
  useValue: UserChannelRecommendationsConfig,
};

@NgModule({
  imports: [
    MassDataModule,
  ],
  providers: [
    USER_CHANNEL_RECOMMENDATIONS_CONTAINER_CONFIG,
    UserChannelRecommendationsMassDataContainer,
    UserChannelRecommendationsMassDataAdapter,
    UserChannelRecommendationsMassDataRepository,
  ]
})
export class UserChannelRecommendationsMassDataModule { }
