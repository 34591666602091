import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { WenBreakpointObserver } from '../services/resize/wen-breakpoint-observer';

@Directive({
  standalone: true,
  selector: '[isDesktopView]'
})
export class IsDesktopViewDirective implements OnInit {

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private breakpointObserver: WenBreakpointObserver
  ) { }

  ngOnInit() {
    if (this.breakpointObserver.isDesktopStyleDevice) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
