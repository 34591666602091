import { Injectable } from '@angular/core';
import { isAbsoluteUrl, isMailUrl, isRelativeUrl } from '@portal/wen-common';
import { marked } from 'marked';

@Injectable({
  providedIn: 'root'
})
export class MarkdownService {
  private onlyContentRenderer: marked.Renderer;
  private safeAutolinkRenderer: marked.Renderer;
  private onlyUrlAndBasicFormattingTokenizer: marked.Tokenizer;
  private normalTokenizer: marked.Tokenizer;

  constructor() {
    this.onlyContentRenderer = this.createOnlyContentRenderer();
    this.safeAutolinkRenderer = this.createSafeAutolinkRenderer();
    this.onlyUrlAndBasicFormattingTokenizer = this.createOnlyUrlAndBasicFormattingTokenizer();
    this.normalTokenizer = this.createNormalTokenizer();
    this.disableImageRecognition();
  }

  private disableImageRecognition() {
    const walkTokens = (token) => {
      if (token.type === 'image') {
        token.type = 'text';
        token.text = token.raw;
      }
    };

    marked.use({ walkTokens });
  }

  format(text: string, markdownEnabled = true): string {
    return marked.parse(
      text,
      {
        renderer: this.safeAutolinkRenderer,
        headerIds: false,
        gfm: true,
        breaks: true,
        tokenizer: markdownEnabled ? this.normalTokenizer : this.onlyUrlAndBasicFormattingTokenizer
      }
    );
  }

  removeMarkdown(text: string, markdownEnabled = true): string {
    return marked.parse(
      text,
      {
        renderer: this.onlyContentRenderer,
        tokenizer: markdownEnabled ? this.normalTokenizer : this.onlyUrlAndBasicFormattingTokenizer
      },
    );
  }

  createSafeAutolinkRenderer(): marked.Renderer<never> {
    const renderer = new marked.Renderer();

    renderer.link = function(href, title, text) {
      if (!isRelativeUrl(href) && !isAbsoluteUrl(href) && !isMailUrl(href)) {
        href = 'https://' + href;
      } else {
        href = href.replace(new RegExp('http://', 'g'), 'https://');
      }
      const link = marked.Renderer.prototype.link.call(this, href, title, text);

      return link;
    };

    return renderer;
  }

  createOnlyContentRenderer(): marked.Renderer {
    const renderer = new marked.Renderer();

    renderer.code = (code, infostring, escaped) => code + ' ';
    renderer.blockquote = (quote) => '"' + quote + '" ';
    renderer.html = (html) => '';
    renderer.heading = (text, level, raw, slugger) => text + '\n';
    renderer.hr = () => '';
    renderer.list = (body, ordered, start) => body;
    renderer.listitem = (text) => '- ' + text + '\n';
    renderer.checkbox = (checked) => '';
    renderer.paragraph = (text) => text + ' ';
    renderer.table = (header, body) => '\n' + header + body;
    renderer.tablerow = (content) => content + '\n';
    renderer.tablecell = (content, flags) => content + ' ';
    renderer.strong = (text) => text + ' ';
    renderer.em = (text) => text + ' ';
    renderer.codespan = (code) => code + ' ';
    renderer.br = () => '';
    renderer.del = (text) => text;
    renderer.link = (href, title, text) => text + ' ';
    renderer.image = (href, title, text) => text + ' ';
    renderer.text = (text) => text;

    return renderer;
  }

  createNormalTokenizer(): marked.Tokenizer {
    const tokenizer = new marked.Tokenizer();

    //Disable html tags features
    tokenizer.html = () => null;
    tokenizer.tag = () => null;
    //Disable unknown
    tokenizer.def = () => null;

    return tokenizer;
  }

  createOnlyUrlAndBasicFormattingTokenizer(): marked.Tokenizer {
    const tokenizer = new marked.Tokenizer();

    //Disabled tokens
    tokenizer.code = () => null;
    tokenizer.fences = () => null;
    tokenizer.heading = () => null;
    tokenizer.hr = () => null;
    tokenizer.blockquote = () => null;
    tokenizer.list = () => null;
    tokenizer.html = () => null;
    tokenizer.def = () => null;
    tokenizer.table = () => null;
    tokenizer.lheading = () => null;
    tokenizer.paragraph = () => null;
    tokenizer.escape = () => null;
    tokenizer.tag = () => null;
    tokenizer.link = () => null;
    tokenizer.reflink = () => null;
    tokenizer.emStrong = () => null;
    tokenizer.codespan = () => null;
    tokenizer.del = () => null;
    tokenizer.autolink = () => null;

    // Enabled (still handled) tokens:
    // tokenizer.space = () => null;
    // tokenizer.text = () => null;
    // tokenizer.br = () => null;
    // tokenizer.url = () => null;
    // tokenizer.inlineText = () => null;

    return tokenizer;
  }
}
