import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RoomType } from '@portal/wen-backend-api';
import { ActionMenuComponent, ActionMenuItem, CircleImageViewModule, OverlayManager, RemoveMarkdownModule, WenIconModule } from '@portal/wen-components';
import { Observable } from 'rxjs';
import { ActionMenuItemProvider } from '../../../../../core/services/util/action-menu-item.provider';
import { MessageDateFormatterPipe } from '../../../../../shared/channel-specific/pipes/message-date-formatter/message-date-formatter.pipe';
import { ListItemModule } from '../../../../../shared/components/list-item/list-item.module';
import { MessageStatusIndicatorModule } from '../../../../../shared/components/message-status-indicator/message-status-indicator.module';
import { IsCurrentUserModule } from '../../../../../shared/directives/is-current-user/is-current-user.module';
import { ChatListItemModel } from '../../models/chat-list.models';
import { chatListItemContextMenuId } from './action-menu/chat-list-item.actions';
import { SharedModule } from '../../../../../shared/shared.module';

@Component({
  selector: 'wen-chat-list-item',
  templateUrl: './chat-list-item.component.html',
  styleUrls: ['./chat-list-item.component.scss'],
  imports: [
    RemoveMarkdownModule,
    MessageDateFormatterPipe,
    MessageStatusIndicatorModule,
    IsCurrentUserModule,
    WenIconModule,
    CircleImageViewModule,
    ListItemModule,
    SharedModule
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatListItemComponent {

  listItem: ChatListItemModel;
  actions$: Observable<ActionMenuItem[]>;
  roomType = RoomType;

  @Input() set chatItem(chatListItem: ChatListItemModel) {
    this.listItem = chatListItem;
    this.actions$ = this.actionMenuItemProvider.selectActions(chatListItemContextMenuId, chatListItem);
  }

  @Input() markdownEnabled: boolean;

  constructor(
    private actionMenuItemProvider: ActionMenuItemProvider,
    private overlayManager: OverlayManager
  ) { }

  openContextMenu(data: ChatListItemModel) {
    this.overlayManager.menu.open(ActionMenuComponent, { actions: this.actions$, data });
  }

}
