import { Injectable } from '@angular/core';
import { EmbeddedMediaDTO, EmbedDTOType, EmbedImageUploader, generateId, getMediaType, EmbedContextTypes, MediaUseCases, SocketIoService } from '@portal/wen-backend-api';
import { map, Observable, of, switchMap, tap } from 'rxjs';
import { WenStorageService } from '../../storage/wen-storage.service';
import { blobToFile } from '../../util/file-utils';
import { DistributionDataImporter } from './distribution-data-importer';

export type AttachmentData = { uploadId: string; attachmentGUID: string }[];

@Injectable()
export class DistributionChatAttachmentLoader {

  constructor(
    private readonly embedImageUploader: EmbedImageUploader,
    private readonly distributionDataImporter: DistributionDataImporter,
    private readonly wenStorageService: WenStorageService,
    private readonly socketIoService: SocketIoService,
  ) { }

  loadAttachmentAsEmbed(
    attachmentGUID: string,
  ): Observable<EmbeddedMediaDTO> {
    const attachmentCache$ = this.fetchCachedMediaEmbed(attachmentGUID);
    if (attachmentCache$) {
      return attachmentCache$;
    }
    return this.distributionDataImporter.loadDistributionMessageAttachment(attachmentGUID).pipe(
      map((attachment) => {
        const file = blobToFile(attachment.blob, attachment.extension, 'document');
        const embed: EmbeddedMediaDTO = {
          id: null,
          file,
          type: EmbedDTOType.MEDIA,
          subType: getMediaType(file),
          uploaded: false,
          playable: false,
          fullyProcessed: false,
        };
        return embed;
      })
    );
  }

  loadAttachmentForPreview(
    attachmentGUID: string,
  ): Observable<EmbeddedMediaDTO> {
    const attachmentCache$ = this.fetchCachedMediaEmbed(attachmentGUID);
    if (attachmentCache$) {
      return attachmentCache$;
    }
    return this.distributionDataImporter.loadDistributionMessageAttachment(attachmentGUID).pipe(
      switchMap((attachment) => {
        const file = blobToFile(attachment.blob, attachment.extension, 'document');
        const uploadId = this.generateUploadId(attachmentGUID);
        const embed: EmbeddedMediaDTO = {
          uploadId,
          id: null,
          file,
          type: EmbedDTOType.MEDIA,
          subType: getMediaType(file),
          uploaded: false,
          playable: false,
          fullyProcessed: false,
        };
        const previewContext = [{ type: EmbedContextTypes.PREVIEW, id: uploadId }];
        return this.embedImageUploader.createUpload(embed, previewContext, MediaUseCases.MESSAGE).pipe(
          tap((attachmentEmbed) => {
            if (attachmentEmbed.fullUrl) {
              const attachmentData = [{ uploadId, attachmentGUID }];
              this.wenStorageService.setCRMDistributedChatMessageAttachmentData(attachmentData);
            }
          }),
        );
      })
    );
  }

  invalidateAttachmentDataCache() {
    this.wenStorageService.clearCRMDistributedChatMessageAttachmentData();
  }

  private fetchCachedMediaEmbed(attachmentGUID: string): Observable<EmbeddedMediaDTO> {
    const attachmentCache = this.wenStorageService.getCRMDistributedChatMessageAttachmentData();
    const attachmentData = attachmentCache && attachmentCache.find(attachment => attachment.attachmentGUID === attachmentGUID);
    if (attachmentData) {
      const { uploadId } = attachmentData;
      return this.socketIoService.media.get.acknowledgement$({
        uploadId,
        contextId: uploadId,
        contextType: EmbedContextTypes.PREVIEW
      }).pipe(switchMap(media => {
        if (!Boolean(media.error)) {
          return of(media);
        }
        return this.socketIoService.media.get.acknowledgement$({
          uploadId,
          contextId: uploadId,
          contextType: EmbedContextTypes.CHAT_MESSAGE
        });
      }));
    }
    return null;
  }

  private generateUploadId(attachmentGUID: string): string {
    const embedCache = this.wenStorageService.getCRMDistributedChatMessageAttachmentData();
    const attachmentData: AttachmentData = Boolean(embedCache) ? embedCache : null;
    if (Boolean(attachmentData?.length) && attachmentData[0]?.attachmentGUID === attachmentGUID && Boolean(attachmentData[0].uploadId)) {
      return attachmentData[0].uploadId;
    }
    return generateId();
  }

}
