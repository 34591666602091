import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ChatGetAutoReplyResponse, SocketIoService } from '@portal/wen-backend-api';
import { whitespaceValidator } from '@portal/wen-components';
import { Observable, Subject, map, merge, shareReplay, takeUntil } from 'rxjs';
import { firstExisty } from '../../../../core/common/operators/first-existy';
import { validCharacterCount } from '../../../../core/common/util/text-utils';
import { FormStoreService } from '../../../../shared/form-store/form-store.service';
import { HeaderDataHelper } from '../../../../core/services/util/header-data-helper';

@Component({
  selector: 'wen-auto-reply-edit',
  templateUrl: './auto-reply-edit.component.html',
  styleUrls: ['./auto-reply-edit.component.scss']
})
export class AutoReplyEditComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();

  maximumReplyLength = 150;
  itemCount$: Observable<number>;
  autoReplyResult$: Observable<ChatGetAutoReplyResponse>;
  isEnabled$: Observable<boolean>;

  validators = [Validators.required, whitespaceValidator];

  autoReplyFormGroup = new FormGroup({
    enabled: new FormControl(false),
    replyMessage: new FormControl('')
  });

  constructor(
    private readonly formStoreService: FormStoreService,
    private readonly socketIoService: SocketIoService,
    private headerDataHelper: HeaderDataHelper
  ) { }

  ngOnInit(): void {
    this.headerDataHelper.initializeContextTitleWithUserName(true);
    this.autoReplyFormGroup.controls.enabled.valueChanges.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(() => this.updateFormValidator());
    this.isEnabled$ = this.autoReplyFormGroup.controls.enabled.valueChanges.pipe(
      map(value => value)
    );

    this.socketIoService.chat.getAutoReply.emit({});
    this.autoReplyResult$ = this.socketIoService.chat.getAutoReply.listen.pipe(
      shareReplay(1)
    );

    this.itemCount$ = merge(
      this.autoReplyFormGroup.controls.replyMessage.valueChanges,
      this.autoReplyResult$.pipe(map(result => result.replyMessage))
    ).pipe(
      map(value => validCharacterCount(value)),
      shareReplay(1)
    );

    this.autoReplyResult$.pipe(
      firstExisty(),
      map(({ enabled, replyMessage }) => {
        return {
          enabled,
          replyMessage
        };
      }),
      takeUntil(this.onDestroy$)
    ).subscribe(data => {
      this.formStoreService.initializeForm(data);
    });
  }

  clearInput() {
    this.autoReplyFormGroup.controls.replyMessage.markAsDirty();
    this.autoReplyFormGroup.controls.replyMessage.setValue('');
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  updateFormValidator() {
    if (this.autoReplyFormGroup.controls.enabled.value) {
      this.autoReplyFormGroup.controls.replyMessage.setValidators(this.validators);
    } else {
      this.autoReplyFormGroup.controls.replyMessage.setValidators(null);
    }

    this.autoReplyFormGroup.controls.replyMessage.updateValueAndValidity();
  }
}
