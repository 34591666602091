import { Injectable } from '@angular/core';
import type * as Sentry from '@sentry/angular';
import { connectable, first, from, Subject, switchMap } from 'rxjs';
import { getSentryIfLoaded } from '../sentry-wrapper';
import { TracerBreadcrumbParams } from './tracer-types';

@Injectable()
export class Tracer {

  addBreadcrumb(params: TracerBreadcrumbParams) {
    getSentryIfLoaded().subscribe(sentryModule => sentryModule.addBreadcrumb(params));
  }

  captureException(
    data: any,
    hintData?: Record<string, string>,
  ) {
    const hint: Sentry.EventHint = {
      data: hintData
    };
    getSentryIfLoaded().subscribe(sentryModule => sentryModule.captureException(data, hint));
  }

  captureMessage(
    data: string
  ) {
    getSentryIfLoaded().subscribe(sentryModule => sentryModule.captureMessage(data));
  }

  flush(timeout?: number) {
    const flush$ = getSentryIfLoaded().pipe(
      switchMap((sentryModule) => {
        return from(sentryModule.flush(timeout));
      }),
    );
    const source$ = connectable(flush$, { connector: () => new Subject(), resetOnDisconnect: false });
    source$.connect();
    return source$.pipe(first());
  }

}
