import { Injectable } from '@angular/core';
import { EmbeddedMedia } from '../../../../core/store/channel/channel.state';
import { MessageContentBoundaryConfig } from '@portal/wen-components';

export type MessageBoxLayoutConfig = {
  inlineContent: boolean;
  bubbleTail: boolean;
  textBelowEmbeds: boolean;
  footerInformation: boolean;
};

export interface MessageCustomizations {
  handleMediaEmbeds(mediaEmbeds: EmbeddedMedia[], message?: any): EmbeddedMedia[];
  handleMessageValidation?(message?: any): boolean;
  canOpenContextMenu(message?: any): boolean;
  canSwipe(message?: any): boolean;
  getClassOverrides(message?: any): { [className: string]: boolean };
  getLayoutConfig(message?: any): MessageBoxLayoutConfig;
  getContentBoundaryConfig(message?: any): MessageContentBoundaryConfig;
}

@Injectable()
export abstract class MessageBoxCustomizations implements MessageCustomizations {

  abstract handleMediaEmbeds(mediaEmbeds: EmbeddedMedia[], message?: any): EmbeddedMedia[];

  handleMessageValidation(message: any): boolean {
    return !!message;
  }

  canOpenContextMenu(message?: any): boolean {
    return true;
  }

  canSwipe(message?: any): boolean {
    return false;
  }

  protected noopMediaFilter(mediaEmbeds: EmbeddedMedia[]): EmbeddedMedia[] {
    return mediaEmbeds;
  }

  getClassOverrides(message?: any): { [className: string]: boolean } {
    return {};
  }

  getLayoutConfig(message?: any): MessageBoxLayoutConfig {
    return {
      inlineContent: false,
      bubbleTail: false,
      textBelowEmbeds: false,
      footerInformation: false
    };
  }

  getContentBoundaryConfig(message?: any): MessageContentBoundaryConfig {
    return null;
  }
}
