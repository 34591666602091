import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ChannelUpdatePayload, EmbedContextTypes, MediaUseCases, SocketIoService, WeLocalImageHelper } from '@portal/wen-backend-api';
import { WenSnackbarOpener } from '@portal/wen-components';
import { EMPTY, merge, Observable, of } from 'rxjs';
import { filter, first, map, mergeAll, mergeMap, switchMap, tap } from 'rxjs/operators';
import { FormStoreMediator } from '../../../../shared/form-store/form-store-mediator';
import { mapWithFirstFrom } from '../../../common/operators/map-with-first-from';
import { hasProperty } from '../../../common/operators/null-check-util';
import { deepMerge } from '../../../common/util/deepmerge';
import { WenNavigationHelper } from '../../../services/navigation/types';
import { selectCurrentUserData } from '../../auth/auth.selectors';
import { UserData } from '../../auth/models/UserData';
import { DataObjectType } from '../../common/data-objects';
import { doBackNavigation } from '../../header/header.actions';
import { RootState } from '../../root/public-api';
import { clearAllFormValues, clearFormValues } from '../form.actions';
import { selectAllEditForms, selectEditFormById } from '../form.selectors';
import { EditFormEntity } from '../form.state';

@Injectable()
export class ChannelFormEffects {

  saveEditChannelForm$ = this.formStoreMediator.createSaveEditFormEffect((saveAction) => of(saveAction).pipe(
    filter(action => action.dataObjectType === DataObjectType.CHANNEL),
    switchMap(({ formId }) => this.store.pipe(
      select(selectEditFormById(formId)),
      first()
    )),
    filter((editForm) => Boolean(editForm?.initialValues?.id)),
    mapWithFirstFrom(() => this.store.pipe(select(selectCurrentUserData))),
    switchMap(([editForm, userData]) => {
      const { formId, changedValues, initialValues } = editForm;
      const { icon, ...simpleChangedValues } = changedValues;
      const iconRemoved = hasProperty(changedValues, 'icon') && !icon;
      const { id } = initialValues;
      const channelUpdate: ChannelUpdatePayload = {
        id,
        userId: userData.userId,
        ...simpleChangedValues
      };
      const afterSaveActions: Observable<Action>[] = [of([
        clearFormValues({ formId }),
        doBackNavigation(),
      ]).pipe(mergeAll())];

      if (iconRemoved) {
        channelUpdate.icon = '';
      } else if (icon) {
        const updateImageAction = this.uploadAndUpdateChannelImage(icon, id, userData);
        afterSaveActions.push(updateImageAction);
      }
      this.socketIoService.channel.update.emit(channelUpdate);
      const result$ = merge(
        afterSaveActions
      ).pipe(mergeAll());
      return result$;
    })
  ));

  saveCreateChannelForm$ = this.formStoreMediator.createSaveEditFormEffect((saveAction) => of(saveAction).pipe(
    filter(action => action.dataObjectType === DataObjectType.CHANNEL),
    switchMap(() => this.store.pipe(
      select(selectAllEditForms),
      first()
    )),
    map(editForms => {
      let channel = {} as EditFormEntity;
      Object.keys(editForms).forEach(key => {
        channel = deepMerge(channel, editForms[key]);
      });
      return channel;
    }),
    filter((editForm) => !Boolean(editForm?.initialValues?.id)),
    mapWithFirstFrom(() => this.store.pipe(select(selectCurrentUserData))),
    switchMap(([editForm, userData]) => {
      const { changedValues } = editForm;
      const { icon, ...simpleChangedValues } = changedValues;

      this.socketIoService.channel.create.emit({
        userId: userData.userId,
        title: simpleChangedValues.title,
        ...simpleChangedValues
      });
      return this.socketIoService.channel.create.listen.pipe(
        first(),
        tap(({ id }) => {
          if (id) {
            this.navigationHelper.navigateToChannelView(id, { replaceUrl: true }, true);
          } else {
            this.navigationHelper.navigateToChannelList({ replaceUrl: true });

            this.wenSnackbarOpener.openNotificationSnackbar({
              notificationIcon: 'error_robot',
              notificationText: this.translateService.instant('ERROR_GENERIC_DIALOG_MESSAGE')
            });
          }
        }),
        switchMap(({ id }) => {
          const afterSaveActions: Observable<Action>[] = [of(clearAllFormValues())];
          if (icon) {
            const updateImageAction = this.uploadAndUpdateChannelImage(icon, id, userData);
            afterSaveActions.push(updateImageAction);
          }
          return merge(afterSaveActions).pipe(mergeAll());
        })
      );
    }),
  ));

  saveChannelRecommendationsForm$ = this.formStoreMediator.createSaveEditFormEffect(saveAction => of(saveAction).pipe(
    filter(action => action.dataObjectType === DataObjectType.CHANNEL_RECOMMEND),
    switchMap(({ formId }) => this.store.pipe(select(selectEditFormById(formId)), first())),
    switchMap((editForm) => {
      const { formId, initialValues, changedValues } = editForm;

      if (changedValues.selectedRecommendedChannels) {
        const { selectedRecommendedChannels } = changedValues;

        const removedItems = initialValues.filter(item =>
          !selectedRecommendedChannels.some(selected => item.channelId === selected.channelId));
        const addedItems = selectedRecommendedChannels.filter(selected =>
          !initialValues.some(item => item.channelId === selected.channelId));

        this.socketIoService.channel.recommendationsUpdate.acknowledgement$({
          recommendedChannels: [
            ...removedItems.map(item => {
              return { channelId: item.channelId, isRecommended: false };
            }),
            ...addedItems.map(item => {
              return { channelId: item.channelId, isRecommended: true };
            })
          ]
        });
      }

      const afterSaveActions: Observable<Action>[] = [of([
        clearFormValues({ formId }),
        doBackNavigation(),
      ]).pipe(mergeAll())];

      return merge(afterSaveActions).pipe(mergeAll());
    })
  ));


  constructor(
    private store: Store<RootState>,
    private socketIoService: SocketIoService,
    private imageHelper: WeLocalImageHelper,
    private navigationHelper: WenNavigationHelper,
    private formStoreMediator: FormStoreMediator,
    private wenSnackbarOpener: WenSnackbarOpener,
    private translateService: TranslateService
  ) { }

  private uploadAndUpdateChannelImage(icon: File, id: string, userData: UserData) {
    return this.imageHelper.uploadImage(icon, [{ type: EmbedContextTypes.CHANNEL, id}], MediaUseCases.PROFILE).pipe(
      tap((result) => {
        this.socketIoService.channel.update.emit({
          id,
          userId: userData.userId,
          icon: result.thumbnailUrl
        });
      }),
      mergeMap(() => EMPTY),
    );
  }

}
