import { NgModule } from '@angular/core';
import { ListMassDataViewerItemTemplateDirective } from './directives/list-mass-data-viewer.directives';
import { ListMassDataViewerComponent } from './list-mass-data-viewer.component';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ListMassDataCommonModule } from '../common/list-mass-data-common.module';

@NgModule({
  imports: [
    CommonModule,
    ScrollingModule,
    ListMassDataCommonModule,
  ],
  declarations: [
    ListMassDataViewerComponent,
    ListMassDataViewerItemTemplateDirective,
  ],
  exports: [
    ListMassDataViewerComponent,
    ListMassDataViewerItemTemplateDirective,
  ]
})
export class ListMassDataViewerModule { }
