import { ComponentType } from '@angular/cdk/portal';
import { ComponentRef, Directive, inject, Input, OnDestroy, reflectComponentType, ViewContainerRef } from '@angular/core';
import { EmbeddedForwardDTO, EmbedDTOType } from '@portal/wen-backend-api';
import { isNullOrUndefined } from '../../../../../core/common/operators/null-check-util';
import { Embed } from '../../../../../core/store/channel/channel.state';
import { EmbedForwardComponent } from './embed-forward/embed-forward.component';
import { componentTypeForEmbeds } from './models/embed.models';

const EmbedOrder: EmbedDTOType[] = [
  EmbedDTOType.EXPANDABLE,
  EmbedDTOType.QUOTE,
  EmbedDTOType.FORWARD,
  EmbedDTOType.DOCUMENT,
  EmbedDTOType.MEDIA,
  EmbedDTOType.LINK,
  EmbedDTOType.NOTIFICATION,
  EmbedDTOType.WEATHER,
  EmbedDTOType.POLL,
  EmbedDTOType.LOCATION
];

export interface EmbedHostParams {
  embeds: Embed[];
  forwardedComponent: ComponentType<any>;
  messageId: string;
}

@Directive({ selector: '[wen-embed-host]' })
export class EmbedHostDirective implements OnDestroy {

  private viewContainerRef = inject(ViewContainerRef);
  private embedComponentReferenceCache = new Map<string, ComponentRef<any>>();
  private embedTypeList = new Array<EmbedDTOType>();
  private subMessageBoxComponentRef;

  @Input('wen-embed-host') set messageEmbeds(params: EmbedHostParams) {
    if (isNullOrUndefined(params)) {
      return;
    }
    const { embeds, forwardedComponent, messageId } = params;
    const newEmbedTypeList = embeds.map(e => e.type);
    if (!this.sameEmbeds(newEmbedTypeList)) {
      this.clear();
    }
    this.embedTypeList = newEmbedTypeList;
    const components = this.getComponentsForEmbeds(embeds);
    components.filter(component => !!component).forEach((component, index) => {
      const componentRef: ComponentRef<any> = this.tryGetComponentReference(component, index);
      if (component === EmbedForwardComponent) {
        componentRef.hostView.detectChanges();
        const forwardComponentInstance: EmbedForwardComponent = componentRef.instance;
        const forwardData = (embeds[index] as EmbeddedForwardDTO).forwardData.data;
        if (!this.subMessageBoxComponentRef) {
          this.subMessageBoxComponentRef = forwardComponentInstance.contentContainerRef.createComponent(forwardedComponent);
        }
        this.subMessageBoxComponentRef.setInput('message', forwardData);
      } else {
        componentRef.setInput('model', embeds[index]);
        const componentMetadataInputs = reflectComponentType(componentRef.componentType).inputs;
        const hasMessageIdInput = componentMetadataInputs.some(inputProps => inputProps.propName === 'messageId');
        if (hasMessageIdInput) {
          componentRef.setInput('messageId', messageId);
        }
      }
    });
  }

  private tryGetComponentReference(targetComponentType: ComponentType<any>, index: number): ComponentRef<any> {
    const componentIndex = `${targetComponentType.name}_${index}`;
    let componentRef = this.embedComponentReferenceCache.get(componentIndex);
    if (!componentRef) {
      componentRef = this.viewContainerRef.createComponent(targetComponentType);
      this.embedComponentReferenceCache.set(componentIndex, componentRef);
    }
    return componentRef;
  }

  private getComponentsForEmbeds(embeds: Embed[]): ComponentType<any>[] {
    if (embeds.length === 0) {
      this.clear();
      return [];
    }

    embeds.sort((a, b) => (EmbedOrder.indexOf(a.type) - EmbedOrder.indexOf(b.type)) );

    const matchingComponents = embeds.filter(e => !!e).map(embed => componentTypeForEmbeds[embed.type]);
    return matchingComponents;
  }

  private clear() {
    this.embedComponentReferenceCache.clear();
    this.viewContainerRef.clear();
  }

  ngOnDestroy(): void {
    this.embedComponentReferenceCache.clear();
  }

  private sameEmbeds(newEmbeds: EmbedDTOType[]): boolean {
    if (this.embedTypeList?.length !== newEmbeds?.length) {
      return false;
    }

    return this.embedTypeList.every(
      (existingEmbed, index) => existingEmbed === newEmbeds[index]
    );
  }
}
