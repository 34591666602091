import { Directive, forwardRef, HostBinding } from '@angular/core';
import { ContentBoundaryProvider } from '../content-boundary/content-boundary.directive';
import { Dimensions2D } from '../../util/size';
import { FitType } from '../../util/scale-helper';
import { distinctUntilChanged, map, Observable } from 'rxjs';
import { ThumbnailCustomizations } from './thumbnail-customizations';

const MIN_MEDIA_HEIGHT = 90;
const DEFAULT_MAX_MEDIA_HEIGHT = 400;
const MIN_ASPECT_RATIO = 9 / 16;
const MIN_INPUT_SIZE = 1;

export type ThumbnailSizeParams = {
  minWidth: number;
  /** The size of the canvas where the image is rendered onto */
  canvasDimensions: Dimensions2D;
  /** The size of the rendered image */
  scaledDimensions: Dimensions2D & { fitType: FitType };
  /** The position where the rendered image is placed onto the canvas */
  targetOffset: Dimensions2D;
  /** The area of the original image that gets put onto the canvas */
  usedDimensions: Dimensions2D;
  /** The position of the used area inside the original image */
  sourceOffset: Dimensions2D;
};

export enum ObjectFit {
  /** Fills the width and sets the height */
  FILL_WIDTH,
  /** The image is scaled keeping the aspect ratio, but parts that stick out the canvas will be clipped */
  COVER,
  /** The image is scaled keeping the aspect ratio and might not cover the canvas, but in most cases the image will be fully visible */
  CONTAIN,
}

export abstract class ThumbnailSizeHelperProvider {
  abstract calculateSize(width: number, height: number, fit?: ObjectFit): Observable<ThumbnailSizeParams>;
}

@Directive({
  selector: 'wenThumbnailSizeHelper, [wenThumbnailSizeHelper]',
  providers: [{
    provide: ThumbnailSizeHelperProvider,
    useExisting: forwardRef(() => ThumbnailSizeHelperDirective)
  }],
})
export class ThumbnailSizeHelperDirective extends ThumbnailSizeHelperProvider {

  @HostBinding('style.max-width.px') maxWidth: number;

  constructor(
    private contentBoundaryProvider: ContentBoundaryProvider,
    private thumbnailCustomizations: ThumbnailCustomizations,
  ) {
    super();
  }

  calculateSize(width: number, height: number, fit?: ObjectFit) {
    const { objectFit, maxHeight, minAspectRatio, applyMaxWidth } = this.thumbnailCustomizations.getConfig();
    const sanitizedWidth = Math.max(Math.abs(width), MIN_INPUT_SIZE);
    const sanitizedHeight = Math.max(Math.abs(height), MIN_INPUT_SIZE);
    return this.contentBoundaryProvider.contentBoundary$.pipe(
      map(boundary => boundary.width),
      distinctUntilChanged(),
      map(boundaryWidth => {
        const config = ThumbnailSizeHelperDirective.calculateSize(
          sanitizedWidth, sanitizedHeight,
          fit ?? objectFit, boundaryWidth,
          minAspectRatio, maxHeight
        );
        const maxContentWidth = Math.max(config.scaledDimensions.width, config.minWidth);
        if (applyMaxWidth) {
          this.maxWidth = maxContentWidth < boundaryWidth ? maxContentWidth : null;
        }
        return config;
      }),
    );
  }

  /**
   * Calculates the fit type of the aspect ratio.
   * @param aspectRatio The aspect ratio of the image
   * @param minAspectRatio The minimum aspect ratio (eg. width is smaller than height)
   * @param maxAspectRatio The maximum aspect ratio (eg. height is smaller than width)
   * @returns The appropriate fit type for the given aspect ratio
   */
  static calculateFitType(aspectRatio: number, minAspectRatio: number, maxAspectRatio: number): FitType {
    return aspectRatio < minAspectRatio ? FitType.TOO_TALL
      : aspectRatio > maxAspectRatio ? FitType.TOO_WIDE
      : FitType.FIT;
  }

  /**
   * Calculates the size of an image canvas. Respects minimum and maximum aspect ratios.
   * Uses maximum height for aspect ratios >= 1 and maximum width for aspect ratios < 1.
   * @param aspectRatio The aspect ratio of the image
   * @param minAspectRatio The minimum aspect ratio (eg. width is smaller than height)
   * @param maxAspectRatio The maximum aspect ratio (eg. height is smaller than width)
   * @param maxWidth The maximum width the canvas is allowed to have
   * @param maxHeight The maximum height the canvas is allowed to have
   * @returns The calculated size of the canvas
   */
  static calculateCanvasDimensions(aspectRatio, minAspectRatio, maxAspectRatio, maxWidth, maxHeight): Dimensions2D {
    const canvasAspectRatio = Math.max(minAspectRatio, Math.min(aspectRatio, maxAspectRatio));
    const canvasHeight = Math.min(maxWidth / canvasAspectRatio, maxHeight);
    const canvasWidth = canvasHeight * canvasAspectRatio;
    return { width: canvasWidth, height: canvasHeight };
  }

  static calculateFillWidthSize(
    width: number, height: number,
    minWidth: number,
  ): ThumbnailSizeParams {
    return {
      minWidth,
      canvasDimensions: { width, height },
      scaledDimensions: { width, height, fitType: FitType.FIT },
      usedDimensions: { width, height  },
      targetOffset: { width: 0, height: 0 },
      sourceOffset: { width: 0, height: 0 },
    };
  }

  /**
   * Calculates size configuration for to cover the canvas. Fills the maxWidth.
   * @param width The width of the image
   * @param height The height of the image
   * @param minWidth The minimum width the canvas should have
   * @param minHeight The minimum height the canvas should have
   * @param maxWidth The width the canvas should have
   * @param maxHeight The maximum height the canvas should have
   * @returns The calculated configuration
   */
  static calculateCoveredSize(
    width: number, height: number,
    minWidth: number, minHeight: number,
    maxWidth: number, maxHeight: number,
  ): ThumbnailSizeParams {
    const aspectRatio = width / height;

    let scaledWidth = width;
    let scaledHeight = height;
    let usedWidth = width;
    let usedHeight = height;
    let canvasWidth = maxWidth;
    let canvasHeight = maxHeight;
    let horizontalOffset = 0;

    if (aspectRatio > maxWidth / minHeight) {
      scaledHeight = minHeight;
      scaledWidth = scaledHeight * aspectRatio;
      canvasWidth = maxWidth;
      canvasHeight = minHeight;
      usedWidth = scaledWidth * scaledHeight / height;
      horizontalOffset = (width - usedWidth) / 2;
    } else {
      scaledWidth = Math.max(minWidth, Math.min(width, maxWidth));
      scaledHeight = scaledWidth / width * height;
      canvasWidth = scaledWidth;
      canvasHeight = Math.max(minHeight, Math.min(scaledHeight, maxHeight));
      usedHeight = width / scaledWidth * canvasHeight;
    }

    return {
      minWidth,
      canvasDimensions: { width: canvasWidth, height: canvasHeight },
      scaledDimensions: { width: scaledWidth, height: scaledHeight, fitType: FitType.FIT },
      usedDimensions: { width: usedWidth, height: usedHeight },
      targetOffset: { width: 0, height: 0 },
      sourceOffset: { width: horizontalOffset, height: 0 },
    };
  }

  /**
   * Calculates a size configuration for a given thumbnail.
   * @param width The width of the source image
   * @param height The height of the source image
   * @param objectFit The fit behaviour of the thumbnail
   * @param maxWidth The maximum width for the image
   * @param minThumbnailAspectRatio The minimum aspect ratio the image can have before the width gets fixed to 90
   * @param maxThumbnailHeight The maximum thumbnail height. Gets ignored if the aspect ratio is set. Defaults to 400
   * @returns The calculated size configuration
   */
  static calculateSize(
    width: number, height: number,
    objectFit: ObjectFit,
    maxWidth: number,
    minThumbnailAspectRatio?: number,
    maxThumbnailHeight?: number
  ): ThumbnailSizeParams {
    const minAspectRatio = !!minThumbnailAspectRatio ? minThumbnailAspectRatio : MIN_ASPECT_RATIO;
    const maxHeight = !!minThumbnailAspectRatio ? Math.round(maxWidth / minAspectRatio)
      : !!maxThumbnailHeight ? maxThumbnailHeight
      : DEFAULT_MAX_MEDIA_HEIGHT;
    const minWidth = Math.min(maxHeight * minAspectRatio, maxWidth);

    if (objectFit === ObjectFit.FILL_WIDTH) {
      return ThumbnailSizeHelperDirective.calculateFillWidthSize(maxWidth, height, minWidth);
    } else if (objectFit === ObjectFit.COVER) {
      return ThumbnailSizeHelperDirective.calculateCoveredSize(
        width, height,
        minWidth, MIN_MEDIA_HEIGHT,
        maxWidth, maxHeight,
      );
    }

    const maxAspectRatio = maxWidth / MIN_MEDIA_HEIGHT;
    const thumbnailAspectRatio = width / height;

    const fitType = ThumbnailSizeHelperDirective.calculateFitType(thumbnailAspectRatio, minAspectRatio, maxAspectRatio);
    const canvasDimensions = ThumbnailSizeHelperDirective.calculateCanvasDimensions(
      thumbnailAspectRatio,
      minAspectRatio,
      maxAspectRatio,
      maxWidth,
      maxHeight,
    );

    const scaledDimensions = {
      width: canvasDimensions.width,
      height: canvasDimensions.height,
      fitType: objectFit === ObjectFit.CONTAIN ? FitType.FIT : fitType,
    };
    const usedDimensions = { width, height };
    const sourceOffset = { width: 0, height: 0 };
    const targetOffset = { width: 0, height: 0 };

    if (fitType === FitType.TOO_TALL) {
      scaledDimensions.width = Math.round(width * canvasDimensions.height / height);
      targetOffset.width = Math.floor((canvasDimensions.width - scaledDimensions.width) / 2);
    } else if (fitType === FitType.TOO_WIDE) {
      scaledDimensions.height = Math.round(height * canvasDimensions.width / width);
      targetOffset.height = Math.floor((canvasDimensions.height - scaledDimensions.height) / 2);
    }

    return {
      minWidth,
      canvasDimensions,
      scaledDimensions,
      usedDimensions,
      targetOffset,
      sourceOffset,
    };
  }
}
