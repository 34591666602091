import { Component, OnInit, inject } from '@angular/core';
import { RootState } from '../../../../../core/store/root/public-api';
import { select, Store } from '@ngrx/store';
import { ChannelEntity } from '../../../../../core/store/channel/channel.state';
import { filter, map, Observable, shareReplay } from 'rxjs';
import { selectCurrentChannel } from '../../../../../core/store/channel/channel.selectors';
import { ChannelType } from '@portal/wen-backend-api';
import { TranslateService } from '@ngx-translate/core';
import { isNullOrUndefined } from '../../../../../core/common/operators/null-check-util';

@Component({
  selector: 'wen-channel-view-desktop',
  templateUrl: './channel-view-desktop.component.html',
  styleUrls: ['./channel-view-desktop.component.scss']
})
export class ChannelViewDesktopComponent implements OnInit {

  private store: Store<RootState> = inject(Store);
  private translateService = inject(TranslateService);
  channel$: Observable<ChannelEntity>;
  subscriberCount$: Observable<string>;

  ngOnInit(): void {
    this.channel$ = this.store.pipe(
      select(selectCurrentChannel),
      filter((model) => !isNullOrUndefined(model)),
      shareReplay(1)
    );

    this.subscriberCount$ = this.channel$.pipe(
      filter((model: ChannelEntity) => model.channelType === ChannelType.NORMAL),
      map((model: ChannelEntity) => this.translateService.instant('SUBSCRIBER', { count: model?.subscriptions }))
    );
  }

}
