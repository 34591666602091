@if (autoReplyEnable$ | async) {
  <div class="wen-auto-reply">
    <wen-icon
      iconName="auto_reply"
      class="wen-auto-reply-icon wen-icon-small"
      color="orange"
    ></wen-icon>
    <div class="wen-auto-reply-text" portal-subheading-1 [innerHTML]="(autoReplyText$ | async) | removeMarkdown"></div>
  </div>
}
