import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { SD_WEB_API_HOST_QUERY_PARAM } from '../navigation/query-params';

export type LoginStrategy = 'normal' | 'popup';

@Injectable()
export class LoginStrategyProvider {

  public getLoginStrategy(queryParamMap: ParamMap): LoginStrategy {
    const sdHostParam = queryParamMap.get(SD_WEB_API_HOST_QUERY_PARAM) || null;
    if (Boolean(sdHostParam)) {
      return 'popup';
    }
    return 'normal';
  }

}
