import { BehaviorSubject, map, Observable } from 'rxjs';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MediaViewParams, MediaEventOptions } from '../../models/models';
import { ThumbnailProvider } from '../../directives/thumbnail-evaluator.directive';
import { LazyLoadParams } from '@portal/wen-components';
import { TranslateService } from '@ngx-translate/core';
import { getMediaAltText } from '../../media-utils';

@Component({
  selector: 'wen-embed-image',
  templateUrl: './embed-image.component.html',
  styleUrls: ['./embed-image.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmbedImageComponent implements AfterViewInit, OnDestroy {

  private params: MediaViewParams;
  private isMediaClickable = false;
  thumbnail$: Observable<LazyLoadParams> = this.thumbnailProvider.thumbnail$.pipe(
    map(({ url, scaledDimensions: { width, height } }) => {
      return {
        src: url,
        originalWidth: width,
        originalHeight: height
      };
    })
  );
  error = false;
  showLoading = true;
  imageAltText = '';

  embedView$ = new BehaviorSubject<'normal' | 'error'>('normal');

  @HostBinding('class.wen-image-embed') className = true;

  @ViewChild('image') imageElement: ElementRef<HTMLImageElement>;

  @Input() set config(params: MediaViewParams) {
    this.params = params;
  }

  @Output() mediaClick = new EventEmitter<MediaEventOptions>();

  constructor(private thumbnailProvider: ThumbnailProvider, private translateService: TranslateService) { }

  ngAfterViewInit() {
    this.imageAltText = getMediaAltText(this.translateService, this.params.mediaType, 'MEDIA_ATTACHMENT_DESCRIPTION');
  }

  onMediaClick(event: Event, thumbnail: LazyLoadParams) {
    if (!this.isMediaClickable) {
      event.preventDefault();
      return;
    }
    this.mediaClick.next({ mediaId: this.params.id, thumbnailUrl: thumbnail.src });
  }

  ngOnDestroy() {
    this.params = null;
  }

  handleImageError() {
    this.isMediaClickable = false;
  }

  onFinalError = () => {
    this.error = true;
    this.showLoading = false;
  };

  handleImageLoaded() {
    this.error = false;
    this.isMediaClickable = true;
    this.showLoading = false;
  }

  reloadImage() {
    this.error = false;
    const imageElement = this.imageElement.nativeElement;
    imageElement.src = imageElement.src;
    this.showLoading = true;
  }
}
