<wen-adaptive-background>
  @if (!isVerificationInProgress) {
    <wen-dialog-layout wen-adaptive-background-content [headerGravity]="headerGravity">
      <wen-dialog-layout-header [inline]="true" wenIosKeyboardSticky>
        <button headerLeft portal-button inlined (click)="onCancel()" tabindex="-1">
          <span portal-title>{{ "REGISTRATION_BACK_LABEL" | translate }}</span>
        </button>
        <button headerRight portal-button inlined (click)="onSubmit()">
          <span portal-title>{{ "REGISTRATION_CONTINUE_LABEL" | translate }}</span>
        </button>
      </wen-dialog-layout-header>
      <div class="wen-registration-code-verification-content" dialogLayoutContent>
        <h1 class="cdk-visually-hidden">{{ 'REGISTRATION_CONTEXT_LABEL' | translate }}</h1>
        <wen-silent-logout></wen-silent-logout>
        <form wenFormStore class="wen-registration-code-verification-form" [formGroup]="registrationCodeVerificationFormGroup"
          (ngSubmit)="onSubmit()" wenFocusFirstInput>
          <div class="wen-registration-code-verification-info">
            <h2 class="wen-registration-code-verification-header" wen-header-big>{{
              "REGISTRATION_START_PHONE_NUMBER_LABEL" | translate }}
            </h2>
            <div class="wen-registration-code-verification-description" tabindex="0">{{ "REGISTRATION_CODE_VERIFICATION_DESCRIPTION" |
            translate: { phoneNumber: phoneNumber } }}</div>
          </div>
          <div class="wen-registration-code-verification-input-wrapper">
            <mat-form-field>
              <mat-label>{{ 'REGISTRATION_CODE_VERIFICATION_CODE_LABEL' | translate }}</mat-label>
              <input class="wen-phone-number-control-input" matInput autocomplete="off"
                [formControlName]="'verificationCodeControl'"
                [placeholder]="'REGISTRATION_CODE_VERIFICATION_CODE_PLACEHOLDER' | translate"
                wenIosKeyboardStickyTarget [type]="codeFieldType" pattern="\d*"/>
                @if (hasError(['required', 'minlength', 'maxlength'])) {
                  <mat-error portal-caption>{{
                  "REGISTRATION_CODE_VERIFICATION_INVALID" | translate }}</mat-error>
                }
                @if (hasError('verifyCooldown')) {
                  <mat-error portal-caption>{{ "REGISTRATION_CODE_VERIFICATION_COOLDOWN" |
                  translate: { cooldown: (remainingResubmit$ | async) } }}</mat-error>
                }
              </mat-form-field>
            </div>
            <wen-registration-code-resend [disabled]="resendDisabled$ | async" [remaining]="remainingResend$ | async"
            (resend)="onResendCode()"></wen-registration-code-resend>
          </form>
        </div>
      </wen-dialog-layout>
    } @else {
      <wen-dialog-layout [headerGravity]="headerGravity">
        <div class="wen-registration-code-verification-progress-content" registrationContent>
          <mat-spinner [diameter]="78"></mat-spinner>
          <div class="wen-registration-code-verification-progress-text">{{ 'REGISTRATION_CODE_VERIFICATION_PROGRESS_TEXT'
            |
          translate: { phoneNumber: phoneNumber } }}</div>
        </div>
      </wen-dialog-layout>
    }

  </wen-adaptive-background>
