import { inject, Injectable } from '@angular/core';
import { filter, first, ReplaySubject, tap } from 'rxjs';
import { TracerRuntimeParams } from '../config-types';
import { SentryLoader } from './sentry-loader';

@Injectable()
export class TracerInitializer {

  private firstAttach$ = new ReplaySubject<boolean>(1);
  private sentryLoader = inject(SentryLoader);

  enableSentry(runtimeParams?: TracerRuntimeParams) {
    return this.sentryLoader.attach(runtimeParams).pipe(
      tap(() => this.firstAttach$.next(true))
    );
  }

  disableSentry() {
    return this.sentryLoader.detach();
  }

  /**
   * Any log emitted before attach will be lost unless this is used
   * Can be used to schedule logs even before the logging is loaded
   * Use this ONLY for events on app initialization before the app is ready!
   *
   * @returns An observable which will emit once when logging is possible
   */
  scheduleLogWhenReady() {
    return this.firstAttach$.pipe(
      filter(isAttached => isAttached),
      first()
    );
  }

}
