<div class="wen-poll-option-wrapper">
  <div class="wen-poll-option-textarea-wrapper">
    <textarea matInput
      cdkTextareaAutosize
      #textArea
      [placeholder]="placeholder"
      [formControl]="control"
      (keydown.enter)="onenter($event)"
      [maxlength]="CHARACTER_LIMIT"
    ></textarea>
  </div>
  <div class="character-count-and-remove-wrapper">
    @if (removable) {
      <wen-action
        wenVariant="text"
        iconName="close"
        [altText]="'DELETE_BUTTON_LABEL' | translate"
        (click)="removeOption()">
      </wen-action>
    }
    @if (characterCount$ | async; as characterCount) {
      <mat-hint
      >{{ characterCount }}/{{ CHARACTER_LIMIT }}</mat-hint>
    }
  </div>
</div>
