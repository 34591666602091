import { LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { isAbsoluteUrl } from '@portal/wen-common';
import { AuthConfig, LoginOptions, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import jwtDecode from 'jwt-decode';
import { Observable, from, of } from 'rxjs';
import { catchError, filter, finalize, first, map, switchMap, tap } from 'rxjs/operators';
import { WenOauthStorage } from '../../../frame/api/oauth-storage';
import { AUTH_CLIENT_CONFIG, AuthClientConfig } from '../../../frame/api/tokens';
import { emailverificationUtil } from '../../../shared/feature-specific/email-verification/email-verification-util';
import { DateUtil } from '../../common/date/date-util';
import { equalsIgnoreCase, isNullOrUndefined } from '../../common/operators/null-check-util';
import { WenJWTResponse } from '../../store/auth/models/JWTData';
import { UserData } from '../../store/auth/models/UserData';
import { FeatureEnablementService } from '../configuration/feature-enablement';
import { NativeConfigurationService } from '../configuration/native-configuration';
import { SSO_DEEP_LINK_PATH } from '../navigation/query-params';
import { WenNavigationHelper } from '../navigation/types';
import { WenStorageService } from '../storage/wen-storage.service';
import { PopupEventHandler } from '../util/popup-event-handler';
import { CodeFlowError, LoginError } from './auth-errors';
import { AuthTracer } from './auth-tracer';
import { OauthUrlOpener } from './oauth-url-opener';
import { PermissionLevel } from './permission-level';

export interface LoginState {
  redirectAfterLogin?: string;
  needsDataProtectionDialog?: boolean;
  embeddedSsoAppId?: string;
}

export interface LogoutParams {
  suggested_username?: string;
  isByUserInteraction?: boolean;
  postLogoutRedirectUri?: string;
}

export interface LoginParams {
  suggested_username?: string;
  redirectAfterLogin?: string;
  anonymousAutoLogin?: boolean;
  ui_context?: 'emailverification';
  needsDataProtectionDialog?: boolean;
  embeddedSsoAppId?: string;
}

type AuthServerCodeFlowParams = Partial<Pick<LoginParams, 'suggested_username' | 'anonymousAutoLogin' | 'ui_context'>>;

interface WenOathServiceConfig {
  clientId: string;
  disablePKCE: boolean;
}

@Injectable()
export class WenOAuthService {

  private postLogoutRedirectUri: string;

  get userId() {
    return this.getUserData()?.userId;
  }

  get oauthRoutes() {
    return {
      login: '/oauth/login',
      callback: '/oauth/callback',
      logout: '/oauth/logout'
    };
  }

  constructor(
    @Inject(OAuthService) protected oauthService: OAuthService,
    @Inject(AUTH_CLIENT_CONFIG) private externalConfig: AuthClientConfig,
    @Inject(OAuthStorage) private oauthStorage: WenOauthStorage,
    private wenStorageService: WenStorageService,
    private locationStrategy: LocationStrategy,
    private oauthUrlOpener: OauthUrlOpener,
    private nativeConfiguration: NativeConfigurationService,
    private navigationHelper: WenNavigationHelper,
    private featureEnablementService: FeatureEnablementService,
    private authTracer: AuthTracer,
    private httpClient: HttpClient,
    protected popupEventHandler: PopupEventHandler,
  ) {
    this.init();
  }

  protected init() {
    this.configure({
      clientId: this.externalConfig.clientId,
      disablePKCE: false
    });
  }

  configure(config: WenOathServiceConfig) {
    const { clientId, disablePKCE } = config;
    this.postLogoutRedirectUri = this.prepareExternalUrl(this.externalConfig.postLogoutRedirectUri || this.oauthRoutes.login);
    const redirectUri = this.prepareExternalUrl(this.externalConfig.redirectUri || this.oauthRoutes.callback);

    const clockSkewInSec = isNullOrUndefined(this.externalConfig.clockSkewInSec) ? 0 : this.externalConfig.clockSkewInSec;
    const decreaseExpirationBySec = isNullOrUndefined(
      this.externalConfig.decreaseExpirationByMSec
    ) ? 60000 : this.externalConfig.decreaseExpirationByMSec;

    const appConfig: AuthConfig = {
      ...this.externalConfig,
      clientId,
      ...{
        redirectUri,
        scope: 'openid profile email gguid',
        responseType: 'code',
        disablePKCE,
        customQueryParams: {
          device_id: this.wenStorageService.getDeviceId()
        }
      },
      clockSkewInSec,
      decreaseExpirationBySec,
      openUri: (url) => {
        this.authTracer.addAuthBreadcrumb('will_open_uri');
        this.authTracer.flush().subscribe(() => {
          this.oauthUrlOpener.openUrl(url);
        });
      }
    };
    this.oauthService.configure(appConfig);
    this.diagnoseInitialState();
  }

  private diagnoseInitialState() {
    const hasAccessToken = Boolean(this.getAccessToken());
    const hasRefreshToken = Boolean(this.getCurrentRefreshToken());
    const hasValidTokens = this.hasValidTokens();
    this.authTracer.addAuthBreadcrumb('oauth_init', { hasAccessToken, hasRefreshToken, hasValidTokens });
  }

  tryLogin(options?: LoginOptions) {
    this.authTracer.addAuthBreadcrumb('try_login');
    return from(this.oauthService.loadDiscoveryDocumentAndTryLogin(options)).pipe(
      map(() => this.getAccessToken()),
      catchError((e) => {
        this.authTracer.captureException(LoginError(e));
        throw e;
      })
    );
  }

  initCodeFlow(params: LoginParams = {}) {
    this.authTracer.addAuthBreadcrumb('init_code_flow_start');
    const codeFlowParams: AuthServerCodeFlowParams = {};
    const state: LoginState = {};
    if (params?.suggested_username) {
      codeFlowParams.suggested_username = params?.suggested_username;
    }
    if (params?.anonymousAutoLogin) {
      codeFlowParams.anonymousAutoLogin = true;
    }
    if (params?.ui_context) {
      codeFlowParams.ui_context = params.ui_context;
    } else if (emailverificationUtil.getCode(params.redirectAfterLogin)) {
      codeFlowParams.ui_context = 'emailverification';
    }
    if (params?.redirectAfterLogin) {
      state.redirectAfterLogin = params.redirectAfterLogin;
    }
    if (params?.embeddedSsoAppId) {
      state.embeddedSsoAppId = params?.embeddedSsoAppId;
    }
    if (isNullOrUndefined(params?.needsDataProtectionDialog)) {
      state.needsDataProtectionDialog = this.featureEnablementService.isAgbEnabled();
    } else {
      state.needsDataProtectionDialog = params.needsDataProtectionDialog;
    }
    return from(this.oauthService.loadDiscoveryDocumentAndTryLogin()).pipe(
      tap(() => this.oauthService.initCodeFlow(JSON.stringify(state), codeFlowParams)),
      catchError((e) => {
        this.authTracer.captureException(CodeFlowError(e, { redirectUrl: params?.redirectAfterLogin }));
        throw e;
      })
    );
  }

  protected calculatePopupFeatures(options: {
    height?: number;
    width?: number;
  }): string {
    const height = options.height || 470;
    const width = options.width || 500;
    const left = window.screenLeft + (window.outerWidth - width) / 2;
    const top = window.screenTop + (window.outerHeight - height) / 2;
    return `location=no,toolbar=no,width=${width},height=${height},top=${top},left=${left}`;
  }

  initLoginFlowInPopup(
    popupOptions: { height?: number; width?: number } = {},
    authQueryParams: object = {}
  ) {
    const baseConfig = this.oauthService.customQueryParams;

    const popupWindowRef = window.open(
      this.prepareExternalUrl(this.oauthRoutes.logout) + '?onlyAnon=true',
      '',
      this.calculatePopupFeatures(popupOptions)
    );

    return this.popupEventHandler.listenToPopupEvents().pipe(
      first(),
      switchMap(() => {
        this.oauthService.customQueryParams = { ...this.oauthService.customQueryParams, ui_context: 'onlylogin', ...authQueryParams };
        return from(this.oauthService.loadDiscoveryDocumentAndTryLogin()).pipe(
          switchMap(() => from(this.oauthService.initLoginFlowInPopup({ windowRef: popupWindowRef }))),
          finalize(() => {
            this.oauthService.customQueryParams = baseConfig;
          })
        );
      })
    );

  }

  refreshToken() {
    this.authTracer.addAuthBreadcrumb('refresh_token_start');
    return from(this.oauthService.refreshToken()).pipe(
      map(tokens => {
        const { refresh_token } = tokens;
        return { refresh_token };
      }),
      tap(() => {
        this.authTracer.addAuthBreadcrumb('refresh_token_success');
      })
    );
  }

  refreshAuthSessionIfNeeded() {
    const { keepAliveEndpoint } = this.externalConfig;
    if (!keepAliveEndpoint) {
      return;
    }
    const isKeepAliveNeeded = this.wenStorageService.isKeepAliveNeeded();
    if (isKeepAliveNeeded) {
      this.httpClient.get(keepAliveEndpoint).pipe(
        catchError((error) => {
          this.authTracer.addAuthBreadcrumb('keep_alive_error');
          this.authTracer.captureException(error);
          return of();
        })
      ).subscribe(() => {
        this.wenStorageService.setKeepAliveForSession(DateUtil.currentDateMs());
      });
    }
  }

  logout(logoutParams?: LogoutParams) {
    this.authTracer.addAuthBreadcrumb('logout_start');
    const customParams = {
      redirect_url: this.prepareExternalUrl(logoutParams?.postLogoutRedirectUri || this.postLogoutRedirectUri)
    };
    if (logoutParams?.suggested_username) {
      const newRedirectUrl = new URL(customParams.redirect_url);
      newRedirectUrl.searchParams.append('suggested_username', logoutParams.suggested_username);
      customParams.redirect_url = newRedirectUrl.toString();
    }
    this.oauthStorage.removeItem('refresh_token');

    if (this.nativeConfiguration.isSSoDialogEnabled()) {
      this.navigationHelper.navigateToSignIn();
    }
    return from(this.oauthService.revokeTokenAndLogout(customParams)).pipe(
      tap(() => this.authTracer.addAuthBreadcrumb('logout_success'))
    );
  }

  logoutAndInitPasswordResetFlow() {
    this.oauthService.logOut(true);
    const url = this.externalConfig.issuer + '/password/reset';
    location.assign(url);
  }

  navigateToPasswordReset() {
    const url = this.externalConfig.issuer + '/password/reset';
    location.assign(url);
  }

  getAccessToken() {
    return this.oauthService.getAccessToken();
  }

  getCurrentRefreshToken() {
    return this.oauthService.getRefreshToken();
  }

  getIdToken() {
    return this.oauthService.getIdToken();
  }

  getRedirectAfterLoginUrl() {
    const state = this.getState();
    return state?.redirectAfterLogin;
  }

  hasValidTokens() {
    return this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken();
  }

  getUserData(): UserData {
    const idToken = this.oauthService.getIdToken();
    if (!idToken) {
      return null;
    }
    const jwtResponse = jwtDecode<WenJWTResponse>(idToken);
    if (jwtResponse.anonymous === 'true') {
      return {
        userId: jwtResponse.sub,
        permissionLevel: PermissionLevel.ANONYMOUS
      };
    }
    return {
      permissionLevel: PermissionLevel.REGISTERED_USER,
      userId: jwtResponse.gguid,
      username: jwtResponse.name,
    };
  }

  isCurrentUserId(value: string) {
    return equalsIgnoreCase(this.userId, value);
  }

  /**
   * Parse and get the state as object either from raw string or use the currently active state
   *  from the latest auth flow
   */
  getState(stateParam = this.oauthService.state) {
    try {
      const state: LoginState = JSON.parse(decodeURIComponent(stateParam));
      return state;
    } catch {
      return null;
    }
  }

  /**
   * Parse the raw state directly eg. from the oauth callback's query params
   * The noonce is stripped down and only he "user state" is returned
   *
   * @param state The state string raw from query params
   * @returns The parsed state
   */
  parseStateFromRaw(state: string) {
    let userState = '';
    if (state) {
      const idx = state.indexOf(this.oauthService.nonceStateSeparator);
      if (idx > -1) {
        userState = state.substring(idx + this.oauthService.nonceStateSeparator.length);
      }
    }
    return this.getState(userState);
  }

  protected prepareExternalUrl(route: string) {
    if (this.nativeConfiguration.isSSoDialogEnabled()) {
      return `${this.nativeConfiguration.getNativeDeepLink()}://${SSO_DEEP_LINK_PATH}${route}`;
    }
    if (isAbsoluteUrl(route)) {
      return route;
    }
    return window.location.origin + this.locationStrategy.prepareExternalUrl(route);
  }

  loadDiscoveryDocument() {
    return this.oauthService.loadDiscoveryDocument();
  }

  isDiscoveryDocumentLoaded(): Observable<boolean> {
    return this.oauthService.events.pipe(
      filter(event => event.type === 'discovery_document_loaded' || event.type === 'discovery_document_load_error'),
      map((event) => event.type === 'discovery_document_loaded'),
    );
  }
}
