import { Injectable } from '@angular/core';
import { combineLatest, defaultIfEmpty, first, forkJoin, map, Observable, of } from 'rxjs';
import { EmbeddedDocumentDTO, EmbeddedForwardDTO, EmbeddedLinkDTO, EmbeddedLocationDTO, EmbeddedMediaDTO, EmbedDTOTypes } from '../../../../data-types/embed/embed';
import { PollModel } from '../../../../data-types/poll/poll';
import { EmbedContext, MediaUseCases } from '../../../../events/events';
import { EmbedImageUploader } from './embed-image-uploader';
import { EmbedPollCreator } from './embed-poll-creator';

export type ComposerEmbedsData = {
  linkEmbeds?: EmbeddedLinkDTO[];
  documentEmbeds?: EmbeddedDocumentDTO[];
  mediaEmbeds?: EmbeddedMediaDTO[];
  locationEmbeds?: EmbeddedLocationDTO[];
  pollEmbeds?: PollModel[];
  forwardEmbeds?: EmbeddedForwardDTO[];
};

export type PersistEmbedsResult = {
  immediate?: boolean;
  persistEmbedsResult$: Observable<EmbedDTOTypes[]>;
  anchorEmbeds?: EmbedDTOTypes[];
};

@Injectable()
export class EmbedCreator {

  constructor(
    private embedImageUploader: EmbedImageUploader,
    private embedPollCreator: EmbedPollCreator,
  ) { }

  prepareEmbeds(
    embeds: ComposerEmbedsData,
    contexts: EmbedContext[]
  ): PersistEmbedsResult {
    if (!embeds) {
      return {
        immediate: true,
        persistEmbedsResult$: of([])
      };
    }
    const {
      locationEmbeds = [],
      linkEmbeds = [],
      documentEmbeds = [],
      mediaEmbeds = [],
      pollEmbeds = [] } = this.initializeEmbeds(embeds);
    const lazyEmbeds: Observable<EmbedDTOTypes>[] = [];
    const simpleEmbeds: EmbedDTOTypes[] = [];
    const simpleMediaEmbeds = mediaEmbeds.filter((mediaEmbed) => !mediaEmbed.file);
    simpleEmbeds.push(...linkEmbeds, ...documentEmbeds, ...locationEmbeds, ...simpleMediaEmbeds);

    const lazyMediaEmbeds = mediaEmbeds
      .filter((mediaEmbed) => mediaEmbed.file)
      .map((mediaEmbed) => this.embedImageUploader.createUpload(mediaEmbed, contexts, MediaUseCases.MESSAGE));
    const lazyPollEmbeds = pollEmbeds.map((pollModel) => {
      return this.embedPollCreator.createPoll(
        { ...pollModel, channelId: contexts[0].parent.id }, /** For compatibility until polls for chat bacend is fully released  */
        contexts[0]
      );
    });
    lazyEmbeds.push(...lazyMediaEmbeds, ...lazyPollEmbeds);

    const lazyEmbedsResult$ = forkJoin(lazyEmbeds).pipe(
      defaultIfEmpty([] as EmbedDTOTypes[])
    );
    const simpleEmbedsResult$ = forkJoin(
      simpleEmbeds.map(embed => of(embed)),
    ).pipe(
      defaultIfEmpty([] as EmbedDTOTypes[])
    );
    const persistEmbedsResult$ = combineLatest([
      lazyEmbedsResult$, simpleEmbedsResult$
    ]).pipe(
      first(),
      map(([lazyResult, simpleResult]) => {
        return [...lazyResult, ...simpleResult];
      }),
      defaultIfEmpty([] as EmbedDTOTypes[])
    );
    return {
      immediate: lazyEmbeds.length === 0,
      persistEmbedsResult$
    };
  }

  prepareAsAnchors(
    embeds: ComposerEmbedsData,
    contexts: EmbedContext[]
  ): PersistEmbedsResult {
    if (!embeds) {
      return {
        immediate: true,
        persistEmbedsResult$: of([])
      };
    }
    const {
      locationEmbeds = [],
      linkEmbeds = [],
      documentEmbeds = [],
      mediaEmbeds = [],
      pollEmbeds = [] } = this.initializeEmbeds(embeds);
    const lazyEmbeds: Observable<EmbedDTOTypes>[] = [];
    const simpleEmbeds: EmbedDTOTypes[] = [];

    const mediaEmbedAnchors = mediaEmbeds.map(({ file, ...otherProps }) => {
      return {
        ...otherProps,
        thumbnailUrl: URL.createObjectURL(file)
      };
    });
    const lazyMediaEmbeds = mediaEmbeds
      .filter((mediaEmbed) => mediaEmbed.file)
      .map((mediaEmbed) => this.embedImageUploader.createUpload(mediaEmbed, contexts, MediaUseCases.MESSAGE));
    const lazyPollEmbeds = pollEmbeds.map((pollModel) => {
      return this.embedPollCreator.createPoll(pollModel, contexts[0]);
    });
    simpleEmbeds.push(...linkEmbeds, ...documentEmbeds, ...locationEmbeds, ...mediaEmbedAnchors);
    lazyEmbeds.push(...lazyPollEmbeds, ...lazyMediaEmbeds);

    const lazyEmbedResult$ = forkJoin(lazyEmbeds).pipe(
      defaultIfEmpty([] as EmbedDTOTypes[])
    );

    return {
      persistEmbedsResult$: lazyEmbedResult$,
      immediate: lazyEmbeds.length === 0,
      anchorEmbeds: simpleEmbeds
    };
  }

  private initializeEmbeds(embeds: ComposerEmbedsData): ComposerEmbedsData {
    const nullSafeEmbeds = Object.keys(embeds).reduce((acc, key) => {
      if (embeds[key]) {
        return { ...acc, [key]: embeds[key] };
      }
      return acc;
    }, {} as ComposerEmbedsData);
    return { ...nullSafeEmbeds };
  }
}
