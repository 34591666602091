<div class="wen-selection-list-item-content"  [wen-test-id]="'wen-selection-list-item'" cdkSelectionToggle>
  @if (!isDisabled) {
    <mat-checkbox
      [class.multi-check]="isMultiSelect"
      [class.single-check]="!isMultiSelect"
      [class.borderless-check]="selectionOptionBorderType === 'borderless'"
      wen-primary-color
      [disableRipple]="'true'"
      [ngModel]="selected"
    ></mat-checkbox>
  }
  <span class="wen-selection-list-item-label">
    @if (selectionOptionPrefixIcon) {
      <wen-icon class="wen-icon-medium" [iconName]="selectionOptionPrefixIcon.name" [altText]="selectionOptionPrefixIcon.altText" ></wen-icon>
    }
    <ng-content></ng-content>
  </span>
</div>
