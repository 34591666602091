import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { RoomDTO } from '@portal/wen-backend-api';
import { Observable } from 'rxjs';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { ChatLoadResult } from '../../models/chat-list.models';
import { UserChatListDataSource } from './user-chat-list-datasource';
import { CalculateMessageStatusPipe } from '../../../../../shared/components/message-status-indicator/pipes/calculate-message-status.pipe';
import { ChatListItemFactory } from '../chat-list-item/providers/chat-list-item-factory';
import { ChatMessageService } from '../../../common/providers/chat-message-descriptor-service';
import { ChatListItemComponent } from '../chat-list-item/chat-list-item.component';
import { SharedModule } from '../../../../../shared/shared.module';
import { ScrollStateModule } from '../../../../../shared/directives/scroll-state/scroll-state.module';
import { IsDesktopViewDirective } from '@portal/wen-components';

@Component({
  selector: 'wen-user-chat-list',
  templateUrl: './user-chat-list.component.html',
  styleUrls: ['./user-chat-list.component.scss'],
  imports: [AsyncPipe, NgTemplateOutlet, ChatListItemComponent, ScrollingModule, ScrollStateModule, SharedModule, IsDesktopViewDirective],
  standalone: true,
  providers: [
    UserChatListDataSource,
    ChatListItemFactory,
    CalculateMessageStatusPipe,
    ChatMessageService,
  ]
})
export class UserChatListComponent implements OnInit {

  chatLoadResult$: Observable<ChatLoadResult>;
  markdownEnabled = true;
  itemSize = 75;

  constructor(
    private readonly dataSource: UserChatListDataSource,
    private readonly navigationHelper: WenNavigationHelper,
  ) {}

  ngOnInit() {
    this.chatLoadResult$ = this.dataSource.bindToSource();
  }

  onClick(chat: RoomDTO) {
    this.navigationHelper.navigateToChatView(chat.id);
  }

  navigateToDiscover() {
    this.navigationHelper.navigateToDialogChatCreation();
  }

  trackByFn(chat: RoomDTO) {
    return chat.id;
  }
}
