import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, combineLatest, map, takeUntil } from 'rxjs';
import { selectorWithParam } from '../../../../../core/common/util/selector-with-param';
import { addContentProviderToCurrentChannel } from '../../../../../core/store/channel/channel.actions';
import { selectCurrentChannelAdmins, selectCurrentChannelContentProviders } from '../../../../../core/store/channel/channel.selectors';
import { removeFilter } from '../../../../../core/store/filter/filter.actions';
import { selectSearchTerm } from '../../../../../core/store/filter/filter.selectors';
import { FilterEntityType } from '../../../../../core/store/filter/models/filter';
import { UserSearchListItem } from '../../../../../shared/components/user-search-list/user-search-list.types';
import { UserSearchService } from '../../../../chat/chat-creation/providers/user-search-service';
import { HeaderDataHelper } from '../../../../../core/services/util/header-data-helper';

@Component({
  selector: 'wen-add-content-provider',
  templateUrl: './add-content-provider.component.html',
  styleUrls: ['./add-content-provider.component.scss'],
  providers: [UserSearchService]
})
export class AddContentProviderComponent implements OnInit, OnDestroy {

  private store = inject(Store);
  private headerDataHelper = inject(HeaderDataHelper);

  private onDestroy$ = new Subject<void>();
  searchTerm$: Observable<string>;
  selectedUserIds$: Observable<string[]>;

  ngOnInit() {
    this.headerDataHelper.initializeContextTitleWithChannelTitle(true);

    this.searchTerm$ = this.store.pipe(
      selectorWithParam(selectSearchTerm, FilterEntityType.CHANNEL_ADD_CONTENT_PROVIDER_LISTS),
      takeUntil(this.onDestroy$),
    );
    this.selectedUserIds$ = combineLatest([
      this.store.pipe(select(selectCurrentChannelContentProviders)),
      this.store.pipe(select(selectCurrentChannelAdmins))
    ]).pipe(
      map(([channelContentProviders, channelAdmins]) => {
        return [...channelContentProviders, ...channelAdmins].map(user => user.userId);
      })
    );
  }

  addUserAsContentProvider(user: UserSearchListItem) {
    this.store.dispatch(addContentProviderToCurrentChannel({ userId: user.userId }));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.store.dispatch(removeFilter({ filterEntityType: FilterEntityType.CHANNEL_ADD_CONTENT_PROVIDER_LISTS }));
  }

}
