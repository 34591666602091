import { concat, debounceTime, Observable, skip, take } from 'rxjs';

export const debounceTimeAfter = (
  amount: number,
  dueTime: number
) => <R>(source: Observable<R>) => {
  return concat(
      source.pipe(take(amount)),
      source.pipe(skip(amount), debounceTime(dueTime))
  );
};

export const debounceTimeAfterFirst = (
  dueTime: number,
) => {
  return debounceTimeAfter(1, dueTime);
};
