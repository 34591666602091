<div class="wen-form" cdkScrollable>
  @if (roomDetails$ | async; as roomDetails) {
    @if (headerData$ | async; as headerData) {
      <wen-detail-header [model]="headerData">
        <div wen-detail-header-title>
          <h1 portal-title>{{ headerData.title }}</h1>
        </div>
        @if (isAdmin$ | async) {
          <ng-container ngProjectAs="[wen-detail-header-quick-actions]">
            <wen-action wenVariant="filled" class="wen-action-round wen-action-round-padding" iconName="edit" [isNavigating]="true" [altText]="'EDIT_ACTION_LABEL' | translate" (click)="editRoom(roomDetails.id)"></wen-action>
            <wen-action wenVariant="filled" class="wen-action-round wen-action-round-padding" iconName="add_user" [isNavigating]="true" [altText]="'GROUP_CHAT_ADD_MEMBERS' | translate" [isDisabled]="roomDetails.memberCount >= maxNumberOfUsers" (click)="addMembers(roomDetails.id)"></wen-action>
          </ng-container>
        }
        <wen-mute-unmute-chat-button wen-detail-header-quick-actions [chat]="roomDetails">
        </wen-mute-unmute-chat-button>
        <wen-action wen-detail-header-quick-actions wenVariant="filled" class="wen-action-round wen-action-round-padding" iconName="logout" [altText]="'GROUP_CHAT_LEAVE_BUTTON_LABEL' | translate" (click)="leaveRoom(roomDetails.id)"></wen-action>
      </wen-detail-header>
    }
    @if (roomDetails.description) {
      <div class="wen-form-group">
        <div class="wen-form-field-wrapper wen-chat-detail-field-text">
          <div class="wen-form-field-label" portal-caption>{{ 'LABEL_DESCRIPTION' | translate }}</div>
          <div class="wen-form-field" portal-body>{{ roomDetails.description }}</div>
        </div>
      </div>
    }
    <div class="wen-form-group wen-single-group">
      <wen-chat-detail-user-list [isAdmin]="isAdmin$ | async" [roomId]="roomDetails.id" [memberCount]="roomDetails.memberCount"></wen-chat-detail-user-list>
    </div>
  }
</div>
