import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ContactRequestStatus, SocketIoService } from '@portal/wen-backend-api';
import { Observable, combineLatest, filter, map, of, switchMap } from 'rxjs';
import { mapAnyTrue } from '../../../../../core/common/operators/map-all-bool';
import { FeatureEnablementService } from '../../../../../core/services/configuration/feature-enablement';
import { ContactResponseResponseActionData } from '../../../../../shared/components/contact-response-action/contact-response-action.types';
import { DetailHeaderModel } from '../../../../../shared/components/detail-header/detail-header.component';
import { ProfileActionsConditions } from './providers/profile-actions-conditions';
import { ProfileActionsHandler } from './providers/profile-actions-handler';
import { OtherProfileData } from './providers/profile-data.service';
import { LocationFormatterPipe } from '../../../../../shared/pipes/location-formatter.pipe';
import { OtherProfileRecommendationsSectionDatasource } from './other-profile-recommendations-section-datasource';
import { WenRouteId } from '../../../../../frame/routing/types';
import { userProfileIdentifier } from '../../../tokens';
import { AppNavigator } from '../../../../../core/services/navigation/app-navigator';

@Component({
  selector: 'wen-other-profile-view',
  templateUrl: './other-profile-view.component.html',
  styleUrls: ['./other-profile-view.component.scss'],
  providers: [
    ProfileActionsHandler,
    ProfileActionsConditions,
    OtherProfileData,
    LocationFormatterPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OtherProfileViewComponent implements OnInit {

  constructor(
    private featureEnablementService: FeatureEnablementService,
    public profileData: OtherProfileData,
    public actions: ProfileActionsHandler,
    public conditions: ProfileActionsConditions,
    private locationFormatterPipe: LocationFormatterPipe,
    private socketIoService: SocketIoService,
    private appNavigator: AppNavigator,
  ) { }

  responseActionData$: Observable<ContactResponseResponseActionData>;
  headerData$: Observable<DetailHeaderModel>;
  additionalInformation$: Observable<string>;
  company$: Observable<string>;
  address$: Observable<string>;

  enableContacts$: Observable<boolean>;
  canInitiateChat$: Observable<boolean>;
  showActions$: Observable<boolean>;
  showHint$: Observable<boolean>;

  sectionData$: Observable<{ dataSource: OtherProfileRecommendationsSectionDatasource; name: string; userId: string }>;

  ngOnInit() {
    this.enableContacts$ = this.featureEnablementService.featureConfigObs.enableContacts$;
    this.canInitiateChat$ = this.conditions.canInitiateChat$;
    this.showActions$ = this.conditions.actionsEnabled$.pipe(
      switchMap(actionsEnabled => {
        if (actionsEnabled) {
          return combineLatest([this.enableContacts$, this.canInitiateChat$]).pipe(mapAnyTrue());
        }
        return of(false);
      })
    );
    this.showHint$ = combineLatest([this.profileData.profile$, this.showActions$]).pipe(
      map(([profileData, showActions]) =>
        showActions && profileData.requestStatus === ContactRequestStatus.REQUESTER
      ),
    );

    this.responseActionData$ = this.profileData.profile$.pipe(
      map(profileData => {
        const { userId, requestStatus, displayname: displayName } = profileData;
        const data: ContactResponseResponseActionData = {
          userId, requestStatus, displayName
        };
        return data;
      })
    );
    this.headerData$ = this.profileData.profile$.pipe(
      map((response) => {
        const headerData: DetailHeaderModel = {
          title: response.displayname,
          imageUrl: response.avatarUrl,
        };
        return headerData;
      })
    );

    this.additionalInformation$ = this.profileData.profile$.pipe(
      filter(() => this.featureEnablementService.featureFlagMethods.isEnableAboutMeField()),
      filter(profile => Boolean(profile.additionalInformation)),
      map(profile => profile.additionalInformation)
    );

    this.company$ = this.profileData.profile$.pipe(
      filter(() => this.featureEnablementService.featureFlagMethods.isEnableProfileExtensions()),
      filter(profile => Boolean(profile.company)),
      map(profile => profile.company)
    );

    this.address$ = this.profileData.profile$.pipe(
      filter(() => this.featureEnablementService.featureFlagMethods.isEnableProfileExtensions()),
      filter(profile => Boolean(profile.address)),
      map(profile => this.locationFormatterPipe.transform(profile.address) as string)
    );

    this.sectionData$ = this.profileData.profile$.pipe(
      filter(this.featureEnablementService.featureFlagMethods.isEnableChannelRecommendations),
      map(({ userId, displayname }) => {
        const dataSource = new OtherProfileRecommendationsSectionDatasource(this.socketIoService);
        dataSource.loadUserRecommendations(userId);
        return {
          dataSource,
          name: displayname,
          userId
        };
      })
    );

  }

  navigateToShowAllRecommendations(userId: string) {
    this.appNavigator.navigateToRoute(WenRouteId.OTHER_USERPROFILE_CHANNEL_RECOMMENDATIONS, { [userProfileIdentifier]: userId });
  }

}
