import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { ConfirmationDialogData, CoreComponentsModule, DownloaderService, OverlayManager } from '@portal/wen-components';
import { EventIcalEventPickerComponent } from '../event-ical-event-picker/event-ical-event-picker.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { getRootUrl } from '@portal/wen-common';
import { EventICalEventPickerResultData, ICalQueryData } from '../../models/event-detail.models';

@Component({
  selector: 'wen-event-ical-button',
  templateUrl: './event-ical-button.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CoreComponentsModule, TranslateModule]
})
export class EventIcalButtonComponent {

  private overlayManager = inject(OverlayManager);
  private translateService = inject(TranslateService);
  private downloaderService = inject(DownloaderService);

  @Input() iCalQueryData: ICalQueryData;

  downloadICal(): void {
    if (this.iCalQueryData.possibleReccuring) {
      const dialogRef = this.overlayManager.dialog.openConfirmationDialog
        <ConfirmationDialogData, EventICalEventPickerResultData, EventIcalEventPickerComponent>(
          EventIcalEventPickerComponent,
          {
            header: this.translateService.instant('EVENT_RECUR_PICKER_LABEL'),
            okLabel: this.translateService.instant('EVENT_RECUR_PICKER_OK_BUTTON'),
            dismissLabel: this.translateService.instant('CANCEL_BUTTON_LABEL'),
          }
        );
      dialogRef.afterClosed().pipe(
        filter(data => data?.result === 'ok')
      ).subscribe(result => {
        this.initiateDownload(result.recur);
      });
    } else {
      this.initiateDownload(false);
    }
  }

  private initiateDownload(isRecurring: boolean): void {
    const urlBase = getRootUrl();
    const url = `${urlBase}/events/iCal?recur=${isRecurring}
      &eventId=${this.iCalQueryData.eventId}&startDate=${this.iCalQueryData.currentEventStartDate}`;
    this.downloaderService.downloadFileAuthorized(url, this.iCalQueryData.caption);
  }
}
