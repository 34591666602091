import { DateTime, Duration, DurationUnits, Info, Settings } from 'luxon';

export const dateFormats = {
  FULL_DATE_AND_TIME: 'dd.LL.yyyy HH:mm',
  NO_YEAR: 'dd.LL. HH:mm',
  NO_YEAR_NO_TIME: 'dd.LL.',
  DATE_ONLY: 'dd.LL.yyyy',
  TIME_ONLY: 'HH:mm',
  DAY_OF_THE_WEEK_SHORT: 'ccc',
  DAY_OF_WEEK_AND_TIME: 'EEEE, T',
  DAY_OF_WEEK: 'EEEE',
  MONTH_NAME_WITH_DAY: 'LLL dd',
  YEAR_WITH_MONTH_NAME: 'LLL yyyy'
};


export class DateUtil {

  private static dateCache: Map<string, Date> = new Map();
  private static locale = 'de';
  public static readonly MILISECONDS_IN_A_DAY = 86400000;

  private static getCachedDate(isoString: string): Date {
    let date = this.dateCache.get(isoString);
    if (!date) {
      date = new Date(isoString);
      this.dateCache.set(isoString, date);
    }
    return date;
  }

  static setLocale(newLanguage: string) {
    DateTime.local({ locale: newLanguage });
    Settings.defaultLocale = newLanguage;
    this.locale = newLanguage;
  }

  static getLocale() {
    return this.locale;
  }

  static getLocalFirstDayOfWeek() {
    return DateTime.local().setLocale(this.getLocale()).startOf('week').weekday;
  }

  static getLocalMonthNames() {
    return Info.months('short', { locale: this.getLocale() });
  }

  static startOfToday(): DateTime {
    return DateTime.local().startOf('day');
  }

  static fromIsoString(isoString: string): DateTime {
    return DateTime.fromISO(isoString).setLocale(DateUtil.getLocale());
  }

  static fromRfc7231String(rfc7231String: string): DateTime {
    return DateTime.fromHTTP(rfc7231String).setLocale(DateUtil.getLocale());
  }

  static isValidISOString(isoString: string) {
    const parsedDate = DateUtil.fromIsoString(isoString);
    return !parsedDate.invalidReason;
  }

  static currentDateString() {
    return new Date().toISOString();
  }

  static currentDateMs() {
    return new Date().getTime();
  }

  /**
   * Compare Dates
   *
   * @returns
   * -  1 if isoString1 is smaller than isoString2
   * - -1 if isoString2 is smaller than isoString1
   * -  0 if both are equal
   */
  static compare(isoString1: string, isoString2: string) {
    const date1 = this.getCachedDate(isoString1);
    const date2 = this.getCachedDate(isoString2);
    return DateUtil.compareInternal(date1.getTime(), date2.getTime());
  }

  /**
   * Compare null safe expecting the null value to be smaller
   *
   * @returns
   * -  1 if isoString1 is undefined or smaller than isoString2
   * - -1 if isoString2 is undefined or smaller than isoString1
   * -  0 if both are undefined or equal
   */
  static compareNullSafe(isoString1: string, isoString2: string) {
    const date1 = isoString1 ? this.getCachedDate(isoString1) : new Date(0);
    const date2 = isoString2 ? this.getCachedDate(isoString2) : new Date(0);
    return DateUtil.compareInternal(date1.getTime(), date2.getTime());
  }
  /**
   * Compare null safe expecting the null value to be smaller
   *
   * @returns
   * -  1 if isoString1 is undefined or smaller than isoString2
   * - -1 if isoString2 is undefined or smaller than isoString1
   * -  0 if both are undefined or equal
   */
  static compareNullSafeDates(dateValue1: Date, dateValue2: Date) {
    const date1 = dateValue1 ? dateValue1 : new Date(0);
    const date2 = dateValue2 ? dateValue2 : new Date(0);
    return DateUtil.compareInternal(date1.getTime(), date2.getTime());
  }

  private static compareInternal(date1: number, date2: number) {
    return date1 === date2 ? 0 : date1 < date2 ? 1 : -1;
  }

  static isCurrentYear(dateTime: DateTime) {
    return dateTime.hasSame(DateTime.local(), 'year');
  }

  static isToday(dateTime: DateTime) {
    return dateTime.hasSame(DateTime.local(), 'day');
  }

  static isLaterToday(dateTime: DateTime) {
    const currentDate = DateTime.now();
    const millisecondDiff = currentDate.diff(dateTime).toObject().milliseconds;
    return this.isToday(dateTime) && millisecondDiff < 0;
  }

  static isEarlierToday(dateTime: DateTime) {
    const currentDate = DateTime.now();
    const millisecondDiff = currentDate.diff(dateTime).toObject().milliseconds;
    return this.isToday(dateTime) && millisecondDiff > 0;
  }


  static isYesterday(dateTime: DateTime) {
    const distanceFromTodayAsDays = dateTime.startOf('day').diff(DateUtil.startOfToday(), 'days').days;

    return distanceFromTodayAsDays === -1;
  }

  static isTomorrow(dateTime: DateTime) {
    const distanceFromTodayAsDays = dateTime.startOf('day').diff(DateUtil.startOfToday(), 'days').days;

    return distanceFromTodayAsDays === 1;
  }

  static isLaterInCurrentYear(dateTime: DateTime) {
    const currentDate = DateTime.local();
    return this.isCurrentYear(dateTime) && currentDate < dateTime;
  }

  static isAfterCurrentYear(dateTime: DateTime) {
    const currentDate = DateTime.local().get('year');
    const year = dateTime.get('year');
    return (year - currentDate) >= 1;
  }

  static isNearToday(dateToTest: DateTime): boolean {
    const distanceFromTodayAsDays = Math.abs(
      dateToTest.startOf('day').diff(DateUtil.startOfToday(), 'days').days
    );

    return distanceFromTodayAsDays < 2;
  }

  static isWithinLast7Days(dateTime: DateTime) {
    const oneWeekBefore = DateUtil.startOfToday().minus({ days: 7 });
    return dateTime > oneWeekBefore;
  }

  static isWithinLast1Day(dateMilis: number) {
    const nowMillis = DateUtil.currentDateMs();
    const diffInMs = nowMillis - dateMilis;
    if (diffInMs < 0) {
      return false;
    }
    const isWithinLast1Day = diffInMs < DateUtil.MILISECONDS_IN_A_DAY;
    return isWithinLast1Day;
  }

  static isFromYesterTo6DaysInFuture(date: DateTime): boolean {
    const daysInFuture = date.startOf('day')
      .diff(DateUtil.startOfToday(), 'days').days;

    return -2 < daysInFuture && daysInFuture < 7;
  }

  static isDateOnly(date: DateTime) {
    return date.hour === 0 && date.minute === 0;
  }

  static isInSameDay(date1: DateTime, date2: DateTime): boolean {
    return date1.hasSame(date2, 'day');
  }

  static toFullDateAndTime(dateTime: DateTime) {
    return dateTime.toFormat(dateFormats.FULL_DATE_AND_TIME);
  }

  static toDateOnly(dateTime: DateTime) {
    return dateTime.toFormat(dateFormats.DATE_ONLY);
  }

  static toDayOfTheWeekShort(dateTime: DateTime) {
    return dateTime.toFormat(dateFormats.DAY_OF_THE_WEEK_SHORT);
  }

  static toDayNameWithTime(dateTime: DateTime) {
    return dateTime.toFormat(dateFormats.DAY_OF_WEEK_AND_TIME);
  }

  static toDayNameOnly(dateTime: DateTime) {
    return dateTime.toFormat(dateFormats.DAY_OF_WEEK);
  }

  static toMonthNameWithDay(dateTime: DateTime) {
    return dateTime.toFormat(dateFormats.MONTH_NAME_WITH_DAY);
  }

  static toMonthAndDayOnly(dateTime: DateTime) {
    return dateTime.toFormat(dateFormats.NO_YEAR_NO_TIME);
  }

  static toNoYearDateTime(dateTime: DateTime) {
    return dateTime.toFormat(dateFormats.NO_YEAR);
  }

  static toTimeClientFormat(dateTime: DateTime) {
    return dateTime.toFormat(dateFormats.TIME_ONLY);
  }

  static toRelativeTime(dateTime: DateTime) {
    return this.capitalize(`${dateTime.toRelativeCalendar({ unit: 'days' })} ${dateTime.toFormat(dateFormats.TIME_ONLY)}`);
  }

  static toRelativeDate(dateTime: DateTime) {
    return this.capitalize(`${dateTime.toRelativeCalendar({ unit: 'days' })}`);
  }

  static toShortWeekDay(dateTime: DateTime) {
    return dateTime.weekdayShort;
  }

  static toLongWeekDay(dateTime: DateTime) {
    return dateTime.weekdayLong;
  }

  static toDateMed(dateTime: DateTime) {
    return dateTime.toLocaleString(DateTime.DATE_MED);
  }

  static toSQLDateFilter(dateTimeStr: string): string {
    // TODO: Adjust this when a proper date time handling is implemented for filters
    return DateTime.fromISO(dateTimeStr).minus({ hours: -2 }).toISO().slice(0, 19).replace('T', ' ');
  }

  static getTodayAsDateRange() {
    const currentDate = DateTime.now();
    return [currentDate, currentDate.endOf('day')];
  }

  static getTomorrowAsDateRange() {
    const currentDate = DateTime.now();
    return [currentDate.plus({ days: 1 }).startOf('day'), currentDate.plus({ days: 1 }).endOf('day')];
  }

  static getThisWeekendRange() {
    const currentDate = DateTime.now();
    const fridayStart = currentDate.startOf('week').plus({ days: 4, hours: 15 });
    const sundayEnd = currentDate.endOf('week');
    return currentDate >= fridayStart ? [currentDate, sundayEnd] : [fridayStart, sundayEnd];
  }

  static getThisWeekRange() {
    const startOfWeekRangeFromToday = DateTime.now();
    const endOfThisWeek = startOfWeekRangeFromToday.endOf('week');
    return [startOfWeekRangeFromToday, endOfThisWeek];
  }

  static getNextWeekRange() {
    const currentDate = DateTime.now();
    const startOfNextWeek = currentDate.startOf('week').plus({ weeks: 1 });
    const endOfNextWeek = currentDate.endOf('week').plus({ weeks: 1 });

    return [startOfNextWeek, endOfNextWeek];
  }

  static getThisMonthRange() {
    const startOfMonthRangeFromToday = DateTime.now();
    const endOfThisMonth = startOfMonthRangeFromToday.endOf('month');
    return [startOfMonthRangeFromToday, endOfThisMonth];
  }

  static getNextMonthRange() {
    const currentDate = DateTime.now();
    const startOfNextMonth = currentDate.startOf('month').plus({ months: 1 });
    const endOfNextMonth = currentDate.endOf('month').plus({ months: 1 });
    return [startOfNextMonth, endOfNextMonth];
  }

  static getDiffOfIsoStrings(isoString1: string, isoString2: string, units: DurationUnits = ['hours']): Duration {
    const date1 = DateUtil.fromIsoString(isoString1);
    const date2 = DateUtil.fromIsoString(isoString2);
    return date1.diff(date2, units);
  }

  private static capitalize(toCapitalize: string): string {
    return `${toCapitalize[0].toUpperCase()}${toCapitalize.slice(1)}`;
  }
}

