import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WenSnackbarOpener } from '@portal/wen-components';
import { QuoteData } from '@portal/wen-backend-api';
import { FeedItemScroller } from '../../../../shared/components/feed/providers/feed-item-scroller';

@Injectable()
export class QuoteService {

  private feedItemScroller = inject(FeedItemScroller);
  private translateService = inject(TranslateService);
  private snackBarOpener = inject(WenSnackbarOpener);

  scrollToQuote(quoteItem: QuoteData) {
    const originalMessage = this.feedItemScroller.getFeedItem(quoteItem.id);
    if (!Boolean(originalMessage)) {
      const notificationText = this.translateService.instant('QUOTE_NOT_LOADED');
      const notificationIcon = 'error_outline';
      this.snackBarOpener.openNotificationSnackbar({ notificationIcon, notificationText });
    } else {
      this.feedItemScroller.scrollTo({ top: originalMessage.offsetTop - 5 });
      this.highlight(originalMessage);
    }
  }

  private highlight(originalMessage: HTMLElement) {
    originalMessage.classList.add('wen-message-box-highlight');
    setTimeout(() => {
      originalMessage.classList.remove('wen-message-box-highlight');
    }, 600);
  }
}
