import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable({ providedIn: 'root' })
export class DateRangeConverter {

  convertRangeToRequestParameters(range: DateTime[]): [string, string] {
    return [
      this.ensureDateTimeType(range[0]).toUTC().toISO(),
      this.ensureDateTimeType(range[1]).toUTC().toISO(),
    ];
  }

  private ensureDateTimeType(date: any): DateTime {
    const isNotDateTime = !(date instanceof DateTime);
    const isCompatibleWithDateTime = DateTime.isDateTime(date);
    if (isNotDateTime && isCompatibleWithDateTime) {
      return DateTime.local(
        date.year,
        date.month,
        date.day,
        date.hour,
        date.minute,
        date.second,
        date.millisecond
        );
    }

    return date;
  }
}
