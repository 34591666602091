import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ScrollStateModule } from '../../../shared/directives/scroll-state/scroll-state.module';
import { SharedModule } from '../../../shared/shared.module';
import { DiscoverAppListComponent } from './components/discover-app-list/discover-app-list.component';
import { UserAppListComponent } from './components/user-app-list/user-app-list.component';
import { AppListComponent } from './components/app-list/app-list.component';
import { DiscoverAppListDataResolver } from './providers/discovery-app-list-data.resolver';
import { APP_CONTEXT_MENU_ITEMS } from './components/actions/app-action-menu';
import { ActionMenuModule, ACTION_MENU_ITEMS, ContextMenuComponent, ContextMenuTriggerDirective, IsDesktopViewDirective } from '@portal/wen-components';

@NgModule({
  imports: [
    SharedModule,
    MatDialogModule,
    ScrollingModule,
    ScrollStateModule,
    ActionMenuModule,
    ContextMenuTriggerDirective,
    ContextMenuComponent,
    IsDesktopViewDirective
  ],
  declarations: [
    UserAppListComponent,
    AppListComponent,
    DiscoverAppListComponent,
  ],
  providers: [
    DiscoverAppListDataResolver,
    { provide: ACTION_MENU_ITEMS, useValue: APP_CONTEXT_MENU_ITEMS, multi: true }
  ],
  exports: [
    UserAppListComponent,
    DiscoverAppListComponent,
    AppListComponent,
  ]
})
export class AppListModule { }
