import { Injectable } from '@angular/core';
import { PaletteProvider } from '@portal/wen-components';

@Injectable()
export class WenPaletteProvider extends PaletteProvider {

  private colorFallback = '#F2F2F2';
  private readonly palette = [
    { dark: '#F46711', light: '#FF8942' },
    { dark: '#DFA208', light: '#F2BF3D' },
    { dark: '#77A900', light: '#AFCC69' },
    { dark: '#0595FF', light: '#79BEF1' },
    { dark: '#1168BE', light: '#418AD2' },
    { dark: '#DE7293', light: '#ECB6C6' }
  ];

  public getColorFor(seed: string) {
    if (!seed) {
      return this.colorFallback;
    }
    const paletteIndex = this.getPaletteIndex(seed);
    return this.palette[paletteIndex].dark;
  }

  public getGradientFor(seed: string) {
    if (!seed) {
      return this.colorFallback;
    }

    const paletteIndex = this.getPaletteIndex(seed);
    return `linear-gradient(135deg, ${this.palette[paletteIndex].light}, ${this.palette[paletteIndex].dark})`;
  }

  private getPaletteIndex(seed: string): number {
    const charCodeSum = Array.from(seed).reduce((acc, curr) => {
      try {
        acc += curr.charCodeAt(0);
      } catch { }
      return acc;
    }, 0);
    return charCodeSum % this.palette.length;
  }

}

export enum ColorPalette {
  LIGHT_BLUE = '#0099C5',
  LIGHT_BLUE2 = '#008AB2',
  DARK_BLUE = '#1467BA',
  OCEAN_BLUE = '#0D98FF',
  GREY = '#8E8E93',
  DARK_GREEN = '#019621',
  LIGHT_GREEN = '#7BAA0B',
  WHITE = '#fff',
  RED = '#E2001A',
  RED2 = '#f44336',
  /** active filter background color */
  RED3 = '#FFEEEF',
  ORANGE = '#FF6B11',
  GOLD = '#E9AA0B',
  GREEN = '#7BAA0A',
  PURPLE = '#6C187B',
  PINK = '#E3819F'
}

export enum GreyPalette {
  WHITE2 = '#f7f7f7',
  GREY2 = '#eeeeee',
  GREY4 = '#959595',
  GREY5 = '#cccccc',
  GREY6 = '#5d5d5d',
  GREY7 = '#767676',
  GREY8 = '#333333',
  GREY9 = '#f2f2f2',
}
