@if (displayData) {
  @if(!displayData.hideLine) {
    <hr class="wen-separator-line" />
  }
  @if (displayData.rightText) {
    <div class="wen-separator-arrow"></div>
    <div class="wen-separator-text">{{ displayData.rightText }}</div>
  }
  @if (displayData.centerText) {
    <div class="wen-separator-date">{{ displayData.centerText }}</div>
  }
}