import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { SimpleUserDTO } from '@portal/wen-backend-api';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { selectorWithParam } from '../../../core/common/util/selector-with-param';
import { addAdminForCurrentChannel } from '../../../core/store/channel/channel.actions';
import { selectCurrentChannelAdmins } from '../../../core/store/channel/channel.selectors';
import { removeFilter } from '../../../core/store/filter/filter.actions';
import { selectSearchTerm } from '../../../core/store/filter/filter.selectors';
import { FilterEntityType } from '../../../core/store/filter/models/filter';
import { UserSearchService } from '../../chat/chat-creation/providers/user-search-service';
import { HeaderDataHelper } from '../../../core/services/util/header-data-helper';

@Component({
  selector: 'wen-channel-add-admin',
  templateUrl: './channel-add-admin.component.html',
  styleUrls: ['./channel-add-admin.component.scss'],
  providers: [UserSearchService]
})
export class ChannelAddAdminComponent implements OnInit, OnDestroy {

  private store = inject(Store);
  private headerDataHelper = inject(HeaderDataHelper);

  private onDestroy$ = new Subject<void>();
  searchTerm$: Observable<string>;
  selectedUserIds$: Observable<string[]>;

  ngOnInit() {
    this.headerDataHelper.initializeContextTitleWithChannelTitle(true);

    this.searchTerm$ = this.store.pipe(
      selectorWithParam(selectSearchTerm, FilterEntityType.CHANNEL_ADD_ADMIN_LISTS),
      takeUntil(this.onDestroy$),
    );
    this.selectedUserIds$ = this.store.pipe(
      select(selectCurrentChannelAdmins),
      map(users => users.map(user => user.userId))
    );
  }

  addUserAsAdmin(user: SimpleUserDTO) {
    this.store.dispatch(addAdminForCurrentChannel({ userId: user.userId }));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.store.dispatch(removeFilter({ filterEntityType: FilterEntityType.CHANNEL_ADD_ADMIN_LISTS }));
  }
}
