import { Injectable } from '@angular/core';
import { DocumentUtils, NavigatorUtils } from '@portal/wen-common';
import { distinctUntilChanged, map, merge, shareReplay, startWith } from 'rxjs';
import { ConnectionMonitor, ConnectionScope } from './types';

@Injectable()
export class BrowserConnectionMonitor implements ConnectionMonitor {

  public readonly connectionState$ = merge(
    DocumentUtils.isOnline$,
  ).pipe(
    startWith(NavigatorUtils.isOnline()),
    distinctUntilChanged(),
    shareReplay(1),
    map(isOnline => {
      return {
        isOnline, scope: ConnectionScope.INTERNET_CONNECTION
      };
    })
  );

}
