import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { PollCreationComponent } from './poll-creation.component';
import { PollNewOptionComponent } from './components/poll-new-option/poll-new-option.component';
import { WenIconModule } from '../../wen-icon/wen-icon.module';
import { TranslateModule } from '@ngx-translate/core';
import { DialogLayoutModule } from '../../dialog-layout/dialog-layout.module';
import { CommonModule } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FocusFirstInputModule } from '../../../directives/focus-first-input/focus-first-input.module';
import { ActionComponent } from '../../core/action/action.component';
import { WithVariantDirective } from '../../../directives/styling/with-variant.directive';

@NgModule({
  declarations: [PollCreationComponent, PollNewOptionComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    WenIconModule,
    MatInputModule,
    TranslateModule,
    DialogLayoutModule,
    MatSlideToggleModule,
    FocusFirstInputModule,
    ActionComponent,
    WithVariantDirective
  ],
  exports: [PollCreationComponent],
})
export class PollCreationModule { }
