import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { DocumentUtils } from '@portal/wen-common';
import { Observable, Subject, takeUntil } from 'rxjs';
import { AppNavigator } from '../../../../core/services/navigation/app-navigator';
import { ErrorReasons } from './error-page-reason-content/error-page-reason-content.component';
import { ErrorPageDatasource } from './error-page.datasource';

@Component({
  selector: 'wen-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  providers: [
    ErrorPageDatasource
  ]
})
export class ErrorPageComponent implements OnInit, OnDestroy {

  private readonly navigator = inject(AppNavigator);
  private readonly errorPageDatasource = inject(ErrorPageDatasource);

  private onDestroy$ = new Subject<void>();
  isClientError$: Observable<boolean>;
  errorReason$: Observable<ErrorReasons>;

  ngOnInit(): void {
    const {
      isClientError$,
      onIdle$,
      onOnline$,
      mostImportantScope$
    } = this.errorPageDatasource.attach();

    this.isClientError$ = isClientError$;

    onIdle$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(() => {
      this.errorPageDatasource.forceRetry();
    });

    this.errorReason$ = mostImportantScope$;

    onOnline$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(() => {
      this.navigator.leaveStackAndNavigateBack();
    });
  }

  reloadClicked() {
    DocumentUtils.reload();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
