<div class="wen-channel-post-editor-content" portalDragOverlay [overlayContent]="overlayContent"
  (fileDropped)="onFilesDropped($event)">
  <form wenFormStore wenFormControlledHeader class="wen-form" [formGroup]="channelMessageFormGroup" wenIosViewportFix
    (click)="formClicked()">
    <div class="wen-form-group">
      <div class="wen-form-field-wrapper">
        <wen-extended-text-editor [formControlName]="'postContent'"
          [inputPlaceholder]="'CHANNEL_POST_EDITOR_INPUT_PLACEHOLDER' | translate"></wen-extended-text-editor>
      </div>
    </div>
  </form>
  <div class="wen-channel-post-editor-actions">
    <wen-action wenVariant="textSubtle" iconName="image_stacked" (click)="uploadMedia()" [isDisabled]="hasMedia">
      {{ 'CHANNEL_POST_EDITOR_ACTION_MEDIA' | translate }}
    </wen-action>
    <wen-action wenVariant="textSubtle" iconName="location" (click)="addLocation()" [isDisabled]="hasLocation">
      {{ 'CHANNEL_POST_EDITOR_ACTION_LOCATION' | translate }}
    </wen-action>
  </div>
</div>
