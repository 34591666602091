import { inject, Injectable } from '@angular/core';
import { RoomMemberData, SocketIoService } from '@portal/wen-backend-api';
import { partitionArray } from '@portal/wen-common';
import { defaultIfEmpty, forkJoin, map, Observable, switchMap, throwIfEmpty } from 'rxjs';
import { ChatBackendApi, ChatUserApi } from '../../../backend-api/backend-api';
import { SessionKeyShareServiceBase, SessionKeyShareServiceProvider } from '../session-keys/session-key-share-service';
import { ChatMessageTransactionData } from '../types/chat-transaction-data';
import { PrepareDialogRoomService } from './prepare-chat-room/prepare-dialog-room';

export type PrepareRoomResult = {
  validRoomRequests: ValidRoomResult[];
  invalidRoomRequests: ChatMessageTransactionData[];
};

export type ValidRoomResult = {
  roomMemberData: RoomMemberData;
  transactionDataRequest: ChatMessageTransactionData;
};

export class ChatRoomPrepareService {

  constructor(
    private socketIoService: SocketIoService,
    private chatUserApi: ChatUserApi,
    private sessionKeyShareService: SessionKeyShareServiceBase,
    private prepareDialogRoomService: PrepareDialogRoomService,
  ) { }

  /**
   * Prepare the chat rooms for every transaction data
   *  - Datas with roomId are used to fetch the members of the room
   *  - Datas with dialogUserId are considered dialog chat therefore the following validation steps are done:
   *    - Check if the user has available chat keys (eg.: logged in at elast once)
   *    - Create or get the current dialog room based on userid (assuming only 1 dialog room can exist between 2 users)
   * Once the rooms/dialogs are validated share the session ekys for the given datas in the corresponding rooms
   */
  prepareRooms(
    composerDataRequests: ChatMessageTransactionData[],
  ): Observable<PrepareRoomResult> {
    const directRoomDatas = composerDataRequests.filter(composerDataRequest => composerDataRequest.roomId);
    const dialogDatas = composerDataRequests.filter(composerDataRequest => composerDataRequest.dialogUserId);
    const dialogDatas$ = this.chatUserApi.getUserData().pipe(
      switchMap((currentUserData) => {
        const { userId: currentUserId } = currentUserData;
        const [otherDatas, roomToSelf = []] = partitionArray(
          dialogDatas,
          (composerDataRequest => composerDataRequest.dialogUserId !== currentUserId)
        );
        return this.prepareDialogRoomService.prepareDialogRoom(otherDatas, currentUserId).pipe(
          switchMap(({ prepareResults, invalidRoomRequests = [] }) => {
            const invalids = [...invalidRoomRequests, ...roomToSelf];
            const roomMemberDatas = prepareResults.map((prepareResult) => prepareResult.roomMemberData);
            return this.shareSessionKeys(roomMemberDatas).pipe(
              map(() => {
                const res: PrepareRoomResult = {
                  validRoomRequests: prepareResults,
                  invalidRoomRequests: invalids
                };
                return res;
              })
            );
          })
        );
      })
    );
    const directRoomDatas$ = this.fetchMembersForRooms(directRoomDatas).pipe(
      switchMap((prepareResults) => {
        const roomMemberDatas = prepareResults.map((prepareResult) => prepareResult.roomMemberData);
        return this.shareSessionKeys(roomMemberDatas).pipe(
          map(() => {
            const res: PrepareRoomResult = {
              validRoomRequests: prepareResults,
              invalidRoomRequests: []
            };
            return res;
          })
        );
      })
    );
    return forkJoin([dialogDatas$, directRoomDatas$]).pipe(
      throwIfEmpty(),
      map(([data1, data2]) => {
        const res: PrepareRoomResult = {
          validRoomRequests: [...data1.validRoomRequests, ...data2.validRoomRequests],
          invalidRoomRequests: [...data1.invalidRoomRequests, ...data2.invalidRoomRequests]
        };
        return res;
      })
    );
  }

  private shareSessionKeys(roomMemberDatas: RoomMemberData[]): Observable<RoomMemberData[]> {
    return this.sessionKeyShareService.ensureSessionKeysAreShared(roomMemberDatas).pipe(
      map(() => roomMemberDatas)
    );
  }

  private fetchMembersForRooms(composerDataRequests: ChatMessageTransactionData[]): Observable<ValidRoomResult[]> {
    const roomDetails$ = composerDataRequests.map((transactionDataRequest) => {
      const { roomId } = transactionDataRequest;
      return this.socketIoService.chat.room.details.acknowledgement$({ id: roomId }).pipe(
        map((chatRoom) => {
          const { members } = chatRoom;
          const memberUserIds = members.map(member => member.userId);
          const roomMemberData: RoomMemberData = {
            roomId,
            memberUserIds,
          };
          const prepareRoomResult: ValidRoomResult = {
            roomMemberData,
            transactionDataRequest
          };
          return prepareRoomResult;
        })
      );
    });
    return forkJoin(roomDetails$).pipe(defaultIfEmpty([]));
  }

}

@Injectable()
export class ChatRoomPrepareServiceFactory {

  private socketIoService = inject(SocketIoService);
  private sessionKeyShareServiceProvider = inject(SessionKeyShareServiceProvider);
  private chatBackendApi = inject(ChatBackendApi);
  private chatUserApi = inject(ChatUserApi);
  private prepareDialogRoomService = inject(PrepareDialogRoomService);

  create(encryptedSendingEnabled: boolean) {
    return new ChatRoomPrepareService(
      this.socketIoService, this.chatUserApi,
      this.sessionKeyShareServiceProvider.get(encryptedSendingEnabled), this.prepareDialogRoomService,
    );
  }
}
