import { Component, EventEmitter, HostBinding, Injectable, inject, Input, OnInit, Output } from '@angular/core';
import { VariantConfig } from '../../directives/styling/variant-config';
import { Icon } from '../wen-icon/types';
import { WithVariantDirective } from '../../directives/styling/with-variant.directive';
import { TranslateService } from '@ngx-translate/core';

export enum LocationPreviewVariant {
  normal = 'normal',
  small = 'small',
}

@Injectable()
class LocationPreviewVariantConfig extends VariantConfig {
  possibleVariants = LocationPreviewVariant;
  defaultVariant = 'normal';
}

@Component({
  selector: 'wen-location-preview',
  templateUrl: './location-preview.component.html',
  styleUrls: ['./location-preview.component.scss'],
  providers: [
    WithVariantDirective,
    { provide: VariantConfig, useClass: LocationPreviewVariantConfig }
  ]
})
export class LocationPreviewComponent implements OnInit {

  private translateService = inject(TranslateService);
  locationRemoveAltText = '';

  @Input() icon: Icon;
  @Input() locationData: string;

  @Output() dismiss = new EventEmitter();

  @HostBinding('attr.title') get title() {
    return this.locationData || '';
  }

  get isDismissable() {
    return this.dismiss.observed;
  }

  ngOnInit(): void {
    const locationType = this.translateService.instant('CHANNEL_POST_EDITOR_ACTION_LOCATION');
    this.locationRemoveAltText = this.translateService.instant('REMOVE_LABEL', { itemName: locationType });
  }

  emitDismiss() {
    this.dismiss.emit();
  }
}
