import { fromEvent, map, merge, startWith } from 'rxjs';

export class DocumentUtils {

  static isInBackground$ = fromEvent(document, 'visibilitychange').pipe(
    startWith(null),
    map(() => document.visibilityState === 'hidden'),
  );

  static isOnline$ = merge(
    fromEvent(window, 'online').pipe(map(() => true)),
    fromEvent(window, 'offline').pipe(map(() => false))
  );

  static reload() {
    document.location.reload();
  }
}
