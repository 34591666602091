@if(pollModel$ | async; as pollModel){
  <div portal-title class="poll-question">{{ pollModel.question }}</div>
  @if (isReadonlyMode$ | async) {
    <ng-container [ngTemplateOutlet]="readonlyView"></ng-container>
  } @else {
    <wen-selection-list
      class="wen-selection-list with-text-wrap-preserve-whitespace"
      (selectionChanged)="onSelectionChange($event)"
      [selectionItems]="pollModel.options"
      [selectionModel]="selectionModel$ | async"
    ></wen-selection-list>
    <div class="wen-poll-save-button">
      <span (click)="sendPollSelection()">{{
        "VOTE_ACTION_LABEL" | translate
      }}</span>
    </div>
  }

  <ng-template #readonlyView>
    <wen-read-only-poll [poll]="pollModel"></wen-read-only-poll>
    <div class="wen-poll-choice-counter">
      {{ pollModel.chooserCount }} {{'VOTE_LABEL' | translate: {count: pollModel.chooserCount} }}
    </div>
  </ng-template>
}

