import { ChatMessageEmbedResolver } from '../../../services/chat/chat-message-embed-resolver';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ScheduledMessageEvent, SocketIoService, ToRoomEventType } from '@portal/wen-backend-api';
import { distinctUntilChanged, filter, forkJoin, map, switchMap, tap, withLatestFrom } from 'rxjs';
import { mapReplayDirectionToStateFlags } from '../../../../shared/components/paginated-scrollview/replay-direction-mapper';
import { ScheduledMessageDecryptor } from '../../../services/chat/decryption/scheduled-message-decryptor';
import { RootState } from '../../root/public-api';
import { fetchScheduledChatMessages, fetchScheduledChatMessagesComplete, removeOneScheduledChatMessage, requestRedactScheduledChatEvent, upsertManyScheduledChatMessages, upsertScheduledChatHistoryStateExtras } from '../actions/chat-scheduled-messages.actions';
import { subscribeChatUpdates } from '../chat.actions';
import { selectCurrentRoom } from '../chat.selectors';

@Injectable()
export class ChatScheduledMessagesEffects {

  private store = inject(Store<RootState>);
  private actions$ = inject(Actions);
  private socketIoService = inject(SocketIoService);
  private scheduledMessageDecryptor = inject(ScheduledMessageDecryptor);

  private processScheduledEventsResponse(events: ScheduledMessageEvent[]) {
    const decryptedEvents$ = events.map((event) => {
      return this.scheduledMessageDecryptor.decryptScheduledEvent(event);
    });
    return forkJoin(decryptedEvents$).pipe(
      map((decryptedEvents) => {
        return decryptedEvents.map(decryptedEvent => decryptedEvent.asChatStoreEntity());
      })
    );
  }

  fetchScheduledMessages$ = createEffect(() => this.actions$.pipe(
    ofType(fetchScheduledChatMessages),
    switchMap(({ payload }) => {
      return this.socketIoService.chat.room.schedules.scheduledEventsReplay.acknowledgment$(payload);
    }),
    switchMap((response) => {
      const { events, more, direction, roomId } = response;
      const flags = mapReplayDirectionToStateFlags(direction, more);
      return this.processScheduledEventsResponse(events).pipe(
        tap((entities) => this.chatMessageEmbedResolver.clearCache(entities.map(e => e.eventId))),
        switchMap((entities) => {
          return [
            upsertManyScheduledChatMessages({
              roomId,
              messages: entities,
              flags
            }),
            fetchScheduledChatMessagesComplete({
              roomId,
              hasResult: Boolean(entities.length)
            })
          ];
        })
      );
    }),
  ));

  redactScheduledChatEvent$ = createEffect(() => this.actions$.pipe(
    ofType(requestRedactScheduledChatEvent),
    withLatestFrom(this.store.pipe(select(selectCurrentRoom))),
    switchMap(([{ eventId }, currentRoom]) => {
      if (!currentRoom?.id) {
        return [];
      }
      const { id: roomId } = currentRoom;
      return this.socketIoService.chat.room.schedules.scheduledEventDelete.acknowledgment$({
        eventId, roomId
      }).pipe(
        filter((response) => response?.ok),
        map(() => {
          return removeOneScheduledChatMessage({ eventId, roomId });
        })
      );
    }),
  ));

  listenRealtimeCleanSentRealtimeScheduledChatEvent$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeChatUpdates),
    switchMap(() => {
      return this.socketIoService.chat.room.messages.listen.pipe(
        filter((realTimeMessageEvent) => {
          return realTimeMessageEvent?.payload?.eventType === ToRoomEventType.SEND_MESSAGE;
        }),
        map((realTimeMessageEvent) => {
          const { eventId, roomId } = realTimeMessageEvent;
          return removeOneScheduledChatMessage({
            roomId, eventId
          });
        })
      );
    }),
  ));

  reloadScheduledChatMessageCount$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeChatUpdates),
    switchMap(() => {
      return this.store.pipe(
        select(selectCurrentRoom),
        map((currentRoomEntity) => currentRoomEntity?.id),
        filter(roomId => Boolean(roomId)),
        distinctUntilChanged()
      );
    }),
    switchMap((roomId) => {
      return this.socketIoService.chat.room.schedules.scheduledEventCount.acknowledgment$({ roomId });
    }),
    map(response => {
      const { roomId, count } = response;
      return upsertScheduledChatHistoryStateExtras({
        roomId, extras: { totalCount: count }
      });
    })
  ));

  listenRealtimeScheduledChatMessageCountUpdates$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeChatUpdates),
    switchMap(() => {
      return this.socketIoService.chat.room.schedules.scheduledEventCount.listen;
    }),
    map((countResponse) => {
      const { roomId, count } = countResponse;
      return upsertScheduledChatHistoryStateExtras({
        roomId, extras: { totalCount: count }
      });
    })
  ));

  listenRealtimeScheduledChatMessageEventUpdates$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeChatUpdates),
    switchMap(() => {
      return this.socketIoService.chat.room.schedules.scheduledEventsReplay.listen;
    }),
    switchMap((scheduledMessagesResponse) => {
      const { roomId, events } = scheduledMessagesResponse;
      return this.processScheduledEventsResponse(events).pipe(
        tap((entities) => this.chatMessageEmbedResolver.clearCache(entities.map(e => e.eventId))),
        switchMap((entities) => {
          return [
            upsertManyScheduledChatMessages({
              roomId,
              messages: entities,
              flags: {} // Ignore flags: this code path is only used to upsert entity changes, not scroll-like paging
            })
          ];
        })
      );
    }),
  ));

  constructor(
    private chatMessageEmbedResolver: ChatMessageEmbedResolver
  ) {}
}
