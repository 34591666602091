import { InjectionToken } from '@angular/core';
import type * as ISdApiModule from '@smartdesign/web-api';

type SdApiModuleType = typeof ISdApiModule;

export type SdModuleWrapper = {
  connect: SdApiModuleType['connect'];
};
export type SdModuleLoaderFn = () => Promise<SdModuleWrapper>;

export const SD_MODULE_LOADER = new InjectionToken<SdModuleLoaderFn>('SD_MODULE_LOADER');

export const SDModuleLoader: SdModuleLoaderFn = async () => {
  const smartdesign = await import('@smartdesign/web-api');
  return smartdesign;
};
