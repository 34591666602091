import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { ReadCategoriesFieldModule } from '../../../shared/components/read-categories-field/read-categories-field.module';
import { SharedModule } from '../../../shared/shared.module';
import { AppDetailComponent } from './components/app-detail/app-detail.component';
import { AppViewComponent } from './components/app-view/app-view.component';
import { ActionComponent, WithVariantDirective } from '@portal/wen-components';

@NgModule({
  imports: [
    SharedModule,
    ScrollingModule,
    ReadCategoriesFieldModule,
    ActionComponent,
    WithVariantDirective
  ],
  declarations: [
    AppViewComponent,
    AppDetailComponent
  ],
  exports: [
    AppViewComponent,
    AppDetailComponent
  ]
})
export class AppDetailModule { }
