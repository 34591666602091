import { Injectable } from '@angular/core';
import { generateId } from '@portal/wen-backend-api';
import { DateUtil } from '../../common/date/date-util';
import { AttachmentData } from '../smartdesign/chat-distribution/distribution-chat-attachment-loader';

const WEN_DEVICE_ID = 'wen_device_id';
const WEN_ONBOARDING_DONE = 'wen_onboarding_done';
const WEN_DATA_PROTECTION_NEEDED = 'wen_data_protection_needed';
const WEN_INITIAL_NATIVE_DATA_PROCESSED = 'wen_initial_native_data_processed';
const WEN_EXCLUSIVE_INVITE_CODE_ADDED = 'wen_exclusive_invite_code_added';
const CRM_DISTRIBUTED_CHAT_MESSAGE_ATTACHMENT_DATA = 'crm_distributed_chat_message_attachment_data';
const WEN_PRIMARY_LANGUAGE = 'primaryLanguage';
const WEN_UNENCRYPTED_CHAT_DIALOG_DISMISSED = 'wen_unencrypted_chat_dialog_dismissed';
const WEN_KEEP_ALIVE_LAST_EMIT = 'wen_keep_alive_last_emit';
/**
 * Store the tracing enabled/disabled state in storage therefore it can be
 *  loaded up without log-in
 */
const WEN_TRACING_ENABLED = 'wen_tracing_enabled';

// TODO: find a better way to handle this (with cross signing?)
const WEN_CHAT_LAST_USER_SIGNIN_ID = 'wen_chat_last_user_signin_id';

@Injectable()
export class WenStorageService {

  getDeviceId() {
    let deviceId = localStorage.getItem(WEN_DEVICE_ID);
    if (!deviceId) {
      deviceId = generateId();
      localStorage.setItem(WEN_DEVICE_ID, deviceId);
    }
    return deviceId;
  }

  setOnboardingDone() {
    localStorage.setItem(WEN_ONBOARDING_DONE, 'true');
  }

  isOnboardingDone() {
    return Boolean(localStorage.getItem(WEN_ONBOARDING_DONE));
  }

  setDataProtectionNeeded() {
    if (localStorage.getItem(WEN_DATA_PROTECTION_NEEDED)) {
      return;
    }
    localStorage.setItem(WEN_DATA_PROTECTION_NEEDED, 'true');
  }

  setDataProtectionDone() {
    localStorage.setItem(WEN_DATA_PROTECTION_NEEDED, 'false');
  }

  isDataProtectionNeeded() {
    return localStorage.getItem(WEN_DATA_PROTECTION_NEEDED) === 'true';
  }

  setInitialNativeDataProcessed() {
    sessionStorage.setItem(WEN_INITIAL_NATIVE_DATA_PROCESSED, 'true');
  }

  isInitialNativeDataProcessed() {
    return sessionStorage.getItem(WEN_INITIAL_NATIVE_DATA_PROCESSED) === 'true';
  }

  setLastUserId(userId: string) {
    return localStorage.setItem(WEN_CHAT_LAST_USER_SIGNIN_ID, userId);
  }

  getLastUserId() {
    return localStorage.getItem(WEN_CHAT_LAST_USER_SIGNIN_ID);
  }

  setExclusiveInviteCodeAdded(userId: string) {
    localStorage.setItem(WEN_EXCLUSIVE_INVITE_CODE_ADDED, userId);
  }

  getExclusiveInviteCodeValue() {
    return localStorage.getItem(WEN_EXCLUSIVE_INVITE_CODE_ADDED);
  }

  clearDeviceId() {
    localStorage.removeItem(WEN_DEVICE_ID);
  }

  clearExclusiveInviteData() {
    localStorage.removeItem(WEN_EXCLUSIVE_INVITE_CODE_ADDED);
  }

  setCRMDistributedChatMessageAttachmentData(attachmentData: AttachmentData) {
    sessionStorage.setItem(CRM_DISTRIBUTED_CHAT_MESSAGE_ATTACHMENT_DATA, JSON.stringify(attachmentData));
  }

  getCRMDistributedChatMessageAttachmentData(): AttachmentData {
    const data = sessionStorage.getItem(CRM_DISTRIBUTED_CHAT_MESSAGE_ATTACHMENT_DATA);
    return JSON.parse(data);
  }

  clearCRMDistributedChatMessageAttachmentData() {
    sessionStorage.removeItem(CRM_DISTRIBUTED_CHAT_MESSAGE_ATTACHMENT_DATA);
  }

  getLanguage() {
    return localStorage.getItem(WEN_PRIMARY_LANGUAGE);
  }

  setLanguage(languageCode: string) {
    return localStorage.setItem(WEN_PRIMARY_LANGUAGE, languageCode);
  }

  setUnencryptedChatDialogDismissedForDevice() {
    localStorage.setItem(WEN_UNENCRYPTED_CHAT_DIALOG_DISMISSED, 'true');
  }

  setUnencryptedChatDialogDismissedForSession() {
    sessionStorage.setItem(WEN_UNENCRYPTED_CHAT_DIALOG_DISMISSED, 'true');
  }

  isUnencryptedChatDialogDismissed() {
    return !!localStorage.getItem(WEN_UNENCRYPTED_CHAT_DIALOG_DISMISSED) || !!sessionStorage.getItem(WEN_UNENCRYPTED_CHAT_DIALOG_DISMISSED);
  }

  setKeepAliveForSession(newValue: number) {
    const nowStr = newValue.toString();
    sessionStorage.setItem(WEN_KEEP_ALIVE_LAST_EMIT, nowStr);
  }

  isKeepAliveNeeded() {
    const lastEmitData = sessionStorage.getItem(WEN_KEEP_ALIVE_LAST_EMIT);
    if (!lastEmitData || isNaN(Number(lastEmitData))) {
      return true;
    }
    const lastEmitDataMs = parseInt(lastEmitData, 10);
    return !DateUtil.isWithinLast1Day(lastEmitDataMs);
  }

  setTracingEnabled(isEnabled: boolean) {
    if (isEnabled) {
      localStorage.setItem(WEN_TRACING_ENABLED, 'true');
    } else {
      localStorage.removeItem(WEN_TRACING_ENABLED);
    }
  }

  isTracingEnabled() {
    return Boolean(localStorage.getItem(WEN_TRACING_ENABLED));
  }

}
