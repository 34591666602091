import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, Inject, Input, OnDestroy, OnInit, Optional, Output, ViewContainerRef } from '@angular/core';
import { MessageModificationState, ReactionContext, ToRoomEventType } from '@portal/wen-backend-api';
import { FeedRenderingRegistry, ResizeHandlerProvider } from '@portal/wen-components';
import { Subject, takeUntil } from 'rxjs';
import { DateUtil } from '../../../core/common/date/date-util';
import { FeatureEnablementService } from '../../../core/services/configuration/feature-enablement';
import { Embed } from '../../../core/store/channel/channel.state';
import { IMAGE_PROXY_BASE_URL } from '../../../frame/api/tokens';
import { ColorPalette } from '../../directives/colorize/palette-provider';
import { MessageBoxStatus } from '../message-status-indicator/components/message-status-indicator.component';
import { TextToSpeechContext } from '../text-to-speech/text-to-speech-button/text-to-speech-button.component';
import { onlyContainsEmojis } from './emoji-only-checker/emoji-only-checker';
import { MessageBoxModel } from './models/message-box.models';
import { MESSAGE_BOX_ACTIONS_HANDLER_PROVIDER_WITH_FALLBACK } from './providers/message-box-actions-handler';
import { MessageBoxCustomizations } from './providers/message-box-embed-customizations';
import { MessageBoxActionsHandler } from './providers/message-box-tokens';
import { getAvatarUrl } from '@portal/wen-common';

@Component({
  selector: 'wen-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss', './chat-message-box.component.scss', './channel-message-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ResizeHandlerProvider,
    MESSAGE_BOX_ACTIONS_HANDLER_PROVIDER_WITH_FALLBACK,
  ]
})
export class MessageBoxComponent implements OnInit, OnDestroy {
  @HostBinding('class') get classNames() {
    return {
      'wen-message-box': true,
      ...(this.messageBoxCustomization?.getClassOverrides(this) ?? {}),
    };
  }

  @HostBinding('class.wen-message-box-wide') wideClassName = false;

  private onDestroy$ = new Subject<void>();

  /** TODO: Get rid of the other properties in a different task */
  @Input() message: MessageBoxModel;
  @Input() set wide(newValue: boolean) { this.wideClassName = newValue; }
  @Input() messageId: string;
  @Input() referenceId: string;
  @Input() sender: string;
  @Input() senderVisible = true;
  @Input() content: string;
  @Input() reactionContext: ReactionContext;
  @Input() disableEmojiReaction: boolean;
  @Input() hideEmojiReactionPlaceholder: boolean;
  @Input() shareVisible = false;
  @Input() commentsVisible = false;
  @Input() commentsEnabled = false;
  @Input() scheduled: boolean;
  @Input() status: MessageBoxStatus;
  @Input() textToSpeechEnabled: boolean;
  @HostBinding('class.wen-message-box-highlighted') @Input() highlighted: boolean;

  @Output() swipeGesture = new EventEmitter<{ message: MessageBoxModel }>();

  @HostBinding('class.wen-emoji-only-message') get isEmojiOnlyMessage(): boolean {
    if (!this.isValidMessage || this.embeds?.length > 0) {
      return false;
    }
    return onlyContainsEmojis(this.content);
  }

  isEdited: boolean;
  isDeleted: boolean;
  isAutoReply: boolean;
  replyIconColor: string;

  @Input() set modificationState(modificationState: MessageModificationState) {
    this.isEdited = modificationState === MessageModificationState.EDITED
      || (modificationState === MessageModificationState.EDITED_SCHEDULE && this.scheduled);
    this.isDeleted = modificationState === MessageModificationState.DELETED;
  }
  @Input() embeds: Embed[];
  @Input() set timestamp(value: string) {
    this.rawTimeStamp = value;
    if (!DateUtil.isValidISOString(value)) {
      return;
    }
    const dateTime = DateUtil.fromIsoString(value);
    this.formattedTimeStamp = DateUtil.toTimeClientFormat(dateTime);
  }

  public formattedTimeStamp: string;
  public rawTimeStamp: string;

  public messageContext = TextToSpeechContext.MESSAGE;

  get isSharable() {
    return this.shareVisible && this.messageId;
  }

  get isForwardable() {
    return this.featureEnablementService.isChatEnabled() && !(this.isDraft || this.message?.scheduled);
  }

  get textToSpeechButtonVisible() {
    return this.textToSpeechEnabled && this.content;
  }

  get canOpenContextMenu() {
    return this.messageBoxCustomization.canOpenContextMenu(this.message);
  }

  get isValidMessage() {
    return this.messageBoxCustomization.handleMessageValidation(this.message);
  }

  get challengeVisible() {
    return this.message?.challengeEnabled && !this.message?.currentUserUpdater;
  }

  get footerFunctionsEnabled() {
    return !this.message?.disableFooterFunctions;
  }

  get userNavigationEnabled() {
    return !this.message?.disableNavigationToSender;
  }

  get isDraft() {
    return this.message?.isDraft;
  }

  get layoutConfig() {
    return this.messageBoxCustomization?.getLayoutConfig(this.message);
  }

  get contentBoundaryConfig() {
    return this.messageBoxCustomization?.getContentBoundaryConfig(this.message);
  }

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly resizeHandlerProvider: ResizeHandlerProvider,
    private readonly actionsHandler: MessageBoxActionsHandler,
    public readonly element: ElementRef,
    private readonly featureEnablementService: FeatureEnablementService,
    @Optional() private readonly feedRenderingRegistry: FeedRenderingRegistry,
    @Inject(IMAGE_PROXY_BASE_URL) private imageProxyBaseUrl: string,
    @Optional() private readonly messageBoxCustomization: MessageBoxCustomizations,
  ) {}

  ngOnInit(): void {
    if (this.feedRenderingRegistry) {
      const nativeElement = this.viewContainerRef.element.nativeElement;
      const resizeHandler = this.resizeHandlerProvider.create(nativeElement, { debounceTime: 100 });
      resizeHandler.onResize$.pipe(
        takeUntil(this.onDestroy$)
      ).subscribe(() => {
        this.feedRenderingRegistry.clearRenderingProgress(this.messageId, nativeElement);
      });
    }

    this.isAutoReply = this.message?.eventType === ToRoomEventType.AUTO_REPLY;
    if (this.isAutoReply) {
      this.replyIconColor = this.highlighted ? ColorPalette.WHITE : ColorPalette.ORANGE;
    }
  }

  onSenderClicked() {
    if (this.userNavigationEnabled) {
      this.actionsHandler.handleSenderClicked(this.messageId, this.message?.authorId);
    }
  }

  onOpenCommentsClicked() {
    if (!this.commentsEnabled || this.scheduled || this.commentsVisible || this.isDraft) {
      return;
    }
    this.actionsHandler.handleCommentsClicked(this.messageId);
  }

  forwardDeepLink() {
    if (this.isDraft) {
      return;
    }
    this.actionsHandler.handleForwardClicked(this.messageId);
  }

  shareDeepLink() {
    if (this.isValidMessage) {
      this.actionsHandler.handleShareClicked(this.messageId);
    }
  }

  onChallengeClicked() {
    this.actionsHandler.handleChallengeClicked(this.messageId);
  }

  onEmojiReactionsClicked() {
    this.actionsHandler.handleReactionClicked(this.message);
  }

  onMessageContentClicked() {
    if (this.messageBoxCustomization.canOpenContextMenu(this.message)) {
      this.actionsHandler.handleMessageContentClicked(this.message);
    }
  }

  canSwipe() {
    return this.messageBoxCustomization.canSwipe(this.message);
  }

  onSwipe() {
    this.swipeGesture.emit({ message: this.message });
    this.actionsHandler.handleSwipe(this.message);
  }

  getAvatarUrl() {
    if (this.message?.avatarIcon) {
      return this.message.avatarIcon;
    } else {
      const baseUrl = this.imageProxyBaseUrl ?? location.origin;
      return getAvatarUrl(baseUrl, this.message?.authorId);
    }
  }

  ngOnDestroy() {
    this.resizeHandlerProvider.detach();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
