import { from, map, mergeMap, Observable, toArray } from 'rxjs';

export const executeInChunks = <T, R>(
  operation: (chunk: T[]) => Observable<R>,
  data: T[],
  chunkSize: number,
) => {
  if (data.length > chunkSize) {
    const chunks: Array<T[]> = [];
    for (let i = 0; i < data.length; i += chunkSize) {
      chunks.push(data.slice(i, i + chunkSize));
    }
    return from(chunks).pipe(
      mergeMap((chunk) => {
        return operation(chunk);
      }),
      toArray(),
      map((responses) => {
        return responses[0];
      })
    );
  }
  return operation(data);
};

export const executeInChunksEmit = <T = any>(
  operation: (chunk: T[]) => void,
  data: T[],
  chunkSize: number,
) => {
  if (data.length > chunkSize) {
    for (let i = 0; i < data.length; i += chunkSize) {
      const chunk = data.slice(i, i + chunkSize);
      operation(chunk);
    }
    return;
  }
  return operation(data);
};
