@if (isWeLocalPlayerNeeded) {
  <iframe [wenLazyload]="sourceUrl" allowfullscreen></iframe>
} @else {
  @if (canUseNewVideoPlayer) {
    <div class="wen-embed-overlay">
      <wen-action class="wen-action-round wen-action-no-padding" wenVariant="emphasisSubtle" iconName="play" iconSize="huge" [altText]="playIconAltText" (click)="onMediaClick()"></wen-action>
    </div>
    <div class="wen-embed-video-wrapper">
      <img
        #image
        class="wen-embed-video"
        [wenLazyload]="thumbnail$ | async"
        [wenLazyloadDisableScaling]="true"
        retry-image-load [alt]="mediaAltText"
        />
    </div>
  } @else {
    <video [poster]="thumbnail$ | async" preload="metadata" controls playsInline [title]="mediaAltText">
      <source [src]="sourceUrl" />
    </video>
  }
}
