<wen-adaptive-background [desktopWidth]="375">
  <wen-dialog-layout wen-adaptive-background-content [headerGravity]="'top'">
    <div class="wen-error-page-content-wrapper" dialogLayoutContent>
      <div portal-body class="wen-error-page-body">
        <ng-container *ngTemplateOutlet="infoLayout"></ng-container>
      </div>
    </div>
  </wen-dialog-layout>
</wen-adaptive-background>


<ng-template #infoLayout>
  <div class="wen-info-layout">
    <div class="wen-error-page-media">
      @if (contentErrorModel.imagePath) {
        <img [src]="contentErrorModel.imagePath" alt=""/>
      }
      @if (contentErrorModel.lottiePath) {
        <ng-lottie [options]="lottieOptions" role="presentation"></ng-lottie>
      }
    </div>
    <div class="wen-error-page-content">
      <h1 portal-title class="wen-error-page-title">{{ contentErrorModel.titleTranslationKey | translate }}</h1>
      <div class="wen-error-page-description"
      [innerHtml]="contentErrorModel.descriptionTranslationKey | translate"></div>
    </div>
  </div>
</ng-template>
