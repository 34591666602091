@if (actions$ | async; as actions) {
  @if (actions.length) {
    <wen-action (click)="openContextMenu()"
                [isDisabled]="disabled"
                wenVariant="text"
                iconName="add"
                class="wen-action-no-padding"
                [altText]="'MESSAGE_ADD_ATTACHMENT' | translate">
    </wen-action>
  }
}
