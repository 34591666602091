import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppNavigator } from '../../../core/services/navigation/app-navigator';
import { PassFilterData, WenNavigationHelper } from '../../../core/services/navigation/types';
import { removeFilter } from '../../../core/store/filter/filter.actions';
import { FilterEntityType } from '../../../core/store/filter/models/filter';
import { RootState } from '../../../core/store/root/public-api';
import { SectionService } from '../../../core/store/sections/sections.facade';
import { WenRouteId } from '../../../frame/routing/types';
import { map, Observable } from 'rxjs';
import { FilteredSectionDataSource } from '../../../core/store/sections/datasources/filtered-section-datasource';
import { SectionProps } from '../../../core/common/util/section.model';
import { DataContext } from '../../../core/common/types/data-context';
import { AppDTO } from '@portal/wen-backend-api';

@Component({
  selector: 'wen-app-explore',
  templateUrl: './app-explore.component.html',
  styleUrls: ['./app-explore.component.scss']
})
export class AppExploreComponent implements OnInit {

  sectionsData$: Observable<{ dataSource: FilteredSectionDataSource<AppDTO>; name: string; sectionProps: SectionProps }[]> =
    this.sectionService.getAvailableSectionsBy(FilterEntityType.APP_LISTS).pipe(
      map(sections => sections.map(section => ({
        dataSource: new FilteredSectionDataSource<AppDTO>(section, DataContext.APP, this.sectionService),
        name: section.name,
        sectionProps: {
          filter: section.filter,
          filterEntityType: section.filterType,
          filterId: section.filterId
        }
      })))
    );

  constructor(
    private navigationHelper: WenNavigationHelper,
    private appNavigator: AppNavigator,
    private sectionService: SectionService,
    private store: Store<RootState>,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(removeFilter({ filterEntityType: FilterEntityType.APP_LISTS }));
  }

  navigateToUserList() {
    this.appNavigator.navigateToRoute(WenRouteId.APP_LIST);
  }

  navigateToDiscoveryList(filterData?: PassFilterData) {
    this.navigationHelper.navigateToAppDiscoverWithFilter(filterData);
  }

}
