import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EmbedDTOType } from '@portal/wen-backend-api';
import { ActionMenuItem } from '@portal/wen-components';
import { of } from 'rxjs';
import { PollViewState, PollViewStateHandler } from '../../../../../core/services/polling/poll-view-state-handler';
import { MessageBoxModel } from '../../models/message-box.models';

@Injectable()
export class MessageBoxSwitchPollViewStateAction implements ActionMenuItem {

  label: string;
  icon = 'chart';

  private pollId: string;
  private readonly switchToResultMode = 'MESSAGE_BOX_CONTEXT_MENU_LABEL_SWITCH_TO_POLL_RESULT_VIEW';
  private readonly switchToVoteMode = 'MESSAGE_BOX_CONTEXT_MENU_LABEL_SWITCH_TO_POLL_VOTE_VIEW';

  private translateService = inject(TranslateService);
  private pollViewStateHandler = inject(PollViewStateHandler);

  isVisible$ = (data: MessageBoxModel) => {
    const poll = data.embeds?.find(embed => embed.type === EmbedDTOType.POLL);
    this.pollId = poll?.pollId;
    const currentViewState = this.pollViewStateHandler.getViewStateFor(this.pollId);
    const key = currentViewState === 'voteMode' ? this.switchToResultMode : this.switchToVoteMode;
    this.label = this.translateService.instant(key);
    return of(Boolean(poll) && this.pollViewStateHandler.canChangePollViewState(this.pollId));
  };

  execute(_: MessageBoxModel) {
    const currentPollState = this.pollViewStateHandler.getViewStateFor(this.pollId);
    const viewStateToSwitch: PollViewState = currentPollState === 'voteMode' ? 'resultMode' : 'voteMode';
    this.pollViewStateHandler.setViewStateFor(this.pollId, viewStateToSwitch);
  }

}
