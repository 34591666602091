import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, catchError, filter, take, tap, throwError } from 'rxjs';
import { LightboxImageData } from '../../components/lightbox-dialog/models/lightbox.models';
import { WenSnackbarOpener } from '../snackbar/wen-snackbar-opener';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export abstract class DownloaderService {
  private ongoingDownloads = new Set<string>();
  ongoingDownloads$ = new BehaviorSubject<Set<string>>(this.ongoingDownloads);

  protected getFileNameFrom(url: string) {
    const fileName = url.split('/').pop();
    return fileName;
  }

  abstract canDownload(mediaData: LightboxImageData): boolean;

  constructor(
    private snackBar: WenSnackbarOpener,
    private translateService: TranslateService,
  ){}

  downloadFile(url: string, mimeType: string, fileType: string, fileName?: string): Observable<boolean> {
    if (this.ongoingDownloads.has(url)) {
      return throwError(() => this.alreadyInDownloadError());
    }
    this.ongoingDownloads.add(url);
    this.ongoingDownloads$.next(this.ongoingDownloads);
    return this.downloadFileImplementation(url, mimeType, fileType, fileName).pipe(
      tap((success) => {
        if(!success) {
          throw new Error();
        }
        this.onEndDownload(url);
      }),
      catchError(error => {
        this.onEndDownload(url);
        return throwError(() => error);
      })
    );
  }

  downloadFileAuthorized(url: string, fileName: string): Observable<boolean> {
    if (this.ongoingDownloads.has(url)) {
      return throwError(() => this.alreadyInDownloadError());
    }
    this.ongoingDownloads.add(url);
    this.ongoingDownloads$.next(this.ongoingDownloads);
    this.downloadFileAuthorizedImplementation(url, fileName).pipe(
      tap((success) => {
        if(!success) {
          throw new Error();
        }
        this.onEndDownload(url);
      }),
      catchError(error => {
        this.onEndDownload(url);
        return throwError(() => error);
      }),
      take(1),
      filter(downloadResult => downloadResult),
      catchError(() => {
        this.downloadFail();
        return EMPTY;
      })
    ).subscribe(() => this.downloadSuccess());
  }

  downloadInBackground(url: string, mimeType: string, fileType: string, fileName?: string) {
    this.downloadFile(
      url,
      mimeType,
      fileType,
      fileName
    ).pipe(
      take(1),
      filter(downloadResult => downloadResult),
      catchError(() => {
        this.downloadFail();
        return EMPTY;
      })
    ).subscribe(() => {
      this.downloadSuccess();
    });
  }

  private downloadSuccess(): void {
    this.showNotification('success', 'FILE_DOWNLOAD_SUCCESS');
  }

  private downloadFail(): void {
    this.showNotification('error_robot', 'FILE_DOWNLOAD_FAIL');
  }

  private showNotification(icon: string, textKey: string): void {
    return this.snackBar.openNotificationSnackbar({
      notificationIcon: icon,
      notificationText: this.translateService.instant(textKey),
    });
  }

  private onEndDownload(url: string) {
    this.ongoingDownloads.delete(url);
    this.ongoingDownloads$.next(this.ongoingDownloads);
  }

  private alreadyInDownloadError(): Error{
    return new Error('ALREADY IN DOWNLOAD');
  }

  protected abstract downloadFileImplementation(url: string, mimeType: string, fileType: string, fileName: string): Observable<boolean>;

  protected abstract downloadFileAuthorizedImplementation(url: string, fileName: string): Observable<boolean>;
}
