
import { Injectable } from '@angular/core';
import { EmbeddedMedia } from '../../../../../../core/store/channel/channel.state';
import { MessageBoxModel } from '../../../../../../shared/components/message-box/models/message-box.models';
import { MessageBoxCustomizations } from '../../../../../../shared/components/message-box/providers/message-box-embed-customizations';
import { isMediaPlayable } from '../../../../../../shared/util/embed-media-util';
import { MessageContentBoundaryConfig } from '@portal/wen-components';

@Injectable()
export class ChannelMessageCustomizations extends MessageBoxCustomizations {

  handleMediaEmbeds(mediaEmbeds: EmbeddedMedia[], message: MessageBoxModel): EmbeddedMedia[] {
    const notPlayableEmbeds = mediaEmbeds.filter(mediaEmbed => !isMediaPlayable(mediaEmbed) && !mediaEmbed.markedForRemove);
    if (notPlayableEmbeds.length > 0 && message.currentUserUpdater) {
      return notPlayableEmbeds;
    }
    return mediaEmbeds.filter(embed => isMediaPlayable(embed));
  }

  canOpenContextMenu(message?: any): boolean {
    return this.handleMessageValidation(message);
  }

  canSwipe(message?: any): boolean {
    return false;
  }

  getClassOverrides(message?: any) {
    return {
      'wen-channel-message-box': true,
    };
  }

  getLayoutConfig(message?: any) {
    return {
      inlineContent: false,
      bubbleTail: false,
      textBelowEmbeds: false,
      footerInformation: false
    };
  }

  getContentBoundaryConfig(message?: any): MessageContentBoundaryConfig {
    return {
      paddingCorrection: 16,
    };
  }
}
