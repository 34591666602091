import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EmbedDTOType, GeoAutocompletePayload, GeoAutocompleteResponse, GeoFeatureEntityType, SocketIoService } from '@portal/wen-backend-api';
import { DelayedOperationScheduler } from '@portal/wen-components';
import { BehaviorSubject, combineLatest, debounceTime, filter, map, Observable, of, shareReplay, startWith, Subject, switchMap, tap } from 'rxjs';
import { DeviceLocationData, LocationService } from '../../../core/services/location/location.service';
import { EmbeddedLocation } from '../../../core/store/channel/channel.state';
import { LocationFormatter } from '../../pipes/location-formatter.service';
import { Store } from '@ngrx/store';
import { selectUserProfile } from '../../../core/store/user/user.selectors';
import { UserProfile } from '../../../core/store/user/models/UserProfile';
import { FeatureEnablementService } from '../../../core/services/configuration/feature-enablement';

export type LocationSelectorModel = EmbeddedLocation & { customPlaceholder?: string };
export type LocationCustomEndpoint = {
  acknowledgement$: (arg: GeoAutocompletePayload) => Observable<GeoAutocompleteResponse>;
};

@Component({
  selector: 'wen-location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationSelectorComponent),
      multi: true,
    }
  ]
})
export class LocationSelectorComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

  @Input() set customEndpoint(endpoint: LocationCustomEndpoint) {
    if (Boolean(endpoint)) {
      this.locationEndpoint = endpoint;
    }
  }

  private operationScheduler = new DelayedOperationScheduler();
  private locationEndpoint = this.socketIoService.geo.autocomplete;
  private onChange: (value: LocationSelectorModel) => void = (_: LocationSelectorModel) => { };
  private onTouched: () => void = () => { };

  get searchInputValue() {
    const inputEl: HTMLInputElement = this.searchInput?.nativeElement;
    return inputEl?.value || '';
  }

  private readonly inputChanges = new Subject<string>();

  modelValue: LocationSelectorModel;
  searchResult$: Observable<EmbeddedLocation[]>;
  hasResultList$: Observable<boolean>;
  showResultList$: Observable<boolean>;
  userLocations$: Observable<EmbeddedLocation[]>;
  searchHistory$: Observable<EmbeddedLocation[]>;
  userHomeLocation$: Observable<EmbeddedLocation>;
  inputPlaceholder: string;

  readonly deviceLocation = new BehaviorSubject<DeviceLocationData>(null);
  readonly locationPermission = new BehaviorSubject(false);

  constructor(
    private readonly socketIoService: SocketIoService,
    private readonly locationService: LocationService,
    private readonly locationFormatter: LocationFormatter,
    private readonly store: Store,
    private readonly featureEnablementService: FeatureEnablementService,
  ) {}

  ngOnInit(): void {
    this.searchResult$ = this.inputChanges.pipe(
      debounceTime(100),
      map(searchTerm => searchTerm?.trim()),
      switchMap((query) => {
        if (!Boolean(query)) {
          return of({ } as GeoAutocompleteResponse);
        }
        return this.locationEndpoint.acknowledgement$({ query });
      }),
      map((autocompleteResult) => {
        if (!autocompleteResult?.features?.length) {
          return [];
        }
        const geoItems: EmbeddedLocation[] = autocompleteResult.features.map((geoModel) => ({
          type: EmbedDTOType.LOCATION,
          locationData: geoModel
        }));

        if (this.modelValue?.geoEntityTypeRestriction && this.modelValue?.geoEntityTypeRestriction?.length > 0) {
          const filteredItems = geoItems.filter((item) =>
            this.modelValue.geoEntityTypeRestriction.includes(item.locationData.properties.geoEntityType)
          );
          return filteredItems;
        } else {
          return geoItems;
        }
      }),
      startWith([]),
      shareReplay(1)
    );

    this.hasResultList$ = this.searchResult$.pipe(
      map((searchResult) => Boolean(searchResult?.length)),
      shareReplay(1)
    );

    const locationHistory$ = this.socketIoService.geo.locationHistory.listMy.acknowledgement$().pipe(
      map((result => {
        if (!result?.length) {
          return [];
        }
        return result.map(entry => {
          const item: EmbeddedLocation = {
            type: EmbedDTOType.LOCATION,
            locationData: entry.geoFeature
          };
          return item;
        });
      })),
      shareReplay(1)
    );

    this.searchHistory$ = this.hasResultList$.pipe(
      switchMap(hasResultList => {
        if (hasResultList) {
          return of([]);
        } else {
          return locationHistory$;
        }
      })
    );

    this.locationService.checkLocationPermission().pipe(
      tap(hasPermission => {
        if (hasPermission && !Boolean(this.deviceLocation.getValue())) {
          this.requestDeviceLocation();
        }
      })
    ).subscribe(hasPermission => this.locationPermission.next(hasPermission));

    this.showResultList$ = combineLatest([
      this.hasResultList$,
      this.locationPermission,
      this.searchHistory$.pipe(map(history => history.length))
    ]).pipe(
      map(([hasResultList, hasPermission, hasSearchHistory]) => {
        return Boolean(hasResultList || hasPermission || hasSearchHistory);
      })
    );

    this.userLocations$ = this.deviceLocation.pipe(
      filter(data => Boolean(data)),
      map(locationDatas => {
        const geoFeatures = locationDatas.reverseGeoData?.features;
        if (!geoFeatures?.length) {
          return [];
        }
        return geoFeatures.map((locationData) => {
          const item: EmbeddedLocation = {
            type: EmbedDTOType.LOCATION,
            locationData
          };
          return item;
        });
      }),
      shareReplay(1),
      startWith([])
    );

    this.userHomeLocation$ = this.store.select(selectUserProfile).pipe(
      filter(this.featureEnablementService.featureFlagMethods.isEnableProfileExtensions),
      filter((profile) => profile.addressPublic),
      map((profile: UserProfile) => {
        const item: EmbeddedLocation = {
          type: EmbedDTOType.LOCATION,
          locationData: {
            ...profile.address,
            properties: {
              ...profile.address.properties,
              geoEntityType: GeoFeatureEntityType.USER_HOME
            }
          }
        };
        return item;
      })
    );
  }

  ngAfterViewInit(): void {
    this.operationScheduler.setReady();
    this.operationScheduler.flush();
    this.inputPlaceholder = this.modelValue?.customPlaceholder ?? 'CHANNEL_POST_LOCATION_SELECTOR_INPUT_PLACEHOLDER';
  }

  updateValue(newValue: LocationSelectorModel, emit: boolean = true) {
    this.modelValue = newValue;
    if (emit) {
      this.socketIoService.geo.locationHistory.update.emit({ geoJson: newValue.locationData });
      this.onChange(newValue);
    } else {
      const newInputValue = this.locationFormatter.format(newValue?.locationData) as string;
      this.searchInput.nativeElement.value = newInputValue;
      if (newInputValue) {
        this.searchInput.nativeElement.focus();
      }
      this.onSearchInput();
    }
  }

  onSearchInput() {
    this.inputChanges.next(this.searchInputValue);
  }

  clearInput() {
    this.searchInput.nativeElement.value = '';
    this.onSearchInput();
  }

  selectCurrentLocation() {
    const deviceLocation = this.deviceLocation.getValue();
    const { devicePosition: { coords } } = deviceLocation;

    const properties = deviceLocation.reverseGeoData?.features[0]?.properties ?? null;
    const currentLocationEmbed: EmbeddedLocation = {
      type: EmbedDTOType.LOCATION,
      locationData: {
        type: 'Feature',
        properties,
        geometry: {
          type: 'Point',
          coordinates: [coords.longitude, coords.latitude]
        }
      }
    };
    this.updateValue(currentLocationEmbed);
  }

  requestDeviceLocation() {
    this.locationService.requestLocation().subscribe((data) => {
      this.deviceLocation.next(data);
      this.locationPermission.next(Boolean(data));
    });
  }

  prefillLocation(location: EmbeddedLocation) {
    this.updateValue(location, false);
  }

  writeValue(newValue: LocationSelectorModel): void {
    this.operationScheduler.scheduleOrRun(() => {
      this.updateValue(newValue, false);
      this.onSearchInput();
    });
  }

  registerOnChange(fn: (value: any) => void): void { this.onChange = fn; }
  registerOnTouched(fn: () => void): void { this.onTouched = fn; }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      throw new Error('Disabling LocationSelectorComponent is not supported!');
    }
  }

}
