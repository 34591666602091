import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Icon } from '../../../wen-icon/types';
import { ActionComponent } from '../../../core/action/action.component';
import { WithVariantDirective } from '../../../../directives/styling/with-variant.directive';
import { ActionVariant } from '../../../core/action/types';

@Component({
  selector: 'wen-quick-action-panel',
  standalone: true,
  templateUrl: './quick-action-panel.component.html',
  imports: [ActionComponent, WithVariantDirective],
  styleUrls: ['./quick-action-panel.component.scss']
})
export class QuickActionPanelComponent {
  @Input() icon: Icon;
  @Input() variant = ActionVariant.text;

  @Output() quickActionTriggered = new EventEmitter<string>();

  onClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.quickActionTriggered.emit();
  }
}
