import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { RootState } from '../../../../../core/store/root/public-api';
import { selectUnitedKioskFlatCredential } from '../../../../../core/store/wallet/wallet.selectors';
import { WalletCredentialType } from '@portal/wen-backend-api';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { SharedModule } from '../../../../../shared/shared.module';
import { HeaderDataHelper } from '../../../../../core/services/util/header-data-helper';

@Component({
  selector: 'wen-credentials-menu',
  templateUrl: './credentials-menu.component.html',
  styleUrls: ['./credentials-menu.component.scss'],
  imports: [
    SharedModule
  ],
  standalone: true
})
export class CredentialsMenuComponent implements OnInit {
  unitedKioskCredential$ = this.store.pipe(select(selectUnitedKioskFlatCredential));

  constructor(
    private store: Store<RootState>,
    private readonly navigationHelper: WenNavigationHelper,
    private headerDataHelper: HeaderDataHelper
  ) { }

  ngOnInit(): void {
    this.headerDataHelper.initializeContextTitleWithUserName(true);
  }

  navigateToCredentialDetails(type: WalletCredentialType) {
    this.navigationHelper.navigateToCredentialDetails(type);
  }
}
