<div class="chat-skeleton-container">
  <div class="chat-messages-container">
      @for(msg of skeletonMessages; track msg.id) {
        <div class="chat-message-container skeleton" [ngClass]="{'outbound': msg.outbound}">
          <div class="chat-message-author circle"></div>
          <div class="chat-message">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      }
  </div>
  <div class="chat-bottom-controls skeleton"></div>
</div>