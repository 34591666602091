import { Component, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { selectUserProfile } from '../../../../../core/store/user/user.selectors';

@Component({
  selector: 'wen-embedded-chat',
  templateUrl: './embedded-chat.component.html',
  styleUrls: ['./embedded-chat.component.scss']
})
export class EmbeddedChatComponent {

  private readonly store = inject(Store);
  private readonly navigationHelper = inject(WenNavigationHelper);

  public readonly userProfile$ = this.store.pipe(
    select(selectUserProfile)
  );

  navigateToProfile() {
    this.navigationHelper.navigateToCrmProfileReadOnlyView();
  }
}
