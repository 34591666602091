@if (pendingJoinRequests$ | async; as pendingJoinRequests) {
  <div class="wen-pending-join-request-container">
    <div portal-subheading-2>{{ "CHANNEL_PENDING_JOIN_REQUESTS_LIST_LABEL" | translate }}</div>
    @if (pendingJoinRequests.length) {
      @for (joinRequest of pendingJoinRequests; track joinRequest) {
        <wen-user-list-item  [user]="joinRequest.user" [userNavigable]="joinRequest.user.userId">
          <div class="wen-list-item-after" wen-user-after>
            <wen-action iconSize="medium"
                        iconName="checkmark"
                        wenVariant="textSubtle"
                        class="wen-action-no-padding"
                        [altText]="'CHANNEL_ACCEPT_JOIN_REQUEST_CONFIRMATION_DIALOG_TITLE' | translate"
                        (click)="acceptRequest(joinRequest.id, joinRequest.user.displayName)"></wen-action>
            <wen-action iconSize="medium"
                        iconName="close"
                        wenVariant="textSubtle"
                        class="wen-action-no-padding"
                        [altText]="'CHANNEL_DENY_JOIN_REQUEST_CONFIRMATION_DIALOG_TITLE' | translate"
                        (click)="denyRequest(joinRequest.id, joinRequest.user.displayName)"></wen-action>
          </div>
        </wen-user-list-item>
      }
    } @else {
      <img src="/assets/wen-widget/image/empty-placeholders/pic-empty-list-wen-sitting-robot.svg" alt=""/>
    }
  </div>
}
