<div class="wen-current-user-info-container">
  @if(userProfile$ | async; as profile) {
    <div class="wen-current-user-info-footer" (click)="navigateToProfile()">
      <p class="wen-current-user-info-user-data-wrapper">
        {{ "CRM_EMBEDDED_CHAT_CURRENTLY_LOGGED_IN_USER_TEXT" | translate }}
        <span>{{ profile.displayname }}</span>
      </p>
      <wen-circle-image-view
        [imageUrl]="profile.avatarUrl"
        [imageSize]="{ height: 20 }"
        [placeholderText]="profile.displayname"
      ></wen-circle-image-view>
    </div>
  }
</div>
