@if (restrictions) {
  @if (requestOnly) {
    @if (pendingRequest$ | async) {
      <wen-interact-button (click)="withdrawJoinRequest()">
        <wen-icon wen-interact-button-icon class="wen-icon-small" iconName="lock"></wen-icon>
        {{ "CHANNEL_JOIN_REQUEST_WITHDRAW_BUTTON_LABEL" | translate }}
      </wen-interact-button>
    } @else {
      <wen-interact-button (click)="requestAccessToChannel()">
        <wen-icon wen-interact-button-icon class="wen-icon-small" iconName="lock"></wen-icon>
        {{ "CHANNEL_JOIN_REQUEST_SEND_BUTTON_LABEL" | translate }}
      </wen-interact-button>
    }
  } @else {
    <wen-interact-button (click)="subscribeToChannel()" [wen-test-id]="'channel-subscribe-button'">
      @if (restrictions.length) {
        <wen-icon wen-interact-button-icon class="wen-icon-small" iconName="lock"></wen-icon>
      }
      {{ "CHANNEL_SUBSCRIBE_BUTTON_LABEL" | translate }}
    </wen-interact-button>
  }
}


