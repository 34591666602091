import { Injectable } from '@angular/core';
import { SelectionOption } from '@portal/wen-components';
import { TranslateService } from '@ngx-translate/core';
import { Recur } from '../../../models/event-detail.models';

@Injectable()
export class EventICalSettingsProvider {

  getRecurSettingItems(): SelectionOption<Recur>[] {
    return [
      { id: Recur.DISABLED, label: 'EVENT_RECUR_SETTINGS_DISABLE_LABEL', value: Recur.DISABLED },
      { id: Recur.ENABLED, label: 'EVENT_RECUR_SETTING_ENABLED_LABEL', value: Recur.ENABLED }
    ].map(item => {
      return {
        id: item.id,
        value: item.value,
        text: this.translateService.instant(item.label)
      };
    });
  }

  constructor(
    private translateService: TranslateService,
  ) { }
}
