@if (thumbnailParams$ | async; as params) {
  <img [wenLazyload]="params" alt="" [wenLazyloadDisableScaling]="true" />
}
@if (canvasSize$ | async; as canvas) {
  <div class="wen-canvas-spacer" [ngStyle]="canvas"></div>
}
<div class="wen-embed-overlay">
  <wen-loading-indicator [variant]="mediaType$ | async" [ariaLabel]="mediaAwareAriaLabel$ | async"></wen-loading-indicator>
</div>
@if (processing) {
  <div class="wen-media-embed-status-text">{{ 'MEDIA_IS_PROCESSING' | translate}}</div>
}
<div #canvasWrapper>
  <canvas #canvas></canvas>
</div>
