import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { EmbedContextTypes, MediaUseCases, MessageEvent, RoomType, RoomUpdateEventDTO, SocketIoService, ToRoomEventType, WeLocalImageHelper } from '@portal/wen-backend-api';
import { catchError, filter, first, map, mergeMap, of, switchMap, tap } from 'rxjs';

import { withLatestFrom } from 'rxjs/internal/operators/withLatestFrom';
import { switchMapFirst } from '../../../common/operators/switch-map-first';
import { LoadingState } from '../../../common/types/store-loading-state';
import { selectorWithParam } from '../../../common/util/selector-with-param';
import { WenNavigationHelper } from '../../../services/navigation/types';
import { WenOAuthService } from '../../../services/user-management/wen-oauth.service';
import { RootState } from '../../root/public-api';
import { selectRouteParams } from '../../root/root.selectors';
import { fetchAcknowledgeTimestamps, invalidateFetchState, loadRoomDetails, navigateToRoom, removeRoom, roomCreateRequest, roomRemoved, roomUpdateRequest, setMuteForRoom, subscribeChatUpdates, updateRoomData, updateRoomMuteState, updateUserChatListLoadingState, uploadImageForRoom, upsertChatFetched, upsertChatRoom, upsertChatRooms } from '../chat.actions';
import { selectRoomById, selectUserChatListIsLoaded } from '../chat.selectors';
import { ChatRoomCreationValidator } from '../utils/chat-room-creation-validator';

export class NotLoadedRoomDetailError {
  public static readonly message = 'Room detail could not be loaded';
}

@Injectable()
export class ChatRoomEffects {

  get userId() {
    return this.oAuthService.getUserData()?.userId;
  }

  roomCreateRequest$ = createEffect(() => this.actions$.pipe(
    ofType(roomCreateRequest),
    switchMap(({ fromCreateMode, icon, roomType, targetUserIds, description, title }) => {
      return this.chatRoomCreationValidator.validateRoomData(roomType, targetUserIds).pipe(
        filter(Boolean),
        switchMap(() => {
          return this.socketIoService.chat.room.createRoom.acknowledgement$({
            roomType: roomType || RoomType.DIALOG,
            userIds: [...targetUserIds, this.userId],
            title,
            description
          });
        }),
        switchMap(({ id }) => {
          const actions: Action[] = [navigateToRoom({ roomId: id, fromCreateMode })];
          if (icon) {
            actions.push(uploadImageForRoom({ image: icon, roomId: id }));
          }
          return actions;
        })
      );
    })
  ));

  newRoomCreated$ = createEffect(() =>
    this.socketIoService.chat.room.newRoomCreated.listen.pipe(
      switchMap((response) => {
        this.socketIoService.chat.room.relevantMessages.emit({ roomId: response.id });
        return [
          upsertChatRoom({ room: response })
        ];
      })
    )
  );

  loadedRooms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(subscribeChatUpdates),
      switchMap(() => this.socketIoService.chat.room.list.listenWithReplay),
      withLatestFrom(this.store.pipe(select(selectUserChatListIsLoaded))),
      filter(([_, isLoaded]) => !isLoaded),
      switchMap(([response]) => {
        const roomIds = response.map(room => room.id);
        const action: Action = fetchAcknowledgeTimestamps({ roomIds });
        return [
          upsertChatRooms({ rooms: response }),
          updateUserChatListLoadingState({ loadingState: LoadingState.LOADED }),
          action
        ];
      }),
      catchError(() => of(updateUserChatListLoadingState({ loadingState: LoadingState.ERROR })))
    )
  );

  loadRoomDetailsIfNotLoaded$ = createEffect(() => this.actions$.pipe(
    ofType(loadRoomDetails),
    switchMapFirst(({ roomId }) => this.store.pipe(selectorWithParam(selectRoomById, roomId))),
    filter(room => room && room.loadingState !== LoadingState.LOADED),
    switchMapFirst(({ id }) => this.socketIoService.chat.room.details.acknowledgement$({ id })),
    filter(({ details, members }) => Boolean(details && members)),
    map(({ details, members }) => upsertChatFetched({ room: details, members })),
  ));


  upload$ = createEffect(() => this.actions$.pipe(
    ofType(uploadImageForRoom),
    switchMap(({ image, roomId }) => {
      return this.weLocalImageHelper.uploadImage(image, [{ type: EmbedContextTypes.CHAT, id: roomId }], MediaUseCases.PROFILE).pipe(
        tap((result) => {
          this.socketIoService.chat.room.updateRoom.emit({
            roomId,
            icon: result.thumbnailUrl
          });
        })
      );
    })
  ), { dispatch: false });

  navigateToRoom$ = createEffect(() => this.actions$.pipe(
    ofType(navigateToRoom),
    switchMap(({ roomId, fromCreateMode }) => this.store.pipe(
      selectorWithParam(selectRoomById, roomId),
      filter(room => !!room),
      first(),
      tap(() => this.navigationHelper.navigateToChatView(roomId, { replaceUrl: fromCreateMode }))
    )),
  ), { dispatch: false });

  removeRoom$ = createEffect(() => this.actions$.pipe(
    ofType(removeRoom),
    tap(({ roomId }) =>
      this.socketIoService.chat.remove.emit({ roomId })
    )
  ), { dispatch: false });

  onRemoveRoom$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeChatUpdates),
    switchMap(() => {
      return this.socketIoService.chat.remove.listen.pipe(
        switchMap(({ roomId }) => this.store.pipe(
          selectorWithParam(selectRoomById, roomId),
          first(),
          //TODO Remove sessions related to roomId
          map(() => roomRemoved({ roomId }))
        )),
      );
    })
  ));

  updateRoom$ = createEffect(() => this.actions$.pipe(
    ofType(roomUpdateRequest),
    tap(({ room }) => {
      this.socketIoService.chat.room.updateRoom.emit(room);
    })
  ), { dispatch: false });

  reLoadRoomDetailsAfterUpdate$ = createEffect(() =>
    this.socketIoService.chat.room.updateRoom.listen.pipe(
      map(({ roomId, ...changedProperties }) => updateRoomData({ roomId, changedProperties }))
    )
  );

  onChatMessageRoomUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(subscribeChatUpdates),
      switchMap(() =>
        this.socketIoService.chat.room.messages.listen.pipe(
          filter(({ payload }) =>
            payload.eventType === ToRoomEventType.UPDATED
          ),
          map((message: MessageEvent<RoomUpdateEventDTO>) => {
            const { roomId, payload } = message;
            const changedProperties = payload.changedProperties;
            return updateRoomData({
              roomId,
              changedProperties
            });
          })
        )
      )
    )
  );

  onReconnect$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeChatUpdates),
    switchMap(() => {
      return this.socketIoService.onReconnected$.pipe(
        mergeMap(() => [
          invalidateFetchState(),
          updateUserChatListLoadingState({ loadingState: LoadingState.NOT_LOADED })
        ])
      );
    })
  ));

  navigateCurrentUserAwayFromCurrentRoom$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(roomRemoved),
      switchMap(({ roomId }) => {
        return this.store.pipe(
          select(selectRouteParams),
          first(),
          filter(({ chatId }) => chatId === roomId)
        );
      }),
      tap(() => this.navigationHelper.doUserChatListBackNavigation())
    );
  }, { dispatch: false });

  muteRoom$ = createEffect(() => this.actions$.pipe(
    ofType(setMuteForRoom),
    tap(({ mute, room }) => {
      this.socketIoService.chat.room.mute.emit({ roomId: room, isMuted: mute });
    })
  ), { dispatch: false });

  roomMuteStateUpdated$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeChatUpdates),
    switchMap(() => {
      return this.socketIoService.chat.room.mute.listen.pipe(
        map(({ roomId, isMuted }) => updateRoomMuteState({ roomId, isMuted }))
      );
    })
  ));

  constructor(
    private store: Store<RootState>,
    private actions$: Actions,
    private oAuthService: WenOAuthService,
    private chatRoomCreationValidator: ChatRoomCreationValidator,
    private socketIoService: SocketIoService,
    private navigationHelper: WenNavigationHelper,
    private weLocalImageHelper: WeLocalImageHelper
  ) { }

}
