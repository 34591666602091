import { DataSource } from '@angular/cdk/collections';
import { SectionEntity } from '../sections.state';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DataContext } from '../../../common/types/data-context';
import { SectionService } from '../sections.facade';
import { takeUntil } from 'rxjs/operators';

export class FilteredSectionDataSource<T = unknown> implements DataSource<T> {

  private sectionItems = new BehaviorSubject<T[]>([]);
  private onDisconnect$ = new Subject<void>();

  constructor(
    private section: SectionEntity,
    private dataContext: DataContext,
    private sectionService: SectionService,
  ) {
    this.sectionService.loadSectionContent(this.section.id, this.section.filter, this.dataContext).pipe(
      takeUntil(this.onDisconnect$)
    ).subscribe(
      sectionItems => this.sectionItems.next(sectionItems)
    );
  }

  connect(): Observable<T[]> {
    return this.sectionItems.asObservable();
  }

  disconnect(): void {
    this.onDisconnect$.next();
    this.onDisconnect$.complete();
    this.sectionItems.complete();
  }

}
