import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SocketIoService } from '@portal/wen-backend-api';
import { Observable, first, map, shareReplay, switchMap } from 'rxjs';
import { RootState } from '../../../../../../core/store/root/public-api';
import { FilterSelectorOpener } from '../../../../../../shared/components/filter-view/components/filter-selector/filter-selector-opener';
import { Filterable } from '../../../../../../shared/components/filter-view/models/filter-item';
import { createFilterFromRegion } from '../../../../../../shared/components/filter-view/models/filter-helper';
import { FilterSelectorData } from '../../../../../../shared/components/filter-view/components/filter-selector/filter-selector.component';

@Injectable()
export class AppRegionFilterDataSource {

  private filterPossibleValues$: Observable<Filterable[]>;

  get fallbackAllRegionFilter(): Filterable {
    return { id: null, name: this.translateService.instant('FILTER_REGIONS_SELECTOR') };
  }

  constructor(
    readonly socketIoService: SocketIoService,
    readonly store: Store<RootState>,
    private translateService: TranslateService,
    private filterSelectorOpener: FilterSelectorOpener,
  ) {
    this.filterPossibleValues$ = this.loadRegions().pipe(
      shareReplay(1)
    );
  }

  private loadRegions() {
    return this.socketIoService.app.getRegions.acknowledgement$().pipe(
      first(),
      map(regions => regions.map(region => createFilterFromRegion(region)))
    );
  }

  openFilterSelector(activeRegion: Filterable) {
    return this.filterPossibleValues$.pipe(
      switchMap((possibleValues) => {
        const dialogData: FilterSelectorData = {
          visibleFilters: possibleValues,
          activeFilterIds: [activeRegion?.id],
          isMultiselect: true,
          multiSelectorLabelTranslationKey: 'REGION_SELECTOR_LABEL',
          headerTitle: 'REGION_SELECTOR_LABEL'
        };
        return this.filterSelectorOpener.openFilterSelector(dialogData);
      })
    );
  }
}
