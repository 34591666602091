import { FilterViewType } from '../components/filter-selector/custom-filter-view';

export enum FilterCategory {
  QUICKSELECTION = 'quickselection',
  OTHER = 'other'
}

export type Categorized<T> = {
  [k in keyof T]: T[k];
} & { category?: FilterCategory };


export interface CanHaveCustomView {
  viewType?: FilterViewType.DATE_RANGE;
}

export interface CanHaveCustomLabel<P = any> {
  customLabel?: ((args: P[]) => string);
}

export interface Filterable<T = any, P = any> extends CanHaveCustomView, CanHaveCustomLabel<P> {
  id: string;
  name?: string;
  /**
   * @deprecated All filter information should be moved to filter
   */
  data?: any;
  filter?: T;
}

export const hasCategory = (filter: Filterable): filter is Categorized<Filterable> => {
  return (filter as any).category !== undefined;
};

export const hasCustomLabel = (filterable: Filterable): filterable is Filterable & CanHaveCustomLabel => {
  return (filterable as any)?.customLabel !== undefined;
};
