import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ChannelMessageDTO, PagingReplayDirection, SocketIoService } from '@portal/wen-backend-api';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { combineLatest, merge, Observable, of, Subject } from 'rxjs';
import { delay, distinctUntilChanged, exhaustMap, filter, first, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UserData } from '../../../../../../core/store/auth/models/UserData';
import { clearChannelMessagesHistory, fetchHistoricalMessagesForCurrentChannel, upsertChannelMessagesHistory } from '../../../../../../core/store/channel/channel.actions';
import { selectCurrentChannel, selectCurrentChannelMessagesHistoryFlags } from '../../../../../../core/store/channel/channel.selectors';
import { ChannelMessageEntity } from '../../../../../../core/store/channel/channel.state';
import { RootState } from '../../../../../../core/store/root/public-api';
import { resetChannelMessageOriginTransferData } from '../../../../../../core/store/ui/ui.actions';
import { selectChannelMessageOriginTransferData } from '../../../../../../core/store/ui/ui.selectors';
import { PageRequestEvent, WeFeedDataset, WeFeedItemType, WeFeedMessageItem } from '../../../../../../shared/components/feed/components/we-feed/we-feed-model';
import { FeedDatasource } from '../../../../../../shared/components/feed/providers/feed-datasource';
import { mapReplayDirectionToStateFlags } from '../../../../../../shared/components/paginated-scrollview/replay-direction-mapper';
import { MessagesDatasource } from '../../../../../../shared/services/messages.datasource';
import { ChannelConfigurationProvider, ChannelFeedMessageData } from '../../../../common/providers/channel-configuration';
import { ChannelViewDataSource } from './channel-view-datasource';

@Injectable()
export class ChannelFeedDatasource extends FeedDatasource implements OnDestroy {

  private onDestroy$ = new Subject<void>();

  private readonly channelMessagesHistory$ = merge(
    this.socketIoService.channel.messagesReplay.listen,
    this.socketIoService.channel.peek.listen
  );

  disableEmojiReaction$ = this.channelConfigurationProvider.getDisableEmojiReactionForCurrentChannel();
  channelData$ = this.channelConfigurationProvider.getCurrentChannelData().pipe(smartDistinctUntilChanged());
  isPersonalChannel$ = this.channelData$.pipe(map(data => data.isPersonalCurrentChannel));
  disableFooterFunctions$ = this.isPersonalChannel$;

  constructor(
    protected store: Store<RootState>,
    private socketIoService: SocketIoService,
    private messageDatasource: MessagesDatasource<ChannelMessageEntity>,
    private channelConfigurationProvider: ChannelConfigurationProvider,
    private channelViewDataSource: ChannelViewDataSource,
  ) {
    super(store);
  }

  bindToSource() {
    const savedContextData$ = this.channelViewDataSource.currentChannelId$.pipe(
      switchMap((channelId) => {
        return this.store.pipe(
          select(selectChannelMessageOriginTransferData),
          smartDistinctUntilChanged(),
          filter((transferData) => {
            return !transferData || transferData.channelId === channelId;
          }),
          first(),
          tap(() => this.store.dispatch(resetChannelMessageOriginTransferData())),
          shareReplay(1),
        );
      })
    );
    const messages$ = this.messageDatasource.messages$;

    const commentsEnabled$ = this.channelConfigurationProvider.getCommentsEnabledForCurrentChannel();
    const textToSpeechEnabled$ = this.channelConfigurationProvider.getIsTextToSpeechCurrentChannel();
    const lastUnreadMessageId$ = this.messageDatasource.hasUnreadMessages$.pipe(
      switchMap((hasUnreadMessages) => {
        return hasUnreadMessages ? this.messageDatasource.lastUnreadMessage$.pipe(
          first(),
          map(message => message?.id)
        ) : of(null);
      }),
      shareReplay(1),
      distinctUntilChanged()
    );
    const featureEnablements$ = combineLatest([
      this.disableFooterFunctions$,
      this.disableEmojiReaction$,
      commentsEnabled$,
      textToSpeechEnabled$,
    ]).pipe(
      smartDistinctUntilChanged()
    );
    const historyFlags = this.store.pipe(
      select(selectCurrentChannelMessagesHistoryFlags),
      smartDistinctUntilChanged()
    );
    return combineLatest([
      messages$,
      lastUnreadMessageId$,
      this.channelData$,
      historyFlags,
      this.currentUser$,
      savedContextData$,
      featureEnablements$,
    ]).pipe(
      map(([
        messages,
        newMessageLineItemId,
        channelData,
        flags,
        currentUser,
        messageOriginData,
        featureEnablements
      ]) => {
        const [disableFooterFunctions, disableEmojiReaction, commentsEnabled, textToSpeechEnabled] = featureEnablements;
        if (!messages?.length) {
          return {
            items: []
          };
        }
        const items = messages.map((message) => {
          const feedItem = this.convertToFeedItem(
            message,
            channelData,
            disableFooterFunctions,
            disableEmojiReaction,
            commentsEnabled,
            textToSpeechEnabled,
            currentUser
          );
          return feedItem;
        });
        const dataSet: WeFeedDataset = {
          newMessageLineItemId,
          scrollReferenceItemId: messageOriginData?.messageId,
          items,
          hasMoreOlder: flags?.hasMoreOlder,
        };
        return dataSet;
      }),
      takeUntil(this.onDestroy$)
    );
  }

  loadNextPage(event: PageRequestEvent): Observable<any> {
    return combineLatest([
      this.messageDatasource.messages$,
      this.store.pipe(select(selectCurrentChannelMessagesHistoryFlags)),
    ]).pipe(
      first(),
      filter(([_, historyFlags]) => {
        const { direction } = event;
        if (direction === PagingReplayDirection.Up && historyFlags.hasMoreOlder === false) {
          return false;
        }
        if (direction === PagingReplayDirection.Down && !historyFlags.hasMoreNewer) {
          return false;
        }
        return true;
      }),
      filter(([currentMessages]) => {
        return currentMessages?.length > 0;
      }),
      exhaustMap(([currentMessages]) => {
        const { direction } = event;
        let firstMessage: ChannelMessageDTO;
        if (direction === PagingReplayDirection.Up) {
          firstMessage = currentMessages[0];
        } else {
          firstMessage = currentMessages[currentMessages.length - 1];
        }

        this.store.dispatch(fetchHistoricalMessagesForCurrentChannel({
          timestamp: firstMessage.timestamp,
          direction
        }));
        return this.waitForNextPage().pipe(
          map((response) => ({ response })),
          delay(200),
          first()
        );
      }),
      map(({ response }) => {
        return {
          hasResult: Boolean(response?.hasResult)
        };
      })
    );
  }

  loadFirstPage() {
    return this.store.pipe(
      select(selectCurrentChannel),
      first(),
      switchMap((currentChannel) => {
        const { history } = currentChannel;
        if (!history || history.hasMoreNewer) {
          this.store.dispatch(clearChannelMessagesHistory({ channelId: currentChannel.id }));
          this.store.dispatch(fetchHistoricalMessagesForCurrentChannel({
            timestamp: null,
            direction: PagingReplayDirection.Down
          }));
          return this.waitForNextPage();
        }
        return of({
          hasResult: false
        });
      }),
      first()
    );
  }

  private waitForNextPage() {
    return this.channelMessagesHistory$.pipe(
      first(),
      map((response) => {
        const flags = mapReplayDirectionToStateFlags(response.direction, response.more);

        this.store.dispatch(upsertChannelMessagesHistory({
          channelId: response.channelId,
          flags,
          messages: response.messages
        }));
        return {
          hasResult: response.messages?.length > 0
        };
      })
    );
  }

  private convertToFeedItem(
    message: ChannelMessageDTO,
    channelData: ChannelFeedMessageData,
    disableFooterFunctions: boolean,
    disableEmojiReaction: boolean,
    commentsEnabled: boolean,
    textToSpeechEnabled: boolean,
    currentUser: UserData
  ) {
    const {
      id,
      authorId,
      userId,
      content,
      scheduled,
      embeds,
      timestamp,
      formattedUpdateUserId,
      type,
      updateTimestamp,
    } = message;
    const lastEditorId = formattedUpdateUserId ?? authorId;
    const feedItem: WeFeedMessageItem = {
      key: message.id,
      type: WeFeedItemType.MESSAGEBOX,
      value: {
        messageId: id,
        referenceId: id,
        authorId,
        currentUserUpdater: currentUser.userId === lastEditorId,
        sender: userId,
        senderVisible: true,
        content,
        scheduled,
        embeds,
        timestamp,
        shareVisible: true,
        commentsVisible: false,
        commentsEnabled,
        highlighted: false,
        wide: false,
        disableEmojiReaction: disableEmojiReaction || channelData.isPersonalCurrentChannel,
        hideEmojiReactionPlaceholder: false,
        status: null,
        modificationState: type,
        updateTimestamp,
        textToSpeechEnabled,
        challengeEnabled: true,
        disableNavigationToSender: channelData.isPersonalCurrentChannel,
        disableFooterFunctions,
        isDraft: message.draft,
        avatarIcon: channelData.isPersonalCurrentChannel ? channelData.channelIcon : null
      },
    };
    return feedItem;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
