import { Injectable } from '@angular/core';
import { PollModel, SocketIoService } from '@portal/wen-backend-api';
import { merge, Observable, ReplaySubject } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { WenOAuthService } from '../user-management/wen-oauth.service';

export type PollViewState = 'voteMode' | 'resultMode';

@Injectable()
export class PollViewStateHandler {
  public viewStateMap: Map<string, PollViewState> = new Map();
  public pollCache: Map<string, PollModel> = new Map();
  private pollUpdates: ReplaySubject<{ pollId: string; viewState: PollViewState }> = new ReplaySubject();
  private pollUpdates$ = this.pollUpdates.asObservable().pipe(shareReplay());

  constructor(
    private oAuthService: WenOAuthService,
    socketIoService: SocketIoService,
  ) {
    merge(
      socketIoService.messageEmbed.poll.chooseOption.listen,
      socketIoService.messageEmbed.poll.update.listen
    ).pipe(
      filter(partialPoll => Boolean(partialPoll.id))
    ).subscribe(partialPoll => {
      const completePoll = this.upsertPoll(partialPoll);
      this.setViewStateFor(completePoll.id, this.calculatePollViewState(completePoll));
    });
  }

  add(pollModel: PollModel): void {
    const viewState = this.calculatePollViewState(pollModel);
    this.pollCache.set(pollModel.id, pollModel);
    this.setViewStateFor(pollModel.id, viewState);
  }

  getUpdatesFor(id: string): Observable<PollViewState> {
    return this.pollUpdates$.pipe(
      filter(poll => poll.pollId === id),
      map(poll => poll.viewState)
    );
  }

  getViewStateFor(id: string): PollViewState | null {
    return this.viewStateMap.get(id) ?? null;
  }

  setViewStateFor(id: string, viewState: PollViewState): void {
    this.viewStateMap.set(id, viewState);
    this.pollUpdates.next({ pollId: id, viewState });
  }

  canChangePollViewState(pollId: string): boolean {
    const currentPollViewState = this.getViewStateFor(pollId);
    const poll = this.pollCache.get(pollId);
    if (currentPollViewState === 'resultMode') {
      return !poll.userAlreadyChose;
    } else if (currentPollViewState === 'voteMode') {
      return poll.createdBy?.id === this.oAuthService.userId;
    }
    return false;
  }

  private calculatePollViewState(pollUpdate: PollModel): PollViewState {
    if (pollUpdate.userAlreadyChose) {
      return 'resultMode';
    }
    if (pollUpdate.createdBy?.id === this.oAuthService.getUserData().userId) {
      return 'resultMode';
    }
    return 'voteMode';
  }

  // TODO Remove this when the poll.update endpoint provides the full poll
  upsertPoll(partialPoll: Partial<PollModel>): PollModel {
    const cachedPoll = this.pollCache.get(partialPoll.id) ?? {} as PollModel;
    let completeOptions = cachedPoll.options ?? [];
    if (partialPoll.options?.length) {
      completeOptions = completeOptions.map(option => {
        const partialOption = (partialPoll.options ?? []).find(partialOpt => option.id === partialOpt.id);
        return Boolean(partialOption) ? { ...option, ...partialOption } : option;
      });
    }
    const completePoll: PollModel = {
      ...cachedPoll,
      ...partialPoll,
      options: completeOptions
    };
    this.pollCache.set(completePoll.id, completePoll);
    return completePoll;
  }

}
