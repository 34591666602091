<div class="wen-channel-message-detail-content">
  @if (errorState) {
    <div class="wen-channel-message-detail-errors-wrapper">
      <wen-message-error [errorType]="errorState.error" [channelData]="errorState.channelData"></wen-message-error>
    </div>
  } @else {
    @if (currentChannel$ | async; as channel) {
      <wen-channel-messages-view
        [flowDirection]="'down'"
        [disableContextMenu]="true"
        [disableScrollToBottomButton]="true">
        @if (navigatedFromDifferentContext) {
          <wen-navigate-to-channel-button
            wen-messages-view-before-content
            class="wen-channel-message-view-button"
            [channel]="channel">
          </wen-navigate-to-channel-button>
        }
      </wen-channel-messages-view>
    }
  }
</div>
@if (showCommentButton$ | async) {
  <wen-comment-message-send-field [parentId]="messageId$ | async" [wen-test-id]="'comment-send-area'"></wen-comment-message-send-field>
}

