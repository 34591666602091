import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DateTimeProvider } from 'angular-oauth2-oidc';
import { Observable, retry, tap, throwError, timer } from 'rxjs';
import { DateUtil } from '../../core/common/date/date-util';
import { OauthDateTimeProvider } from '../../core/services/user-management/oauth/oauth-datettime-provider';
import { WenOAuthService } from '../../core/services/user-management/wen-oauth.service';
import { AUTH_CLIENT_CONFIG, AuthClientConfig } from './tokens';

const retryableStatuses = [
  504 // Gateway timeout
];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    @Inject(AUTH_CLIENT_CONFIG) private authConfig: AuthClientConfig,
    protected oauthService: WenOAuthService,
    @Inject(DateTimeProvider) protected dateTimeProvider: OauthDateTimeProvider,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes(this.authConfig.issuer)) {
      return next.handle(req);
    }
    return next.handle(req).pipe(
      retry({
        count: 3,
        resetOnSuccess: false,
        delay: (error, retryCount) => {
          if (retryableStatuses.includes(error.status)) {
            return timer(retryCount * 200);
          }
          return throwError(() => error);
        }
      }),
      tap((response: HttpResponse<any>) => {
        const serverTimeStr = response?.headers?.get('Date');
        if (serverTimeStr) {
          const date = DateUtil.fromRfc7231String(serverTimeStr);
          this.dateTimeProvider.setServerTime(date.toJSDate());
        }
      })
    );
  }

}
