import { Injectable } from '@angular/core';
import { concat, first, Observable, ReplaySubject, switchMap } from 'rxjs';
import { generateId } from '../../../../common/generate-id';
import { ChannelMessageDTO } from '../../../../data-types/channel/channelMessage';
import { EmbedContextTypes } from '../../../../events/events';
import { EmbedCreator } from '../../core/embeds/embed-creator';
import { ChannelMessageComposerItem } from './channel-message-composer-item';
import { ChannelMessageComposerData, ChannelMessageComposerResult, ChannelMessageComposerExtras } from './types';

@Injectable()
export class ChannelMessageComposer {

  constructor(
    private channelMessageItem: ChannelMessageComposerItem,
    private embedCreator: EmbedCreator,
  ) {
  }

  sendMessage(data: ChannelMessageComposerData, extras?: ChannelMessageComposerExtras): ChannelMessageComposerResult {
    return data?.draft ? this.sendWithNewApproach(data, extras) : this.sendWithOldApproach(data, extras);
  }

  private sendWithOldApproach(data: ChannelMessageComposerData, extras?: ChannelMessageComposerExtras) {
    const isEdit = Boolean(data.id);
    data.id = data.id || generateId();
    const {
      immediate, persistEmbedsResult$
    } = this.embedCreator.prepareEmbeds(
      extras?.embeds,
      [
        {
          type: EmbedContextTypes.CHANNEL_MESSAGE,
          id: data.id,
          parent: { type: EmbedContextTypes.CHANNEL, id: data.channelId }
        },
      ]
      );

    const result$ = new ReplaySubject<ChannelMessageDTO>(1);
    const message$ = persistEmbedsResult$.pipe(
      switchMap((persistedEmbeds) => {
        return this.channelMessageItem.upsertMessage(data, isEdit, persistedEmbeds);
      })
    );
    message$.pipe(
      first()
    ).subscribe({
      next: (message) => {
        result$.next(message);
        result$.complete();
      },
      error: (error) => {
        result$.error(error);
      }
    });
    return {
      immediate,
      result$
    };
  }

  private sendWithNewApproach(data: ChannelMessageComposerData, extras?: ChannelMessageComposerExtras): ChannelMessageComposerResult {
    const uploadId = generateId();
    const updatedMediaEmbeds = extras.embeds.mediaEmbeds.map((mediaEmbed) => {
      return {
        ...mediaEmbed,
        uploadId,
      };
    });
    const updatedExtras: ChannelMessageComposerExtras = {
      embeds: {
        ...extras.embeds,
        mediaEmbeds: updatedMediaEmbeds
      }
    };

    const isEdit = Boolean(data.id);
    data.id = data.id || generateId();

    const { persistEmbedsResult$, anchorEmbeds, immediate } = this.embedCreator.prepareAsAnchors(
      updatedExtras.embeds,
      [
        {
          type: EmbedContextTypes.CHANNEL_MESSAGE,
          id: data.id,
          parent: { type: EmbedContextTypes.CHANNEL, id: data.channelId }
        },
      ]
    );

    const message$: Observable<ChannelMessageDTO> = this.channelMessageItem.upsertMessage(data, isEdit, anchorEmbeds);
    const result$ = new ReplaySubject<ChannelMessageDTO>(1);

    concat(message$, persistEmbedsResult$).subscribe({
      next: (result: ChannelMessageDTO) => {
        result$.next(result);
        result$.complete();
      }
    });

    return {
      result$,
      immediate
    };
  }

}
