import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DeletedIndicatorComponent } from '@portal/wen-components';
import { ChatMessageErrorModule } from '../../../chat-message-error/chat-message-error.module';
import { ChatMessageContentComponent } from './chat-message-content.component';


@NgModule({
  imports: [ChatMessageErrorModule, CommonModule, TranslateModule, DeletedIndicatorComponent],
  exports: [ChatMessageContentComponent],
  declarations: [ChatMessageContentComponent],
  providers: [],
})
export class ChatMessageContentModule { }
