import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EmbeddedDocumentDTO, ReactionContext } from '@portal/wen-backend-api';
import { OverlayManager, ReactionSelectorComponent, ReactionSelectorData } from '@portal/wen-components';
import { RootState } from '../../../../core/store/root/public-api';
import { navigateToUserProfile } from '../../../../core/store/user/user.actions';
import { MessageBoxMenuComponent, MessageBoxMenuData } from '../../../../shared/components/message-box-menu/message-box-menu.component';
import { chatMessageBoxContextMenuId } from '../../../../shared/components/message-box/actions/message-box.action-menu';
import { BaseMessageModel } from '../../../../shared/components/message-box/models/base-message-box.model';
import { MessageBoxActionsHandler } from '../../../../shared/components/message-box/providers/message-box-tokens';
import { MessageBoxModel } from '../../../../shared/components/message-box/models/message-box.models';
import { QuoteHandler } from '../../../../core/services/util/quote-handler';

@Injectable()
export class ChatMessageBoxActionsHandler extends MessageBoxActionsHandler {

  protected store = inject(Store<RootState>);
  private overlayService = inject(OverlayManager);
  private quoteHandler = inject(QuoteHandler);

  handleCommentsClicked(messageId: string): void {
    throw new Error('Method not implemented.');
  }

  handleDocumentClicked(messageId: string, documentModel: EmbeddedDocumentDTO): void {
    throw new Error('Method not implemented.');
  }

  handleSenderClicked(messageId: string, authorId: string): void {
    this.store.dispatch(navigateToUserProfile({ userId: authorId }));
  }

  handleForwardClicked(messageId: string): void {
    throw new Error('Method not implemented.');
  }

  handleShareClicked(messageId: string): void {
    throw new Error('Method not implemented.');
  }

  handleChallengeClicked(messageId: string): void {
    throw new Error('Method not implemented.');
  }

  handleReactionClicked(message: BaseMessageModel): void {
    const reactionSelectorData: ReactionSelectorData = {
      reactionParentId: message.referenceId,
      reactionParentType: ReactionContext.CHAT_MESSAGE
    };
    this.overlayService.menu.open(ReactionSelectorComponent, { reactionSelectorData });
  }

  handleMessageContentClicked(message: MessageBoxModel): void {
    const chatReactionSelectorData: ReactionSelectorData = {
      reactionParentId: message.referenceId,
      reactionParentType: ReactionContext.CHAT_MESSAGE
    };
    const reactionSelectorData = !message.scheduled ? chatReactionSelectorData : null;
    const menuData: MessageBoxMenuData = {
      reactionSelectorData,
      menuId: chatMessageBoxContextMenuId,
      message
    };
    this.overlayService.menu.open(MessageBoxMenuComponent, menuData);
  }

  handleSwipe(message: MessageBoxModel): void {
    this.quoteHandler.initiateQuote({
      id: message.referenceId,
      author: message.sender,
      content: message.content,
      embeds: message.embeds
    });
  }
}
