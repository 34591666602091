import { AsyncPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { first, Observable } from 'rxjs';
import { ChannelEntity } from '../../../../../core/store/channel/channel.state';
import { HeaderModule } from '../../../../../frame/components/header/header.module';
import { ActiveItemImageViewModule } from '../../../../../shared/components/active-item-image-view/active-item-image-view.module';
import { FeedDatasource } from '../../../../../shared/components/feed/providers/feed-datasource';
import { ChannelViewDesktopHeaderComponent } from '../../../channel-view/components/channel-view-desktop/channel-view-desktop-header/channel-view-desktop-header.component';
import { ChannelMessageDetailModule } from '../../channel-message-detail.module';
import { ChannelMessageDetailDatasource } from '../../providers/channel-message-detail.datasource';

@Component({
  selector: 'wen-channel-message-detail-desktop',
  standalone: true,
  imports: [
    AsyncPipe,
    ChannelMessageDetailModule,
    ChannelViewDesktopHeaderComponent,
    HeaderModule,
    ActiveItemImageViewModule,
    TranslateModule
  ],
  providers: [
    { provide: FeedDatasource, useClass: ChannelMessageDetailDatasource }
  ],
  templateUrl: './channel-message-detail-desktop.component.html',
  styleUrl: './channel-message-detail-desktop.component.scss'
})
export class ChannelMessageDetailDesktopComponent implements OnInit {

  channel$: Observable<ChannelEntity>;

  constructor(@Inject(FeedDatasource) private datasource: ChannelMessageDetailDatasource,) {
  }

  ngOnInit() {
    this.datasource.initialize();
    this.channel$ = this.datasource.channel$.pipe(
      first()
    );
  }

}
