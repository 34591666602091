@for (option of pollOptionsWithCalculations; track option) {
  <div class="wen-poll-option">
    <div class="wen-option-body">
      <div class="wen-option-prefix">
        <span>
          {{ option.avgVotePercent }}<span>&percnt;</span></span
          >
        </div>
        <div class="wen-option">
          <div class="wen-option-text">{{ option.text }}</div>
        </div>
      </div>
      <div class="wen-option-footer">
        <div class="wen-option-prefix">
          @if (option.chosenByUser) {
            <mat-checkbox
              wen-primary-color
              [disableRipple]="'true'"
              [checked]="true"
              [className]="(isMultiSelect ? 'multi-check' : 'single-check') + ' cursor-initial wen-option-prefix-checkbox'"
            ></mat-checkbox>
          }
        </div>
        @if (option.avgVotePercent > 0) {
          <wen-status-bar
            [value]="option.avgVotePercent"
          ></wen-status-bar>
        }
      </div>
    </div>
  }
