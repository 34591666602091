<form wenFormStore class="wen-form" [formGroup]="emailChangeFormGroup"
  [wen-test-id]="'email-change-form'">
  <div class="wen-form-group">
    <div class="wen-form-field-wrapper">
      <mat-form-field>
        <mat-label>{{ 'PROFILE_FORM_EMAIL_LABEL' | translate }}</mat-label>
        <portal-field [formControlName]="'email'" [autocomplete]="'off'" wenIosKeyboardStickyTarget [wen-test-id]="'email-change-form-email'"></portal-field>
        @if (emailValue) {
          <wen-action matSuffix wenVariant="text" class="wen-action-no-padding" iconName="close" iconSize="medium" [altText]="'REMOVE_TEXT_LABEL' | translate" (click)="clearInput()"></wen-action>
        }
        <mat-error portal-caption>{{ "PROFILE_FORM_EMAIL_ERROR" | translate }}</mat-error>
      </mat-form-field>
    </div>
  </div>
</form>