import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ErrorHandler, SocketErrors, SocketSystemError } from '@portal/wen-backend-api';
import { logout } from '../../store/auth/auth.actions';
import { Tracer } from '@portal/wen-tracer';

@Injectable()
export class SocketErrorHandler implements ErrorHandler {

  constructor(
    private readonly store: Store,
    private readonly tracer: Tracer,
  ) { }

  onError(error: SocketErrors): void {
    this.tracer.captureException(error);
    if (error instanceof SocketSystemError) {
      this.store.dispatch(logout());
    }
  }

}
