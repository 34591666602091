import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, select, Store } from '@ngrx/store';
import { generateId, ListEventRequestParameters, SocketIoService } from '@portal/wen-backend-api';
import { catchError, filter, first, map, Observable, of, switchMap, withLatestFrom } from 'rxjs';
import { WenRouteId } from '../../../../frame/routing/types';
import { DateUtil } from '../../../common/date/date-util';
import { LoadingState } from '../../../common/types/store-loading-state';
import { RootState } from '../../root/public-api';
import { appendDiscoveryListOccurences, extractSubscription, fetchDiscoveryEvents, fetchDiscoveryEventsNextPage, onDiscoveryEventListResult, updateDiscoveryEventsLoadingState, updateDiscoveryEventsPaging, updateDiscoveryListOccurences } from '../event.actions';
import { selectDiscoveryEventsIsLoaded, selectDiscoveryListPaging } from '../event.selectors';
import { smartDistinctUntilChanged } from '@portal/wen-components';

export const setNotLoadedStateForDiscoveryEventListOnFilterChange = (
  currentFilters$: Observable<ListEventRequestParameters>
) => {
  return createEffect(() =>
    currentFilters$.pipe(
      map(() => updateDiscoveryEventsLoadingState({ loadingState: LoadingState.NOT_LOADED }))
    )
  );
};

export const resetDiscoveryEventListPagingOnFilterChange = (
  currentFilters$: Observable<ListEventRequestParameters>
) => {
  return createEffect(() =>
    currentFilters$.pipe(
      map(() => updateDiscoveryEventsPaging({ discoverEventsPaging: null }))
    )
  );
};

export const createEventDiscoveryListNavigationEffect = (
  actions$: Actions,
  carriedOverFilter$: Observable<ListEventRequestParameters>,
  socketIoService: Pick<SocketIoService, 'events'>,
  store: Store<RootState>
) => {
  return createEffect(() =>
    actions$.pipe(
      ofType(fetchDiscoveryEvents),
      concatLatestFrom(() => store.pipe(
        select(selectDiscoveryEventsIsLoaded)
      )),
      filter(([_, hasLoadedContent]) => !hasLoadedContent),
      concatLatestFrom(() => carriedOverFilter$.pipe(first())),
      switchMap(([_, eventFilters]) => {
        const { filter: filterData, ...rest } = eventFilters || {};
        const requestParam = { ...filterData, ...rest };
        return socketIoService.events.listForDiscover.acknowledgement$(requestParam).pipe(
          map((eventListResult) => onDiscoveryEventListResult(eventListResult)),
        );
      })
    )
  );
};

export const createEventDiscoveryListFilterUpdateEffect = (
  currentFilters$: Observable<ListEventRequestParameters>,
  currentPage$: Observable<WenRouteId>,
  socketIoService: Pick<SocketIoService, 'events'>
) => {
  return createEffect(() => {
    return currentFilters$.pipe(
      withLatestFrom(currentPage$),
      filter(([_, currentPage]) => currentPage === WenRouteId.EVENT_DISCOVERY),
      smartDistinctUntilChanged(),
      switchMap(([eventFilters]) => {
        const { filter: filterData, ...rest } = eventFilters || {};
        const requestParam = { ...filterData, ...rest };
        return socketIoService.events.listForDiscover.acknowledgement$(requestParam).pipe(
          map(eventListResult => onDiscoveryEventListResult(eventListResult))
        );
      }),
    );
  });
};

export const createEventDiscoveryListEffect = (
  actions$: Actions,
  store: Store<RootState>
) => {
  return createEffect(() =>
    actions$.pipe(
      ofType(onDiscoveryEventListResult),
      withLatestFrom(store.pipe(select(selectDiscoveryListPaging))),
      switchMap(([discoveryEvents, discoveryListPaging]) => {
        const { content, ...paging } = discoveryEvents;

        if (content) {
          const isInBetweenEmptyPage = !content?.length && paging?.hasLaterEvent;
          const contentWithOccurenceId = content.map(ev => ({ ...ev, id: ev.recurring ? generateId() : ev.id, eventId: ev.id }));
          const eventVisibilityAction = DateUtil.compare(discoveryListPaging?.endDate, paging?.startDate) === 0
            ? appendDiscoveryListOccurences({ data: contentWithOccurenceId })
            : updateDiscoveryListOccurences({ data: contentWithOccurenceId });
          const loadingState = isInBetweenEmptyPage
            ? LoadingState.NOT_LOADED
            : LoadingState.LOADED;

          const actions: Action[] = [
            updateDiscoveryEventsLoadingState({ loadingState }),
            updateDiscoveryEventsPaging({ discoverEventsPaging: paging }),
            extractSubscription({ data: contentWithOccurenceId }),
            eventVisibilityAction,
          ];

          if (isInBetweenEmptyPage) {
            actions.push(fetchDiscoveryEventsNextPage());
          }

          return actions;
        }
        return of(updateDiscoveryEventsLoadingState({ loadingState: LoadingState.LOADED }));
      }),
      catchError((e) => of(updateDiscoveryEventsLoadingState({ loadingState: LoadingState.ERROR })))
    )
  );
};
