import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RootState } from '../../root/public-api';
import { Store, select } from '@ngrx/store';
import { upsertChatMessagesNotification } from '../notification.actions';
import { NotificationService } from '../../../../shared/services/notification-service';
import { Observable, filter, map, merge, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { selectCurrentUserData } from '../../auth/auth.selectors';
import { selectChannelById } from '../../channel/channel.selectors';
import { selectorWithParam } from '../../../common/util/selector-with-param';
import { ChannelMessageDTO, ChannelMessagesResponse, RoomType, isDocumentEmbed, isLinkEmbed, isMediaEmbed, isNotificationEmbed, isPollEmbed } from '@portal/wen-backend-api';
import { selectRoomById } from '../../chat/chat.selectors';
import { TranslateService } from '@ngx-translate/core';
import { BrowserNotification, BrowserNotificationType, WenNativeApi } from '@portal/wen-native-api';
import { firstExisty } from '../../../common/operators/first-existy';
import { getAvatarUrl } from '@portal/wen-common';
import { ChatNotificationEventEntity } from '../notification.state';
import { lastItem } from '../../../common/operators/array-utils';
import { getMediaTypeData } from '@portal/wen-components';


const createChatNotification = (
    data: ChatNotificationEventEntity,
    translate: TranslateService,
    imageProxyBaseUrl: string,
    store: Store<RootState>
  ): Observable<BrowserNotification> => {
  return store.pipe(
    selectorWithParam(selectRoomById, data.originalEvent.roomId),
    firstExisty(),
    filter(room => !room.details.isMuted),
    switchMap((room) => {
      const isGroup = room.details.type === RoomType.GROUP;
      const avatarUrl = getAvatarUrl(imageProxyBaseUrl, data.insertUser.id);
      const icon = isGroup && room.details.icon ? room.details.icon : avatarUrl;
      const title = isGroup ? `${data.insertUser.name} (${room.details.title})` : data.insertUser.name;
      const content = translate.instant('CHAT_MESSAGE_NEW');
      return of({
        title,
        notificationType: BrowserNotificationType.NEW_CHAT_MESSAGE,
        options: {
          body: content,
          icon: icon || null,
          data: {
            routeId: room.id
          }
        }
      });
    })
  );
};

const createChannelNotification = (
    data: ChannelMessageDTO,
    translate: TranslateService,
    store: Store<RootState>
  ): Observable<BrowserNotification> => {
  return store.pipe(
    selectorWithParam(selectChannelById, data.channelId),
    firstExisty(),
    filter(channel => !channel.isMuted),
    switchMap((channel) => {
      const content = data.content || translate.instant(createContentTextFromEmbeds(data)?.text);
      return of({
        title: channel.title,
        notificationType: BrowserNotificationType.NEW_CHANNEL_MESSAGE,
        options: {
          body: content,
          icon: channel.icon || null,
          data: {
            routeId: channel.id
          }
        }
      });
    })
  );
};

export const createBrowserNotificationHandlerEffect = (
  actions$: Actions,
  store: Store<RootState>,
  notificationService: NotificationService,
  newChannelMessages$: Observable<ChannelMessagesResponse>,
  translateService: TranslateService,
  wenNativeApi: WenNativeApi,
  imageProxyBaseUrl: string
) => {

  if(wenNativeApi.isReactNativeApp()) {
    return;
  }

  const currentUserId$ = store.pipe(
    select(selectCurrentUserData),
    map(user => user?.userId)
  );

  const chatMessages$ = actions$.pipe(
    ofType(upsertChatMessagesNotification),
    withLatestFrom(currentUserId$),
    filter(([chatData, currentUserId]) => currentUserId !== chatData.eventEntity.insertUser.id && chatData.eventEntity.originalEvent.new),
    switchMap(([chatData, _]) => {
      return of({
        data: chatData.eventEntity,
        notificationType: BrowserNotificationType.NEW_CHAT_MESSAGE,
      });
    })
  );

  const channelMessages$ = newChannelMessages$.pipe(
    withLatestFrom(currentUserId$),
    filter(([messageData, currentUserId]) => currentUserId !== messageData[messageData.length - 1].authorId),
    switchMap(([messageData, _]) => {
      const latestMessage = messageData[messageData.length - 1];
      return of({
        data: latestMessage,
        notificationType: BrowserNotificationType.NEW_CHANNEL_MESSAGE,
      });
    })
  );

  return createEffect(() =>
    merge(chatMessages$, channelMessages$).pipe(
      withLatestFrom(notificationService.requestPermission$()),
      filter(([notificationData, permission]) => permission === 'granted'),
      switchMap(([notificationData]) => {
        return notificationData.notificationType === BrowserNotificationType.NEW_CHAT_MESSAGE
          ? createChatNotification(notificationData.data as ChatNotificationEventEntity, translateService, imageProxyBaseUrl, store)
          : createChannelNotification(notificationData.data as ChannelMessageDTO, translateService, store );
      }),
      tap((message: BrowserNotification) => {
        return notificationService.showNotification(message);
      })
    ),
    { dispatch: false }
  );
};

function createContentTextFromEmbeds(message: ChannelMessageDTO) {
  if (!message.content && message.embeds?.length) {
    const lastEmbed = lastItem(message.embeds);
    if (isLinkEmbed(lastEmbed)) {
      return { text: lastEmbed.title };
    }
    if (isDocumentEmbed(lastEmbed)) {
      return { text: lastEmbed.title };
    }
    if (isPollEmbed(lastEmbed)) {
      return { text: 'POLL_LABEL' };
    }
    if (isMediaEmbed(lastEmbed)) {
      const { title } = getMediaTypeData(lastEmbed.subType);
      return {
        text: title
      };
    }
    if (isNotificationEmbed(lastEmbed)) {
      return {
        text: lastEmbed.notificationData.message
      };
    }
  }
}
