import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { AppNavigator } from '../../../../../core/services/navigation/app-navigator';
import { WenNativeApi } from '@portal/wen-native-api';
import { OccurenceEntity } from '../../../../../core/store/events/event.state';
import { SECTION_LIMIT, SectionService } from '../../../../../core/store/sections/sections.facade';
import { WenRouteId } from '../../../../../frame/routing/types';
import { eventStartDate, eventViewIdentifier } from '../../../tokens';
import { DataSource } from '@angular/cdk/collections';

@Component({
  selector: 'wen-event-section',
  templateUrl: './event-section.component.html',
  styleUrls: ['./event-section.component.scss']
})
export class EventSectionComponent implements OnDestroy {

  private innerDataSource: DataSource<OccurenceEntity>;

  @Input() sectionName: string;

  sectionItems$: Observable<readonly OccurenceEntity[]>;

  isNativeApp = this.wenNativeApi.isReactNativeApp();

  sectionLimit = SECTION_LIMIT;

  @Input() set dataSource(dataSource: DataSource<OccurenceEntity>) {
    if (this.innerDataSource) {
      this.innerDataSource.disconnect(null);
    }
    this.innerDataSource = dataSource;
    this.sectionItems$ = this.innerDataSource.connect(null);
  }

  @Output() showAll = new EventEmitter();

  constructor(
    private appNavigator: AppNavigator,
    private sectionService: SectionService,
    private wenNativeApi: WenNativeApi
  ) { }

  navigateToDetail(eventId: string, startDate: string) {
    this.appNavigator.navigateToRoute(WenRouteId.EVENT_DETAIL, { [eventViewIdentifier]: eventId , [eventStartDate]: startDate});
  }

  showAllElements() {
    this.showAll.emit();
  }

  ngOnDestroy(): void {
    this.innerDataSource.disconnect(null);
  }
}
