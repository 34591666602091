import { Directive, HostListener, inject, Input, TemplateRef } from '@angular/core';
import { SELECTION_MODEL } from '../providers/md-selection-model';
import { SelectionListMassDataViewerControl } from '../providers/md-selection-control';

@Directive({
  selector: 'wen-selection-list-mass-data-item, [wen-selection-list-mass-data-item]'
})
export class SelectionListMassDataViewerItemTemplateDirective {

  constructor(
    public templateRef: TemplateRef<any>
  ) { }

}

@Directive({
  selector: 'wen-selection-list-mass-data-item-selectable, [wen-selection-list-mass-data-item-selectable]'
})
export class SelectionListMassDataViewerSelectableDirective<T> {

  private mdControl = inject(SelectionListMassDataViewerControl);
  private selectionModel = inject(SELECTION_MODEL);

  @Input('wen-selection-list-mass-data-item-selectable') value: T;

  @HostListener('click')
  @HostListener('press')
  onClick() {
    this.selectionModel.toggle(this.value);
    this.mdControl.syncSelection();
  }

}
