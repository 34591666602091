import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { combineLatest, map, Observable, Subject } from 'rxjs';
import { SECTION_LIMIT, SectionService } from '../../../../../core/store/sections/sections.facade';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { selectUserAppIds } from '../../../../../core/store/apps/apps.selectors';
import { select, Store } from '@ngrx/store';
import { RootState } from '../../../../../core/store/root/public-api';
import { AppListData } from '../../../app-list/models/AppLoadResult';
import { setSubscriptionToApp } from '../../../../../core/store/apps/apps.actions';
import { WenNativeApi } from '@portal/wen-native-api';
import { DataSource } from '@angular/cdk/collections';
import { AppDTO } from '@portal/wen-backend-api';
import { Icon } from '@portal/wen-components';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'wen-app-section',
  templateUrl: './app-section.component.html',
  styleUrls: ['./app-section.component.scss']
})
export class AppSectionComponent implements OnDestroy {
  private onDestroy$ = new Subject<void>();
  private innerDataSource: DataSource<AppDTO>;

  readonly downloadIcon: Icon = { name: 'download_active', altText: this.translateService.instant('REMOVE_APP_LABEL') };
  readonly downloadInactiveIcon: Icon = { name: 'download_inactive', altText: this.translateService.instant('APP_SUBSCRIBE_BUTTON_LABEL') };

  @Input() sectionName: string;
  sectionItems$: Observable<AppListData[]>;
  imageSize = { height: 110 };
  sectionLimit = SECTION_LIMIT;

  @Input() set dataSource(dataSource: DataSource<AppDTO>) {
    if (this.innerDataSource) {
      this.innerDataSource.disconnect(null);
    }

    this.innerDataSource = dataSource;
    this.sectionItems$ = combineLatest([
      this.store.pipe(select(selectUserAppIds)),
      this.innerDataSource.connect(null)
    ]).pipe(
      map(([userAppIds, apps]) => {
        const appItems = apps?.map((app) => {
          return {
            ...app,
            isSubscribed: userAppIds?.includes(app.id)
          };
        });
        return appItems;
      })
    );
  }

  @Output() showAll = new EventEmitter();

  isNativeApp = this.wenNativeApi.isReactNativeApp();

  constructor(
    private sectionService: SectionService,
    private navigationHelper: WenNavigationHelper,
    private store: Store<RootState>,
    private wenNativeApi: WenNativeApi,
    private translateService: TranslateService
  ) { }

  navigateToAppDetail(app: AppListData) {
     if (app.isSubscribed) {
        this.navigationHelper.navigateToApp(app);
     } else {
        this.navigationHelper.navigateToAppDetail(app.id);
     }
  }

  showAllElements() {
    this.showAll.emit();
  }

  ngOnDestroy(): void {
    this.innerDataSource.disconnect(null);
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  toggleAppSubscription(id: string, setSubscribed: boolean) {
    this.store.dispatch(setSubscriptionToApp({ id, setSubscribed }));
  }
}
