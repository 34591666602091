<wen-form-progress [hasProgress]="hasProgress$ | async"></wen-form-progress>
<wen-confirmation-dialog>
  <div wen-confirmation-dialog-custom-content [formGroup]="inviteFormGroup">
    @if (onlyNetworkInvitees) {
      <div portal-title>{{ "CRM_SEND_CHAT_INVITE_SEND_DIALOG_TITLE" | translate }}</div>
      <div portal-body-2>{{ "CRM_SEND_CHAT_INVITE_SEND_DIALOG_MESSAGE" | translate }}</div>
    } @else {
      <img [src]="'/assets/wen-widget/icons/success.svg'" alt="">
      <div portal-title>{{ "CRM_SEND_CHAT_INVITE_SEND_DIALOG_SUCCESS_TITLE" | translate }}</div>
      <div portal-body-2>{{ "CRM_SEND_CHAT_INVITE_SEND_DIALOG_SUCCESS_MESSAGE" | translate }}</div>
    }
    <mat-form-field>
      <mat-label>{{ "CRM_SEND_CHAT_INVITE_SEND_DIALOG_EMAIL_FIELD_LABEL" | translate }}</mat-label>
      <portal-field [formControlName]="'emailBody'" [multiLine]="true" [rows]="{ min: 1, max: 5 }"></portal-field>
    </mat-form-field>
  </div>
</wen-confirmation-dialog>