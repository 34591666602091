import { Component, OnInit, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { isConnectedStatus } from '@portal/wen-backend-api';
import { Observable } from 'rxjs';
import { FeatureEnablementService } from '../../../../../core/services/configuration/feature-enablement';
import { FilteredListMDContainerConnector, provideFilteredMassDataContainerConnector } from '../../../../../core/services/mass-data/filtering/filtered-list-mass-data-viewer-container-connector';
import { AppNavigator } from '../../../../../core/services/navigation/app-navigator';
import { createAndNavigateToDialogChat } from '../../../../../core/store/chat/chat.actions';
import { ContactsMassDataContainer } from '../../../../../core/store/contacts/contacts-mass-data-config/contacts-mass-data-container';
import { ContactsSearchMassDataContainer } from '../../../../../core/store/contacts/contacts-search-mass-data-config/contacts-search-mass-data-container';
import { selectContactsOpenRequestCount } from '../../../../../core/store/contacts/contacts.selectors';
import { ContactListEntity } from '../../../../../core/store/contacts/contacts.state';
import { Filter, FilterEntityType, FilterType } from '../../../../../core/store/filter/models/filter';
import { RootState } from '../../../../../core/store/root/public-api';
import { WenRouteId } from '../../../../../frame/routing/types';
import { ContactResponseResponseActionData } from '../../../../../shared/components/contact-response-action/contact-response-action.types';
import { ColorPalette } from '../../../../../shared/directives/colorize/palette-provider';
import { userProfileIdentifier } from '../../../../user-profile/tokens';
import { ContactListService } from '../../providers/contact-list-service';
import { ContactListConnectorType } from '../../providers/contact-list-types';
import { HeaderDataHelper } from '../../../../../core/services/util/header-data-helper';

const filteredConnectorConfig = {
  list: ContactsMassDataContainer,
  filterList: ContactsSearchMassDataContainer,
  relevantFilterEntity: FilterEntityType.CONTACTS_USER_LIST_FILTER,
  selectRelevantFilter: (filters: Filter[]) => {
    const searchFilter = filters.filter(listFilter => listFilter.filterType === FilterType.SEARCH);
    return searchFilter;
  }
};

@Component({
  selector: 'wen-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.scss'],
  providers: [
    ContactListService,
    provideFilteredMassDataContainerConnector(filteredConnectorConfig),
  ]
})
export class ContactsListComponent implements OnInit {

  public toPendingMenuEntryConfig = {
    iconName: 'contact_add',
    translationKey: 'CONTACTS_LIST_PENDING_MENU_LABEL',
    fillColor: ColorPalette.OCEAN_BLUE
  };

  public containerConnector = inject<ContactListConnectorType>(FilteredListMDContainerConnector);
  public openContactRequestCount$: Observable<number>;

  constructor(
    public appNavigator: AppNavigator,
    public listService: ContactListService,
    public store: Store<RootState>,
    public featureEnablementService: FeatureEnablementService,
    private headerDataHelper: HeaderDataHelper
  ) {  }

  ngOnInit(): void {
    this.headerDataHelper.initializeContextTitleWithUserName(true);
    this.openContactRequestCount$ = this.store.pipe(
      select(selectContactsOpenRequestCount)
    );
  }

  handleListItemClick(item: ContactListEntity) {
    this.appNavigator.navigateToRoute(WenRouteId.OTHER_USERPROFILE_READ, {
      [userProfileIdentifier]: item.user.id
    });
  }

  initiateChatWithUser(event: Event, item: ContactListEntity) {
    event.stopPropagation();
    const { id } = item.user;
    this.store.dispatch(createAndNavigateToDialogChat({ userId: id }));
  }

  handleToPendingMenuItemClick() {
    this.appNavigator.navigateToRoute(WenRouteId.PENDING_CONTACTS_LIST);
  }

  isNotConnected(item: ContactListEntity) {
    const { requestStatus } = item;
    if (!requestStatus) {
      return true;
    }
    return !isConnectedStatus(requestStatus);
  }

  convertToResponseActionData(item: ContactListEntity): ContactResponseResponseActionData {
    const data: ContactResponseResponseActionData = {
      userId: item.user.id, requestStatus: item.requestStatus, displayName: item.user.displayName
    };
    return data;
  }

  chatAllowed(userChatSetting: boolean) {
    return userChatSetting && this.featureEnablementService.isChatEnabled();
  }
}
