import { Injectable, inject } from '@angular/core';
import { from, tap } from 'rxjs';
import { WenNavigationHelper } from '../../core/services/navigation/types';
import { DeepLinkNavigator } from '../../core/services/navigation/deep-link/deep-link-navigator';
import { BrowserNotification, BrowserNotificationType } from '@portal/wen-native-api';

@Injectable()
export class NotificationService {

  private navigationHelper = inject(WenNavigationHelper);
  private deepLinkNavigator = inject(DeepLinkNavigator);

  requestPermission$() {
    return from(Notification.requestPermission()).pipe(
      tap(permission => {
        if (permission !== 'granted') {
          console.warn('Notification permisison was not granted.');
        }
      })
    );
  }

  showNotification(browserNotification: BrowserNotification) {
    if (Notification.permission === 'granted') {
      const notification = new Notification(browserNotification.title, browserNotification.options);
      notification.onclick = () => {
        if (document.visibilityState === 'hidden') {
          window.focus();
        }
        switch (browserNotification.notificationType) {
          case BrowserNotificationType.NEW_CHANNEL_MESSAGE:
            this.deepLinkNavigator.runGuarded(() => {
              this.navigationHelper.navigateToChannelView(browserNotification.options.data.routeId, { state: { fromDeepLink: true } });
            });
            break;
          case BrowserNotificationType.NEW_CHAT_MESSAGE:
            this.deepLinkNavigator.runGuarded(() => {
              this.navigationHelper.navigateToChatView(browserNotification.options.data.routeId, { state: { fromDeepLink: true } });
            });
            break;
        }
      };
    }
  }
}
