import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppDTO } from '@portal/wen-backend-api';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { setSubscriptionToCurrentApp } from '../../../../../core/store/apps/apps.actions';
import { selectAppPermission, selectCurrentAppDetail } from '../../../../../core/store/apps/apps.selectors';
import { AppEntity } from '../../../../../core/store/apps/apps.state';
import { RootState } from '../../../../../core/store/root/public-api';
import { DetailHeaderModel } from '../../../../../shared/components/detail-header/detail-header.component';
import { SubscribedToSource } from '../../../../../shared/directives/subscibed-to/subscribed-to-source';
import { AppPermissionService } from '../../../common/providers/app-permission.service';
import { AppSubscribedToSource } from '../../../common/providers/app-subscribed-to-source';
import { AppNavigator } from '../../../../../core/services/navigation/app-navigator';
import { WenRouteId } from '../../../../../frame/routing/types';
import { appDetailIdentifier } from '../../../tokens';

@Component({
  selector: 'wen-app-detail',
  templateUrl: './app-detail.component.html',
  styleUrls: ['./app-detail.component.scss'],
  providers: [
    AppPermissionService
  ],
  viewProviders: [
    { provide: SubscribedToSource, useClass: AppSubscribedToSource }
  ]
})
export class AppDetailComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();

  currentApp$: Observable<AppEntity>;
  currentAppId$: Observable<string>;
  headerData$: Observable<DetailHeaderModel>;
  hasAndroidStoreInfo$: Observable<boolean>;
  hasIOSStoreInfo$: Observable<boolean>;
  hasUri$: Observable<boolean>;
  canEdit$: Observable<boolean>;

  constructor(
    private readonly store: Store<RootState>,
    private readonly translateService: TranslateService,
    private readonly navigationHelper: WenNavigationHelper,
    private readonly appNavigator: AppNavigator,
    private readonly appPermissionService: AppPermissionService,
  ) { }

  ngOnInit(): void {
    this.currentApp$ = this.store.pipe(
      select(selectCurrentAppDetail)
    );
    this.currentAppId$ = this.currentApp$.pipe(
      map((app) => app?.id),
      shareReplay(1)
    );
    this.headerData$ = this.currentApp$.pipe(
      filter(app => Boolean(app)),
      map((app) => {
        const description = `${this.translateService.instant('SUBSCRIBER', {count: app.subscriptions})}`;
        const data: DetailHeaderModel = {
          imageUrl: app.icon,
          title: app.title,
          description
        };
        return data;
      })
    );
    this.currentAppId$.pipe(
      distinctUntilChanged(),
      takeUntil(this.onDestroy$)
    ).subscribe(appId => this.appPermissionService.checkPermissionToApp(appId));
    this.hasAndroidStoreInfo$ = this.currentApp$.pipe(map(app => Boolean(app?.androidPlayStoreId)));
    this.hasIOSStoreInfo$ = this.currentApp$.pipe(map(app => Boolean(app?.iosAppName && app?.iosAppStoreId)));
    this.hasUri$ = this.currentApp$.pipe(map(app => Boolean(app) ? this.navigationHelper.canOpenApp(app) : false));
    this.canEdit$ = this.currentAppId$.pipe(
      switchMap((appId) => this.store.pipe(
        select(selectAppPermission),
        map((appPermissionFn) => appPermissionFn(appId)),
      )),
      map(permission => permission?.canEdit),
      takeUntil(this.onDestroy$)
    );
  }

  subscribeToApp() {
    this.store.dispatch(setSubscriptionToCurrentApp({ setSubscribed: true }));
  }

  unSubscribeFromApp() {
    this.store.dispatch(setSubscriptionToCurrentApp({ setSubscribed: false }));
  }

  navigateToApp(app: AppDTO) {
    this.navigationHelper.navigateToApp(app);
  }

  openAndroidStore(androidPlayStoreId: string) {
    const options = { androidPlayStoreId };
    this.navigationHelper.navigateToAppStore(options, true);
  }

  openIOSStore(iosAppName: string, iosAppStoreId: string) {
    const options = { iosAppName, iosAppStoreId };
    this.navigationHelper.navigateToAppStore(options, false);
  }

  editApp(appId: string) {
    this.appNavigator.navigateToRoute(WenRouteId.APP_INFO_EDIT, { [appDetailIdentifier]: appId });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
