import { Component, HostBinding, Input, ElementRef, Renderer2, OnInit, Optional, ViewChild } from '@angular/core';
import { MediaType } from '@portal/wen-backend-api';
import { filter, map, Observable, ReplaySubject, shareReplay } from 'rxjs';
import { isNullOrUndefined } from '../../../../../../../../core/common/operators/null-check-util';
import { ThumbnailProvider } from '../../directives/thumbnail-evaluator.directive';
import { MediaViewParams } from '../../models/models';
import { LazyLoadParams } from '@portal/wen-components';
import { MessageBoxCustomizations } from '../../../../../providers/message-box-embed-customizations';
import { TranslateService } from '@ngx-translate/core';
import { getMediaAltText } from '../../media-utils';

@Component({
  selector: 'wen-embed-upload',
  templateUrl: './embed-upload.component.html',
  styleUrls: ['./embed-upload.component.scss'],
})
export class EmbedUploadComponent implements OnInit {

  mediaType$ = new ReplaySubject<MediaType>(1);
  localThumbnail$: Observable<{ hasImage: boolean; params: LazyLoadParams } | void>;
  thumbnailParams$: Observable<LazyLoadParams>;
  canvasSize$: Observable<{ width: string; height: string }>;
  processing: boolean;
  mediaAwareAriaLabel$: Observable<string>;

  @HostBinding('class.wen-local-embed') className = true;

  @Input() set config(params: MediaViewParams) {
    this.mediaType$.next(params.mediaType);
    this.processing = params.embedType === MediaType.PROCESS;
  }

  @ViewChild('canvasWrapper') canvasWrapper: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;

  constructor(
    private thumbnailProvider: ThumbnailProvider,
    private renderer: Renderer2,
    private elementRef: ElementRef<HTMLElement>,
    @Optional() private messageBoxCustomizations: MessageBoxCustomizations,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.handleThumbnail();
    this.setAriaLabel();
  }

  private handleThumbnail() {
    const arrivingThumbnail$ = this.thumbnailProvider.thumbnail$.pipe(
      shareReplay(1)
    );
    this.thumbnailParams$ = arrivingThumbnail$.pipe(
      filter(({ url, imageElement }) => isNullOrUndefined(imageElement) && !!url),
      map(({ url, scaledDimensions: { width, height } }) => {
        return {
          src: url,
          originalWidth: width,
          originalHeight: height
        };
      })
    );

    this.canvasSize$ = arrivingThumbnail$.pipe(
      filter(({ url }) => !url),
      map(({ canvasDimensions }) => ({
        width: `${canvasDimensions.width}px`,
        height: `${canvasDimensions.height}px`,
      })),
    );
    arrivingThumbnail$.pipe(
      filter(({ imageElement }) => !isNullOrUndefined(imageElement)),
    ).subscribe(({ imageElement, scaledDimensions, canvasDimensions, usedDimensions, targetOffset, sourceOffset }) => {
      const canvasWidth = Math.round(canvasDimensions.width);
      const canvasHeight = Math.round(canvasDimensions.height);

      this.canvasWrapper.nativeElement.style.width = `${canvasWidth}px`;
      this.canvasWrapper.nativeElement.style.height = `${canvasHeight}px`;

      const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
      const context = canvasEl.getContext('2d');
      context.clearRect(0, 0, canvasEl.width, canvasEl.height);

      canvasEl.width = canvasWidth;
      canvasEl.height = canvasHeight;

      const targetWidth = Math.min(scaledDimensions.width, canvasDimensions.width - targetOffset.width);
      const targetHeight = Math.min(scaledDimensions.height, canvasDimensions.height - targetOffset.height);
      context.drawImage(
        imageElement,
        sourceOffset.width, sourceOffset.height,
        usedDimensions.width, usedDimensions.height,
        targetOffset.width, targetOffset.height,
        targetWidth, targetHeight,
      );
    });
  }

  private setAriaLabel() {
    if (this.messageBoxCustomizations) {
      this.mediaAwareAriaLabel$ = this.mediaType$.pipe(
        map((mediaType) => getMediaAltText(this.translateService, mediaType, 'MEDIA_IS_UPLOADING_DESCRIPTION'))
      );
    }
  }

}
