import { Injectable, OnDestroy, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { WenLanguage } from '@portal/wen-backend-api';
import { WenLanguageService } from '@portal/wen-translation';
import { filter, first, map, ReplaySubject, Subject, switchMap } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { DateUtil } from '../../core/common/date/date-util';
import { WenStorageService } from '../../core/services/storage/wen-storage.service';
import { RootState } from '../../core/store/root/public-api';
import { UserProfile } from '../../core/store/user/models/UserProfile';
import { updateUserPrimaryLanguage } from '../../core/store/user/user.actions';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class LanguageService extends WenLanguageService implements OnDestroy {

  private languageCode$ = new ReplaySubject<WenLanguage>(1);
  private onDestroy$ = new Subject<void>();

  private browserLanguage = this.getBrowserLanguage();
  private storageLanguage = this.getStorageLanguage();

  constructor(
    private storageService: WenStorageService,
    private translateService: TranslateService,
    private store: Store<RootState>,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
    this.setAppLanguage();
  }

  initLanguage() {
    const supportedLanguages = super.getSupportedLanguages();
    /**
     * When the app supports only one language, then always that language should be used.
     * This language should not be broadcasted to be the user's preferred language
     */
    const initialLanguage = this.storageLanguage || this.browserLanguage || WenLanguage.GERMAN;
    const targetLanguage = supportedLanguages.length === 1 ? supportedLanguages[0] : initialLanguage;

    if (Object.values(WenLanguage).includes(targetLanguage)) {
      this.updateLanguage(targetLanguage).pipe(
        first()
      ).subscribe(() => this.updateDocumentLanguage(targetLanguage));
    }
  }

  /** The newly selected language from the settings */
  setLanguage(languageCode: WenLanguage) {
    this.languageCode$.next(languageCode);
  }

  getLanguage(userProfile: UserProfile): WenLanguage {
    return userProfile?.primaryLanguage || this.storageLanguage;
  }

  private setAppLanguage() {
    this.languageCode$.pipe(
      filter((languageCode) => Object.values(WenLanguage).includes(languageCode)),
      switchMap(languageCode => this.updateLanguage(languageCode).pipe(
        map(() => languageCode)
      )),
      takeUntil(this.onDestroy$)
    ).subscribe((languageCode) => {
      this.store.dispatch(updateUserPrimaryLanguage({ languageCode: languageCode as WenLanguage }));
      this.updateDocumentLanguage(languageCode);
    });
  }

  private updateLanguage(langCode: string) {
    DateUtil.setLocale(langCode);
    this.translateService.setDefaultLang(langCode);
    this.storageService.setLanguage(langCode);
    return this.translateService.use(langCode);
  }

  private getStorageLanguage() {
    const storageLanguage = this.storageService.getLanguage() as WenLanguage;
    return storageLanguage;
  }

  private getBrowserLanguage() {
    const userPrefLanguage = navigator.language || navigator.languages[0];
    const formattedUserLang: WenLanguage = userPrefLanguage.split('-')[0] as WenLanguage;
    const isSupportedLanguage = Object.values(WenLanguage).includes(formattedUserLang);
    const supportedLang: WenLanguage = isSupportedLanguage ? formattedUserLang : null;
    return supportedLang;
  }

  /** By default it always uses german. The language change is helpful mainly for screen readers on how to explain the app properties */
  private updateDocumentLanguage(languageCode: WenLanguage) {
    this.document.documentElement.lang = languageCode;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
