import { IndicatorType } from '../components/channel-indicator/channel-indicator.component';

export const getChannelIndicatorType = (
  geo: boolean,
  readingContent: boolean,
  restricted: boolean,
  isPrivate: boolean): IndicatorType | undefined => {

  if (readingContent) {
    return (restricted && isPrivate) ? IndicatorType.PREMIUM_READING_CHANNEL : IndicatorType.READING_CHANNEL;
  }

  if (geo) {
    return IndicatorType.GEO_CHANNEL;
  }

  return undefined;
};
