@if (featuredData$ | async; as featuredData) {
  <div class="wen-channel-featured-view-content">
    <wen-channel-messages-view [flowDirection]="'down'" [disableContextMenu]="true" [disableScrollToBottomButton]="true">
      <wen-navigate-to-channel-button
        wen-messages-view-before-content
        class="wen-channel-message-view-button"
        [channel]="featuredData.channel">
      </wen-navigate-to-channel-button>
    </wen-channel-messages-view>
  </div>
}
@if (commentsEnabled$ | async) {
  <wen-comment-message-send-field
  [parentId]="(featuredData$ | async)?.message.id"></wen-comment-message-send-field>
}