import { Component, HostBinding, inject, Input, OnInit } from '@angular/core';
import { WenNavigationHelper } from '../../../../../../core/services/navigation/types';
import { ChannelEntity } from '../../../../../../core/store/channel/channel.state';
import { ActiveItemImageViewModule } from '../../../../../../shared/components/active-item-image-view/active-item-image-view.module';
import { WenIconModule, WithVariantDirective } from '@portal/wen-components';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'wen-channel-view-desktop-header',
  templateUrl: './channel-view-desktop-header.component.html',
  styleUrls: ['./channel-view-desktop-header.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ActiveItemImageViewModule,
    TranslateModule,
    WenIconModule,
    WithVariantDirective
  ]
})
export class ChannelViewDesktopHeaderComponent implements OnInit {

  private navigationHelper = inject(WenNavigationHelper);
  private innerModel: ChannelEntity;

  imageUrl$: Observable<string>;
  placeholder$: Observable<string>;

  @HostBinding('class.wen-channel-view-desktop-header') className = true;

  @Input() set model(value: ChannelEntity) {
    this.innerModel = value;
  }
  get model() {
    return this.innerModel;
  }
  @Input() subHeader: string;

  ngOnInit(): void {
    this.imageUrl$ = of(this.innerModel.icon);
    this.placeholder$ = of(this.innerModel.title);
  }

  openDetail(currentChannelId: string) {
    this.navigationHelper.navigateToChannelDetail(currentChannelId);
  }

}
