import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';

export type ErrorReasons = 'noInternet' | 'auth' | 'websocket' | 'healthStatus' | 'unknown';

@Component({
  selector: 'wen-error-page-reason-content',
  templateUrl: './error-page-reason-content.component.html',
  styleUrl: './error-page-reason-content.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    LottieComponent,
  ],
  standalone: true,
})
export class ErrorPageReasonContentComponent {

  @Input() errorReason: ErrorReasons;

  lottieOptions: AnimationOptions = {
    path: '/assets/wen-widget/error-page/InfoPageAnimation.json',
    autoplay: true,
    loop: true,
  };
  private lottieAnimation: AnimationItem;

  onAnimationCreated(animationItem: AnimationItem) {
    this.lottieAnimation = animationItem;
    this.lottieAnimation.setSpeed(1);
  }

}
