@if (isNavigating) {
  <a href="javascript:void(0)" [attr.aria-disabled]="isDisabled" [attr.aria-label]="altText" class="wen-action-text-content" portal-body-2>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
}
@else {
  <button [attr.aria-disabled]="isDisabled" [attr.aria-label]="altText" class="wen-action-text-content" portal-body-2>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
}

<ng-template #content>
  @if (iconName) {
    <wen-icon [iconName]="iconName" [ngClass]="['wen-icon-' + iconSize]"></wen-icon>
  }
  <ng-content></ng-content>
</ng-template>

