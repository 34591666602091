import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { FilterId } from '../../../../../core/common/types/filter-id';
import { FeatureEnablementService } from '../../../../../core/services/configuration/feature-enablement';
import { reloadDiscoverChannelList } from '../../../../../core/store/channel/channel.actions';
import { Filter, FilterEntityType } from '../../../../../core/store/filter/models/filter';
import { RootState } from '../../../../../core/store/root/public-api';
import { Filterable } from '../../../../../shared/components/filter-view/models/filter-item';
import { ChannelListHeaderDataUpdater } from '../../providers/channel-list-header-data-updater';
import { ChannelLoadResult } from '../channel-list/models/channel-load-result';
import { DiscoverChannelListDataSource } from './discover-channel-list-data-source';
import { ChannelCategoryFilterDataSource } from './filter-sources/channel-category-filter-data-source';
import { ChannelListItemFactory } from '../channel-list/channel-list-item/providers/channel-list-item-factory';
import { ChannelListItemDataSource } from '../channel-list/channel-list-item/providers/channel-list-item-data-source';
import { ChannelRegionFilterDataSource } from './filter-sources/channel-region-filter-data-source';

@Component({
  selector: 'wen-discover-channel-list',
  templateUrl: './discover-channel-list.component.html',
  styleUrls: ['./discover-channel-list.component.scss'],
  providers: [
    DiscoverChannelListDataSource, ChannelListHeaderDataUpdater, ChannelListItemFactory,
    ChannelCategoryFilterDataSource, ChannelRegionFilterDataSource, ChannelListItemDataSource
  ]
})
export class DiscoverChannelListComponent implements OnInit, OnDestroy {

  @HostBinding('class.wen-discover-channel-list') className = true;
  private onDestroy$ = new Subject<void>();

  loadResult$: Observable<ChannelLoadResult>;
  isFilteringEnabled = this.featureEnablementService.featureFlagMethods.isEnableFiltering();
  isFilteringRegionEnabled = this.featureEnablementService.featureFlagMethods.isEnableFilteringRegion();

  filterEntityType = FilterEntityType.CHANNEL_LISTS;
  channelCategoryControlName = FilterId.CHANNEL_CATEGORIES;
  channelRegionControlName = FilterId.CHANNEL_REGIONS;

  filterGroup = new FormGroup({
    [this.channelCategoryControlName]: new FormControl(),
    ...(this.isFilteringRegionEnabled ? {
      [this.channelRegionControlName]: new FormControl()
    } : {})
  });

  get channelCategoryControl() {
    return this.filterGroup.controls[this.channelCategoryControlName];
  }
  get channelRegionControl() {
    return this.filterGroup.controls[this.channelRegionControlName];
  }

  constructor(
    private readonly store: Store<RootState>,
    private readonly dataSource: DiscoverChannelListDataSource,
    private readonly channelListHeaderDataUpdater: ChannelListHeaderDataUpdater,
    private readonly featureEnablementService: FeatureEnablementService,
    public readonly regionFilterService: ChannelRegionFilterDataSource,
    public readonly categoryFilterService: ChannelCategoryFilterDataSource,
  ) { }

  ngOnInit() {
    this.channelListHeaderDataUpdater.initialize();
    this.loadResult$ = this.dataSource.loadResult$;
    this.store.dispatch(reloadDiscoverChannelList());

    this.categoryFilterService.currentActiveChannelCategory$.subscribe((activeChannelCategoryFilter: Filter) => {
      this.channelCategoryControl.setValue(activeChannelCategoryFilter?.filterValue);
    });

    if (this.isFilteringRegionEnabled) {
      this.regionFilterService.currentActiveChannelRegion$.subscribe((activeChannelRegionFilter: Filter) => {
        this.channelRegionControl.setValue(activeChannelRegionFilter?.filterValue);
      });
    }
  }

  openChannelCategoryFilterSelector() {
    const activeCategory: Filterable = this.channelCategoryControl.value;
    this.categoryFilterService.openFilterSelector(activeCategory).subscribe((newValue) => {
      this.channelCategoryControl.setValue(newValue);
      this.store.dispatch(reloadDiscoverChannelList());
    });
  }

  openChannelRegionFilterSelector() {
    const activeRegion: Filterable = this.channelRegionControl.value;
    this.regionFilterService.openFilterSelector(activeRegion).subscribe((newValue) => {
      this.channelRegionControl.setValue(newValue);
      this.store.dispatch(reloadDiscoverChannelList());
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
