import { EditMessageEventDTO } from '../send-to-room/modify-message';
import { PlainQuoteMessageEventDTO } from '../send-to-room/send-message';
import { MessageEvent } from '../messages/response';

export type MessageRelations = {
  [RelationType.EDIT]: (EditMessageEventDTO & RelatesTo<EditRelation> | MessageEvent<EditMessageEventDTO>);
  [RelationType.QUOTE]: MessageEvent<PlainQuoteMessageEventDTO>;
};

export enum RelationType {
  EDIT = 'replace',
  QUOTE = 'quote'
}

type MappedRelation<T extends RelationType = RelationType> = {
  [K in T]: {
    eventId: string;
  };
};

/** To Differentiate the different kind of relations */
interface Relation<T extends RelationType> {
  type: T;
}

export type RelatesTo<T extends RelationTypes = RelationTypes> = {
  relatesTo: T;
};

export type EditRelation = Relation<RelationType.EDIT> & MappedRelation<RelationType.EDIT>;

export type QuoteRelation = Relation<RelationType.QUOTE> & MappedRelation<RelationType.QUOTE>;

/** Extend this type with other relations later on */
export type RelationTypes = EditRelation | QuoteRelation;

export const isEditRelation = (relation: RelationTypes): relation is EditRelation => {
  return relation?.type === RelationType.EDIT;
};

export const isQuoteRelation = (relation: RelationTypes): relation is QuoteRelation => {
  return relation.type === RelationType.QUOTE;
};
