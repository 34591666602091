import { Pipe, PipeTransform } from '@angular/core';
import { DateUtil } from '../../../../core/common/date/date-util';
import { MessageDateFormatter } from '../../providers/message-date-formatter';

@Pipe({
  name: 'messageDateFormatter',
  standalone: true
})
export class MessageDateFormatterPipe implements PipeTransform {

  constructor(
    private messageDateFormatter: MessageDateFormatter,
  ) { }

  transform(value: string) {
    if (!value) {
      return '';
    }
    return this.messageDateFormatter.formatMessageDateForList(DateUtil.fromIsoString(value));
  }

}
