<ng-container>
  <div class="wen-explore-header">
    <div>
      <h1 class="wen-explore-header-label">{{ 'EVENT_CONTEXT_TITLE' | translate }}</h1>
      <span portal-subheading-1 class="wen-explore-header-label-suffix">{{'EVENT_EXPLORE_TITLE_SUFFIX' | translate }}</span>
    </div>
    <div class="wen-explore-header-event-list-user-events-button" portal-body-2 wen-primary-color
      (click)="navigateToUserEventList()" [wen-test-id]="'event-explore-user-list-button'">
      <wen-icon [iconName]="'favorite_active'" class="wen-icon-small"></wen-icon>
      <div>{{ 'USER_EVENTS_BUTTON_LABEL' | translate }}</div>
    </div>
  </div>
</ng-container>

<div class="quick-filters">
  <wen-action class="wen-action-rounded"
              wenVariant="outlined"
              [isNavigating]="true"
              (click)="navigateToEventDiscovery('clearFilter')"
              wen-test-id="event-explore-discover-list-button">
    {{"SHOW_ALL_EVENTS_LABEL" | translate}}
  </wen-action>
</div>
<cdk-virtual-scroll-viewport class="wen-section-wrapper" itemSize="375" scrollStateHandler>
  @for (sectionData of sectionsData$ | async; track sectionData) {
    <wen-event-section
      [dataSource]="sectionData.dataSource"
      [sectionName]="sectionData.name | translate"
      (showAll)="navigateToEventDiscovery(sectionData.sectionProps)">
    </wen-event-section>
  }
</cdk-virtual-scroll-viewport>
