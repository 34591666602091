import { inject, Injectable } from '@angular/core';
import { MessageModificationState, PinDTO } from '@portal/wen-backend-api';
import { DataContext } from '../../../../../../core/common/types/data-context';
import { CalculateMessageStatusPipe } from '../../../../../../shared/components/message-status-indicator/pipes/calculate-message-status.pipe';
import { ChatMessageEntityForStatus } from '../../../../../../shared/components/message-status-indicator/util/chat-message-status-calculator';
import { ChatListItemModel, ChatRoomProperties } from '../../../models/chat-list.models';
import { isNullOrUndefined } from '../../../../../../core/common/operators/null-check-util';
import { ChatNotificationEventEntity } from '../../../../../../core/store/notification/notification.state';
import { ChatRoomEntity } from '../../../../../../core/store/chat/chat.state';
import { ListItemDescription } from '../../../../../../shared/components/list-item/list-item.models';
import { ChatMessageService } from '../../../../common/providers/chat-message-descriptor-service';
import { select, createSelector, Store } from '@ngrx/store';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { Observable } from 'rxjs';
import { selectIsCurrentRoom } from '../../../../../../core/store/chat/chat.selectors';
import { selectLatestMessageForChat, selectNotificationCountForChat } from '../../../../../../core/store/notification/notification.selectors';
import { selectPinItem } from '../../../../../../core/store/pin/pin.selectors';
import { RootState } from '../../../../../../core/store/root/public-api';

@Injectable()
export class ChatListItemFactory {

  private messageStatusCalculator = inject(CalculateMessageStatusPipe);
  private chatMessageService = inject(ChatMessageService);
  private store: Store<RootState> = inject(Store);

  createListItem(props: ChatRoomProperties): ChatListItemModel {
    if (!props || Object.values(props).every(prop => isNullOrUndefined(prop))) {
      return null;
    }
    const { room, message, pin, notificationCount } = props;
    return {
      id: room.id,
      title: room.details.title,
      imageUrl: room.details.icon,
      muteIcon: room.details.isMuted ? 'mute' : null,
      roomType: room.details.type,
      description: this.createDescription(room, message),
      timestamp: this.createTimestamp(room, message),
      pinTimestamp: this.createPinTimestamp(room, pin),
      notifications: { count: notificationCount, isMuted: room.details.isMuted },
      selected: props.isCurrentRoom,
      // TODO: Common message entity?
      insertUser: message?.insertUser || null,
      messageStatus: !!message && !message.redacted ?
        this.messageStatusCalculator.transform(this.createStatusFrom(message), room) : null
    };
  }

  getRoomProperties(room: ChatRoomEntity): Observable<ChatRoomProperties> {
    return this.store.pipe(
      select(this.selectListItemProperties(room)),
      smartDistinctUntilChanged()
    );
  }

  private createTimestamp(room: ChatRoomEntity, message: ChatNotificationEventEntity): string {
    if (!message) {
      return room.lastReadTimestamp;
    }
    if (message.redacted) {
      return message.decryptedEvent.message.timestamp;
    }
    return message.originalEvent.insertTimestamp;
  }

  private createPinTimestamp(room: ChatRoomEntity, pin: PinDTO): string {
    if (!pin) {
      return null;
    }
    return pin.contextId === room.id && pin.contextType === DataContext.CHAT && pin.timestamp;
  }

  private createDescription(room: ChatRoomEntity, message: ChatNotificationEventEntity): ListItemDescription {
    if (!message) {
      return { text: room.details.description };
    }
    return this.chatMessageService.getListDescription(message);
  }

  private createStatusFrom(message: ChatNotificationEventEntity): ChatMessageEntityForStatus {
    if (!message) {
      return null;
    }
    return {
      messageContent: { ...message.decryptedEvent, userId: null },
      state: MessageModificationState.ORIGINAL,
      insertTimestamp: message.originalEvent.insertTimestamp,
      eventId: message.id,
      decryptionError: message.decryptionError
    };
  }

  private selectListItemProperties(room: ChatRoomEntity) {
    return createSelector(
      selectLatestMessageForChat,
      selectPinItem,
      selectNotificationCountForChat,
      selectIsCurrentRoom,
      (
        latestMessageSelector,
        pinSelector,
        notificationCountSelector,
        isCurrentRoomSelector,
      ) => {
        const message = latestMessageSelector(room.id);
        const notificationCount = notificationCountSelector(room.id);
        const isCurrentRoom = isCurrentRoomSelector(room.id);
        const pin = pinSelector(room.id);
        return {
          room,
          message,
          pin,
          notificationCount,
          isCurrentRoom
        } satisfies ChatRoomProperties;
      }
    );
  }
}
