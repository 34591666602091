import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { EmbedContextTypes, MediaUploadResponse } from '@portal/wen-backend-api';
import { OverlayManager } from '@portal/wen-components';
import { Observable } from 'rxjs';
import { distinct, filter, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { mapWithFirstFrom } from '../../../common/operators/map-with-first-from';
import { MediaSettingsService } from '../../../services/media/media-settings.service';
import { removePendingChannelMessage, updatePendingChannelMessage } from '../../channel/channel.actions';
import { updateChatMedia } from '../../chat/chat.actions';
import { RootState } from '../../root/public-api';
import { haltCRMChatFlow } from '../../smartdesign/smartdesign.actions';
import { selectIsFromSmartDesign } from '../../smartdesign/smartdesign.selectors';
import { updateProfileImageState } from '../../user/user.actions';
import { ProfileImageState } from '../../user/user.state';
import { subscribeToMediaUploadNotification } from '../notification.actions';
import { selectCurrentUserData } from '../../auth/auth.selectors';

const handleMediaMessageResponse = (status: MediaUploadResponse) => {
  if (status.mediaProcessed || status.error) {
    return removePendingChannelMessage({ uploadId: status.uploadId, channelId: status.channelId });
  }
  if (status.uploadSucceeded) {
    return updatePendingChannelMessage({ uploadId: status.uploadId, channelId: status.channelId });
  }
  return null;
};

const handleProfileResponse = (status: MediaUploadResponse) => {
  if (status.error) {
    return updateProfileImageState({ state: ProfileImageState.ERROR });
  }
  if (status.mediaProcessed) {
    return updateProfileImageState({ state: ProfileImageState.LOADED });
  }
  if (status.uploadSucceeded) {
    return updateProfileImageState({ state: ProfileImageState.PENDING_SAFE });
  }
  return null;
};

const handleChatMessageResponse = (status: MediaUploadResponse, isFromSmartDesign: boolean): Action => {
  if (status.error && isFromSmartDesign) {
    return haltCRMChatFlow();
  }
  return updateChatMedia({ status });
};

export const createMediaUploadErrorHandlerEffect = (
  actions$: Actions,
  mediaUploadSuccess$: Observable<MediaUploadResponse>,
  overlayManager: OverlayManager,
  mediaSettingsService: MediaSettingsService,
  store: Store<RootState>,
) => {
  return createEffect(() => actions$.pipe(
    ofType(subscribeToMediaUploadNotification),
    mergeMap(() => mediaUploadSuccess$),
    filter(
      ({ dbMediaMetadata }) =>
        dbMediaMetadata.error
        || dbMediaMetadata.uploaded
        || dbMediaMetadata.playable
        || dbMediaMetadata.fullyProcessed
    ),
    distinct(mediaUploadResponse => mediaUploadResponse.uploadId),
    withLatestFrom(store.pipe(
      select(selectCurrentUserData)
    )),
    tap(([status, currentUser]) => {
      if (status.contexts?.some(c => c.type === EmbedContextTypes.CHAT_MESSAGE) && currentUser.userId !== status.userId) {
        return;
      }

      if (!status.error) {
        return;
      }

      if (status.contexts?.some(c => c.type === EmbedContextTypes.REGISTRATION)) {
        return; // An image upload error during registration is considered a successful registration. No error dialog is needed.
      }

      if (status.errorCode === 'UPLOAD_INTERRUPTED') {
        return; // If the user interrups the upload himself, we dont want to show a error dialog
      }

      const label = 'ERROR_MEDIA_UPLOAD_' + status.errorCode;
      let params = null;
      if (status.errorCode === 'SIZE_TOO_BIG') {
        params = { maxFileSize: mediaSettingsService.getMaxFileSizeInMiB().toString() };
      } else if (status.errorCode === 'DIMENSIONS_TOO_BIG') {
        params = { maxDimensions: mediaSettingsService.getMaxImagePixelDimensions().toString() };
      } else if (status.errorCode === 'MEDIA_NOT_FOUND') {
        /**
         * Backend will return this error code when media was deleted by the user during processing of the media.
         * Since its not a error in that case we dont show it.
         */
        return;
      }

      overlayManager.dialog.openErrorDialog({ errorMessageLabel: label, messageParams: params }, 'ERROR_MEDIA_UPLOAD_TITLE');
      return;
    })
  ), { dispatch: false });
};

export const createMediaUploadResponseHandlerEffect = (
  actions$: Actions,
  mediaUploadSuccess$: Observable<MediaUploadResponse>,
  store: Store<RootState>,
) => {
  return createEffect(() => actions$.pipe(
    ofType(subscribeToMediaUploadNotification),
    mergeMap(() => mediaUploadSuccess$),
    filter(
      ({ dbMediaMetadata }) =>
        dbMediaMetadata.error
        || dbMediaMetadata.uploaded
        || dbMediaMetadata.playable
        || dbMediaMetadata.fullyProcessed
    ),
    mapWithFirstFrom(() => store.pipe(select(selectIsFromSmartDesign))),
    switchMap(([status, isFromSmartDesign]) => {
      if (status.contexts?.some(c => c.type === EmbedContextTypes.CHANNEL_MESSAGE)) {
        const action = handleMediaMessageResponse(status);
        return action ? [action] : [];
      } else if (status.contexts?.some(c => c.type === EmbedContextTypes.PROFILE || c.type === EmbedContextTypes.REGISTRATION)) {
        const action = handleProfileResponse(status);
        return action ? [action] : [];
      } else if (status.contexts?.some(c => c.type === EmbedContextTypes.CHAT_MESSAGE)) {
        const action = handleChatMessageResponse(status, isFromSmartDesign);
        return action ? [action] : [];
      }
      return [] as Action[];
    })
  ));
};
