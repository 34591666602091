<div class="wen-registration-success-content">
  <h1 class="cdk-visually-hidden">{{ 'REGISTRATION_CONTEXT_LABEL' | translate }}</h1>

  <img class="wen-registration-success-image" src="/assets/wen-widget/image/registration-success.svg" alt="">

  <h2 class="wen-registration-success-header" wen-header-big>
    {{ 'REGISTRATION_SUCCESS_HEADER' | translate }}
  </h2>

  <div class="wen-registration-success-subheader" portal-body-2>
    {{ 'REGISTRATION_SUCCESS_SUBHEADER' | translate }}
  </div>

  <div class="wen-registration-success-greeting" portal-body-2>
    {{ 'REGISTRATION_SUCCESS_GREETING' | translate }}
  </div>

  @if (!isNativeApp) {
    <div class="wen-registration-success-store-links">
      @if (showPlayStore) {
        <wen-action class="wen-action-no-padding"
                [altText]="'REGISTRATION_SUCCESS_BUTTON_TO_APP_ANDROID' | translate"
                [isNavigating]="true"
                (click)="goToPlayStore()">
          <img src="/assets/wen-widget/image/google-play-badge.png" aria-hidden="true">
        </wen-action>
      }

      @if (showAppStore) {
        <wen-action class="wen-action-no-padding" [ngClass]="{ 'wen-registration-success-app-store-link': showPlayStore }"
                [altText]="'REGISTRATION_SUCCESS_BUTTON_TO_APP_IOS' | translate"
                [isNavigating]="true"
                (click)="goToAppStore()">
          <img src="/assets/wen-widget/image/apple-store-badge.png" aria-hidden="true">
        </wen-action>
      }
    </div>
  }
  @if (isNativeApp) {
    <wen-action class="wen-registration-success-button wen-action-rounded" wenVariant="filled" [isNavigating]="true"
                (click)="navigateToLogin()">
      {{ 'REGISTRATION_SUCCESS_BUTTON_LABEL' | translate }}
    </wen-action>
  }
  @if (!isNativeApp) {
    <wen-action class="wen-registration-success-button wen-action-no-padding" wenVariant="text" [isNavigating]="true"
                (click)="navigateToLogin()">
      {{ 'REGISTRATION_SUCCESS_BROWSER_BUTTON_LABEL' | translate }}
    </wen-action>
  }
</div>
