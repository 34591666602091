import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ChannelMessageDTO } from '@portal/wen-backend-api';
import { combineLatest, map, Observable, of, shareReplay } from 'rxjs';
import { existy } from '../../../../core/common/operators/existy';
import { selectFeaturedData } from '../../../../core/store/channel/channel.selectors';
import { FeaturedEntity } from '../../../../core/store/channel/channel.state';
import { RootState } from '../../../../core/store/root/public-api';
import { PageRequestEvent, WeFeedDataset, WeFeedItemType, WeFeedMessageItem } from '../../../../shared/components/feed/components/we-feed/we-feed-model';
import { FeedDatasource } from '../../../../shared/components/feed/providers/feed-datasource';
import { ChannelConfigurationProvider } from '../../common/providers/channel-configuration';

@Injectable()
export class FeaturedChannelMessagesDatasource extends FeedDatasource {

  featuredData$: Observable<FeaturedEntity>;
  commentsEnabled$: Observable<boolean>;
  disableEmojiReaction$: Observable<boolean>;

  constructor(
    protected store: Store<RootState>,
    private channelConfigurationProvider: ChannelConfigurationProvider,
  ) {
    super(store);
    this.featuredData$ = this.store.pipe(
      select(selectFeaturedData),
      existy(),
      shareReplay(1)
    );
    this.disableEmojiReaction$ = this.featuredData$.pipe(
      map(featuredData => featuredData.channel),
      map(channel => {
        return this.channelConfigurationProvider.getDisableEmojiReactionForChannel(channel);
      })
    );
    this.commentsEnabled$ = this.featuredData$.pipe(
      map(featuredData => featuredData.channel),
      map(channel => {
        return this.channelConfigurationProvider.getCommentsEnabledForChannel(channel);
      })
    );
  }

  bindToSource(): Observable<WeFeedDataset> {
    return combineLatest([this.featuredData$, this.disableEmojiReaction$, this.commentsEnabled$]).pipe(
      map(([featuredData, disableEmojiReaction, commentsEnabled]) => {
        const item = this.convertToFeedItem(featuredData.message, disableEmojiReaction, commentsEnabled);
        const dataSet: WeFeedDataset = {
          items: [item]
        };
        return dataSet;
      })
    );
  }

  loadNextPage(event: PageRequestEvent): Observable<{ hasResult: boolean }> {
    return of({ hasResult: false });
  }

  loadFirstPage(): Observable<{ hasResult: boolean }> {
    return of({ hasResult: false });
  }

  private convertToFeedItem(message: ChannelMessageDTO, disableEmojiReaction: boolean, commentsEnabled: boolean) {
    const {
      id,
      authorId,
      userId,
      content,
      scheduled,
      embeds,
      timestamp,
      type,
      updateTimestamp,
    } = message;
    const feedItem: WeFeedMessageItem = {
      key: message.id,
      type: WeFeedItemType.MESSAGEBOX,
      value: {
        messageId: id,
        referenceId: id,
        authorId,
        sender: userId,
        senderVisible: true,
        content,
        scheduled,
        embeds,
        timestamp,
        shareVisible: true,
        commentsVisible: commentsEnabled,
        commentsEnabled,
        highlighted: false,
        wide: false,
        disableEmojiReaction,
        hideEmojiReactionPlaceholder: false,
        status: null,
        modificationState: type,
        updateTimestamp,
        textToSpeechEnabled: false,
        challengeEnabled: false
      },
    };
    return feedItem;
  }
}
