import { isGuidOnlyRoute } from '../../../core/common/util/routing-helpers';
import { ownProfileGuardFn } from '../../../core/guards/own-profile-guard';
import { userProfileLoadedGuardFn } from '../../../core/guards/user-profile-loaded-guard';
import { FormEditLeaveConfirmationBackNavigation } from '../../../core/services/navigation/back-navigation/back-navigation-behaviors/form-edit-leave-confirmation-behavior';
import { ProfileSubMenuBackNavigationBehavior } from '../../../core/services/navigation/back-navigation/back-navigation-behaviors/profile-sub-menu-back-navigation-behavior';
import { WenRoute, WenRouteId } from '../../../frame/routing/types';
import { AppLogoComponent } from '../../../shared/components/app-logo/app-logo.component';
import { CustomFormStoreKey } from '../../../shared/form-store/form-store-types';
import { ComingSoonComponent } from '../../coming-soon-page/coming-soon.component';
import { EmailChangeFormComponent } from '../email-settings/components/email-change-form/email-change-form.component';
import { EmailSettingsComponent } from '../email-settings/components/email-settings/email-settings.component';
import { FAQViewComponent } from '../faq-view/components/faq-view/faq-view.component';
import { LicensesViewComponent } from '../licenses/components/licenses-view/licenses-view.component';
import { ManifestViewComponent } from '../manifest-view/components/manifest-view/manifest-view.component';
import { PrivacySettingsViewComponent } from '../privacy-view/components/privacy-view/privacy-settings-view.component';
import { OtherProfileViewComponent } from '../profile-form/components/other-profile/other-profile-view.component';
import { UserProfileResolver } from '../profile-form/components/other-profile/resolvers/user-profile.resolver';
import { ProfileEditViewComponent } from '../profile-form/components/profile-edit/profile-edit-view.component';
import { ProfileReadViewComponent } from '../profile-form/components/profile-read/profile-read-view.component';
import { userProfileIdentifier } from '../tokens';
import { CredentialDetailsComponent } from '../user-credentials/components/credential-details/credential-details.component';
import { CredentialsMenuComponent } from '../user-credentials/components/credentials-menu/credentials-menu.component';
import { UserChangePasswordVerifyCodeComponent } from '../user-personal-account-settings/components/user-change-password-verify-code/user-change-password-verify-code.component';
import { UserChangePasswordComponent } from '../user-personal-account-settings/components/user-change-password/user-change-password.component';
import { UserPersonalAccountSettingsComponent } from '../user-personal-account-settings/components/user-personal-account-settings/user-personal-account-settings.component';
import { HelpViewComponent } from '../user-profile-view/components/user-profile-view/sub-views/help-view/help-view.component';
import { HotlineViewComponent } from '../user-profile-view/components/user-profile-view/sub-views/hotline-view/hotline-view.component';
import { SettingViewComponent } from '../user-profile-view/components/user-profile-view/sub-views/setting-view/setting-view.component';
import { UserProfileViewComponent } from '../user-profile-view/components/user-profile-view/user-profile-view.component';
import { UserLanguageSettingComponent } from '../user-settings/components/user-language-setting/user-language-setting.component';
import { UserSettingsComponent } from '../user-settings/components/user-settings/user-settings.component';
import { ProfileEditLocationSelectorComponent } from '../profile-form/components/profile-edit/profile-edit-location-selector/profile-edit-location-selector.component';
import { OtherProfileRecommendationsListComponent } from '../profile-form/components/other-profile/other-profile-recommendations-list/other-profile-recommendations-list.component';
import { UserChannelRecommendationsComponent } from '../user-channel-recommendations/components/user-channel-recommendations/user-channel-recommendations.component';

export const otherProfileViewRoute: WenRoute = {
  path: `:${userProfileIdentifier}`,
  canActivate: [ownProfileGuardFn],
  canMatch: [isGuidOnlyRoute],
  component: OtherProfileViewComponent,
  resolve: {
    profile: UserProfileResolver
  },
  data: {
    routeId: WenRouteId.OTHER_USERPROFILE_READ
  }
};

export const otherProfileChannelRecommendationsRoute: WenRoute = {
  path: `:${userProfileIdentifier}/recommendations`,
  canActivate: [ownProfileGuardFn],
  canMatch: [isGuidOnlyRoute],
  component: OtherProfileRecommendationsListComponent,
  resolve: {
    profile: UserProfileResolver
  },
  data: {
    routeId: WenRouteId.OTHER_USERPROFILE_CHANNEL_RECOMMENDATIONS
  }
};

export const userProfileViewRoute: WenRoute = {
  path: '',
  component: UserProfileViewComponent,
  data: {
    routeId: WenRouteId.USERPROFILE_VIEW
  }
};

export const userProfileDashboardRoute: WenRoute = {
  path: 'dashboard',
  component: AppLogoComponent,
  data: {
    routeId: WenRouteId.USERPROFILE_DASHBOARD
  }
};

export const userProfileManifestRoute: WenRoute = {
  path: 'manifest',
  component: ManifestViewComponent,
  data: {
    routeId: WenRouteId.USERPROFILE_MANIFEST
  }
};

export const userProfileFAQRoute: WenRoute = {
  path: 'help/faq',
  component: FAQViewComponent,
  data: {
    routeId: WenRouteId.USERPROFILE_FAQ,
    backNavigationBehavior: ProfileSubMenuBackNavigationBehavior
  }
};

export const userProfileMarkdownFAQRoute: WenRoute = {
  path: 'help/markdown',
  component: FAQViewComponent,
  data: {
    routeId: WenRouteId.USERPROFILE_MARKDOWN,
    backNavigationBehavior: ProfileSubMenuBackNavigationBehavior
  }
};

export const userProfileHelpRoute: WenRoute = {
  path: 'help',
  component: HelpViewComponent,
  data: {
    routeId: WenRouteId.USERPROFILE_HELP
  }
};

export const userSettingsRoute: WenRoute = {
  path: 'settings',
  component: SettingViewComponent,
  data: {
    routeId: WenRouteId.USER_SETTINGS
  }
};

export const userProfileHotlineRoute: WenRoute = {
  path: 'hotline',
  component: HotlineViewComponent,
  data: {
    routeId: WenRouteId.USERPROFILE_HOTLINE
  }
};

export const userProfileComingSoonRoute: WenRoute = {
  path: 'coming-soon',
  component: ComingSoonComponent,
  data: {
    routeId: WenRouteId.USERPROFILE_COMING_SOON
  }
};

export const userProfileReadRoute: WenRoute = {
  path: 'read',
  component: ProfileReadViewComponent,
  data: {
    routeId: WenRouteId.USERPROFILE_READ
  }
};
export const userProfileEditRoute: WenRoute = {
  path: 'edit',
  component: ProfileEditViewComponent,
  canActivate: [userProfileLoadedGuardFn],
  data: {
    routeId: WenRouteId.USERPROFILE_EDIT,
    backNavigationBehavior: FormEditLeaveConfirmationBackNavigation,
    formStoreStoreKey: CustomFormStoreKey.USER_LOCATION_SELECTOR_KEY
  }
};

export const userProfileChannelRecommendationsRoute: WenRoute = {
  path: 'channel-recommendations',
  component: UserChannelRecommendationsComponent,
  canActivate: [userProfileLoadedGuardFn],
  data: {
    routeId: WenRouteId.USERPROFILE_CHANNEL_RECOMMENDATIONS,
    backNavigationBehavior: FormEditLeaveConfirmationBackNavigation
  }
};

export const userProfileLanguageRoute: WenRoute = {
  path: 'language',
  component: UserLanguageSettingComponent,
  data: {
    routeId: WenRouteId.USERPROFILE_LANGUAGE,
    backNavigationBehavior: FormEditLeaveConfirmationBackNavigation,
  }
};
export const licensesRoute: WenRoute = {
  path: 'licenses',
  component: LicensesViewComponent,
  data: {
    routeId: WenRouteId.USERPROFILE_LICENSES
  }
};

export const userProfileEmailSettingsRoute: WenRoute = {
  path: 'email-settings',
  component: EmailSettingsComponent,
  data: {
    routeId: WenRouteId.USERPROFILE_EMAIL_SETTINGS
  }
};

export const userProfileChangeEmailRoute: WenRoute = {
  path: 'change-email',
  component: EmailChangeFormComponent,
  canActivate: [userProfileLoadedGuardFn],
  data: {
    routeId: WenRouteId.USERPROFILE_CHANGE_EMAIL,
    backNavigationBehavior: FormEditLeaveConfirmationBackNavigation,
  }
};

export const userChangePasswordRoute: WenRoute = {
  path: 'change-password',
  component: UserChangePasswordComponent,
  data: {
    routeId: WenRouteId.USERPROFILE_CHANGE_PASSWORD,
    backNavigationBehavior: FormEditLeaveConfirmationBackNavigation,
    formStoreStoreKey: CustomFormStoreKey.USER_CHANGE_PASSWORD_FORM_KEY,
  }
};

export const userChangePasswordVerifyCodeRoute: WenRoute = {
  path: 'change-password-verify-code',
  component: UserChangePasswordVerifyCodeComponent,
  data: {
    routeId: WenRouteId.USERPROFILE_CHANGE_PASSWORD_VERIFY_CODE,
    backNavigationBehavior: FormEditLeaveConfirmationBackNavigation,
    formStoreStoreKey: CustomFormStoreKey.USER_CHANGE_PASSWORD_FORM_KEY,
  }
};

export const userProfileSettingsRoute: WenRoute = {
  path: 'contact-settings',
  component: UserSettingsComponent,
  canActivate: [userProfileLoadedGuardFn],
  data: {
    routeId: WenRouteId.USERPROFILE_SETTINGS,
    backNavigationBehavior: FormEditLeaveConfirmationBackNavigation,
  }
};

export const userProfileCredentialsMenuRoute: WenRoute = {
  path: 'credentials-menu',
  component: CredentialsMenuComponent,
  canActivate: [userProfileLoadedGuardFn],
  data: {
    routeId: WenRouteId.USERPROFILE_CREDENTIALS_MENU,
  }
};

export const userProfileCredentialDetails: WenRoute = {
  path: 'credential-details',
  component: CredentialDetailsComponent,
  canActivate: [userProfileLoadedGuardFn],
  data: {
    routeId: WenRouteId.USERPROFILE_CREDENTIAL_DETAILS
  }
};

export const userProfilePrivacySettingsRoute: WenRoute = {
  path: 'privacy-settings',
  component: PrivacySettingsViewComponent,
  data: {
    routeId: WenRouteId.USERPROFILE_PRIVACY_SETTINGS,
    backNavigationBehavior: FormEditLeaveConfirmationBackNavigation,
  }
};

export const userPersonalAccountSettingsRoute: WenRoute = {
  path: 'personal-account-settings',
  component: UserPersonalAccountSettingsComponent,
  data: {
    routeId: WenRouteId.USERPROFILE_PERSONAL_ACCOUNT_SETTINGS,
  }
};

export const userProfileLocationSelectorRoute: WenRoute = {
  path: 'select-location',
  component: ProfileEditLocationSelectorComponent,
  canActivate: [userProfileLoadedGuardFn],
  data: {
    routeId: WenRouteId.USERPROFILE_LOCATION_SELECTOR,
    formStoreStoreKey: CustomFormStoreKey.USER_LOCATION_SELECTOR_KEY
  }
};
