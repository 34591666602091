import { Injectable } from '@angular/core';
import { Store, createSelector, select } from '@ngrx/store';
import { selectChannelById, selectIsChannelSubscribed, selectPinForChannel } from '../../../../../../../core/store/channel/channel.selectors';
import { selectRecentMessageForChannel, selectNotificationCountForChannel } from '../../../../../../../core/store/notification/notification.selectors';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { Observable, switchMap } from 'rxjs';
import { ChannelListItemProperties } from './types';
import { isInviteToChannelState } from '@portal/wen-backend-api';
import { ChannelEntity } from '../../../../../../../core/store/channel/channel.state';
import { selectorWithParam } from '../../../../../../../core/common/util/selector-with-param';

@Injectable()
export class ChannelListItemDataSource {

  constructor(
    private readonly store: Store
  ) { }

  getChannelListItemProps(channelId: string): Observable<ChannelListItemProperties | null> {
    return this.store.pipe(
      selectorWithParam(selectChannelById, channelId),
      switchMap(channel => this.store.pipe(
        select(this.selectListItemProps(channel))
      )),
      smartDistinctUntilChanged()
    );
  }

  getChannelListItemPropsFromChannel(channel: ChannelEntity): Observable<ChannelListItemProperties | null> {
    return this.store.pipe(
      select(this.selectListItemProps(channel)),
      smartDistinctUntilChanged()
    );
  }

  private selectListItemProps(channel: ChannelEntity) {
    return createSelector(
      selectIsChannelSubscribed,
      selectRecentMessageForChannel,
      selectNotificationCountForChannel,
      selectPinForChannel,
      (
        isSubscribedSelector,
        mostRecentMessageSelector,
        notificationCountSelector,
        pinTimestampSelector
      ) => {
        if (!channel) {
          return null;
        }
        const {
          id, icon, title, description, isMuted,
          isPrivate, restricted, geo, invite, readingContent
        } = channel;
        const mostRecentMessage = mostRecentMessageSelector(id);

        const messageForProps = mostRecentMessage ? {
          timestamp: mostRecentMessage.timestamp,
          embeds: mostRecentMessage.embeds,
          content: mostRecentMessage.content
        } : null;
        const inviteForProps = invite ? {
          state: invite.state,
          updateTimestamp: invite.updateTimestamp,
          insertUserName: isInviteToChannelState(invite) ? invite.insertUserName : null
        } : null;
        return {
          channel: {
            id,
            icon,
            title,
            description,
            isMuted,
            isPrivate,
            restricted,
            geo,
            invite: inviteForProps,
            readingContent
          },
          subscribed: isSubscribedSelector(id),
          mostRecentMessage: messageForProps,
          notificationCount: notificationCountSelector(id),
          pinTimestamp: pinTimestampSelector(id)
        } satisfies ChannelListItemProperties;
      }
    );
  }
}
