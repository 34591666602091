import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { WenNavigationHelper } from '../navigation/types';
import { NetworkLoadedGuard } from '../network/network-loaded-guard';
import { CrmConnectionInitializer } from '../smartdesign/connection-initializer/crm-connection-initializer';
import { ExclusiveInviteHelper } from '../util/exclusive-invite-helper';
import { AuthStateValidator } from './auth-state-validator.service';
import { LoginStrategyProvider } from './login-strategy-provider';
import { WenOAuthService } from './wen-oauth.service';

export const authGuardFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(AuthGuard).canActivateChild(route, state);
};

@Injectable()
export class AuthGuard {

  constructor(
    private authStateValidator: AuthStateValidator,
    private navigationHelper: WenNavigationHelper,
    private networkLoadedGuard: NetworkLoadedGuard,
    private exclusiveInviteHelper: ExclusiveInviteHelper,
    private oAuthService: WenOAuthService,
    private loginStrategyProvider: LoginStrategyProvider,
    private crmConnectionInitializer: CrmConnectionInitializer,
  ) { }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authStateValidator.validateAuthState().pipe(
      switchMap((userData) => {
        const currentUrl = state.url;
        const isCodeDialogNeeded = this.exclusiveInviteHelper.isCodeDialogNeeded(route?.data?.routeId);
        if (isCodeDialogNeeded) {
          this.navigationHelper.navigateToExclusiveInvite(currentUrl);
          return of(false);
        }
        return this.networkLoadedGuard.canActivateChild(route, state).pipe(
          first(),
          switchMap(() => {
            if (this.loginStrategyProvider.getLoginStrategy(route.queryParamMap) === 'popup') {
              return this.crmConnectionInitializer.initialize(route);
            }
            return of(Boolean(userData));
          })
        );
      }),
      catchError(() => {
        const redirectUrl = state.url;
        if (this.loginStrategyProvider.getLoginStrategy(route.queryParamMap) === 'popup') {
          return this.handleNormalPopupLogin(redirectUrl);
        } else {
          return this.handleNormalLogin(redirectUrl);
        }
      })
    );
  }

  private handleNormalLogin(redirectUrl): Observable<boolean> {
    return this.oAuthService.initCodeFlow({ redirectAfterLogin: redirectUrl }).pipe(
      map(() => false)
    );
  }

  private handleNormalPopupLogin(redirectUrl): Observable<boolean> {
    this.navigationHelper.navigateToPopupSignIn(redirectUrl);
    return of(false);
  }

}
