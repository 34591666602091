import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WenIconModule } from '../wen-icon/wen-icon.module';
import { ActionComponent } from './action/action.component';
import { WithVariantDirective } from '../../directives/styling/with-variant.directive';

@NgModule({
  imports: [WenIconModule, CommonModule, WithVariantDirective, ActionComponent],
  exports: [ActionComponent, WithVariantDirective],
})
export class CoreComponentsModule { }
