<div class="wen-embed-preview-container">
  <wen-legacy-quote-embed-preview #quotePreview></wen-legacy-quote-embed-preview>
</div>
<wen-message-send-field
  [inputPlaceholder]="'SEND_COMMENT_INPUT_PLACEHOLDER' | translate"
  [contextId]="parentId"
  [disabled]="isAnonymousUser$ | async"
  [hideTopBorder]="quotePreview.isQuoting | async"
  (messageSent)="onMessageSent()"
  (click)="onClick()">
</wen-message-send-field>
