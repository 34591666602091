<div class="wen-search-bar-desktop-container">
  <wen-search-input
    [inputValue]="searchTerm$ | async"
    (changed)="onChange($event)"
    (cleared)="onClear()"
    [placeholder]="searchBarPlaceholder | translate"
    [disabled]="disabled"
    (focused)="onSearchInputFocused()"
  ></wen-search-input>

  @if (canCreateChannel$ | async) {
  <wen-action
    iconName="add"
    class="wen-action-no-padding"
    wenVariant="text"
    [altText]="'CREATE_CHANNEL_WELCOME' | translate"
    [isNavigating]="true"
    (click)="navigateToChannelAdd()"
  ></wen-action>
  } @if (canCreateApp$ | async) {
  <wen-action
    iconName="add"
    class="wen-action-no-padding"
    wenVariant="text"
    [altText]="'CREATE_APP_WIZARD_FORM_TITLE' | translate"
    [isNavigating]="true"
    (click)="navigateToAppAdd()"
  ></wen-action>
  } @if (canCreateChat$ | async) {
  <div class="wen-chat-create-wrapper">
    @if (hasGroupChatEnabled) {
    <wen-header-command [commandData]="rightCommand"></wen-header-command>
    } @else {
    <wen-action
      iconName="add"
      class="wen-action-no-padding"
      wenVariant="text"
      [altText]="'CHAT_MESSAGE_NEW' | translate"
      [isNavigating]="true"
      (click)="navigateToNewChat()"
    ></wen-action>
    }
  </div>
  }
</div>
