import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { selectCurrentEvent, selectCurrentSubscription } from '../../../../../../core/store/events/event.selectors';
import { RootState } from '../../../../../../core/store/root/public-api';
import { DetailHeaderModel } from '../../../../../../shared/components/detail-header/detail-header.component';
import { selectRouteParam } from '../../../../../../core/store/root/root.selectors';
import { eventStartDate } from '../../../../tokens';

@Injectable()
export class EventDetailDataSource {

  eventDetail$ = this.store.pipe(
    select(selectCurrentEvent),
    filter((ev) => ev?.id === this.activatedRoute.snapshot.params.eventId),
    shareReplay(1)
  );
  eventSubscription$ = this.store.pipe(
    select(selectCurrentSubscription),
    filter((sub) => sub?.id === this.activatedRoute.snapshot.params.eventId),
    shareReplay(1)
  );

  virtualEventStartDate$ = this.store.pipe(
    select(selectRouteParam(eventStartDate)),
    shareReplay(1)
  );


  headerData$: Observable<DetailHeaderModel> = combineLatest([
    this.eventSubscription$,
    this.eventDetail$
  ]).pipe(
    map(([subscription, event]) => {
      const count = subscription?.subscriptionCount ? subscription.subscriptionCount : 0;
      return {
        title: event.caption,
        imageUrl: event.imageBig || event.imageSmall,
        description: `${count} ${this.translateService.instant('EVENT_SUBSCRIBER', {count})}`
      };
    })
  );

  constructor(
    private store: Store<RootState>,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute
  ) { }

}
