import { NgModule } from '@angular/core';
import { EmbedLinkComponent } from './embed-link.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { WenPipesModule } from '../../../../../pipes/wen-pipes.module';
import { ThumbnailImageModule } from '@portal/wen-components';

@NgModule({
  imports: [
    CommonModule,
    ThumbnailImageModule,
    WenPipesModule,
    TranslateModule.forChild(),
  ],
  exports: [EmbedLinkComponent],
  declarations: [EmbedLinkComponent],
  providers: [],
})
export class EmbedLinkModule { }
