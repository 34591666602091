import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions } from '@angular/material/checkbox';
import { PollModel, PollOption } from '@portal/wen-backend-api';

interface PollOptionWithCalculations extends PollOption {
  avgVotePercent: number;
}

@Component({
  selector: 'wen-read-only-poll',
  templateUrl: './read-only-poll.component.html',
  styleUrls: ['./read-only-poll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: {
        clickAction: 'noop'
      } as MatCheckboxDefaultOptions
    },
  ]
})
export class ReadOnlyPollComponent {

  pollOptionsWithCalculations: PollOptionWithCalculations[] = [];
  isMultiSelect: boolean;

  @Input() set poll(pollModel: PollModel) {
    this.isMultiSelect = pollModel.multiSelect;
    this.pollOptionsWithCalculations = pollModel.options.map(option => ({
      ...option,
      avgVotePercent: Math.round(option.chooserCount / Math.max(pollModel.choiceCount, 1) * 100)
    }));
  }

}
