import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, filter, first, map, shareReplay, switchMap } from 'rxjs';
import { generateId } from '../../common/generate-id';
import { MediaType } from '../../data-types/embed/embed';
import { EmbedContext, MediaUseCases } from '../../events/events';
import { BACKEND_API_CONFIG, BackendApiConfig } from '../api-providers/api-config';
import { SocketIoService } from '../api-providers/socket-io-service';
import { WE_LOCAL_URL } from './tokens';
import { WeLocalImageUrlTransformator } from './we-local-image-url-transformator';

export type MediaData = {
  id: string;
  publicId?: string;
  thumbnailUrl: string;
  fullUrl: string;
  width: number;
  height: number;
  rawUrl?: string;
  hlsUrl?: string;
  title?: string;
  sizeInBytes?: number;
};

type MediaEndpoints = 'uploadMedia' | 'activatePreview';

@Injectable()
export class WeLocalImageHelper {

  constructor(
    @Inject(WE_LOCAL_URL) private weLocalBaseUrl: string,
    @Inject(BACKEND_API_CONFIG) private backendApiConfig: BackendApiConfig,
    private http: HttpClient,
    private socketIoService: SocketIoService,
    private imageUrlTransformator: WeLocalImageUrlTransformator,
  ) { }

  getImageInSize(mediaUrl: string, targetSize: number) {
    if (!mediaUrl) {
      return null;
    }
    return this.imageUrlTransformator.transform(mediaUrl, { width: targetSize });
  }

  uploadImage(
    file: File,
    contexts: EmbedContext[],
    useCase: MediaUseCases,
    uploadId?: string,
    oneTimeCode?: string,
    subType?: MediaType
  ): Observable<MediaData> {
    if (!Boolean(file)) {
      throw new Error('Upload image must contain a file!');
    }
    return this.handleMediaFile(contexts, useCase, 'uploadMedia', uploadId, file, oneTimeCode, subType);
  }

  activatePreview(
    contexts: EmbedContext[],
    useCase: MediaUseCases,
    uploadId: string,
  ): Observable<MediaData> {
    return this.handleMediaFile(contexts, useCase, 'activatePreview', uploadId);
  }

  private handleMediaFile(
    contexts: EmbedContext[],
    useCase: MediaUseCases,
    mediaEndpoint: MediaEndpoints,
    uploadId?: string,
    file?: File,
    oneTimeCode?: string,
    subType?: MediaType,
  ): Observable<MediaData> {
    const formData = new FormData();
    if (file) {
      formData.append('file', file);
    }
    uploadId = uploadId || generateId();
    formData.append('uploadId', uploadId);
    formData.append('contextsString', JSON.stringify(contexts));
    formData.append('useCase', useCase);
    if (subType) {
      formData.append('subType', subType);
    }
    if (oneTimeCode) {
      formData.append('oneTimeCode', oneTimeCode);
    }

    const upload$ = this.socketIoService.media.upload.listen.pipe(
      shareReplay()
    );

    return this.http.post(`${this.backendApiConfig.mediaServiceBaseUrl}media/${mediaEndpoint}`, formData, {
      headers: { 'Upload-Id': `${uploadId}`, 'Context-Objects': JSON.stringify(contexts) }
    }).pipe(
      switchMap(() => upload$),
      filter(mediaUpload => {
        return mediaUpload.uploadId === uploadId && mediaUpload.mediaProcessed;
      }),
      first(),
      map(({ mediaMetadata: { mediaUrl }, dbMediaMetadata }) => {
        let thumbnailUrl: string;
        if (dbMediaMetadata.subType === MediaType.PICTURE) {
          const isGif = mediaUrl.split('.').pop().includes('gif');
          thumbnailUrl = isGif
            ? mediaUrl
            : dbMediaMetadata.thumbnailUrl;
        } else {
          thumbnailUrl = dbMediaMetadata.thumbnailUrl;
        }
        return {
          thumbnailUrl,
          fullUrl: mediaUrl,
          ...dbMediaMetadata,
        } as MediaData;
      })
    );
  }

}
