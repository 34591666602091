import { CategoriesDTO, RegionsDTO } from '@portal/wen-backend-api';
import { SelectionOption } from '@portal/wen-components';

export const toSelectionOptions = (data: CategoriesDTO | RegionsDTO): SelectionOption<string>[] => {
  return (data || []).map(possible => {
    return {
      id: possible.id,
      value: possible.name,
      text: possible.name
    };
  });
};
