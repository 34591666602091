import { Injectable } from '@angular/core';
import { SocketIoService } from '@portal/wen-backend-api';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { map, shareReplay, startWith } from 'rxjs';
import { ConnectionMonitor, ConnectionScope } from './types';

@Injectable()
export class HealthStatusMonitor implements ConnectionMonitor {

  public readonly connectionState$ = this.socketIoService.system.healthStatus.listen.pipe(
    map(value => value.systemCoreUp),
    smartDistinctUntilChanged(),
    startWith(true),
    shareReplay(1),
    map(isOnline => {
      return {
        isOnline, scope: ConnectionScope.HEALTH_STATUS
      };
    })
  );

  constructor(private readonly socketIoService: SocketIoService) { }
}
