<wen-confirmation-dialog>
  <div wen-confirmation-dialog-custom-content>
    @if (dialogData.image) {
      <img [src]="dialogData.image" alt="">
    }
    <h2 id="dialogHeaderLabel">{{ dialogData.title }}</h2>
    @if (dialogData.message) {
      <div portal-body-2>{{ dialogData.message }}</div>
    }
  </div>
  <button class="wen-confirmation-dialog-wide-action" type="button" tabindex="-1" wen-confirmation-dialog-ok-action (click)="onAction()">
    {{ dialogData.acceptLabel }}
  </button>
</wen-confirmation-dialog>