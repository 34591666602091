import { ChannelGeoFilterDTO } from '@portal/wen-backend-api';
import { Filterable } from '../../../../shared/components/filter-view/models/filter-item';
import { FilterId } from '../../../common/types/filter-id';
import { SearchFilterId } from '../../header/models/HeaderData';

export enum FilterType {
  SEARCH = 'search',
  SELECT = 'select'
}

export type AllFilterIds = FilterId | SearchFilterId;

export interface FilterBase<T extends FilterValueType> {
  filterType: FilterType;
  filterValue: T;
  filterId: AllFilterIds;
}

export interface SearchFilter extends FilterBase<string> {
  filterType: FilterType.SEARCH;
}

export interface SelectFilter extends FilterBase<Filterable> {
  filterType: FilterType.SELECT;
}

export type GeoFilterEntityValue = Filterable<Pick<ChannelGeoFilterDTO, 'geoJson' | 'distance'>>;

export interface GeoFilter extends FilterBase<GeoFilterEntityValue> {
  filterType: FilterType.SELECT;
}

export type FilterValueType = string | Filterable;
export type Filter = SearchFilter | SelectFilter | GeoFilter;

export enum FilterEntityType {
  CHANNEL_LISTS = 'CHANNEL_LISTS',
  CHANNEL_ADD_ADMIN_LISTS = 'CHANNEL_ADD_ADMIN_LISTS',
  CHANNEL_ADD_CONTENT_PROVIDER_LISTS = 'CHANNEL_ADD_CONTENT_PROVIDER_LISTS',
  APP_LISTS = 'APP_LISTS',
  EVENT_LISTS = 'EVENT_LISTS',
  CHAT_LISTS = 'CHAT_LISTS',
  CHANNEL_MESSAGE_FILTER = 'CHANNEL_MESSAGE_FILTER',
  CONTACTS_USER_LIST_FILTER = 'CONTACTS_USER_LIST_FILTER',
}

export const isGeoFilter = (filter: Filter): filter is GeoFilter => {
  const filterValue = filter?.filterValue;
  return Boolean((filterValue as GeoFilterEntityValue)?.filter?.geoJson);
};
