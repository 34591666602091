<div class="wen-explore-header">
  <h1 class="wen-explore-header-label">{{ 'APPS_HEADER_TITLE' | translate }}</h1>
  <div class="wen-explore-header-app-list-user-apps-button" portal-body-2 wen-primary-color
    (click)="navigateToUserList()" [wen-test-id]="'wen-app-explore-user-list-button'">
    <wen-icon iconName="download_active" class="wen-icon-small"></wen-icon>
    <div>{{ 'APP_EXPLORE_MINE' | translate }}</div>
  </div>
</div>
<div class="quick-filters">
  <wen-action class="wen-action-rounded"
              wenVariant="outlined"
              [isNavigating]="true"
              (click)="navigateToDiscoveryList('clearFilter')"
              wen-test-id="wen-app-explore-discover-list-button">
    {{"SHOW_ALL_APP_LABEL" | translate}}
  </wen-action>
</div>
<cdk-virtual-scroll-viewport class="wen-section-wrapper" itemSize="375" scrollStateHandler>
  @for (sectionData of sectionsData$ | async; track sectionData) {
    <wen-app-section
      [dataSource]="sectionData.dataSource"
      [sectionName]="sectionData.name | translate"
      (showAll)="navigateToDiscoveryList(sectionData.sectionProps)">
    </wen-app-section>
  }
</cdk-virtual-scroll-viewport>
