import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { deepEqual } from '@portal/wen-components';
import { Subject, filter, first, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs';
import { FilterId } from '../../../../../../core/common/types/filter-id';
import { WenNavigationHelper } from '../../../../../../core/services/navigation/types';
import { updateGeoFilterForChannel } from '../../../../../../core/store/channel/channel.actions';
import { FilterEntityType } from '../../../../../../core/store/filter/models/filter';
import { RootState } from '../../../../../../core/store/root/public-api';
import { FeedLayoutMediator } from '../../../../../../shared/components/feed/providers/feed-layout-mediator';
import { Filterable } from '../../../../../../shared/components/filter-view/models/filter-item';
import { LocationFormatter } from '../../../../../../shared/pipes/location-formatter.service';
import { GeoFilterValueModel, GeoLocationFilterDataSource } from '../filter-sources/geo-location-filter-datasource';
import { ChannelViewDataSource } from './channel-view-datasource';

@Injectable()
export class GeoChannelExtension implements OnDestroy {

  private onDestroy$ = new Subject<void>();

  get defaultGeoFilter(): Filterable {
    return {
      id: null,
      name: this.translateService.instant('GEO_SELECTOR_OPTION_FALLBACK')
    };
  }

  geoFilterEntityType = FilterEntityType.CHANNEL_MESSAGE_FILTER;
  geoLocationControlName = FilterId.CHANNEL_MESSAGE_GEO_LOCATION;
  filterGroup = new FormGroup({
    [this.geoLocationControlName]: new FormControl()
  });
  private get geoLocationControl() {
    return this.filterGroup.controls[this.geoLocationControlName];
  }

  constructor(
    private readonly datasource: ChannelViewDataSource,
    private readonly store: Store<RootState>,
    private readonly navigationHelper: WenNavigationHelper,
    private readonly geoLocationFilterDataSource: GeoLocationFilterDataSource,
    private readonly translateService: TranslateService,
    private readonly locationFormatter: LocationFormatter,
    private readonly feedLayoutMediator: FeedLayoutMediator,
  ) { }

  initialize() {
    this.datasource.currentChannelId$.pipe(
      switchMap((channelId) => {
        return this.geoLocationFilterDataSource.loadGeoFilter(channelId);
      }),
      takeUntil(this.onDestroy$)
    ).subscribe((geoLocation) => {
      const currentFilter = geoLocation?.geoJson;
      const filterValue: GeoFilterValueModel = currentFilter ? {
        id: null,
        name: this.locationFormatter.format(currentFilter) as string,
        filter: geoLocation
      } : null;
      this.geoLocationControl.setValue(filterValue);
    });

  }

  openPostEditor() {
    this.datasource.isGeoChannel$.pipe(
      first(),
      filter((isGeoChannel) => isGeoChannel),
      switchMap(() => this.datasource.currentChannelId$.pipe(first()))
    ).subscribe((channelId) => {
      this.navigationHelper.navigateToChannelPostEditor(channelId);
    });
  }

  openGeoFilterSelector() {
    const activeGeoFilter: GeoFilterValueModel = this.geoLocationControl.value;
    this.geoLocationFilterDataSource.openFilterSelector(activeGeoFilter).pipe(
      map((dialogResult) => dialogResult?.selectedGeoFilter),
      filter((newValue) => {
        const newDistance = newValue?.distance;
        const oldDistance = activeGeoFilter?.filter?.distance;
        const newGeo = newValue?.geoJson?.geometry?.coordinates;
        const oldGeo = activeGeoFilter?.filter?.geoJson.geometry?.coordinates;
        if (!newGeo && !oldGeo) {
          return false;
        }
        return newDistance !== oldDistance || !deepEqual(newGeo, oldGeo);
      }),
      withLatestFrom(this.datasource.currentChannelId$),
      tap(([newValue, channelId]) => {
        this.store.dispatch(updateGeoFilterForChannel({ channelId, ...newValue }));
      }),
    ).subscribe(() => {
      this.feedLayoutMediator.scrollToBottom();
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
