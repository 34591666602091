import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { PassFilterData, WenNavigationHelper } from '../../../core/services/navigation/types';
import { removeFilter } from '../../../core/store/filter/filter.actions';
import { FilterEntityType } from '../../../core/store/filter/models/filter';
import { RootState } from '../../../core/store/root/public-api';
import { SectionService } from '../../../core/store/sections/sections.facade';
import { reloadEventDateFilters } from '../../../core/store/events/event.actions';
import { AppNavigator } from '../../../core/services/navigation/app-navigator';
import { WenRouteId } from '../../../frame/routing/types';
import { map, Observable } from 'rxjs';
import { FilteredSectionDataSource } from '../../../core/store/sections/datasources/filtered-section-datasource';
import { SectionProps } from '../../../core/common/util/section.model';
import { DataContext } from '../../../core/common/types/data-context';
import { OccurenceEntity } from '../../../core/store/events/event.state';

@Component({
  selector: 'wen-explore-event',
  templateUrl: './explore-event.component.html',
  styleUrls: ['./explore-event.component.scss']
})
export class ExploreEventComponent implements OnInit {

  sectionsData$: Observable<{ dataSource: FilteredSectionDataSource<OccurenceEntity>; name: string; sectionProps: SectionProps }[]> =
    this.sectionService.getAvailableSectionsBy(FilterEntityType.EVENT_LISTS).pipe(
      map(sections => sections.map(section => ({
        dataSource: new FilteredSectionDataSource<OccurenceEntity>(section, DataContext.EVENT, this.sectionService),
        name: section.name,
        sectionProps: {
          filter: section.filter,
          filterEntityType: section.filterType,
          filterId: section.filterId
        }
      })))
    );

  constructor(
    private navigationHelper: WenNavigationHelper,
    private appNavigator: AppNavigator,
    private sectionService: SectionService,
    private store: Store<RootState>,
  ) { }

  ngOnInit(): void {
    // TODO: Remove manual re-load of event dates when proper react native notification arrives about the app state
    this.store.dispatch(reloadEventDateFilters());
    this.store.dispatch(removeFilter({ filterEntityType: FilterEntityType.EVENT_LISTS }));
  }

  navigateToUserEventList() {
    this.appNavigator.navigateToRoute(WenRouteId.EVENT_LIST);
  }

  navigateToEventDiscovery(filterData?: PassFilterData) {
    this.navigationHelper.navigateToEventDiscoveryWithFilter(filterData);
  }

}
