import { Injectable } from '@angular/core';
import { DateTimeProvider } from 'angular-oauth2-oidc';
import { DateUtil } from '../../../common/date/date-util';

@Injectable()
export class OauthDateTimeProvider implements DateTimeProvider {

  private offset = 0;

  setServerTime(serverTime: Date): void {
    const serverTimeMs = serverTime.getTime();
    if (isNaN(serverTimeMs)) {
      return;
    }
    this.offset = serverTimeMs - DateUtil.currentDateMs();
  }

  now(): number {
    return DateUtil.currentDateMs() + this.offset;
  }

  new(): Date {
    return new Date(this.now());
  }
}
