import { WenTestIdModule } from '../../directives/test-id/test-id.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WenIconModule } from '../wen-icon/wen-icon.module';
import { EmojiReactionsComponent } from './emoji-reactions.component';
import { ReactionSelectorComponent } from './components/reaction-selector/reaction-selector.component';
import { EmojiComponent } from './components/emoji.component';
import { ThousandFormatModule } from '../../pipes/thousand-format/thousand-format.module';
import { LegacyContextMenuComponent } from '../context-menu/context-menu.component';
import { ContextMenuTriggerDirective } from '../../services/overlays/openers/context-menu-trigger.directive';
import { EmojiAnimationComponent } from './components/emoji-animation/emoji-animation.component';
import { ActionComponent } from '../core/action/action.component';
import { WithVariantDirective } from '../../directives/styling/with-variant.directive';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    WenIconModule,
    ThousandFormatModule,
    WenTestIdModule,
    LegacyContextMenuComponent,
    ContextMenuTriggerDirective,
    EmojiAnimationComponent,
    ActionComponent,
    WithVariantDirective
  ],
  declarations: [
    EmojiReactionsComponent,
    EmojiComponent,
    ReactionSelectorComponent,
  ],
  exports: [
    EmojiReactionsComponent,
    ReactionSelectorComponent
  ]
})
export class EmojiReactionsModule { }
