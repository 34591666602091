import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SECTION_LIMIT } from '../../../../../core/store/sections/sections.facade';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { combineLatest, first, map, Observable, Subject } from 'rxjs';
import { ActionVariant, ChannelIndicatorComponent, CircleImageViewModule, getChannelIndicatorType, Icon, QuickActionPanelComponent, ThousandFormatModule, WenCardModule, WenIconModule, WenSectionModule, WenTestIdModule } from '@portal/wen-components';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { WenNativeApi } from '@portal/wen-native-api';
import { RootState } from '../../../../../core/store/root/public-api';
import { select, Store } from '@ngrx/store';
import { selectUserChannels } from '../../../../../core/store/channel/channel.selectors';
import { setSubscriptionToChannelById } from '../../../../../core/store/channel/channel.actions';
import { isNullOrUndefined } from '../../../../../core/common/operators/null-check-util';
import { selectUnitedKioskFlatCredential } from '../../../../../core/store/wallet/wallet.selectors';
import { DataSource } from '@angular/cdk/collections';
import { ChannelDTO } from '@portal/wen-backend-api';

type ChannelSectionItemModel = {
  id: string;
  title: string;
  imageUrl?: string;
  subscriptions: number;
  restricted: boolean;
  isPrivate?: boolean;
  geo?: boolean;
  readingContent?: boolean;
  isSubscribed: boolean;
  quickActionIcon: Icon;
  quickActionVariant: ActionVariant;
};

@Component({
  selector: 'wen-channel-section',
  imports: [
    TranslateModule,
    CommonModule,
    WenIconModule,
    WenSectionModule,
    CircleImageViewModule,
    WenCardModule,
    ThousandFormatModule,
    WenTestIdModule,
    ChannelIndicatorComponent,
    QuickActionPanelComponent,
  ],
  templateUrl: './channel-section.component.html',
  styleUrls: ['./channel-section.component.scss'],
  standalone: true
})
export class ChannelSectionComponent implements OnDestroy {

  private onDestroy$ = new Subject<void>();
  private innerDataSource: DataSource<ChannelDTO>;

  @Input() sectionName: string;
  sectionItems$: Observable<ChannelSectionItemModel[]>;
  imageSize = { height: 110 };
  sectionLimit = SECTION_LIMIT;

  @Input() set dataSource(dataSource: DataSource<ChannelDTO>) {
    if (this.innerDataSource) {
      this.innerDataSource.disconnect(null);
    }
    this.innerDataSource = dataSource;
    this.sectionItems$ = combineLatest([
      this.store.pipe(select(selectUserChannels)),
      this.innerDataSource.connect(null),
      this.store.pipe(select(selectUnitedKioskFlatCredential))
    ]).pipe(
      map(([userChannels, sectionChannelItems, unitedKioskFlatCredential]) => {
        const channelWithOpenInvites = userChannels.filter(c => c.invite).map(c => c.id);
        const userChannelIds = userChannels.filter(c => isNullOrUndefined(c.invite)).map(c => c.id);
        const channels = sectionChannelItems?.map((channel) => {
          const isSubscribed = userChannelIds?.includes(channel.id);
          const isInvited = channelWithOpenInvites?.includes(channel.id);
          return {
            ...channel,
            imageUrl: channel.icon,
            isSubscribed,
            quickActionIcon: this.getQuickActionIcon(channel, isSubscribed, isInvited, unitedKioskFlatCredential.active),
            quickActionVariant: this.getQuickActionVariant(channel, isSubscribed, isInvited)
          };
        });
        return channels;
      })
    );
  }

  @Output() showAll = new EventEmitter();

  isNativeApp = this.wenNativeApi.isReactNativeApp();

  constructor(
    private navigationHelper: WenNavigationHelper,
    private wenNativeApi: WenNativeApi,
    private store: Store<RootState>,
    private translateService: TranslateService
  ) { }

  navigateToChannelView(channelId: string){
    this.navigationHelper.navigateToChannelView(channelId);
  }


  navigateToChannelDetail(channelId: string){
    this.navigationHelper.navigateToChannelDetail(channelId);
  }

  showAllElements() {
    this.showAll.emit();
  }

  onQuickActionClick(channel: ChannelSectionItemModel) {
    this.store.pipe(select(selectUnitedKioskFlatCredential), first()).subscribe(
      (unitedKioskFlatCredential) => {
        if(channel.isPrivate && !(channel.restricted && channel.readingContent && unitedKioskFlatCredential.active)) {
          this.navigateToChannelDetail(channel.id);
          return;
        }

        this.store.dispatch(setSubscriptionToChannelById({ channelId: channel.id, setSubscribed: !channel.isSubscribed }));
      }
    );
  }

  ngOnDestroy(): void {
    this.innerDataSource.disconnect(null);
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  trackById(index, item: ChannelSectionItemModel) {
    return item.id;
  }

  getIndicatorType(channel: ChannelSectionItemModel) {
    return getChannelIndicatorType(channel.geo, channel.readingContent, channel.restricted, channel.isPrivate);
  }

  private getQuickActionIcon(channel: ChannelDTO, isSubscribed: boolean, isInvited: boolean, hasUKFlatCredential: boolean): Icon {
    if (isSubscribed) {
      return { name: 'save_active', altText: this.translateService.instant('CHANNEL_UNSUBSCRIBE') };
    }
    if (channel.isPrivate && channel.readingContent && channel.restricted && hasUKFlatCredential) {
      return { name: 'save_inactive', altText: this.translateService.instant('CHANNEL_SUBSCRIBE') };
    }
    if (channel.isPrivate && !isInvited) {
      return { name: 'lock', altText: '' };
    }
    return { name: 'save_inactive', altText: this.translateService.instant('CHANNEL_SUBSCRIBE') };
  }

  private getQuickActionVariant(channel: ChannelDTO, isSubscribed: boolean, isInvited: boolean): ActionVariant {
    if (isInvited || (channel.isPrivate && !isSubscribed)) {
      return ActionVariant.textSubtle;
    }

    return ActionVariant.text;
  }
}
