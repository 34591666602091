import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent, ConfirmationDialogData, OverlayManager } from '@portal/wen-components';

@Injectable()
export class SignInInviteDialogOpener {
  constructor(
    private overlayManager: OverlayManager,
    private translateService: TranslateService,
  ) { }

  openInviteSuccessDialog() {
    const dialogDataSuccess: Partial<ConfirmationDialogData> = {
      headerImage: '/assets/wen-widget/icons/success.svg',
      okLabel: this.translateService.instant('EVERYTHING_IS_CLEAR_BUTTON_LABEL'),
      content: this.translateService.instant('INVITE_NETWORK_CONFIRMATION_SUCCESS')
    };
    this.overlayManager.dialog.openConfirmationDialog(ConfirmationDialogComponent, dialogDataSuccess, { disableClose: true });
  }

  openInviteFailureDialog() {
    const dialogDataNotSuccess: Partial<ConfirmationDialogData> = {
      headerImage: '/assets/wen-widget/icons/error_robot.svg',
      okLabel: this.translateService.instant('EVERYTHING_IS_CLEAR_BUTTON_LABEL'),
      content: this.translateService.instant('INVITE_NETWORK_CONFIRMATION_ERROR')
    };
    this.overlayManager.dialog.openConfirmationDialog(ConfirmationDialogComponent, dialogDataNotSuccess, { disableClose: true });
  }
}
