import { select, Store } from '@ngrx/store';
import { map, Observable, of } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { WenBreakpointObserver } from '@portal/wen-components';
import { RootState } from '../../store/root/public-api';
import { selectCurrentChannel } from '../../store/channel/channel.selectors';
import { firstExisty } from '../../common/operators/first-existy';
import { upsertHeaderData } from '../../store/header/header.actions';
import { DataContext } from '../../common/types/data-context';
import { selectCurrentRoom } from '../../store/chat/chat.selectors';
import { selectCurrentUserData } from '../../store/auth/auth.selectors';
import { ChannelEntity } from '../../store/channel/channel.state';
import { selectCurrentAppDetail } from '../../store/apps/apps.selectors';

@Injectable()
export class HeaderDataHelper {
  private store = inject(Store<RootState>);
  private breakpointObserver = inject(WenBreakpointObserver);

  initializeContextTitleWithChannelTitle(onlyForSmallDevices: boolean, channel$?: Observable<ChannelEntity>): void {
    if (onlyForSmallDevices && !this.breakpointObserver.isSmallDevice) {
      return;
    }

    if (!channel$) {
      channel$ = this.store.pipe(select(selectCurrentChannel));
    }

    channel$.pipe(
      firstExisty(),
    ).subscribe(channel => {
      this.store.dispatch(upsertHeaderData({
        headerData: { contextTitle: channel?.title }
      }));
    });
  }

  initializeContextTitleWithAppTitle(onlyForSmallDevices: boolean, app$: Observable<string>): void {
    if (onlyForSmallDevices && !this.breakpointObserver.isSmallDevice) {
      return;
    }

    if (!app$) {
      app$ = this.getDefaultContextTitle(DataContext.APP);
    }
    app$.pipe(
      firstExisty()
    ).subscribe(appTitle => {
      this.store.dispatch(upsertHeaderData({
        headerData: { contextTitle: appTitle }
      }));
    });
  }

  initializeContextTitleWithChatTitle(onlyForSmallDevices: boolean, chatTitle$?: Observable<string>) {
    if (onlyForSmallDevices && !this.breakpointObserver.isSmallDevice) {
      return;
    }
    if (!chatTitle$) {
      chatTitle$ = this.getChatRoomTitle();
    }
    chatTitle$.pipe(
      firstExisty()
    ).subscribe((chatTitle) => {
      this.store.dispatch(upsertHeaderData({
        headerData: { contextTitle: chatTitle }
      }));
    });

  }

  initializeContextTitleWithUserName(onlyForSmallDevices: boolean, userName$?: Observable<string>): void {
    if (onlyForSmallDevices && !this.breakpointObserver.isSmallDevice) {
      return;
    }
    if (!userName$) {
      userName$ = this.getDefaultContextTitle(DataContext.PROFILE);
    }
    userName$.pipe(
      firstExisty()
    ).subscribe(profileTitle => {
      this.store.dispatch(upsertHeaderData({
        headerData: { contextTitle: profileTitle }
      }));
    });
  }

  /*
  * Get the context title according to the data context.
  * For example in the channel data context, the default context title is the channel title.
  * */
  getDefaultContextTitle(dataContext: DataContext): Observable<string> {
    switch (dataContext) {
      case DataContext.CHANNEL:
        return this.getChannelTitle();
      case DataContext.CHAT:
        return this.getChatRoomTitle();
      case DataContext.APP:
        return this.getAppTitle();
      case DataContext.PROFILE:
        return this.getProfileTitle();
      default:
        return of('');
    }
  }

  private getChannelTitle(): Observable<string> {
    return this.store.pipe(
      select(selectCurrentChannel),
      firstExisty(),
      map(channel => channel?.title)
    );
  }

  private getChatRoomTitle(): Observable<string> {
    return this.store.pipe(
      select(selectCurrentRoom),
      firstExisty(),
      map(room => room?.details?.title)
    );
  }

  private getAppTitle(): Observable<string> {
    return this.store.pipe(
      select(selectCurrentAppDetail),
      firstExisty(),
      map(app => app.title)
    );
  }

  private getProfileTitle(): Observable<string> {
    return this.store.pipe(
      select(selectCurrentUserData),
      firstExisty(),
      map(user => user?.username)
    );
  }
}
