import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { firstExisty } from '../../../../../core/common/operators/first-existy';
import { createRoomFromCRM } from '../../../../../core/store/smartdesign/smartdesign.actions';
import { userProfileIdentifier } from '../../../../user-profile/tokens';

@Component({
  selector: 'wen-initiate-chat',
  templateUrl: './initiate-chat.component.html',
  styleUrls: ['./initiate-chat.component.scss']
})
export class InitiateChatComponent {

  private readonly route = inject(ActivatedRoute);
  private readonly store = inject(Store);

  userId$ = this.route.paramMap.pipe(
    map(paramMap => paramMap.get(userProfileIdentifier))
  );

  createAndNavigateToRoom() {
    this.userId$.pipe(
      firstExisty()
    ).subscribe(userId => {
      this.store.dispatch(createRoomFromCRM({ targetUserId: userId }));
    });
  }
}
