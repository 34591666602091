import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { WenIconComponent } from './wen-icon.component';
import { WithVariantDirective } from '../../directives/styling/with-variant.directive';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    WithVariantDirective
  ],
  declarations: [
    WenIconComponent
  ],
  exports: [
    WenIconComponent
  ]
})
export class WenIconModule { }
