import { DataContext } from '../../../core/common/types/data-context';
import { appInitGuardFn } from '../../../core/services/navigation/app-init-guard';
import { authGuardFn } from '../../../core/services/user-management/auth.guard';
import { onboardingAnonymousAutoLoginGuard } from '../../../core/services/user-management/onboarding-anonymous-auto-login.guard';
import { onboardingAuthGuard } from '../../../core/services/user-management/onboarding-auth.guard';
import { preventLogoutGuardFn } from '../../../core/services/user-management/prevent-logout-guard';
import { registrationGuardFn } from '../../../core/services/user-management/registration-guard';
import { singleOutletAppRoutes } from '../../../views/apps/routes/single-outlet-apps.routes';
import { singleOutletChannelRoutes, singleOutletMessageRoutes } from '../../../views/channel/routes/single-outlet-channel.routes';
import { chatRootGuardFn } from '../../../views/chat/routes/chat-root.guard';
import { singleOutletChatRoutes } from '../../../views/chat/routes/single-outlet-chat.routes';
import { singleOutletContactsRoutes } from '../../../views/contacts/routes/single-outlet-contacts.routes';
import { singleOutletCrmRoutes } from '../../../views/crm/routes/single-outlet-crm.routes';
import { embeddedPageRoutes } from '../../../views/embedded-page/embedded-page.routes';
import { ErrorPageModule } from '../../../views/error-page/error-page.module';
import { singleOutletEventRoutes } from '../../../views/events/routes/single-outlet-events.routes';
import { exclusiveInviteRoutes } from '../../../views/exclusive-invite/routes/exclusive-invite.routes';
import { singleOutletHomeRoutes } from '../../../views/home/routes/single-outlet-home.routes';
import { singleOutletInviteRoutes } from '../../../views/invite/routes/single-outlet-invite.routes';
import { LoginModule } from '../../../views/login/login.module';
import { singleOutletonboardingRoutes } from '../../../views/onboarding/routes/single-outlet-onboarding.routes';
import { ReaderModule } from '../../../views/reader/reader.module';
import { singleOutletRegistrationRoutes } from '../../../views/registration/routes/single-outlet-registration.routes';
import { singleOutletSignInRoutes } from '../../../views/sign-in/routes/single-outlet-sign-in.routes';
import { singleOutletUserProfileRoutes } from '../../../views/user-profile/routes/single-outlet-user-profile.routes';
import { networkRoutes } from '../../network/network.routes';
import { WenRoutesCollection } from '../types';

export const wenSingleOutletRoutes = (
  startPage: string
): WenRoutesCollection => [
    {
      path: 'error',
      loadChildren: () => ErrorPageModule,
    },
    {
      path: '',
      children: [
        {
          path: 'oauth',
          loadChildren: () => LoginModule
        },
        {
          path: 'register',
          children: singleOutletRegistrationRoutes,
          canActivate: [registrationGuardFn],
          data: {
            appBannerDisabled: true
          }
        },
        {
          path: 'onboarding',
          children: singleOutletonboardingRoutes,
        },
        {
          path: 'signin',
          children: singleOutletSignInRoutes,
          data: {
            appBannerDisabled: true
          }
        },
        {
          path: 'home',
          children: singleOutletHomeRoutes,
          canActivateChild: [authGuardFn],
          canDeactivate: [preventLogoutGuardFn]
        },
        {
          path: 'channel',
          children: singleOutletChannelRoutes,
          canActivateChild: [authGuardFn],
          data: {
            dataContext: DataContext.CHANNEL
          }
        },
        {
          path: 'message',
          children: singleOutletMessageRoutes,
          canActivateChild: [onboardingAnonymousAutoLoginGuard],
          data: {
            dataContext: DataContext.CHANNEL
          }
        },
        {
          path: 'apps',
          children: singleOutletAppRoutes,
          canActivateChild: [authGuardFn],
          data: {
            dataContext: DataContext.APP
          }
        },
        {
          path: 'contacts',
          children: singleOutletContactsRoutes,
          canActivateChild: [authGuardFn],
          data: {
            dataContext: DataContext.CONTACTS
          }
        },
        {
          path: 'profile',
          children: singleOutletUserProfileRoutes,
          canActivateChild: [authGuardFn],
          data: {
            dataContext: DataContext.PROFILE
          }
        },
        {
          path: 'event',
          children: singleOutletEventRoutes,
          canActivateChild: [onboardingAnonymousAutoLoginGuard],
          data: {
            dataContext: DataContext.EVENT
          }
        },
        {
          path: 'chat',
          children: singleOutletChatRoutes,
          canActivateChild: [authGuardFn, chatRootGuardFn],
          data: {
            dataContext: DataContext.CHAT
          }
        },
        {
          path: 'embedded-page',
          children: embeddedPageRoutes,
          canActivateChild: [authGuardFn]
        },
        {
          path: 'invite',
          children: singleOutletInviteRoutes,
          canActivateChild: [authGuardFn]
        },
        {
          path: 'crm',
          children: singleOutletCrmRoutes,
          canActivateChild: [authGuardFn],
        },
        {
          path: 'reader',
          canActivateChild: [authGuardFn],
          loadChildren: () => ReaderModule,
        },
        {
          path: 'exclusive-invite',
          children: exclusiveInviteRoutes,
          canActivateChild: [authGuardFn],
          data: {
            appBannerDisabled: true
          }
        },
        {
          path: 'network',
          canActivateChild: [onboardingAuthGuard],
          children: networkRoutes
        },
        {
          path: 'wallet',
          loadChildren: () => import('../../../views/wallet/wallet.routes').then(m => m.primaryWalletRoutes)
        },
        {
          path: '**',
          canActivate: [appInitGuardFn],
          children: []
        }
      ]
    },
  ];
