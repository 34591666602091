import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EmbeddedLocation } from '../../../../../core/store/channel/channel.state';

@Component({
  selector: 'wen-location-list-item',
  templateUrl: './location-list-item.component.html',
  styleUrls: ['./location-list-item.component.scss']
})
export class LocationListItemComponent {

  private translateService = inject(TranslateService);

  @Input() model: EmbeddedLocation;
  @Input() searchTerm: string;
  @Output() prefillActionClicked = new EventEmitter<EmbeddedLocation>();
  altText = this.translateService.instant('LOCATION_SELECTOR_COPY_TO_SEARCH_LABEL');

  onPrefillActionClicked(event: Event) {
    event.stopPropagation();
    this.prefillActionClicked.emit(this.model);
  }

}
