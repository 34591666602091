import { Injectable } from '@angular/core';
import { EmbedDTOType, EmbeddedForwardHandler, EmbeddedMediaHandler, RoomMessageDTO, SendMessageContent, generateId } from '@portal/wen-backend-api';
import { ChatMessageTransactionData } from '../types/chat-transaction-data';


export class ChatMessageTransactionPreviewGenerator {

  constructor(
    private embedMediaHandler: EmbeddedMediaHandler,
    private embedForwardHandler: EmbeddedForwardHandler
  ) { }

  createPreview(data: ChatMessageTransactionData): ChatMessageTransactionData {
    const { roomMessage } = data;
    const { embeds } = roomMessage.message;
    const previewEmbeds = embeds.map((embed) => {
      switch (embed.type) {
        case EmbedDTOType.MEDIA:
          const mediaEmbedPreview = this.embedMediaHandler.prepareForPreview(embed);
          return mediaEmbedPreview;
        case EmbedDTOType.FORWARD:
          const forwardPreview = this.embedForwardHandler.prepareForPreview(embed);
          return forwardPreview;
        case EmbedDTOType.POLL:
          const id = generateId();
          return {
            ...embed,
            pollId: id,
            poll: {
              ...embed.poll,
              id
            }
          };
        default:
          return embed;
      }
    });
    const message: RoomMessageDTO = {
      ...roomMessage.message,
      embeds: previewEmbeds
    };
    const content: SendMessageContent<false> = {
      message
    };
    return {
      ...data,
      roomMessage: content
    };
  }
}

@Injectable()
export class MessagePreviewGeneratorFactory {

  create(
    embedMediaHandler: EmbeddedMediaHandler,
    embedForwardHandler: EmbeddedForwardHandler
  ) {
    return new ChatMessageTransactionPreviewGenerator(embedMediaHandler, embedForwardHandler);
  }
}
