import { APP_INITIALIZER, inject, Provider } from '@angular/core';
import { NativeBridge } from '@portal/react-native-api';
import { IS_REACT_NATIVE_ROOT, WenNativeApi } from '@portal/wen-native-api';
import { Tracer, TracerInitializer } from '@portal/wen-tracer';
import { catchError, EMPTY, firstValueFrom, switchMap } from 'rxjs';
import { ShareMenuHandler } from '../navigation/share-menu/share-menu-handler';
import { NativeConnector } from './native-connector';
import { ReactNativeWrapper } from './react-native-wrapper';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function nativeWrapperFactory() {
  return IS_REACT_NATIVE_ROOT ? new ReactNativeWrapper(new NativeBridge()) : null;
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function nativeConnectorInitializer() {
  const nativeConnector = inject(NativeConnector);
  const tracer = inject(Tracer);
  const tracerInitializer = inject(TracerInitializer);
  return () => {
    if (IS_REACT_NATIVE_ROOT) {
      return firstValueFrom(nativeConnector.connect().pipe(
        catchError((error) => {
          return tracerInitializer.scheduleLogWhenReady().pipe(
            switchMap(() => {
              tracer.addBreadcrumb({ category: 'native', level: 'error', message: 'Native connector init failed!' });
              tracer.captureException(error);
              return EMPTY;
            })
          );
        })
      ));
    }
    return Promise.resolve();
  };
}

export const nativeAppProviders: Provider[] = [
  NativeConnector,
  WenNativeApi,
  ShareMenuHandler,
  {
    provide: ReactNativeWrapper,
    useFactory: nativeWrapperFactory
  },
  {
    provide: APP_INITIALIZER,
    useFactory: nativeConnectorInitializer,
    multi: true
  }
];
