import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { LinkOpener, Thumbnail } from '@portal/wen-components';
import { EmbeddedLinkDTO } from '@portal/wen-backend-api';
import { isNullOrUndefined } from '../../../../../../core/common/operators/null-check-util';
import { filter, map, Observable, ReplaySubject, switchMap } from 'rxjs';
import { ThumbnailSizeHelperProvider } from '@portal/wen-components';

@Component({
  selector: 'wen-embed-link',
  templateUrl: './embed-link.component.html',
  styleUrls: ['./embed-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmbedLinkComponent implements OnInit {

  linkViewModel: EmbeddedLinkDTO;
  linkOpenerTranslationKey: string;

  linkViewModel$ = new ReplaySubject<EmbeddedLinkDTO>(1);
  viewParams$ = this.linkViewModel$.pipe(
    map(({ thumbnail }) => thumbnail),
    filter(thumbnail => !!thumbnail),
    switchMap(model => this.thumbnailSizeHelper.calculateSize(model.width, model.height).pipe(
      map(config => ({ url: model.url, ...config })),
    )),
  );
  thumbnail$: Observable<Thumbnail> = this.viewParams$.pipe(
    map(({ url, scaledDimensions: { width, height } }) => {
      return {
        url,
        width,
        height
      };
    })
  );
  minWidth$: Observable<number> = this.viewParams$.pipe(
    map(({ minWidth }) => minWidth)
  );

  @HostBinding('class.wen-embedded-link') className = true;

  @Input() set model(value: EmbeddedLinkDTO) {
    this.linkViewModel = value;
    this.linkViewModel$.next(value);
    if (!isNullOrUndefined(value)) {
      this.linkOpenerTranslationKey = this.linkOpener.evaluateOpenerLabel(value.url);
    }
  }

  constructor(
    private linkOpener: LinkOpener,
    private thumbnailSizeHelper: ThumbnailSizeHelperProvider,
  ) { }

  ngOnInit(): void {
  }

  openUrl() {
    const { url } = this.linkViewModel;
    this.linkOpener.openUrl(url);
  }

}
