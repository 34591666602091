import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, combineLatest, distinctUntilChanged, filter, first, map, of, startWith, switchMap, takeUntil } from 'rxjs';
import { ChannelEntity } from '../../../core/store/channel/channel.state';
import { RootState } from '../../../core/store/root/public-api';
import { selectRouteParam } from '../../../core/store/root/root.selectors';
import { CommentMessageSendFieldDataSource } from '../../../shared/components/comments/comment-message-send-field/comment-message-send-field-data-source';
import { CommentListPagingHelper } from '../../../shared/components/comments/providers/comment-list-paging-helper';
import { FeedDatasource } from '../../../shared/components/feed/providers/feed-datasource';
import { FeedLayoutMediator } from '../../../shared/components/feed/providers/feed-layout-mediator';
import { MessageSendFieldDataSource } from '../../../shared/components/message-send-field/providers/message-send-field-data-source';
import { MessageSeparatorDateFormatter, PastMessageSeparatorDateFormatter } from '../../../shared/directives/directives/message-separator-formatters';
import { channelMessageDetailIdentifier } from '../tokens';
import { MessageDetailsExtras } from './guards/channel-message-detail.guard';
import { ChannelMessageDetailDatasource } from './providers/channel-message-detail.datasource';
import { MessageBoxActionsHandler } from '../../../shared/components/message-box/providers/message-box-tokens';
import { ChannelMessageBoxActionsHandler } from '../common/message-box-actions/channel-message-box-actions-handler';
import { LocationStrategy } from '@angular/common';
import { WenPathLocationStrategy } from '@portal/wen-components';
import { ChannelMessageDetailHeaderDataUpder } from '../../../shared/channel-specific/providers/channel-message-detail-header-data-updater';

@Component({
  selector: 'wen-channel-message-detail',
  templateUrl: './channel-message-detail.component.html',
  styleUrls: ['./channel-message-detail.component.scss'],
  providers: [
    CommentListPagingHelper,
    { provide: MessageSendFieldDataSource, useClass: CommentMessageSendFieldDataSource },
    {
      provide: FeedDatasource,
      useClass: ChannelMessageDetailDatasource
    },
    {
      provide: MessageBoxActionsHandler,
      useClass: ChannelMessageBoxActionsHandler
    },
    FeedLayoutMediator,
    {
      provide: MessageSeparatorDateFormatter,
      useClass: PastMessageSeparatorDateFormatter
    },
    ChannelMessageDetailHeaderDataUpder
  ]
})
export class ChannelMessageDetailComponent implements OnInit, AfterViewInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  errorState: MessageDetailsExtras;
  messageId$: Observable<string>;
  currentChannel$: Observable<ChannelEntity>;
  showCommentButton$: Observable<boolean>;
  navigatedFromDifferentContext: boolean;

  constructor(
    private readonly store: Store<RootState>,
    @Inject(FeedDatasource) private readonly datasource: ChannelMessageDetailDatasource,
    private readonly router: Router,
    private headerDataUpdater: ChannelMessageDetailHeaderDataUpder,
    @Inject(LocationStrategy) private readonly locationStrategy: WenPathLocationStrategy,
  ) {

    const locationState = this.locationStrategy.getState();
    this.navigatedFromDifferentContext = locationState.fromDeepLink || locationState.fromInternalDeepLink;

    this.store.pipe(
      select(selectRouteParam(channelMessageDetailIdentifier)),
      distinctUntilChanged(),
      switchMap(() => this.router.events.pipe(
        filter(e => e instanceof NavigationEnd),
        first()
      )),
      startWith(null),
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.errorState = history.state?.messageDetailExtras as MessageDetailsExtras;
      this.showCommentButton$ = of(false);
    });
  }

  ngOnInit() {
    this.datasource.initialize();
    this.currentChannel$ = this.datasource.channel$.pipe(first());
    this.headerDataUpdater.initialize();
  }

  ngAfterViewInit() {
    if (!this.errorState) {
      this.messageId$ = this.datasource.message$.pipe(
        map(message => message.id)
      );

      this.showCommentButton$ = combineLatest([
        this.messageId$,
        this.datasource.commentsEnabled$
      ]).pipe(
        map(([messageId, commentsEnabled]) => messageId && commentsEnabled)
      );
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
