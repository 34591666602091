import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { PassFilterData, WenNavigationHelper } from '../../../../core/services/navigation/types';
import { DataContext } from '../../../../core/common/types/data-context';
import { SectionService } from '../../../../core/store/sections/sections.facade';
import { map, Observable } from 'rxjs';
import { FeaturedSectionConfig } from '@portal/wen-backend-api';
import { SharedModule } from '../../../../shared/shared.module';
import { ChannelSectionComponent } from '../../../channel/channel-explore/components/channel-section/channel-section.component';
import { AppExploreModule } from '../../../apps/app-explore/app-explore.module';
import { EventSectionModule } from '../../../events/event-explore/components/event-section/event-section.module';
import { FilteredSectionDataSource } from '../../../../core/store/sections/datasources/filtered-section-datasource';
import { SectionProps } from '../../../../core/common/util/section.model';

@Component({
  selector: 'wen-generic-section',
  templateUrl: './generic-section.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    SharedModule,
    ChannelSectionComponent,
    AppExploreModule,
    EventSectionModule,
  ],
})
export class GenericSectionComponent implements OnInit {
  @Input() config?: FeaturedSectionConfig;

  protected readonly DataContext = DataContext;

  protected sectionData$: Observable<{ dataSource: FilteredSectionDataSource; name: string; sectionProps: SectionProps }>;

  constructor(
    private readonly navigationHelper: WenNavigationHelper,
    private readonly sectionService: SectionService,
  ) {}

  ngOnInit(): void {
   this.sectionData$ = this.sectionService.getSectionWithDifferentTitle(this.config.sectionTextKey, this.config?.id).pipe(
     map(section => {
       return {
         dataSource: new FilteredSectionDataSource(section, this.config.context as DataContext, this.sectionService),
         name: section.name,
         sectionProps: {
           filter: section.filter,
           filterEntityType: section.filterType,
           filterId: section.filterId
         }
       };
     })
   );
  }

  protected navigateToChannelDiscovery(filterData?: PassFilterData): void {
    this.navigationHelper.navigateToChannelDiscovery(filterData);
  }

  protected navigateToAppDiscovery(filterData?: PassFilterData): void {
    this.navigationHelper.navigateToAppDiscoverWithFilter(filterData);
  }

  protected navigateToEventDiscovery(filterData?: PassFilterData): void {
    this.navigationHelper.navigateToEventDiscoveryWithFilter(filterData);
  }
}
