<form wenFormStore class="wen-form" [formGroup]="userChannelRecommendationsFormGroup">
  <div class="wen-form-group">
    <div class="wen-selector-form-group">
      <wen-selection-list-mass-data-viewer [datasource]="datasource" [itemSize]="56" [formControl]="selectedRecommendedChannels">
        <ng-container wen-list-mass-data-empty>
          <wen-empty-list
            src="/assets/wen-widget/image/empty-channel-list-background.svg"
            titleLabel="CHANNEL_RECOMMENDATIONS_LIST_EMPTY_HEADER"
            textLabel="CHANNEL_RECOMMENDATIONS_LIST_EMPTY_DESCRIPTION"/>
        </ng-container>
        <ng-template wen-selection-list-mass-data-item let-data let-isSelected="isSelected" let-index="index">
          @if (!index) {
            <div class="wen-form-field-label" portal-subheading-1>{{ 'USER_PROFILE_CHANNEL_RECOMMENDATIONS_CHOOSE' | translate }}</div>
          }
          <div [wen-selection-list-mass-data-item-selectable]="data" class="wen-user-recommendation-list-item">
            <div class="wen-user-recommendation-list-item-label">
              <wen-circle-image-view [imageUrl]="data.icon" [placeholderText]="data.title" />
              <span>{{ data.title }}</span>
            </div>
            <mat-checkbox [disableRipple]="'true'" [checked]="isSelected" />
          </div>
        </ng-template>
      </wen-selection-list-mass-data-viewer>
    </div>
    <div class="wen-form-group-hint" portal-subheading-2>{{ 'USER_PROFILE_CHANNEL_RECOMMENDATIONS_HINT' | translate }}</div>
  </div>
</form>
