import { Injectable } from '@angular/core';
import { eventStartDate, eventViewIdentifier } from '../../../../../views/events/tokens';
import { matchFirstRegExpGroup } from '../../../../common/util/match-first-regexp';
import { eventMatcher } from '../deep-link-regexes';
import { DeepLinkable } from '../deep-linkable';

@Injectable()
export class EventDeepLinkable extends DeepLinkable {

  isMatchForUrl(link: string) {
    return Boolean(this.extractRouteLink(link));
  }

  extractRouteLink(link: string) {
    const match = matchFirstRegExpGroup(link, eventMatcher());
    return match;
  }

  getDeepLink() {
    const eventId = this.extractOutletParam(eventViewIdentifier);
    const startDate = this.extractOutletParam(eventStartDate);
    if (!eventId || !startDate) {
      return null;
    }
    return `${this.rootUrl}/event/${eventId}/${startDate}`;
  }

  getOpenerTranslation(): string {
    return 'EMBED_OPEN_EVENT_LINK';
  }

}
