<wen-confirmation-dialog class="event-ical-event-picker">
  <div wen-confirmation-dialog-custom-content>
    <div class="wen-form-field-label">{{ 'EVENT_RECUR_PICKER_TEXT' | translate }}</div>
    <wen-selection-list class="wen-selection-list with-text-wrap-preserve-whitespace event-ica-event-picker-selection-list"
      [selectionItems]="recurItems"
      [selectionModel]="recurModel"
      [selectionOptionPosition]="'postfix'"
      [selectionOptionBorderType]="'borderless'"
      [allowEmptyList]="false" (selectionChanged)="onSelectionChanged($event)">
    </wen-selection-list>
  </div>
</wen-confirmation-dialog>