<div class="wen-form">
  <div class="wen-form-group wen-single-group">
    <div class="wen-form-field-label" portal-caption>{{ 'CHANNEL_SUBSCRIBERS_TITLE' | translate }}</div>
    <div class="wen-form-field-scroller">
      @for (listItem of subscriberList$ | async; track listItem) {
        <wen-user-list-item [user]="listItem.user" [userNavigable]="listItem.user.userId">
          <wen-user-role [userRole]="listItem.user.roleId" wen-user-description></wen-user-role>
          @if (listItem.hasMenu) {
            <wen-action wenVariant="textSubtle" class="wen-action-no-padding" iconName="more" [altText]="'MORE_OPTION_LABEL' | translate" (click)="openContextMenu(listItem.user.userId)" wen-user-after></wen-action>
          }
        </wen-user-list-item>
      }
      @if (anonymousUserCount > 0) {
        <div class="wen-anonymous-user-list-item">
          {{ anonymousUserCount }} {{ anonymousUserDisplayMessage }}
          <wen-action wenVariant="textSubtle" class="wen-action-no-padding" iconName="more" [altText]="'MORE_OPTION_LABEL' | translate" (click)="openContextMenuAnonymous()" wen-user-after></wen-action>
        </div>
      }
    </div>
  </div>
</div>
