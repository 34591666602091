import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { PollModel } from '@portal/wen-backend-api';
import { distinctUntilChanged, filter, first, map, Observable, ReplaySubject, shareReplay, switchMap } from 'rxjs';
import { WenSelectionListComponent } from '../../../../wen-selection-list/wen-selection-list.component';
import { PollManager } from '../../../providers/poll-manager';

@Component({
  selector: 'wen-poll-representation',
  templateUrl: './poll-representation.component.html',
  styleUrls: ['./poll-representation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PollRepresentationComponent implements OnInit {

  private hasActiveSelection = false;
  private activeSelections: string[] = [];

  pollModel$ = new ReplaySubject<PollModel>(1);
  isReadonlyMode$: Observable<boolean>;
  selectionModel$: Observable<SelectionModel<WenSelectionListComponent<null>>>;

  @HostBinding('class.wen-embed-poll') className = true;
  @HostBinding('class.wen-poll-disabled') get disabledClassName() {
    return !this.hasActiveSelection;
  }

  @Input() set model(value: PollModel) {
    this.pollModel$.next(value);
  }

  constructor(
    private pollManager: PollManager
  ) { }

  ngOnInit(): void {
    const viewStateUpdates$ = this.pollModel$.pipe(
      switchMap(pollModel => this.pollManager.getPollViewUpdatesFor(pollModel.id)),
      distinctUntilChanged(),
      shareReplay(1)
    );

    this.selectionModel$ = this.pollModel$.pipe(
      switchMap(pollModel => {
        return viewStateUpdates$.pipe(
          filter(viewState => viewState === 'voteMode'),
          first(),
          map(() => new SelectionModel<WenSelectionListComponent<null>>(pollModel.multiSelect))
        );
      })
    );

    this.isReadonlyMode$ = viewStateUpdates$.pipe(
      map(viewState => viewState === 'resultMode'),
      distinctUntilChanged()
    );
  }

  onSelectionChange(latestSelectedIds: string[]) {
    this.activeSelections = [...latestSelectedIds];
    this.hasActiveSelection = this.activeSelections.length > 0;
  }

  sendPollSelection() {
    this.pollModel$.pipe(
      first(),
      filter(() => this.hasActiveSelection)
    ).subscribe(pollModel => {
      const optionForPoll = {
        pollId: pollModel.id,
        optionIds: this.activeSelections
      };
      this.pollManager.chooseOption(optionForPoll);
    });
  }
}
