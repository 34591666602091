import { Component, ElementRef, EventEmitter, HostBinding, inject, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { animate } from 'motion';

@Component({
  selector: 'wen-swipeable',
  templateUrl: './swipeable.component.html',
  styleUrls: ['./swipeable.component.scss'],
  standalone: true
})
export class SwipeableComponent implements OnInit {

  private readonly element = inject(ElementRef);
  private readonly zone = inject(NgZone);

  @HostBinding('class.wen-swipeable') className = true;
  @HostBinding('style.width') elementWidth = '100%';

  @Input() enableSwipe = true;

  @Input() set swipeDirection(value: 'left' | 'right' | 'horizontal') {
    this.direction = value;
    if (this.hammerManager) {
      this.setupRecognizer();
    }
  }

  @Input() swipeTriggerRatio = 0.4;
  @Input() swipeDistanceLimitRatio = 0.60;
  @Input() swipeThreshold = 20;

  @Output() swipeGesture = new EventEmitter<'left' | 'right'>();

  @ViewChild('content') readonly contentElement: ElementRef<HTMLElement>;
  @ViewChild('leftIndicator') readonly leftIndicatorElement: ElementRef<HTMLElement>;
  @ViewChild('rightIndicator') readonly rightIndicatorElement: ElementRef<HTMLElement>;

  contentAlign = 'align-left';
  private direction = 'right';
  private hammerManager: HammerManager;
  private hammerRecognizer: PanRecognizer;

  ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      this.hammerManager = new Hammer.Manager(this.element.nativeElement);
      this.setupRecognizer();
      if (this.enableSwipe) {
        this.hammerManager.on('pan', (event) => this.handlePan(event));
        this.hammerManager.on('panend', (event) => this.handlePanEnd(event));
      }
    });
  }

  private clampAnimationOffset(offset) {
    const swipeLimit = (this.element.nativeElement as HTMLElement).offsetWidth * this.swipeDistanceLimitRatio;
    const min = this.direction === 'right' ? 0 : -swipeLimit;
    const max = this.direction === 'left' ? 0 : swipeLimit;
    return Math.min(Math.max(offset, min), max);
  }

  private handlePan(event: HammerInput) {
    if (this.enableSwipe) {
      const swipeTrigger = (this.element.nativeElement as HTMLElement).offsetWidth * this.swipeTriggerRatio;
      const swipeOffset = this.clampAnimationOffset(event.deltaX);

      animate(this.leftIndicatorElement.nativeElement,
        { opacity: Math.min(1, swipeOffset / swipeTrigger) },
        { duration: 0 }
      );
      animate(this.rightIndicatorElement.nativeElement,
        { opacity: Math.min(1, -swipeOffset / swipeTrigger) },
        { duration: 0 }
      );
      animate(this.contentElement.nativeElement,
        { x: swipeOffset },
        { duration: 0 }
      );
    }
  }

  private handlePanEnd(event: HammerInput) {
    if (this.enableSwipe) {
      const swipeTrigger = (this.element.nativeElement as HTMLElement).offsetWidth * this.swipeTriggerRatio;
      const indicators = [this.leftIndicatorElement.nativeElement, this.rightIndicatorElement.nativeElement];

      animate(indicators, { opacity: 0 });
      animate(this.contentElement.nativeElement, { x: 0 });

      if (Math.abs(this.clampAnimationOffset(event.deltaX)) >= swipeTrigger) {
        this.swipeGesture.emit(event.deltaX > 0 ? 'right' : 'left');
      }
    }
  }

  private setupRecognizer() {
    this.hammerManager.remove(this.hammerRecognizer);
    switch (this.direction) {
      case 'left':
        this.contentAlign = 'align-right';
        this.hammerRecognizer = new Hammer.Pan({ direction: Hammer.DIRECTION_LEFT, threshold: this.swipeThreshold });
        break;
      case 'horizontal':
        this.contentAlign = 'align-center';
        this.hammerRecognizer = new Hammer.Pan({ direction: Hammer.DIRECTION_HORIZONTAL, threshold: this.swipeThreshold });
        break;
      default:
        this.contentAlign = 'align-left';
        this.hammerRecognizer = new Hammer.Pan({ direction: Hammer.DIRECTION_RIGHT, threshold: this.swipeThreshold });
        break;
    }
    this.hammerManager.add(this.hammerRecognizer);
  }

}
