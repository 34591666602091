<h1 *isDesktopView class="wen-user-app-list-header-label">{{ 'APP_USER_HEADER_LABEL' | translate }}</h1>

@if (loadResult$ | async; as loadResult) {
  @if (loadResult.data; as apps) {
    @if (loadResult.data.length) {
      <div class="wen-user-app-list">
        <wen-app-list (appClicked)="onAppClick($event)" [apps]="apps" [enableContextMenu]="true"></wen-app-list>
      </div>
    } @else {
      @if (loadResult.mode === 'normal') {
        <wen-empty-list
          src="/assets/wen-widget/image/empty-app-list-background.svg"
          titleLabel="APPS_PLACEHOLDER_HEADER"
          textLabel="APPS_PLACEHOLDER_MESSAGE"
          [wen-test-id]="'wen-user-empty-list'"
        ></wen-empty-list>
      } @else {
        <wen-empty-search-result></wen-empty-search-result>
      }
    }
  }
}
