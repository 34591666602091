import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { ChannelDetailComponent } from './components/channel-detail/channel-detail.component';
import { MatInputModule } from '@angular/material/input';
import { PortalFieldModule } from '@portal/ui-kit/field';
import { LocationSelectorModule } from '../../../shared/components/location-selector/location-selector.module';
import { MatDialogModule } from '@angular/material/dialog';
import { ActionComponent, DialogLayoutModule, WenIconModule, WithVariantDirective } from '@portal/wen-components';
import { ReadCategoriesFieldModule } from '../../../shared/components/read-categories-field/read-categories-field.module';
import { ChannelSettingsModule } from './components/channel-settings/channel-settings.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  imports: [
    MatDialogModule,
    DialogLayoutModule,
    SharedModule,
    ScrollingModule,
    MatInputModule,
    PortalFieldModule,
    LocationSelectorModule,
    WenIconModule,
    ReadCategoriesFieldModule,
    ChannelSettingsModule,
    TranslateModule,
    ActionComponent,
    WithVariantDirective
  ],
  declarations: [
    ChannelDetailComponent
  ]
})
export class ChannelDetailModule { }
