import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SimpleUserDTO } from '@portal/wen-backend-api';
import { ActionMenuComponent, ActionMenuItem, OverlayManager } from '@portal/wen-components';
import { Observable, filter, map, tap, withLatestFrom } from 'rxjs';
import { equalsIgnoreCase } from '../../../core/common/operators/null-check-util';
import { ActionMenuItemProvider } from '../../../core/services/util/action-menu-item.provider';
import { selectCurrentUserData } from '../../../core/store/auth/auth.selectors';
import { selectCurrentChannel } from '../../../core/store/channel/channel.selectors';
import { RootState } from '../../../core/store/root/public-api';
import { ColorPalette } from '../../../shared/directives/colorize/palette-provider';
import { channelAnonymousSubscribersMenuId, channelSubscriberMenuId } from './action-menu/constants';
import { HeaderDataHelper } from '../../../core/services/util/header-data-helper';

@Component({
  selector: 'wen-channel-subscriber-list',
  templateUrl: './channel-subscriber-list.component.html',
  styleUrls: ['./channel-subscriber-list.component.scss'],
  providers: [ActionMenuItemProvider]
})
export class ChannelSubscriberListComponent implements OnInit {

  subscriberList$: Observable<{ user: SimpleUserDTO; hasMenu: boolean }[]>;
  currentUserId$: Observable<string>;
  actions$: Observable<ActionMenuItem[]>;
  anonymousUserCount: number;
  anonymousUserDisplayMessage: string;
  anonymousUserActions$: Observable<ActionMenuItem[]>;

  get contextMenuOpenerFillColor(): ColorPalette {
    return ColorPalette.GREY;
  }

  constructor(
    private store: Store<RootState>,
    private actionMenuItemProvider: ActionMenuItemProvider,
    private translateService: TranslateService,
    private overlayManager: OverlayManager,
    private headerDataHelper: HeaderDataHelper
  ) { }

  ngOnInit() {
    this.headerDataHelper.initializeContextTitleWithChannelTitle(true);

    this.actions$ = this.actionMenuItemProvider.selectActions(channelSubscriberMenuId);
    this.anonymousUserActions$ = this.actionMenuItemProvider.selectActions(channelAnonymousSubscribersMenuId);
    this.currentUserId$ = this.store.pipe(
      select(selectCurrentUserData),
      map((userData) => userData.userId.toLowerCase())
    );

    this.subscriberList$ = this.store.select(selectCurrentChannel).pipe(
      filter(channel => !!channel),
      tap(channel => {
        this.anonymousUserCount = channel?.subscribers?.anonymousUsers;
        return this.anonymousUserDisplayMessage = (this.anonymousUserCount > 1)
          ? this.translateService.instant('ANONYMOUS_SUBSCRIBERS_DISPLAY_NAME')
          : this.translateService.instant('ANONYMOUS_SUBSCRIBER_DISPLAY_NAME');
      }),
      withLatestFrom(this.currentUserId$, this.actions$),
      map(([{ subscribers: users }, currentUserId, actions]) => {
        return users?.subscribedUsers?.length && users?.subscribedUsers.map(user => {
          return {
            user,
            hasMenu: !equalsIgnoreCase(user.userId, currentUserId) && !user.roleId && Boolean(actions.length)
          };
        });
      }),
      filter(v => !!v),
      map(users => {
        if (!users.length) {
          return users;
        }
        return users.sort(({ user: user1 }, { user: user2 }) => user1.displayName.localeCompare(user2.displayName));
      })
    );
  }

  openContextMenu(userId: string) {
    this.overlayManager.menu.open(ActionMenuComponent, {actions: this.actions$, data: userId});
  }

  openContextMenuAnonymous() {
    this.overlayManager.menu.open(ActionMenuComponent, {actions: this.anonymousUserActions$});
  }
}
