import { Injectable } from '@angular/core';
import { distinctUntilChanged, EMPTY, interval, map, shareReplay, startWith, switchMap } from 'rxjs';
import { WenOAuthService } from '../user-management/wen-oauth.service';
import { ConnectionMonitor, ConnectionScope } from './types';

@Injectable()
export class AuthServerConnectionMonitor implements ConnectionMonitor {

  public readonly connectionState$ = this.authService.isDiscoveryDocumentLoaded().pipe(
    map(isLoaded => this.authService.hasValidTokens() || isLoaded),
    startWith(true),
    distinctUntilChanged(),
    shareReplay(1),
    map(isOnline => {
      return {
        isOnline, scope: ConnectionScope.AUTH_SERVER
      };
    })
  );

  private readonly reconnectTimer$ = this.connectionState$.pipe(
    switchMap(({ isOnline }) => isOnline ? EMPTY : interval(5000))
  );

  constructor(private readonly authService: WenOAuthService) {
    this.reconnectTimer$.subscribe(() => {
      this.forceRetry();
    });
  }

  forceRetry() {
    this.authService.loadDiscoveryDocument();
  }

}
