<wen-dialog-layout [headerGravity]="'top'">
  @if (isHeaderVisible$ | async) {
    <div class="wen-route-dialog-header" wen-dialog-layout-static-header>
      <wen-app-header
        wenIosKeyboardSticky
        [contextTitle]="headerContextTitle$ | async"
        [title]="headerTitle$ | async"
        [subTitle]="headerSubTitle$ | async"
        [leftCommand]="headerLeftCommand$ | async"
        [rightCommand]="headerRightCommand$ | async"
        [listenEnablementEvents]="true"
      ></wen-app-header>
      @if (searchConfig$ | async; as searchConfig) {
        <wen-search-bar
          [searchBarPlaceholder]="searchConfig.searchBarPlaceholder"
          [disabled]="searchConfig?.disabled"
          [wen-test-id]="'wen-app-header-search'"
          filter-element [filterId]="searchConfig.filterConfig.filterId" [filterEntityType]="searchConfig.filterConfig.filterEntityType"
        ></wen-search-bar>
      }
    </div>
  }
  @if (isFooterVisible$ | async) {
    <wen-app-header
      wen-dialog-layout-static-footer
      wenIosKeyboardSticky
      [title]="footerTitle$ | async"
      [subTitle]="footerSubTitle$ | async"
      [leftCommand]="footerLeftCommand$ | async"
      [rightCommand]="footerRightCommand$ | async"
      [listenEnablementEvents]="true"
    ></wen-app-header>
  }
  <div class="wen-route-dialog-content" dialogLayoutContent>
    <router-outlet name="dialog"></router-outlet>
  </div>
</wen-dialog-layout>
