import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CircleImageViewModule, CoreComponentsModule, ListMassDataViewerModule, MenuEntryModule, WenIconModule } from '@portal/wen-components';
import { ContactResponseActionModule } from '../../shared/components/contact-response-action/contact-response-action.module';
import { UserListItemComponent } from '../../shared/components/user-list-item/user-list-item.component';
import { SharedModule } from '../../shared/shared.module';
import { ContactsListComponent } from './contacts-list/components/contacts-list/contacts-list.component';
import { PendingContactsListComponent } from './pending-contacts-list/components/pending-contacts-list/pending-contacts-list.component';
import { ToUserListItemPipe } from './pipes/to-user-list-item.pipe';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild(),
    ScrollingModule,
    CircleImageViewModule,
    CoreComponentsModule,
    WenIconModule,
    MenuEntryModule,
    ListMassDataViewerModule,
    ContactResponseActionModule,
    UserListItemComponent,
  ],
  declarations: [
    ContactsListComponent,
    PendingContactsListComponent,
    ToUserListItemPipe,
  ]
})
export class ContactsModule { }
