<h1 *isDesktopView class="wen-user-profile-hotline-header">{{ 'USER_PROFILE_HOTLINE' | translate }}</h1>

<div class="wen-hotline-container">
  @for (contactInformation of contactInformations; track contactInformation) {
    <wen-menu-entry class="wen-user-view-profile-entry-primary"
      (click)="handleEntryClick(contactInformation.phoneNumber)" [attr.entry-id]="'HOTLINE'">
      <wen-icon class="wen-icon-medium" [fillColor]="iconColor" color="white" iconName="phonecall" menuEntryIcon>
      </wen-icon>
      <span portal-body-1 menuEntryTitle>{{contactInformation.fieldName}}</span>
    </wen-menu-entry>
  }
</div>
