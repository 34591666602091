import { Component, HostBinding, inject } from '@angular/core';
import { WenBreakpointObserver } from '@portal/wen-components';
@Component({
  selector: 'wen-channel-skeleton',
  templateUrl: './channel-skeleton.component.html',
  styleUrls: ['./channel-skeleton.component.scss'],
})
export class ChannelSkeletonComponent {

  private breakpointObserver = inject(WenBreakpointObserver);

  @HostBinding('class.wen-desktop-style') className = this.breakpointObserver.isDesktopStyleDevice;

  public skeletonElements = [1, 2];
}
