import { Injectable, Type } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { first, fromEvent, map, merge, of, shareReplay, takeUntil } from 'rxjs';
import { ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResult } from '../../../components/confirmation-dialog/confirmation-dialog.component';
import { LightboxDialogComponent } from '../../../components/lightbox-dialog/lightbox-dialog.component';
import { LightboxDialogData } from '../../../components/lightbox-dialog/models/lightbox.models';
import { WenBreakpointObserver } from '../../resize/wen-breakpoint-observer';
import { OverlayOpener } from '../overlay-types';
import { isNotOnScreenEdge } from './edge-detection-util';

enum WenDialogId {
  INPUT_DIALOG_ID = 'INPUT_DIALOG_ID',
  CONFIRMATION_DIALOG_ID = 'CONFIRMATION_DIALOG_ID',
  LIGHTBOX_DIALOG_ID = 'LIGHTBOX_DIALOG_ID',
  FILTER_DIALOG_ID = 'FILTER_DIALOG_ID',
  LOCATION_FILTER_DIALOG_ID = 'LOCATION_FILTER_DIALOG_ID',
  CATEGORY_DIALOG_ID = 'CATEGORY_DIALOG_ID'
}

enum WenDialogHeaderId {
  DIALOG_HEADER_LABEL = 'dialogHeaderLabel'
}

export interface ErrorDialogData {
  errorMessageLabel: string;
  messageParams?: { [key: string]: string };
}

@Injectable()
export class DialogHandler implements OverlayOpener {

  public readonly afterDialogOpened$ = this.matDialog.afterOpened.pipe(
    map((dialogRef) => dialogRef.id)
  );

  public readonly afterAllDialogsClosed$ = this.matDialog.afterAllClosed;

  hasOpenOverlay$ = merge(
    this.afterDialogOpened$.pipe(map(() => true)),
    this.afterAllDialogsClosed$.pipe(map(() => false)),
  ).pipe(
    shareReplay(1)
  );

  constructor(
    private matDialog: MatDialog,
    private breakpointObserver: WenBreakpointObserver,
    private translateService: TranslateService,
  ) {
    const isDesktopStyle = this.breakpointObserver.isDesktopStyleDevice;
    const inputDialog = this.matDialog.getDialogById(WenDialogId.INPUT_DIALOG_ID);
    const confirmationDialog = this.matDialog.getDialogById(WenDialogId.CONFIRMATION_DIALOG_ID);
    const filterDialog = this.matDialog.getDialogById(WenDialogId.FILTER_DIALOG_ID);
    if (inputDialog) {
      const { width, height } = this.getInputDialogSize(isDesktopStyle);
      inputDialog.updateSize(width, height);
    }
    if (confirmationDialog) {
      const { position, width } = this.getConfirmationDialogStyle(isDesktopStyle);
      confirmationDialog.updatePosition(position);
      confirmationDialog.updateSize(width);
    }
    if (filterDialog) {
      const { position, width } = this.getFilterSelectorDialogStyle(isDesktopStyle);
      filterDialog.updatePosition(position);
      filterDialog.updateSize(width);
    }
  }

  openInputDialog<C = any, D = any, R = any>(
    component: Type<C>, data: D = null, dialogProps?: Pick<MatDialogConfig<D>, 'autoFocus' | 'closeOnNavigation' | 'id'>
  ) {
    const { width, height } = this.getInputDialogSize(this.breakpointObserver.isDesktopStyleDevice);
    return this.matDialog.open<C, D, R>(component, {
      panelClass: 'wen-registration-dialog',
      width,
      height,
      maxWidth: '100vw',
      maxHeight: '100vh',
      data,
      ariaLabelledBy: WenDialogHeaderId.DIALOG_HEADER_LABEL,
      disableClose: true,
      id: WenDialogId.INPUT_DIALOG_ID,
      ...dialogProps
    });
  }

  openLocationDialog<C = any, D = any, R = any>(
    component: Type<C>, data: D = null, dialogProps?: Pick<MatDialogConfig<D>, 'autoFocus' | 'closeOnNavigation'>
  ) {
    return this.openInputDialog(component, data, { ...dialogProps, id: WenDialogId.LOCATION_FILTER_DIALOG_ID });
  }

  openConfirmationDialog<
    D extends ConfirmationDialogData,
    R extends ConfirmationDialogResult,
    C = ConfirmationDialogComponent,
  >(component: Type<C>, data: D = undefined, dialogProps?: Pick<MatDialogConfig<D>, 'disableClose' | 'closeOnNavigation'>) {
    const style = this.getConfirmationDialogStyle(this.breakpointObserver.isDesktopStyleDevice);
    const dialogRef = this.matDialog.open<C, D, R>(component, {
      panelClass: 'wen-confirmation-dialog',
      data,
      ...style,
      ariaLabelledBy: WenDialogHeaderId.DIALOG_HEADER_LABEL,
      id: WenDialogId.CONFIRMATION_DIALOG_ID,
      disableClose: dialogProps?.disableClose || false,
      closeOnNavigation: dialogProps?.closeOnNavigation || false
    });
    if (dialogProps?.disableClose) {
      this.preventOverlayEdgeSwipe(dialogRef);
    }
    return dialogRef;
  }

  openConfirmationDialogWithTranslationKeys(
    data: ConfirmationDialogData = undefined,
    dialogProps?: Pick<MatDialogConfig<ConfirmationDialogData>, 'disableClose' | 'closeOnNavigation'>
  ) {
    const dialogData: ConfirmationDialogData = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        dialogData[key] = this.translateService.instant(data[key]);
      }
    }
    return this.openConfirmationDialog(ConfirmationDialogComponent, dialogData, dialogProps);
  }

  private getInputDialogSize(isDesktopStyle: boolean) {
    if (isDesktopStyle) {
      return {
        width: '375px',
        height: '700px'
      };
    } else {
      return {
        width: '100vw',
        height: '100vh'
      };
    }
  }

  private getConfirmationDialogStyle(isDesktopStyle: boolean): Pick<MatDialogConfig, 'width' | 'position' | 'minWidth'> {
    if (isDesktopStyle) {
      return { width: '375px' };
    } else {
      return { position: { bottom: '0px' }, minWidth: '100%' };
    }
  }

  openLightboxDialog(data: LightboxDialogData) {
    return this.matDialog.open<LightboxDialogComponent, LightboxDialogData>(LightboxDialogComponent, {
      panelClass: 'wen-lightbox-dialog',
      data,
      id: WenDialogId.LIGHTBOX_DIALOG_ID,
      backdropClass: 'wen-lightbox-backdrop',
      ariaLabelledBy: WenDialogHeaderId.DIALOG_HEADER_LABEL
    });
  }

  openErrorDialog(data: ErrorDialogData, title: string = 'ERROR_DIALOG_TITLE') {
    return this.openConfirmationDialog(ConfirmationDialogComponent, {
      header: this.translateService.instant(title ?? 'ERROR_DIALOG_TITLE'),
      content: this.translateService.instant(data.errorMessageLabel, data.messageParams),
      okLabel: this.translateService.instant('OK_BUTTON'),
      dismissLabel: '',
    });
  }

  /**
   * @param headerKey The provided translation key is shown as dialog header
   * @param contentKey The provided translation key is shown as dialog content
   * @param okLabelKey The provided translation key is shown as dialog ok label
   */
  openLeaveConfirmationDialog(
    headerKey = 'LEAVE_DIALOG_HEADER',
    contentKey = 'LEAVE_DIALOG_CONTENT',
    okLabelKey = 'LEAVE_DIALOG_BUTTON'
  ) {
    return this.openConfirmationDialog(ConfirmationDialogComponent, {
      header: this.translateService.instant(headerKey),
      content: this.translateService.instant(contentKey),
      okLabel: this.translateService.instant(okLabelKey),
      dismissLabel: this.translateService.instant('CANCEL_BUTTON_LABEL')
    });
  }

  openFilterSelectorDialog<C = any, D = any, R = any>(component: Type<C>, data: D = null) {
    const style = this.getFilterSelectorDialogStyle(this.breakpointObserver.isDesktopStyleDevice);
    return this.matDialog.open<C, D, R>(component, {
      panelClass: 'wen-filter-dialog',
      maxWidth: '100vw',
      maxHeight: '100vh',
      ...style,
      data,
      ariaLabelledBy: WenDialogHeaderId.DIALOG_HEADER_LABEL,
      id: WenDialogId.FILTER_DIALOG_ID
    });
  }

  openSelectorDialog<C, D, R = any>(component: Type<C>, data: D = null) {
    const style = this.getFilterSelectorDialogStyle(this.breakpointObserver.isDesktopStyleDevice);
    return this.matDialog.open<C, D, R>(component, {
      panelClass: 'wen-selector-dialog',
      maxWidth: '100vw',
      maxHeight: '100vh',
      ...style,
      data,
      ariaLabelledBy: WenDialogHeaderId.DIALOG_HEADER_LABEL,
      id: WenDialogId.CATEGORY_DIALOG_ID
    });
  }

  private getFilterSelectorDialogStyle(isDesktopStyle: boolean): Pick<MatDialogConfig, 'width' | 'position' | 'height' | 'maxHeight'> {
    if (isDesktopStyle) {
      return { width: '375px' };
    } else {
      return { position: { bottom: '0px' }, width: '100vw', maxHeight: '375px' };
    }
  }

  closeInputDialog() {
    const inputDialog = this.matDialog.getDialogById(WenDialogId.INPUT_DIALOG_ID);
    if (inputDialog) {
      inputDialog.close();
    }
  }

  closeConfirmationDialog(result: 'ok' | 'dismiss') {
    const confirmationDialog = this.matDialog.getDialogById(WenDialogId.CONFIRMATION_DIALOG_ID);
    if (confirmationDialog) {
      confirmationDialog.close({ result });
      return confirmationDialog.afterClosed().pipe(first());
    }
    return of(null);
  }

  closeLightboxDialog() {
    const lightboxDialog = this.matDialog.getDialogById(WenDialogId.LIGHTBOX_DIALOG_ID);
    if (lightboxDialog) {
      lightboxDialog.close();
    }
  }

  private preventOverlayEdgeSwipe(dialogRef: MatDialogRef<any>) {
    const overlayContainer = document.getElementsByClassName('cdk-overlay-container')[0];
    fromEvent(overlayContainer, 'touchstart').pipe(
      takeUntil(dialogRef.beforeClosed().pipe(first()))
    ).subscribe((event: any) => {
      if (isNotOnScreenEdge(event)) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
    });
  }

}
