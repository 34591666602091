<div class="wen-initiate-chat-container">
  <div class="wen-initiate-chat-start-chat-button-wrapper">
    <wen-action class="wen-action-rounded"
                wenVariant="filled"
                [isNavigating]="true"
                (click)="createAndNavigateToRoom()">
      {{ "CRM_EMBEDDED_CHAT_START_CHAT_BUTTON_LABEL" | translate}}
    </wen-action>
  </div>
  <wen-current-user-info></wen-current-user-info>
</div>
