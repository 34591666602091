import { EmbedContext } from '../../../../events/events';
import { Injectable } from '@angular/core';
import { first, Observable, of, switchMap, throwError } from 'rxjs';
import { EmbeddedPollDTO, EmbedDTOType } from '../../../../data-types/embed/embed';
import { ErrorResponse, PollModel } from '../../../../data-types/poll/poll';
import { SocketIoService } from '../../../api-providers/socket-io-service';

@Injectable()
export class EmbedPollCreator {

  private newPollResponse$: Observable<PollModel | ErrorResponse> = this.socketIoService.messageEmbed.poll.save.listen;

  constructor(
    private socketIoService: SocketIoService,
  ) { }

  createPoll(
    pollEmbed: PollModel,
    context: EmbedContext
  ): Observable<EmbeddedPollDTO> {
    this.socketIoService.messageEmbed.poll.save.emit({ poll: {...pollEmbed, context} });
    return this.newPollResponse$.pipe(
      first(),
      switchMap((data) => {
        const maybeError = data as ErrorResponse;
        if (maybeError.errorMessage) {
          return throwError(() => maybeError.errorMessage);
        } else {
          const { id } = data as PollModel;
          const embeddedPoll: EmbeddedPollDTO = {
            pollId: id,
            type: EmbedDTOType.POLL,
            poll: pollEmbed
          };
          return of(embeddedPoll);
        }
      })
    );
  }

}
