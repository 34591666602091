<mat-form-field class="wen-chat-scheduled-date-picker-wrapper">
  <input class="wen-chat-scheduled-input" #scheduleInput matInput [ngxMatDatetimePicker]="datePicker"
    [formControl]="datePickerControl" [min]="minDate" readonly />
  <mat-datepicker-toggle matSuffix [for]="datePicker" class="wen-date-picker-toggle"
    (mousedown)="updateMinDateAndTime()" [disableRipple]="true">
    <wen-action matDatepickerToggleIcon class="wen-action-no-padding" [wenVariant]="hasDateAdded ? 'text': 'textSubtle'" [iconName]="hasDateAdded ? 'schedules_date_added_calendar': 'schedules_add_date_calendar'" [altText]="(hasDateAdded ? 'CHAT_SCHEDULE_CHANGE_TIME_LABEL' : 'CHAT_SCHEDULE_MESSAGE_LABEL') | translate "></wen-action>
  </mat-datepicker-toggle>
  <ngx-mat-datetime-picker [defaultTime]="defaultTime" [touchUi]="isMobileView" (opened)="onPickerOpen(datePicker)"
    #datePicker>
    <ngx-mat-datepicker-actions>
      <wen-action [style.visibility]="(isEdit$ | async) ? 'hidden' : 'visible'" class="wen-action-rounded" wenVariant="text"
        ngxMatDatepickerCancel (click)="handleDateCleared()">
        {{ "CHAT_SCHEDULED_DATEPICKER_CLEAR" | translate }}
      </wen-action>
      <wen-action class="wen-action-rounded" wenVariant="filled" ngxMatDatepickerApply
        [isDisabled]="isSaveDisabled$ | async">
        {{ "SCHEDULED_DATEPICKER_APPLY" | translate }}
      </wen-action>
    </ngx-mat-datepicker-actions>
  </ngx-mat-datetime-picker>
</mat-form-field>
