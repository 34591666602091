import { EncryptionType, ToRoomEventType } from '../ncrypted-message';
import { RoomUpdateDataDTO } from '../ncrypted-types';
import { EditMessageEventDTO, RedactMessageEventDTO, RedactionDTO, PlainEditMessageEventDTO } from '../send-to-room/modify-message';
import { AutoReplyMessageEventDTO, QuoteMessageEventDTO, SendMessageEventDTO, PlainSendMessageEventDTO, PlainQuoteMessageEventDTO } from '../send-to-room/send-message';
import { MessageRelations } from './relation';

export const isEncryptedMessageEventResponse = (response: MessageEventResponses): response is EncryptedMessageEventResponses => {
  if (!response) {
    return false;
  }
  return (response as EncryptedMessageEventResponses).type === EncryptionType.ENCRYPTED;
};

export const isEncryptedEvent = (response: MessageEvent<any>): response is MessageEvent<EncryptedMessageEventResponses> => {
  if (!response?.payload) {
    return false;
  }
  return response.payload.type === EncryptionType.ENCRYPTED;
};

export const isEncryptedSendMessageEvent = (response: MessageEvent<any>): response is MessageEvent<SendMessageEventDTO<true>> => {
  if (!response?.payload) {
    return false;
  }
  const maybeSendMessagePayload = response.payload as SendMessageEventDTO<true>;
  return maybeSendMessagePayload.type === EncryptionType.ENCRYPTED &&
    maybeSendMessagePayload.eventType === ToRoomEventType.SEND_MESSAGE;
};

export const isEncryptedSendMessageEventPayload = (response: MessageEvent<any>): response is MessageEvent<SendMessageEventDTO<true>> => {
  if (!response?.payload) {
    return false;
  }
  const maybeSendMessagePayload = response.payload as SendMessageEventDTO<true>;
  return maybeSendMessagePayload.type === EncryptionType.ENCRYPTED &&
    maybeSendMessagePayload.eventType === ToRoomEventType.SEND_MESSAGE;
};

export const isPlainMessageEventResponse = (response: MessageEventResponses): response is PlainMessageEventResponses => {
  if (!response) {
    return false;
  }
  const type = (response as any).type;
  const content = (response as any).content;
  return Boolean(type) && type === EncryptionType.PLAIN && Boolean(content);
};

// TODO: Is the quoted message really should be distinguished from the normal send?
export type EncryptedMessageEventResponses = SendMessageEventDTO<true> | RedactMessageEventDTO | EditMessageEventDTO | QuoteMessageEventDTO;
export type PlainSystemMessageEventResponses = AutoReplyMessageEventDTO
  | RoomUpdateEventDTO
  | RoomLeaveDTO
  | RoomMemberGrantedOwnershipDTO
  | RoomMemberRevokeOwnershipDTO
  | RoomMemberJoinDTO
  | RedactionDTO
  ;

export type MessageEventResponses = EncryptedMessageEventResponses | PlainMessageEventResponses | PlainSystemMessageEventResponses;
export type PlainMessageEventResponses = PlainSendMessageEventDTO
  | PlainEditMessageEventDTO
  | PlainQuoteMessageEventDTO;

export type RoomUpdateEventDTO = {
  eventType: ToRoomEventType.UPDATED;
  changedProperties: RoomUpdateDataDTO;
};

/**
 * The event data of a received message in rooms
 *
 * @see {@link SOCKET_EVENTS.CHAT_MESSAGES}
 */
export type MessageEvent<P extends MessageEventResponses = MessageEventResponses> = {
  payload: P;
  roomId: string;
  eventId: string;
  new: boolean;
  insertTimestamp: string;
  insertUser: InsertUser;
} & { relations: MessageRelations };

export type InsertUser = {
  id: string;
  name: string;
};

export type RoomLeaveDTO = {
  leftUserId: string;
  leftUserName: string;
  removedByUserId: string;
  removedByUserName: string;
  eventType: ToRoomEventType.LEFT;
};

export type RoomMemberGrantedOwnershipDTO = {
  eventType: ToRoomEventType.OWNERSHIP_GRANTED;
  newOwnerUserId: string;
  newOwnerUserName: string;
  grantedByUserId: string;
  grantedByUserName: string;
};

export type RoomMemberRevokeOwnershipDTO = {
  eventType: ToRoomEventType.OWNERSHIP_REVOKED;
  revokedFromUserId: string;
  revokedFromUserName: string;
  revokedByUserId: string;
  revokedByUserName: string;
};

export type RoomMemberJoinDTO = {
  eventType: ToRoomEventType.JOINED;
  joinedUserId: string;
  addedByUserId: string;
  avatarUrl: string;
  displayName: string;
  memberSince: string;
};
