import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RemoveMarkdownModule, WenCardsModule, CircleImageViewModule, ThumbnailImageModule } from '@portal/wen-components';
import { MessagePreviewCardComponent } from './message-preview-card.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    WenCardsModule,
    CircleImageViewModule,
    ThumbnailImageModule,
    RemoveMarkdownModule,
  ],
  declarations: [
    MessagePreviewCardComponent
  ],
  exports: [
    MessagePreviewCardComponent
  ]
})
export class MessagePreviewCardModule { }
