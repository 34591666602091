import { InjectionToken } from '@angular/core';
import { AppConfig, CoordinatesPayload } from '@portal/wen-backend-api';
import { WenTracerConfig } from '@portal/wen-tracer';

export interface AuthClientConfig {
  clientId: string;
  issuer: string;
  tokenEndpoint: string;
  revocationEndpoint: string;
  keepAliveEndpoint?: string;
  /**
   * Redirect url after token response
   * Defaults to /oauth/callback
   */
  redirectUri?: string;
  /**
   * Redirect url after succesful logout
   * Defaults to /oauth/login
   */
  postLogoutRedirectUri?: string;
  logoutUrl?: string;
  clockSkewInSec?: number;
  decreaseExpirationByMSec?: number;
}

export interface TextToSpeechConfig {
  textToSpeechUrl: string;
  textToSpeechApiKey: string;
}

export interface WeatherConfig {
  coordinates: CoordinatesPayload;
  goToLink?: string;
}

export interface OnboardingConfig {
  lottieAnimationUrl: string;
}

export interface DataProtectionConfig {
  manifestEnabled: boolean;
}

export interface NativeConfig {
  /**
   * Boolean indicating whenever use native dialog openng for native login
   */
  useNativeDialog: boolean;
  /**
   * The app link in format of eg.: 'we-network'
   * The native link string without slashes
   */
  nativeDeepLink: string;
  /**
   * The native app store ids
   */
  storeInfos?: {
    androidPlayStoreId?: string;
    iosAppName?: string;
    iosAppStoreId?: string;
    iosTestFlightLink?: string;
  };
}

export interface DebugOptions {
  enableNativeBridgeDebug?: boolean;
  enableAutoRefreshByAssetChanges?: boolean;
}

export interface VersionInfo {
  /**
   * Human readable version description
   */
  versionString?: string;
}

export interface ContactInfoConfig {
  supportEmail: string;
  waitlistUrl?: string;
  hotline?: HotlineItem[];
}

export interface HotlineItem {
  fieldName: string;
  phoneNumber: string;
}

export interface YellowMapConfig {
  yellowMapUrl: string;
  yellowMapApiKey?: string;
}

export interface ExclusiveInviteConfig {
  inviteUrl: string;
}

export interface HomeCoverImageConfig {
  landscape?: string;
  portrait?: string;
}

export interface WalletConfig {
  credentialProviderForUnitedKiosk?: string;
}

export type TracerConfig = WenTracerConfig;

export const APP_CONFIG = new InjectionToken<AppConfig>('APP_CONFIG');
export const AUTH_CLIENT_CONFIG = new InjectionToken<AuthClientConfig>('AUTH_CLIENT_CONFIG');
export const ONBOARDING_CONFIG = new InjectionToken<OnboardingConfig>('ONBOARDING_CONFIG');
export const DATA_PROTECTION_CONFIG = new InjectionToken<DataProtectionConfig>('DATA_PROTECTION_CONFIG');
export const YELLOWMAP_CONFIG = new InjectionToken<YellowMapConfig>('YELLOWMAP_CONFIG');
export const IMAGE_PROXY_BASE_URL = new InjectionToken<string>('IMAGE_PROXY_BASE_URL');
export const NATIVE_CONFIG = new InjectionToken<NativeConfig>('NATIVE_CONFIG');
export const VERSION_INFO = new InjectionToken<VersionInfo>('VERSION_INFO');
export const DEBUG_OPTIONS_CONFIG = new InjectionToken<DebugOptions>('DEBUG_OPTIONS');
export const CONTACT_INFO_CONFIG = new InjectionToken<ContactInfoConfig>('CONTACT_INFO_CONFIG');
export const EXCLUSIVE_INVITE_CONFIG = new InjectionToken<ExclusiveInviteConfig>('EXCLUSIVE_INVITE_CONFIG');
export const WEATHER_CONFIG = new InjectionToken<WeatherConfig>('WEATHER_CONFIG');
export const TEXT_TO_SPEECH_CONFIG = new InjectionToken<TextToSpeechConfig>('TEXT_TO_SPEECH_CONFIG');
export const HOME_COVER_IMAGE_CONFIG = new InjectionToken<HomeCoverImageConfig>('HOME_COVER_IMAGE_CONFIG');
export const WALLET_CONFIG = new InjectionToken<WalletConfig>('WALLET_CONFIG');
