import { getMediaTypeData } from '../../../util/media-type-util';
import { EmbedDescription } from '../embed-description.component';
import { EmbedDTOTypes, isLinkEmbed, isMediaEmbed, isPollEmbed } from '@portal/wen-backend-api';

export interface EmbedDescriptionHandler {
  getEmbedDescription(embed: EmbedDTOTypes): EmbedDescription;
}

export class WenEmbedDescriptionHandler implements EmbedDescriptionHandler {
  getEmbedDescription(embed: EmbedDTOTypes): EmbedDescription {
    if (isMediaEmbed(embed)) {
      if (embed.error) {
          return {icon: 'error_file', translationKey: 'ERROR_FILE_LABEL'};
      }
      const data = getMediaTypeData(embed.subType);
      return { icon: data.icon, translationKey: data.title };
    }
    if (isLinkEmbed(embed)) {
      return { icon: null, translationKey: embed.title };
    }
    if (isPollEmbed(embed)) {
      return { icon: 'chart', translationKey:'POLL_LABEL' };
    }
  }
}
