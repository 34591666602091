import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { distinctUntilChanged, filter, first, map, Observable, shareReplay, Subject, takeUntil } from 'rxjs';
import { switchMapFirst } from '../../../../core/common/operators/switch-map-first';
import { selectCurrentRoom } from '../../../../core/store/chat/chat.selectors';
import { ChatRoomEntity } from '../../../../core/store/chat/chat.state';
import { ChatMessageOperations } from '../../../../core/store/chat/operations/chat-message-operations';
import { isGroupChatRoom } from '../../../../core/store/chat/utils/chat-room-utils';
import { RootState } from '../../../../core/store/root/public-api';

@Injectable()
export class ChatViewDatasource implements OnDestroy {

  private onDestroy$ = new Subject<void>();

  public currentRoom$: Observable<ChatRoomEntity>;
  public isMuted$: Observable<boolean>;
  public isGroupChat$: Observable<boolean>;

  constructor(
    private store: Store<RootState>,
    private chatMessageOperations: ChatMessageOperations
  ) { }

  initialize() {
    this.currentRoom$ = this.store.pipe(
      select(selectCurrentRoom),
      shareReplay(1),
      smartDistinctUntilChanged(),
      takeUntil(this.onDestroy$)
    );

    this.onDestroy$.pipe(
      first(),
      switchMapFirst(() => {
        return this.currentRoom$;
      }),
      filter(v => !!v)
    ).subscribe(({ id }) => {
      const action = this.chatMessageOperations.removeAll(id);
      this.store.dispatch(action);
    });

    this.isMuted$ = this.currentRoom$.pipe(
      map(chatRoomEntity => chatRoomEntity?.details.isMuted),
      distinctUntilChanged()
    );

    this.isGroupChat$ = this.currentRoom$.pipe(
      filter(v => !!v),
      map((currentRoom) => isGroupChatRoom(currentRoom)),
      distinctUntilChanged()
    );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
