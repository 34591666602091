import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { CircleImageViewComponent, CircleImageViewModule } from '@portal/wen-components';
import { OtherUserProfileNavigable, UserNavigable } from '../../directives/user-navigation/model';
import { UserListItemModel } from './models/user-list-item.model';
import { ListItemModule } from '../list-item/list-item.module';

@Component({
  selector: 'wen-user-list-item',
  standalone: true,
  templateUrl: './user-list-item.component.html',
  styleUrls: ['./user-list-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CircleImageViewModule, ListItemModule],
  providers: [{
    provide: UserNavigable, useExisting: UserListItemComponent
  }]
})
export class UserListItemComponent implements OtherUserProfileNavigable {
  @HostBinding('class.wen-user-list-item') className = true;

  @ViewChild(CircleImageViewComponent, { read: ElementRef }) readonly imageElement: ElementRef<HTMLElement>;
  @ViewChild('userContent') readonly userContent: ElementRef<HTMLElement>;

  @HostBinding('class.wen-user-list-item-disabled') disabledClassName = false;
  @Input() set disabled(newValue: boolean) { this.disabledClassName = newValue; }

  @Input() user: UserListItemModel;

  getTargetElement() {
    return [this.imageElement.nativeElement, this.userContent.nativeElement];
  }
}
