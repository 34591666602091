import { WenLanguage } from '../language/language';

export type BaseUserInfo = {
  userId: string;
  christianName?: string;
  lastName?: string;
  email?: string;
  avatarUrl?: string;
};
export enum Contactability {
  ANYONE = 'ANYONE',
  CONTACTS = 'CONTACTS',
  NO_ONE = 'NO_ONE'
}
export type UserProfileSettings = {
  enableChatViaProfile: boolean;
  searchableByName: boolean;
  searchableByPhone: boolean;
  searchableByCompany: boolean;
  contactable: Contactability;
  primaryLanguage: WenLanguage;
};

export interface UserMetaDataSettingsResponse {
  loggingAllowed: boolean;
  primaryLanguage: WenLanguage;
}
export type UserMetaDataSettingsDTO = UserMetaDataSettingsResponse;
