import { DataContext } from '../../../core/common/types/data-context';
import { userProfileLoadedGuardFn } from '../../../core/guards/user-profile-loaded-guard';
import { WenRouteId, WenRoutes } from '../../../frame/routing/types';
import { AdaptiveBackgroundComponent } from '../../../shared/components/adaptive-background/adaptive-background.component';
import { chatCreationRoute } from '../../chat/routes/chat.routes';
import { inviteToChannelResolverFn } from '../../invite/invite-to-channel/guards/invite-to-channel.resolver';
import { inviteToChannel, inviteToChannelSelector } from '../../invite/routes/invite.routes';
import { dialogOutletInviteRoutes } from '../../invite/routes/multi-outlet-invite.routes';
import { ActionSelectorComponent } from '../action-selector/components/action-selector/action-selector.component';
import { ChatPreviewComponent } from '../chat-preview/components/chat-preview/chat-preview.component';
import { DistributeChatComponent } from '../distribute-chat/components/distribute-chat/distribute-chat.component';
import { ProfileReadOnlyViewComponent } from '../logout/components/profile-read-only-view.component';


export const multiOutletCrmRoutes: WenRoutes = [
  {
    path: 'home',
    component: AdaptiveBackgroundComponent,
  },
  {
    path: 'action-selector',
    children: [],
    data: {
      routeId: WenRouteId.CRM_ACTION_SELECTOR,
      isCrmRoot: true
    }
  },
  {
    path: 'distribute-chat',
    children: [],
    data: {
      routeId: WenRouteId.CRM_DISTRIBUTE_CHAT,
      isCrmRoot: true
    }
  },
  {
    path: 'chat-preview',
    component: ChatPreviewComponent,
    canActivate: [userProfileLoadedGuardFn],
    data: {
      routeId: WenRouteId.CRM_CHAT_PREVIEW,
    },
  },
];

export const dialogOutletCrmRoutes: WenRoutes = [
  {
    path: 'invite',
    children: [
      ...dialogOutletInviteRoutes,
      inviteToChannelSelector,
      {
        ...inviteToChannel,
        resolve: {
          selectedChannels: inviteToChannelResolverFn,
        }
      },
    ],
  },
  {
    path: 'chat',
    children: [
      {
        ...chatCreationRoute,
      },
    ],
  },
  {
    path: 'logout',
    component: ProfileReadOnlyViewComponent,
    data: {
      routeId: WenRouteId.CRM_PROFILE_READ_ONLY_VIEW
    }
  },
  {
    path: 'action-selector',
    component: ActionSelectorComponent,
    canActivate: [userProfileLoadedGuardFn],
    data: {
      routeId: WenRouteId.CRM_ACTION_SELECTOR,
      dataContext: DataContext.PROFILE
    },
  },
  {
    path: 'distribute-chat',
    component: DistributeChatComponent,
    canActivate: [userProfileLoadedGuardFn],
    data: {
      routeId: WenRouteId.CRM_DISTRIBUTE_CHAT,
      dataContext: DataContext.PROFILE
    },
  },
  {
    path: 'chat-preview',
    component: ChatPreviewComponent,
    canActivate: [userProfileLoadedGuardFn],
    data: {
      routeId: WenRouteId.CRM_CHAT_PREVIEW
    },
  },
];
