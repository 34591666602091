import { Provider } from '@angular/core';
import { SocketIoService } from '@portal/wen-backend-api';
import { WenNativeApi } from '@portal/wen-native-api';
import { WenOAuthService } from '../user-management/wen-oauth.service';
import { AuthServerConnectionMonitor } from './auth-server-connection-monitor';
import { ConnectionMonitorService } from './connection-monitor.service';
import { HealthStatusMonitor } from './health-status-monitor';
import { SocketConnectionMonitor } from './socket-connection-monitor';
import { CONNECTION_MONITORS } from './tokens';
import { networkConnectionMonitorFactory } from './types';

export const CONNECTION_MONITOR_PROVIDERS: Provider[] = [
  ConnectionMonitorService,
  {
    provide: CONNECTION_MONITORS,
    useFactory: networkConnectionMonitorFactory,
    multi: true,
    deps: [WenNativeApi]
  },
  {
    provide: CONNECTION_MONITORS,
    useClass: SocketConnectionMonitor,
    multi: true,
    deps: [SocketIoService]
  },
  {
    provide: CONNECTION_MONITORS,
    useClass: HealthStatusMonitor,
    multi: true,
    deps: [SocketIoService]
  },
  {
    provide: CONNECTION_MONITORS,
    useClass: AuthServerConnectionMonitor,
    multi: true,
    deps: [WenOAuthService]
  }
];
