<div class="we-feed-scroller" cdkScrollable #weFeedScroller tabindex="-1" wenIosViewportFix
  (afterViewportChange)="handleAfterViewportChange()">
  @if (hasItems$ | async) {
    @if (feedItems$ | async; as feedItems) {
      <div class="we-feed-messages-wrapper" #wrapper [class.we-feed-messages-wrapper-flow-up]="flowDirection === 'up'" wenContentBoundary>
        <ng-content select="wen-messages-view-before-content, [wen-messages-view-before-content]"></ng-content>
        @for (feedItem of feedItems; track trackByFn(i, feedItem); let i = $index) {
          <div class="we-feed-message-item" @fadeOutOnLeave
            [class.we-feed-message-item-hidden]="feedLayoutCommunicator.isRenderingInProgressFor(feedItem.key)">
            @if (feedItem.value; as message) {
              @switch (feedItem.type) {
                @case ('MESSAGEBOX') {
                  <wen-message-box #wenMessageBox observeVisibility
                    [rootElement]="weFeedScroller" (visible)="onMessageVisible(message)"
                    [isActive]="!feedLayoutCommunicator.isRenderingInProgress()"
                    [message]="message" [sender]="message.sender" [senderVisible]="message.senderVisible"
                    [content]="message.content" [scheduled]="message.scheduled" [embeds]="message.embeds"
                    [timestamp]="message | messageTimestamp" [modificationState]="message.modificationState"
                    [messageId]="message.messageId"
                    [referenceId]="message.referenceId"[status]="message.status"
                    [highlighted]="message.highlighted"
                    [commentsVisible]="message.commentsVisible" [commentsEnabled]="message.commentsEnabled"
                    [shareVisible]="message.shareVisible"
                    [reactionContext]="reactionContext"
                    [disableEmojiReaction]="message.disableEmojiReaction"
                    [hideEmojiReactionPlaceholder]="message.hideEmojiReactionPlaceholder"
                    [textToSpeechEnabled]="message.textToSpeechEnabled"
                    [class.wen-message-box-merge-with-next]="feedItem.extraProps?.mergeWithNext"
                    [class.wen-message-box-merge-with-previous]="feedItem.extraProps?.mergeWithPrevious">
                    @if (boxContent?.templateRef) {
                      <ng-container ngProjectAs="wen-message-box-content-template"
                        [ngTemplateOutlet]="boxContent.templateRef"
                        [ngTemplateOutletContext]="{ data: message }"></ng-container>
                    }
                  </wen-message-box>
                }
                @case ('CUSTOM_ITEM_TYPE') {
                  <div observeVisibility (visible)="onMessageVisible(message)">
                    <ng-container  [ngTemplateOutlet]="customItemContent.templateRef"
                      [ngTemplateOutletContext]="{ data: message }">
                    </ng-container>
                  </div>
                }
                @case ('SEPARATOR') {
                  <wen-separator [displayData]="feedItem.value"></wen-separator>
                }
              }
            }
          </div>
        }
      </div>
    }
  } @else {
    <ng-content select="wen-we-feed-empty-placeholder"></ng-content>
  }
</div>
@if (isScrollToBottomButtonVisible$ | async) {
  <ng-content select="wen-scroll-to-button"></ng-content>
}
