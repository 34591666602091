import { Component, Input } from '@angular/core';
import { ChannelDTO } from '@portal/wen-backend-api';
import { WenNavigationHelper } from '../../../../core/services/navigation/types';
import { ChannelEntity } from '../../../../core/store/channel/channel.state';

@Component({
  selector: 'wen-navigate-to-channel-button',
  templateUrl: './navigate-to-channel-button.component.html',
  styleUrls: ['./navigate-to-channel-button.component.scss']
})
export class NavigateToChannelButtonComponent {

  @Input() channel: ChannelDTO | ChannelEntity;

  constructor(private navigationHelper: WenNavigationHelper) { }

  navigateToChannel() {
    this.navigationHelper.navigateToChannelView(this.channel.id);
  }
}
