import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ReactionContext } from '@portal/wen-backend-api';
import { ResizeHandlerProvider, ThumbnailCustomizations } from '@portal/wen-components';
import { Subject } from 'rxjs';
import { enableAutoReadAcknowledgeForCurrentRoom } from '../../../../../core/store/chat/chat.actions';
import { requestExchangeInboundGroupSessionForCurrentRoom } from '../../../../../core/store/chat/key-actions';
import { RootState } from '../../../../../core/store/root/public-api';
import { FeedLayoutHooks } from '../../../../../shared/components/feed/providers/feed-layout-hooks';
import { FeedLayoutMediator } from '../../../../../shared/components/feed/providers/feed-layout-mediator';
import { MessageBoxCustomizations } from '../../../../../shared/components/message-box/providers/message-box-embed-customizations';
import { ChatMessageExtensionDataLoader } from '../../providers/chat-message-extension-data-loader.service';
import { ChatMessageCustomizations } from './providers/chat-message-customizations';
import { QuoteService } from '../../../chat-view/providers/quote.service';
import { SeparatorCustomizations } from '../../../../../shared/directives/directives/separator-customizations';
import { ChatSeparatorCustomizations } from './providers/chat-separator-customizations';
import { ChatThumbnailCustomizations } from './providers/chat-thumbnail-customizations';

const FEED_MAX_WIDTH = 900;
const FEED_ITEM_MAX_WIDTH = 85;

@Component({
  selector: 'wen-chat-messages-view',
  templateUrl: './chat-messages-view.component.html',
  styleUrls: ['./chat-messages-view.component.scss'],
  providers: [
    ResizeHandlerProvider,
    { provide: FeedLayoutHooks, useClass: ChatMessageExtensionDataLoader },
    { provide: MessageBoxCustomizations, useClass: ChatMessageCustomizations },
    { provide: SeparatorCustomizations, useClass: ChatSeparatorCustomizations },
    { provide: ThumbnailCustomizations, useClass: ChatThumbnailCustomizations },
    QuoteService
  ]
})
export class ChatMessagesViewComponent implements OnInit, AfterViewInit, OnDestroy {

  readonly reactionContext = ReactionContext.CHAT_MESSAGE;
  private onDestroy$ = new Subject<void>();

  @Input() isGroupChat: boolean;
  @Input() flowDirection: 'up' | 'down' = 'up';
  @Input() disableScrollToBottomButton: boolean;
  @Input() disableEmojiReaction: boolean;

  get maxWidth() {
    return FEED_MAX_WIDTH;
  }

  get feedItemMaxWidth() {
    return FEED_ITEM_MAX_WIDTH;
  }

  constructor(
    public store: Store<RootState>,
    public feedLayoutMediator: FeedLayoutMediator,
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  onScrollToBottomClicked() {
    this.feedLayoutMediator.scrollToBottom();
    this.enableAutoAcknowledge();
  }

  enableAutoAcknowledge() {
    this.store.dispatch(enableAutoReadAcknowledgeForCurrentRoom());
  }

  onRequestShareKeyClicked() {
    this.store.dispatch(requestExchangeInboundGroupSessionForCurrentRoom({ withDialogFallback: true }));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
