import { Component, inject, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectorWithParam } from '../../../../../core/common/util/selector-with-param';
import { subscribeForEvent, unsubscribeFromEvent } from '../../../../../core/store/events/event.actions';
import { selectSubscriptionForEvent } from '../../../../../core/store/events/event.selectors';
import { SubscriptionEntity } from '../../../../../core/store/events/event.state';
import { RootState } from '../../../../../core/store/root/public-api';
import { Icon, QuickActionPanelComponent } from '@portal/wen-components';
import { TranslateService } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'wen-quick-subscription-panel',
  standalone: true,
  templateUrl: './quick-subscription-panel.component.html',
  imports: [
    AsyncPipe,
    QuickActionPanelComponent
  ]
})
export class QuickSubscriptionPanelComponent {
  private store = inject(Store<RootState>);
  private translateService = inject(TranslateService);

  readonly favorizeIcon: Icon = { name: 'favorite_active', altText: this.translateService.instant('EVENT_UNFAVORIZE') };
  readonly unfavorizeIcon: Icon = { name: 'favorite_inactive', altText: this.translateService.instant('EVENT_FAVORIZE') };
  subscription$: Observable<SubscriptionEntity>;
  id: string;

  @Input()
  set eventId(id: string) {
    this.id = id;
    this.subscription$ = this.store.pipe(
      selectorWithParam(selectSubscriptionForEvent, id)
    );
  }

  onSubscriptionCountClick(subscribed: boolean) {
    if (subscribed) {
      this.store.dispatch(unsubscribeFromEvent(this.id));
    } else {
      this.store.dispatch(subscribeForEvent(this.id));
    }
  }
}
