export enum FilterId {
  EVENT_CATEGORIES = 'event_categories',
  EVENT_DATES = 'event_dates',
  EVENT_GEO_LOCATION = 'event_location',
  CHANNEL_CATEGORIES = 'channel_categories',
  CHANNEL_REGIONS = 'channel_regions',
  APP_CATEGORIES = 'app_categories',
  APP_REGIONS = 'app_regions',
  CHANNEL_MESSAGE_GEO_LOCATION = 'channel_message_geo_location',
}
