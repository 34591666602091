import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BACKEND_API_CONFIG, BackendApiConfig, toNetworkId } from '@portal/wen-backend-api';
import { Observable, combineLatest, filter, first, map, of, switchMap, throwError } from 'rxjs';
import { networkIdentifier } from '../../../frame/network/tokens';
import { equalsIgnoreCase } from '../../common/operators/null-check-util';
import { subscribeToNetwork } from '../../store/network/network.actions';
import { selectActiveNetwork } from '../../store/network/network.selectors';
import { RootState } from '../../store/root/public-api';
import { selectRouteParams } from '../../store/root/root.selectors';
import { WenNavigationHelper } from '../navigation/types';

@Injectable()
export class NetworkLoadedGuard {

  constructor(
    @Inject(BACKEND_API_CONFIG) private backendApiConfig: BackendApiConfig,
    private store: Store<RootState>,
    private navigationHelper: WenNavigationHelper,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivateChild(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const networkIdParam$ = this.store.pipe(
      select(selectRouteParams),
      map(params => params[networkIdentifier] as string)
    );
    const activeNetwork$ = this.store.pipe(
      select(selectActiveNetwork)
    );
    return combineLatest([
      networkIdParam$, activeNetwork$
    ]).pipe(
      first(),
      switchMap(([networkIdParam, storeActiveNetwork]) => {
        const hasActiveNetwork = Boolean(storeActiveNetwork?.id);
        if (networkIdParam) {
          this.store.dispatch(subscribeToNetwork({ networkId: networkIdParam }));
          return this.store.pipe(
            select(selectActiveNetwork),
            filter((activeNetwork) => equalsIgnoreCase(activeNetwork?.id, networkIdParam)),
            first(),
            map(() => {
              this.navigationHelper.navigateToWelcomeScreen();
              return false;
            })
          );
        }
        if (hasActiveNetwork) {
          return of(true);
        }
        if (!hasActiveNetwork) {
          const networkId = toNetworkId(this.backendApiConfig.network);
          this.store.dispatch(subscribeToNetwork({ networkId }));
          return this.store.pipe(
            select(selectActiveNetwork),
            filter((activeNetwork) => equalsIgnoreCase(activeNetwork?.id, networkId)),
            first(),
            map(() => true)
          );
        }
        return throwError(() => new Error('Unhandled network initialization error!'));
      })
    );
  }

}
