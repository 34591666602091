export class NavigatorUtils {
  static setAppBadge(count: number): void {
    if (navigator?.setAppBadge) {
      navigator.setAppBadge(count).catch(() => {});
    }
  }

  static isOnline(): boolean {
    return navigator.onLine ?? true;
  }

  static getUserAgentOrVendor() {
    return navigator?.userAgent || navigator?.vendor || '';
  }

  static getCurrentPosition(): Promise<GeolocationPosition | null> {
    return new Promise((resolve, reject) => {
      if (!navigator?.geolocation?.getCurrentPosition) {
        resolve(null);
        return;
      }

      navigator.geolocation.getCurrentPosition(
        position => resolve(position),
        error => reject(error)
      );
    });
  }

  static async getPermissionStatus(permissionName: PermissionName): Promise<PermissionStatus | null> {
    if (navigator?.permissions) {
      try {
        return await navigator.permissions.query({ name: permissionName });
      } catch {
        return null;
      }
    }
    return null;
  }
}
