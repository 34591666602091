import { inject, Injectable, Injector } from '@angular/core';
import { AuthValidator } from '@portal/wen-backend-api';
import { catchError, map, Observable, of } from 'rxjs';
import { AuthStateValidator, NotAuthenticatedError } from '../../user-management/auth-state-validator.service';
import { AuthTracer } from '../../user-management/auth-tracer';
import { WenOAuthService } from '../../user-management/wen-oauth.service';

@Injectable()
export class SocketIoAuthValidator implements AuthValidator {

  private authValidatorInstance: AuthStateValidator = null;

  private injector = inject(Injector);
  private oAuthService = inject(WenOAuthService);
  private authTracer = inject(AuthTracer);

  private get authValidator() {
    // Use injector directly to avoid circular DI issues
    if (!this.authValidatorInstance) {
      this.authValidatorInstance = this.injector.get(AuthStateValidator);
    }
    return this.authValidatorInstance;
  }

  validateAuth(): Observable<boolean> {
    return this.authValidator.validateAuthState().pipe(
      map(result => Boolean(result)),
      catchError((error) => {
        this.authTracer.captureException(error);
        if (error instanceof NotAuthenticatedError) {
          this.authTracer.addAuthBreadcrumb('socket_auth_validator_will_init_codeflow');
          this.authTracer.captureException(error);
          return this.oAuthService.initCodeFlow().pipe(
            map(() => false)
          );
        }
        this.authTracer.captureException(error);
        return of(false);
      })
    );
  }

}
