import { createAction, props } from '@ngrx/store';
import { ChatMessageAcknowledgeDTO, MediaUploadResponse, RoomDTO, RoomMembers, RoomType, RoomUpdateDataDTO, RoomUpdatePayload } from '@portal/wen-backend-api';
import { UserSearchListItem } from '../../../shared/components/user-search-list/user-search-list.types';
import { LoadingState } from '../../common/types/store-loading-state';
import { EmbeddedMedia } from '../channel/channel.state';
import { DraftMessageEntity, PendingEmbedType } from '../common/models';
import { ChatMessageEntity, DraftChatItemEntity } from './chat.state';

export const subscribeChatUpdates = createAction(
  '[Chat] Start listening for chat events'
);

export const chatInitializationSuccess = createAction(
  '[Chat] Chat initialization was successful'
);

export const roomCreateRequest = createAction(
  '[Chat] Request to create a room',
  props<{ targetUserIds: string[]; roomType?: RoomType; icon?: File; title?: string; description?: string; fromCreateMode: boolean }>()
);

export const roomUpdateRequest = createAction(
  '[Chat] Request to update a room',
  props<{ room: RoomUpdatePayload }>()
);

export const uploadImageForRoom = createAction(
  '[Chat] Upload image for a room',
  props<{ image: File; roomId?: string }>()
);

export const roomCreated = createAction(
  '[Chat] Room has been created',
  props<{ room: Partial<RoomDTO> }>()
);

export const navigateToRoom = createAction(
  '[Chat] Navigate to room by id',
  props<{ roomId: string; fromCreateMode: boolean }>()
);

export const navigateToNewRoom = createAction(
  '[Chat] Navigate to room by id after draft cleanup',
  props<{ roomId: string }>()
);

export const loadRoomDetails = createAction(
  '[Chat] Load the details for a room',
  props<{ roomId: string }>()
);

export const invalidateFetchState = createAction(
  '[Chat] Invalidate the fetch state for all rooms'
);

export const setMuteForRoom = createAction(
  '[Chat] Mute or unmute the current room',
  props<{ mute: boolean; room: string }>()
);

export const updateRoomMuteState = createAction(
  '[Chat] Update the mute status of the current room',
  props<{ isMuted: boolean; roomId: string }>()
);

export const updateRoomData = createAction(
  '[Chat] Update room data after update',
  props<{ roomId: string; changedProperties: RoomUpdateDataDTO }>()
);

export const upsertChatRooms = createAction(
  '[Chat] Create or update chat rooms',
  props<{ rooms: RoomDTO[] }>()
);

export const upsertChatRoom = createAction(
  '[Chat] Create or update a chat room',
  props<{ room: RoomDTO }>()
);

export const upsertChatRoomMembers = createAction(
  '[Chat] Update members of a chat room',
  props<{ members: RoomMembers[]; roomId: string }>()
);

export const upsertChatFetched = createAction(
  '[Chat] Create room data',
  props<{ room: RoomDTO; members: RoomMembers[] }>()
);

export const updateUserChatListLoadingState = createAction(
  '[Chat] Update user chat list loading state',
  props<{ loadingState: LoadingState }>()
);

export const storeChatMessage = createAction(
  '[Chat] Store chat message',
  props<{ roomId: string; message: ChatMessageEntity }>()
);

export const storePendingChatMessage = createAction(
  '[Chat] Store pending chat message',
  props<{ pendingMessage: ChatMessageEntity }>()
);

export const removePendingChatMessage = createAction(
  '[Chat] Remove pending chat message',
  props<{ messageId: string }>()
);

export const updateChatDraftMessage = createAction(
  '[Chat] Update draft chat message',
  props<{ message: DraftMessageEntity; force?: boolean }>()
);

export const removeDraftChatMessage = createAction(
  '[Chat] Remove draft chat message',
  props<{ roomId: string }>()
);

export const removeRoom = createAction(
  '[Chat] Remove a room',
  props<{ roomId: string }>()
);

export const roomRemoved = createAction(
  '[Chat] A room has been removed',
  props<{ roomId: string }>()
);

export const updatePendingChatMessageFileAttachmentLoadState = createAction(
  '[Chat] Update draft chat message file attachment',
  props<{ loadState?: PendingEmbedType; mediaId: string }>()
);

export const updateChatMessageAcknowledgeStatusInRoom = createAction(
  '[Chat] Update the acknowledge status for a chat message in the store',
  props<{ roomId: string; lastReceivedTimestamp: string; lastReadTimestamp: string }>()
);

export const acknowledgeChatMessage = createAction(
  '[Chat] Emit a message acknowledge to the server',
  props<{ payload: ChatMessageAcknowledgeDTO; senderUserId: string }>()
);

export const subscribeChatMessageAcknowledgeUpdates = createAction(
  '[Chat] Listen to chat message acknowledge state updates'
);

export const enableAutoReadAcknowledgeForCurrentRoom = createAction(
  '[Chat] Enables that on every received chat message a READ acknowledgement is sent to the server'
);

export const updateAutoReadAcknowledgeRoomId = createAction(
  '[Chat] Updates the auto acknowledge room id in the store',
  props<{ roomId: string }>()
);

export const initiateDraftMessageWithUser = createAction(
  '[Chat] Initiate draft message with user',
  props<{ user: UserSearchListItem }>()
);

export const createAndNavigateToDialogChat = createAction(
  '[Chat] Create direct chat with user and navigate',
  props<{ userId: string }>()
);

export const actualizeGlobalDraftChat = createAction(
  '[Chat] Actualize the global draft chat'
);

export const actualizeDraftChatRoom = createAction(
  '[Chat] Actualize a draft chat of the specified room',
  props<{ roomId: string }>()
);

export const addDraftChatMember = createAction(
  '[Chat] Update multicast member',
  props<{ user: DraftChatItemEntity }>()
);

export const removeDraftChatMember = createAction(
  '[Chat] Remove multicast member',
  props<{ userId: string }>()
);

export const addDraftChatRoom = createAction(
  '[Chat] Update multicast chat',
  props<{ room: DraftChatItemEntity }>()
);

export const removeDraftChatRoom = createAction(
  '[Chat] Remove multicast chat',
  props<{ roomId: string }>()
);

export const updateDraftChatMessage = createAction(
  '[Chat] Update multicast draft message',
  props<{ message: DraftMessageEntity }>()
);

export const cleanUpDraftChat = createAction(
  '[Chat] Cleanup multicast'
);

export const cleanUpDraftChatMessage = createAction(
  '[Chat] Cleanup multicast draft message'
);

export const clearChatMessageNewState = createAction(
  '[Chat] Reset message new state for a room',
  props<{ roomId: string }>()
);

export const fetchAcknowledgeTimestamps = createAction(
  '[Chat] Gets the latest read and received timestamps from the server for a room',
  props<{ roomIds: string[] }>()
);

export const finishChatMessagesDistribution = createAction(
  '[Chat] Finish up chat message distribution',
  props<{ succeededRecipientIds: string[]; failedRecipientIds: string[] }>()
);

export const cancelChatMessagesDistribution = createAction(
  '[Chat] Cancel chat message distribution'
);

export const updateChatMedia = createAction(
  '[Chat] Update chat media',
  props<{ status: MediaUploadResponse }>()
);

export const updateChatMessageMediaByStatus = createAction(
  '[Chat] update chat message media for room by media upload status',
  props<{ status: MediaUploadResponse }>()
);

export const updateChatMessageMediaByEmbedData = createAction(
  '[Chat] update chat message media for room by embed data',
  props<{ mediaEmbed: EmbeddedMedia }>()
);

export const showScheduledChatPostSuccessToast = createAction(
  '[Chat] Show scheduled post success toast',
);

export const initFileEmbedCacheForRoom = createAction(
  '[Chat] Init file embed cache for room',
  props<{ blobUrl: string; roomId: string; uploadId: string }>()
);

export const cleanUpFileEmbedCacheAfterFullyProcessed = createAction(
  '[Chat] Remove file from fileEmbedCache after upload is fully processed',
  props<{ status: MediaUploadResponse }>()
);
