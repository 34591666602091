<div class="wen-user-profile-scroller" cdkScrollable>
  <div class="wen-user-profile-header-container" [wen-test-id]="'wen-user-profile-header'">
    @if (isRegisteredUser$ | async) {
      <div class="wen-user-profile-registered-user-header"
        (click)="goToProfileRead()">
        @if (userProfile$ | async; as userProfile) {
          <wen-circle-image-view [imageUrl]="userProfile.avatarUrl" [imageSize]="{height:134}" [placeholderText]="userProfile.displayname">
          </wen-circle-image-view>
          <h1 #summaryWrapper class="wen-user-profile-header-username">{{userProfile.displayname}}</h1>
        }
      </div>
    } @else {
      <div class="wen-user-profile-anonymous-header" [wen-test-id]="'wen-user-anonymous-profile-header'">
        <div class="wen-user-profile-anonymous-header-info">
          <img class="wen-user-profile-anonymous-image" src="/assets/wen-widget/image/anonymous-cover.svg" alt="">
          <h1 class="cdk-visually-hidden">{{'ANONYMOUS_USER' | translate }}</h1>
          <h2 class="wen-user-profile-anonymous-message">{{ 'USER_PROFILE_ANONYMOUS_MESSAGE' | translate }}</h2>
        </div>
        <div class="wen-user-profile-anonymous-actions" #summaryWrapper>
          @if (config.anonymousRegisterEntry$ | async; as registerEntry) {
            <button portal-button
              (click)="handleEntryClick(registerEntry.entryId)">
              <span portal-title>{{ registerEntry.translationKey | translate }}</span>
            </button>
          }
          @if (config.anonymousLoginEntry$ | async; as loginEntry) {
            <wen-action class="wen-action-rounded"
                        wenVariant="filled"
                        (click)="handleEntryClick(loginEntry.entryId)">
              <span portal-title>{{ loginEntry.translationKey | translate }}</span>
            </wen-action>
          }
        </div>
      </div>
    }
    <wen-auto-reply-text class="wen-auto-reply-text" (click)="goToAutoReplyEdit()"></wen-auto-reply-text>
  </div>
  <div class="wen-user-profile-view-content">
    <div class="wen-user-profile-view-entries" [wen-test-id]="'wen-user-profile-menus'">
      @if (config.dataEntries$ | async; as dataEntries) {
        @if (dataEntries.length) {
          <div menuEntryLayoutGroup>
            @for (entry of dataEntries; track entry) {
              <wen-menu-entry (click)="handleEntryClick(entry.entryId)" [attr.entry-id]="entry.entryId"
                *wenComingSoonFeature="entry.comingSoonFeature">
                <wen-icon [iconName]="entry.iconName" [fillColor]="entry.fillColor" [color]="'white'"
                  class="wen-icon-medium" menuEntryIcon>
                </wen-icon>
                <span portal-body-1 menuEntryTitle>{{ entry.translationKey | translate }}</span>
                @if (entry.notificationConfig?.count$ | async; as notificationCount) {
                  <ng-container menuEntryDescription>
                    @if (notificationCount > 0) {
                      <wen-notification-count class="wen-notification-count-big" [value]="notificationCount"></wen-notification-count>
                    }
                  </ng-container>
                }
              </wen-menu-entry>
            }
          </div>
        }
      }
      @if (config.generalOptions$ | async; as generalOptions) {
        @if (generalOptions.length) {
          <div menuEntryLayoutGroup>
            @for (option of generalOptions; track option) {
              <wen-menu-entry (click)="handleEntryClick(option.entryId)" [attr.entry-id]="option.entryId"
                *wenComingSoonFeature="option.comingSoonFeature">
                <wen-icon [iconName]="option.iconName" [fillColor]="option.fillColor" [color]="'white'"
                  class="wen-icon-medium" menuEntryIcon>
                </wen-icon>
                <span portal-body-1 menuEntryTitle>{{ option.translationKey | translate }}</span>
                @if (option.notificationConfig?.count$ | async; as notificationCount) {
                  <ng-container menuEntryDescription>
                    @if (notificationCount > 0) {
                      <wen-notification-count class="wen-notification-count-big" [value]="notificationCount"></wen-notification-count>
                    }
                  </ng-container>
                }
              </wen-menu-entry>
            }
          </div>
        }
      }
      @if (config.personalEntries$ | async; as personalEntries) {
        @if (personalEntries.length) {
          <div menuEntryLayoutGroup
            >
            @for (entry of personalEntries; track entry) {
              <wen-menu-entry (click)="handleEntryClick(entry.entryId)" [attr.entry-id]="entry.entryId"
                *wenComingSoonFeature="entry.comingSoonFeature">
                <wen-icon [iconName]="entry.iconName" [fillColor]="entry.fillColor" [color]="'white'"
                  class="wen-icon-medium" menuEntryIcon>
                </wen-icon>
                <span portal-body-1 menuEntryTitle>{{ entry.translationKey | translate }}</span>
                @if (entry.notificationConfig?.count$ | async; as notificationCount) {
                  <ng-container menuEntryDescription>
                    @if (notificationCount > 0) {
                      <wen-notification-count class="wen-notification-count-big" [value]="notificationCount"></wen-notification-count>
                    }
                  </ng-container>
                }
              </wen-menu-entry>
            }
          </div>
        }
      }
      @if (isTrademarkVisible) {
        <div class="wen-user-profile-view-trademark">
          <div portal-subheading-1>{{ 'USER_PROFILE_TRADEMARK' | translate }}</div>
          <div class="wen-user-profile-view-logo"></div>
        </div>
      }
    </div>
  </div>
</div>
