import { VideoThumbnailGenerator } from 'browser-video-thumbnail-generator';
import { Directive, ElementRef, forwardRef, Input, Renderer2, OnInit, OnDestroy } from '@angular/core';
import { MediaType, WeLocalImageUrlTransformator } from '@portal/wen-backend-api';
import { Dimensions2D, FitType, smartDistinctUntilChanged, ObjectFit, ThumbnailSizeHelperProvider } from '@portal/wen-components';
import { fromEvent, map, Observable, ReplaySubject, Subject, switchMap, takeUntil, from, of, catchError, merge } from 'rxjs';
import { WenNativeApi } from '@portal/wen-native-api';
import { MediaEmbedThumbnail, MediaSize, MediaViewParams } from '../models/models';
import { isNullOrUndefined } from '../../../../../../../core/common/operators/null-check-util';

const MIN_MEDIA_WIDTH = 225;
const MIN_MEDIA_HEIGHT = 90;

const AUDIO_PLAYER_WIDTH = 332;
const AUDIO_PLAYER_HEIGHT = 54;
const DEFAULT_MEDIA_WIDTH = 16;
const DEFAULT_MEDIA_HEIGHT = 9;
const DOCUMENT_WIDTH = 332;
const DOCUMENT_HEIGHT = 70;

export abstract class ThumbnailProvider {
  thumbnail$: Observable<MediaEmbedThumbnail>;
}
@Directive({
  selector: '[thumbnail-evaluator]',
  providers: [{
    provide: ThumbnailProvider,
    useExisting: forwardRef(() => ThumbnailEvaluatorDirective)
  }]
})
export class ThumbnailEvaluatorDirective extends ThumbnailProvider implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();
  private viewParams$ = new ReplaySubject<MediaViewParams>(1);

  readonly thumbnail$ = this.viewParams$.pipe(
    switchMap((params) => {
      return this.readDimensions(params).pipe(
        switchMap(({ url, width, height, imageElement, forbidScaling }) => {
          const objectFit = forbidScaling ? ObjectFit.FILL_WIDTH : null;
          return this.thumbnailSizeHelper.calculateSize(width, height, objectFit).pipe(
            map((config) => {
              const thumbnail = {
                ...config,
                url: this.getProperThumbnailUrl(params, config.scaledDimensions, url),
                imageElement,
              };
              return thumbnail as MediaEmbedThumbnail;
            })
          );
        })
      );
    })
  );

  @Input('thumbnail-evaluator') set viewParams(value: MediaViewParams) {
    this.viewParams$.next(value);
  }

  constructor(
    private element: ElementRef<HTMLElement>,
    private welocalImageUrlTransformator: WeLocalImageUrlTransformator,
    private renderer: Renderer2,
    private wenNativeApi: WenNativeApi,
    private thumbnailSizeHelper: ThumbnailSizeHelperProvider,
  ) {
    super();
  }

  ngOnInit() {
    this.thumbnail$.pipe(
      smartDistinctUntilChanged(),
      takeUntil(this.onDestroy$)
    ).subscribe(({ canvasDimensions: { height }, minWidth }) => {
        this.renderer.setStyle(this.element.nativeElement, 'height', `${height}px`);
        this.renderer.setStyle(this.element.nativeElement, 'width', `100%`);
        this.renderer.setStyle(this.element.nativeElement, 'min-width', `${minWidth}px`);
    });
  }

  private getProperThumbnailUrl(params: MediaViewParams, scaledDimensions: Dimensions2D & { fitType: FitType }, url: string) {
    if (this.welocalImageUrlTransformator.hasPlaceholder(params.thumbnailUrl)) {
      if (scaledDimensions.fitType === FitType.TOO_WIDE) {
        url = this.welocalImageUrlTransformator.transform(params.thumbnailUrl, { height: scaledDimensions.height });
      } else {
        url = this.welocalImageUrlTransformator.transform(params.thumbnailUrl, { width: scaledDimensions.width });
      }
    }
    return url;
  }

  private readDimensions(params: MediaViewParams): Observable<MediaSize> {
    const hasDimensions = params?.dimensions?.height && params?.dimensions?.width;
    const fallback = hasDimensions ? of({
      url: params?.thumbnailUrl || null,
      width: params.dimensions.width,
      height: params.dimensions.height
    }) : this.createDefaultSize();

    if (params.embedType === MediaType.ERROR) {
      return this.createMinimalSize();
    }

    if (params.mediaType === MediaType.AUDIO) {
      return this.audioDimensions();
    }
    if (params.mediaType === MediaType.DOCUMENT) {
      return this.documentDimensions();
    }
    if (params.mediaType === MediaType.VIDEO && !params.thumbnailUrl) {
      return this.readVideoThumbnail(params.sourceUrl, fallback);
    }
    if (hasDimensions) {
      return fallback;
    }
    return this.readImage(params.thumbnailUrl, fallback);
  }

  private readImage(url: string, fallback: Observable<MediaSize>): Observable<MediaSize> {
    const img = new Image();
    img.src = url;
    return merge(
      fromEvent(img, 'load').pipe(
        map(event => {
          const currentTarget = event.currentTarget as HTMLImageElement;
          return {
            url,
            width: currentTarget.naturalWidth,
            height: currentTarget.naturalHeight,
            imageElement: currentTarget
          };
        })
      ),
      fromEvent(img, 'error').pipe(
        switchMap(() => fallback)
      )
    );
  }

  private readVideoThumbnail(url: string, fallback: Observable<MediaSize>): Observable<MediaSize> {
    if (isNullOrUndefined(url)) {
      return fallback;
    }
    url = (!url.startsWith('blob:') && this.wenNativeApi.isIOS()) ? url.concat('t=0.001') : url;
    const generator = new VideoThumbnailGenerator(url);
    return from(generator.getThumbnail('start')).pipe(
      map(({ thumbnail, width, height }) => {
        return {
          url: thumbnail,
          width,
          height
        };
      }),
      catchError(() => fallback)
    );
  }

  private audioDimensions(): Observable<MediaSize> {
    return of({
      width: AUDIO_PLAYER_WIDTH,
      height: AUDIO_PLAYER_HEIGHT,
      url: null,
      forbidScaling: true
    });
  }

  private documentDimensions(): Observable<MediaSize> {
    return of({
      width: DOCUMENT_WIDTH,
      height: DOCUMENT_HEIGHT,
      url: null,
      forbidScaling: true
    });
  }

  private createDefaultSize(): Observable<MediaSize> {
    return of({
      width: DEFAULT_MEDIA_WIDTH,
      height: DEFAULT_MEDIA_HEIGHT,
      url: null
    });
  }

  private createMinimalSize(): Observable<MediaSize> {
    return of({
      width: MIN_MEDIA_WIDTH,
      height: MIN_MEDIA_HEIGHT,
      url: null,
      forbidScaling: true,
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
