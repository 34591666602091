import { Pipe, PipeTransform } from '@angular/core';
import { GeoAutocompleteFeature, GeoFeatureEntityType } from '@portal/wen-backend-api';
import { LocationFormat, LocationFormatResult, LocationFormatter } from './location-formatter.service';
import { Icon } from '@portal/wen-components';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'locationFormatter',
})
export class LocationFormatterPipe implements PipeTransform {

  constructor(
    private readonly locationFormatter: LocationFormatter
  ) { }

  transform(location: GeoAutocompleteFeature, format: LocationFormat = 'singleline'): LocationFormatResult {
    return this.locationFormatter.format(location, format);
  }

}

@Pipe({
  name: 'locationIconClassifier',
})
export class LocationIconClassifierPipe implements PipeTransform {

  constructor(
    private readonly translateService: TranslateService
  ) { }

  transform(location: GeoAutocompleteFeature): Icon {
    const type = location?.properties?.geoEntityType;
    switch (type) {
      case GeoFeatureEntityType.AIRPORT:
        return { name: 'plane', altText: this.translateService.instant('LOCATION_AIRPORT_ICON') };
      case GeoFeatureEntityType.TRAIN_STATION:
        return { name: 'train', altText: this.translateService.instant('LOCATION_TRAIN_ICON') };
      case GeoFeatureEntityType.ATTRACTION:
        return { name: 'camera', altText: this.translateService.instant('LOCATION_ATTRACTION_ICON') };
      case GeoFeatureEntityType.USER_HOME:
        return { name: 'home_inactive', altText: this.translateService.instant('LOCATION_HOME_ICON') };
      default:
        return { name: 'location', altText: this.translateService.instant('LOCATION_ADDRESS_ICON') };
    }
  }

}
