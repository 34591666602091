import { MonoTypeOperatorFunction, distinctUntilChanged } from 'rxjs';
import { isEqual } from 'lodash';

export function distinctUntilKeysChanged<T>(keys: (keyof T)[]): MonoTypeOperatorFunction<T> {
  return (source$) => source$.pipe(
    distinctUntilChanged((prev, curr) =>
      keys.every(key => isEqual(prev[key], curr[key]))
    )
  );
}

export function distinctUntilKeysChangedInArray<T>(keys: (keyof T)[]): MonoTypeOperatorFunction<T[]> {
  return (sourceArray$) => sourceArray$.pipe(
    distinctUntilChanged((prevArr, currArr) => {
      if (prevArr.length !== currArr.length) {
        return false;
      }
      return prevArr.every((prev, index) => {
        return keys.every(key => isEqual(prev[key], currArr[index][key]));
      });
    })
  );
}
