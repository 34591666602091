import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { generateId } from '@portal/wen-backend-api';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { DateTime } from 'luxon';
import { dateFormats } from '../../../../../../core/common/date/date-util';
import { FilterId } from '../../../../../../core/common/types/filter-id';
import { selectorWithParam } from '../../../../../../core/common/util/selector-with-param';
import { selectActiveFilterById } from '../../../../../../core/store/filter/filter.selectors';
import { FilterEntityType } from '../../../../../../core/store/filter/models/filter';
import { RootState } from '../../../../../../core/store/root/public-api';
import { FilterViewType } from '../../../../../../shared/components/filter-view/components/filter-selector/custom-filter-view';
import { FilterSelectorOpener } from '../../../../../../shared/components/filter-view/components/filter-selector/filter-selector-opener';
import { FilterSelectorData } from '../../../../../../shared/components/filter-view/components/filter-selector/filter-selector.component';
import { Categorized, FilterCategory, Filterable } from '../../../../../../shared/components/filter-view/models/filter-item';
import { EventDatesLoader } from '../../../../providers/event-dates-loader';

@Injectable()
export class EventDateFilterDataSource {

  currentActiveEventDateFilter$ = this.store.pipe(
    selectorWithParam(selectActiveFilterById, FilterEntityType.EVENT_LISTS, FilterId.EVENT_DATES),
    smartDistinctUntilChanged()
  );

  get fallbackAllDateFilter(): Categorized<Filterable> {
    return {
      id: null,
      name: this.translateService.instant('EVENT_FILTER_DATES_SELECTION_OPTION_ALL_CATEGORY'),
      filter: null,
      category: FilterCategory.OTHER
    };
  }

  constructor(
    readonly store: Store<RootState>,
    private translateService: TranslateService,
    private filterSelectorOpener: FilterSelectorOpener,
    private eventDatesLoader: EventDatesLoader
  ) { }

  loadDates() {
    const dates: Categorized<Filterable<DateTime[], DateTime>>[] = this.eventDatesLoader.loadedDateFilters
      .map((dateFilter, index) => {
        return {
          ...dateFilter,
          name: this.translateService.instant(dateFilter.name),
          category: FilterCategory.QUICKSELECTION
        };
      }
      );
    dates.push({
      name: this.translateService.instant('DATE_RANGE_SELECT_LABEL'),
      id: generateId(),
      filter: [],
      viewType: FilterViewType.DATE_RANGE,
      customLabel: (selectedDates) => {
        return selectedDates.map(date => date.toFormat(dateFormats.NO_YEAR_NO_TIME)).join(' - ');
      },
      category: FilterCategory.OTHER
    });
    return dates;
  }

  openFilterSelector(activeCategory: Filterable) {
    const possibleValues = this.loadDates();
    const dialogData: FilterSelectorData = {
      visibleFilters: [this.fallbackAllDateFilter, ...possibleValues],
      activeFilterIds: [activeCategory?.id || this.fallbackAllDateFilter.id],
      headerTitle: this.translateService.instant('EVENT_FILTER_DATES_HEADER')
    };
    return this.filterSelectorOpener.openFilterSelector(dialogData);
  }
}
