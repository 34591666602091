import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormProgressModule } from '../../shared/components/form-progress/form-progress.module';
import { LoginModule } from '../login/login.module';
import { SignInComponent } from './components/signin/sign-in.component';
import { SignInInviteGuard } from './guards/sign-in-invite-guard';
import { SignInInviteDialogOpener } from './services/sign-in-invite-dialog-opener';
import { ActionComponent, WithVariantDirective } from '@portal/wen-components';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormProgressModule,
    LoginModule,
    ActionComponent,
    WithVariantDirective
  ],
  declarations: [
    SignInComponent
  ],
  exports: [
    SignInComponent
  ],
  providers: [
    SignInInviteGuard,
    SignInInviteDialogOpener
  ]
})
export class SignInModule { }
