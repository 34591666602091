import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { EmbedDTOType, EmbedDTOTypes, isForwardEmbed, MessageModificationState, QuoteData, RoomMessageDTO } from '@portal/wen-backend-api';
import { first, map, Observable, switchMap } from 'rxjs';
import { chatViewIdentifier } from '../../../views/chat/tokens';
import { selectorWithParam } from '../../common/util/selector-with-param';
import { updateChatDraftMessage } from '../../store/chat/chat.actions';
import { selectChatMessageById } from '../../store/chat/selectors/chat-message-selectors';
import { RootState } from '../../store/root/public-api';
import { selectRouteParam } from '../../store/root/root.selectors';
import { QuoteHandler } from './quote-handler';
import { isNullOrUndefined } from '../../common/operators/null-check-util';
import { TranslateService } from '@ngx-translate/core';
import { ChatMessageEntity } from '../../store/chat/chat.state';

export type QuoteInfo = Pick<RoomMessageDTO, 'content' | 'embeds'>;

@Injectable()
export class ChatQuoteHandler extends QuoteHandler {

  private whitelistedEmbeds: EmbedDTOType[] = [EmbedDTOType.MEDIA, EmbedDTOType.LINK, EmbedDTOType.POLL];

  private store = inject(Store<RootState>);
  private translationService = inject(TranslateService);

  retrieveQuote(quoteData: QuoteData): Observable<QuoteData> {
    const quoteData$: Observable<QuoteData> = this.store.pipe(
      select(selectRouteParam(chatViewIdentifier)),
      switchMap((roomId) => {
        return this.store.pipe(
          selectorWithParam(selectChatMessageById, roomId, quoteData.id),
          map((chatMessage) => {
            if (isNullOrUndefined(chatMessage)) {
              return quoteData;
            }
            const messageAsQuote = this.convertMessageToQuote(chatMessage);
            return this.resolveQuote(messageAsQuote);
          })
        );
      })
    );
    return quoteData$.pipe(
      first()
    );
  }

  initiateQuote(quoteData: QuoteData): void {
    this.store.pipe(
      select(selectRouteParam(chatViewIdentifier)),
      first(),
    ).subscribe((contextId) => {
      this.store.dispatch(updateChatDraftMessage({
        message: {
          contextId,
          quote: {
            quoteData: this.resolveQuote(quoteData),
            type: EmbedDTOType.QUOTE
          }
        }
      }));
    });
  }

  private convertMessageToQuote(chatMessage: ChatMessageEntity): QuoteData {
    const { messageContent, state } = chatMessage;
    return {
      id: chatMessage.eventId,
      author: chatMessage.insertUser.name,
      isDeleted: this.isMessageDeleted(state),
      content: this.isMessageDeleted(state)
        ? this.translationService.instant('CHAT_MESSAGE_DELETED')
        : messageContent.content,
      embeds: messageContent?.embeds
    };
  }

  private resolveQuote(quoteData: QuoteData): QuoteData {
    const quoteInfo = this.retrieveQuoteInfoFrom(quoteData?.embeds);
    return {
      id: quoteData.id,
      author: quoteData.author,
      content: quoteData.content || quoteInfo?.content,
      embeds: quoteInfo?.embeds,
      isDeleted: quoteData.isDeleted
    };
  }

  private retrieveQuoteInfoFrom(embeds: EmbedDTOTypes[]): QuoteInfo {
    if (isNullOrUndefined(embeds) && !this.whitelistedEmbeds?.length || !embeds?.length) {
      return null;
    }
    const mappedEmbeds = embeds.map(embed => {
      if (isForwardEmbed(embed)) {
        return {
          content: embed.forwardData.data.content,
          embeds: embed.forwardData.data?.embeds ?? []
        };
      }
      return {
        content: null,
        embeds: [embed]
      };
    });
    return {
      content: mappedEmbeds[0].content,
      embeds: this.filterForQuotableEmbeds(mappedEmbeds[0].embeds)
    };
  }

  private filterForQuotableEmbeds(embeds: EmbedDTOTypes[]): EmbedDTOTypes[] {
    if (!embeds) {
      return;
    }
    return embeds.filter(embed => this.whitelistedEmbeds.includes(embed.type));
  }

  private isMessageDeleted(state: MessageModificationState): boolean {
    return state === MessageModificationState.DELETED;
  }

}
