import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { SocketIoService, UserProfileResponse } from '@portal/wen-backend-api';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { distinctUntilChanged, filter, first, map, share, switchMap, tap } from 'rxjs/operators';
import { firstExisty } from '../../common/operators/first-existy';
import { PermissionLevel } from '../../services/user-management/permission-level';
import { updateAppPermission } from '../apps/apps.actions';
import { logout } from '../auth/auth.actions';
import { selectCurrentUserData } from '../auth/auth.selectors';
import { updateChannelPermission } from '../channel/channel.actions';
import { RootState } from '../root/public-api';
import { createChannelPermissionEffect } from './effects/channel-permission.effect';
import { AppPermission, ChannelPermission } from './models/Permission';
import { deleteUserProfile, fetchSystemPrivileges, initiateLanguageChangeEmits, subscribeToPermissionUpdates, subscribeToProfileUpdates, updatePrivileges, updateUserAppPermission, updateUserChannelPermission, updateUserProfile } from './user.actions';
import { selectUserPrimaryLanguage } from './user.selectors';
import { existy } from '../../common/operators/existy';
import { WenLanguageService } from '@portal/wen-translation';

@Injectable()
export class UserEffects {

  private readonly userData$ = this.store.pipe(select(selectCurrentUserData), firstExisty(), share());

  subscribeToProfileUpdates$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeToProfileUpdates),
    switchMap(() => this.userData$),
    filter(userData => userData.permissionLevel === PermissionLevel.REGISTERED_USER),
    switchMap(({ userId }) => {
      this.socketIoService.user.profile.emit({ userId });
      return this.socketIoService.user.profile.listen.pipe(
        map((userProfile: UserProfileResponse) => userProfile),
        filter(userProfile => userProfile.userId === userId),
        filter(userProfile => userProfile.ok),
        map(userProfile => updateUserProfile({ userProfile }))
      );
    })
  ));

  deleteUserProfile$ = createEffect(() => this.actions$.pipe(
    ofType(deleteUserProfile),
    switchMap(() => this.userData$),
    switchMap(({ userId }) => {
      this.socketIoService.user.delete.emit({ userId });
      return this.socketIoService.user.delete.listen.pipe(
        filter(response => response.userId === userId && response.ok),
        first(),
        map(() => logout())
      );
    })
  ));

  fetchSystemPrivileges$ = createEffect(() => this.actions$.pipe(
    ofType(fetchSystemPrivileges),
    switchMap(() => {
      return this.socketIoService.system.listPrivileges.acknowledgement$().pipe(
        first(),
        filter(privileges => Array.isArray(privileges)),
        map(privileges => updatePrivileges({ privileges }))
      );
    })
  ));

  subscribeToChannelPrivilegeUpdates$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeToPermissionUpdates),
    switchMap(() => this.userData$),
    switchMap(({ userId }) => {
      this.socketIoService.system.channelPolicy.emit({ userId });
      return this.socketIoService.system.channelPolicy.listen.pipe(
        map((({ result, channelId }) => {
          if (channelId) {
            const permission: ChannelPermission = {
              canSend: result.send,
              canEdit: result.update,
              canDelete: result.remove,
              canHandleJoinRequests: result.invites,
              canListAdmins: result.listAdmin,
              canListContentProviders: result.listContentProvider,
              canListSubscribers: result.listSubscribers,
              canAddAdmins: result.addAdmin || result.addManagedAdmin,
              canAddContentProviders: result.addContentProvider,
              canDeleteAdmins: result.deleteAdmin || result.deleteManagedAdmin,
              canDeleteContentProviders: result.deleteContentProvider,
              canDeleteComment: result.deleteComment,
              canDeleteAnyMessage: result.deleteMessage,
              canDeleteOwnMessage: result.deleteOwnMessage,
              canEditAnyMessage: result.editMessage,
              canEditOwnMessage: result.editOwnMessage,
              canUpdateRestrictions: result.updateRestriction,
              canUnsubscribe: result.unsubscribe,
              canRemoveSubscriber: result.removeSubscriber,
            };
            return updateChannelPermission({ channelId, permission });
          } else {
            return updateUserChannelPermission({ permission: { canCreate: result.create } });
          }
        }))
      );
    })
  ));

  subscribeToAppPrivilegeUpdates$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeToPermissionUpdates),
    switchMap(() => this.userData$),
    switchMap(({ userId }) => {
      this.socketIoService.system.appPolicy.emit({ userId });
      return this.socketIoService.system.appPolicy.listen.pipe(
        map((({ result, appId }) => {
          if (appId) {
            const permission: AppPermission = {
              canEdit: result.update,
              canDelete: result.remove,
            };
            return updateAppPermission({ appId, permission });
          } else {
            return updateUserAppPermission({ permission: { canCreate: result.create } });
          }
        }))
      );
    })
  ));

  channelPermission$ = createChannelPermissionEffect(this.actions$, this.store, this.socketIoService, this.userData$);

  initiateLanguageChangeEmits$ = createEffect(() => this.actions$.pipe(
    ofType(initiateLanguageChangeEmits),
    switchMap(() => {
      return this.store.pipe(
        select(selectCurrentUserData),
        filter(userData => userData && userData.permissionLevel !== PermissionLevel.ANONYMOUS),
        smartDistinctUntilChanged(),
        switchMap(() => this.store.pipe(
          select(selectUserPrimaryLanguage),
          existy(),
          distinctUntilChanged(),
          tap(languageCode => {
            this.languageService.setLanguage(languageCode);
            this.socketIoService.user.language.primary.emit({ languageCode });
          })
        ))
      );
    })
  ), { dispatch: false });

  constructor(
    private readonly actions$: Actions,
    private readonly socketIoService: SocketIoService,
    private readonly store: Store<RootState>,
    private readonly languageService: WenLanguageService,
  ) { }

}
