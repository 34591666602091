@if (channel) {
  <div class="wen-navigate-to-channel-button-content" (click)="navigateToChannel()">
    <wen-circle-image-view [imageUrl]="channel?.icon"
      [placeholderText]="channel.title" [fontSize]="18"></wen-circle-image-view>
    <div class="wen-navigate-to-channel-button-text">
      <div class="wen-navigate-to-channel-button-text-header" portal-subheading-2>{{ 'CHANNEL_FEATURED_NAVIGATE_TO_CHANNEL_BUTTON_TITLE' |
        translate }}</div>
      <div class="wen-navigate-to-channel-button-text-description" portal-subheading-1>{{ channel.title }}</div>
    </div>
    <button class="wen-navigate-to-channel-button-button" portal-button inlined>
      <div portal-title>{{ 'CHANNEL_FEATURED_NAVIGATE_TO_CHANNEL_BUTTON_LABEL' | translate }}</div>
    </button>
  </div>
}
