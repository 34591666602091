<h1 *isDesktopView class="wen-discover-app-list-header-label">{{ 'APP_DISCOVER_HEADER_LABEL' | translate }}</h1>

@if (loadResult$ | async; as loadResult) {
  @if (loadResult.data; as apps) {
    @if (isFilteringEnabled) {
      <wen-filter-view [formGroup]="filterGroup">
        <wen-filter-select-component [formControlName]="appCategoryControlName"
                                     [defaultFilter]="categoryFilterService.fallbackAllCategoryFilter" filter-element
                                     [filterEntityType]="filterEntityType" (click)="openAppCategoryFilterSelector()"></wen-filter-select-component>
        @if (isFilteringRegionEnabled) {
          <wen-filter-select-component [formControlName]="appRegionControlName"
                                       [defaultFilter]="regionFilterService.fallbackAllRegionFilter" filter-element
                                       [filterEntityType]="filterEntityType" (click)="openAppRegionFilterSelector()"></wen-filter-select-component>
        }
      </wen-filter-view>
    }
    <div class="wen-discover-app-list">
      @if (apps.length) {
        <wen-app-list (appClicked)="onAppClick($event)" [apps]="apps"></wen-app-list>
      } @else {
        @if (loadResult.mode === 'search') {
          <wen-empty-search-result></wen-empty-search-result>
        }
      }
    </div>
  }
}
