@if (sectionItems$ | async; as sectionItems) {
  @if (sectionItems.length) {
    <wen-section [isNativeApp]="isNativeApp">
      @if (sectionName) {
        <ng-container section-header-title>{{ sectionName }}</ng-container>
      }
      <div class="wen-section-show-all-title" section-header-action (click)="showAllElements()">{{ 'SHOW_ALL_LABEL' |
      translate }}</div>
      @for (item of sectionItems; track trackById($index, item)) {
        <wen-card class="wen-card-narrow"
          [direction]="'column'" [elevate]="true"
          (click)="navigateToChannelView(item.id)" section-content
          [wen-test-id]="'channel-explore-card'">
          <wen-circle-image-view [imageUrl]="item.imageUrl" [placeholderText]="item.title"
                                 [imageSize]="imageSize" card-image></wen-circle-image-view>
          <div card-title>{{item.title}}</div>
          <div card-description>{{ 'SUBSCRIBER' | translate: {count: item.subscriptions | thousandFormat} }}</div>
          <wen-quick-action-panel
            class="wen-icon-medium"
            [variant]="item.quickActionVariant"
            [icon]="item.quickActionIcon"
            (quickActionTriggered)="onQuickActionClick(item)"
            card-floating-panel>
          </wen-quick-action-panel>
          @if (item.readingContent || item.geo) {
            <wen-channel-indicator [indicatorType]="getIndicatorType(item)" card-indicator></wen-channel-indicator>
          }
        </wen-card>
      }
      @if (sectionItems.length === sectionLimit) {
        <wen-card class="last-card wen-card-narrow" [elevate]="true" section-content
          (click)="showAllElements()">
          <div card-title>
            <span wen-primary-color>{{ 'SHOW_ALL_LABEL' | translate }}</span>
          </div>
        </wen-card>
      }
    </wen-section>
  }
}
