import { PlatformLocation } from '@angular/common';
import { NavigationExtras, Router } from '@angular/router';
import { LinkOpenerOptions, LinkOpenerResult } from '@portal/react-native-api';
import { AppDTO, WalletCredentialType } from '@portal/wen-backend-api';
import { WenNativeApi } from '@portal/wen-native-api';
import { SectionProps } from '../../common/util/section.model';
import { WenColorService } from '../util/wen-color.service';
import { AppNavigator } from './app-navigator';
import { WenRouteUrlBuilder } from './outlet-specific/wen-route-url-builder';
import { ReaderParams } from './types/reader-params';

export type PassFilterData = SectionProps | 'clearFilter';

type NativeUrlOpeningResult = {
  canHandleNatively: boolean;
  openUrlResult: Promise<LinkOpenerResult> | null;
};

export abstract class WenNavigationHelper {

  constructor(
    protected readonly router: Router,
    protected readonly location: PlatformLocation,
    protected readonly wenNativeApi: WenNativeApi,
    protected readonly wenColorService: WenColorService,
    protected readonly routeUrlBuilder: WenRouteUrlBuilder,
    protected readonly appNavigator: AppNavigator,
  ) { }

  abstract navigateToWelcomeScreen(extras?: NavigationExtras): void;
  abstract navigateToDialogChatCreation(): void;
  abstract navigateToDialogChatCreationFromShare(): void;
  abstract navigateToDialogChatCreationFromForward(): void;
  abstract navigateToGroupChatCreation(): void;
  abstract navigateToAddGroupChatInfo(): void;
  abstract navigateToGroupChatDetail(chatId: string, extras?: NavigationExtras): void;
  abstract navigateToChatList(extras?: NavigationExtras): void;
  abstract navigateToChatView(chatId: string, extras?: NavigationExtras): void;
  abstract navigateToChatEdit(chatId: string, extras?: NavigationExtras): void;
  abstract navigateToGroupChatAddMembers(chatId: string): void;
  abstract navigateToAutoReplyEdit(): void;

  abstract navigateToEventDiscoveryWithFilter(filterData: PassFilterData): void;
  abstract navigateToExternalUrl(url: string): void;

  abstract navigateToApp(app: AppDTO): void;
  abstract navigateToAppDetail(appId: string, extras?: NavigationExtras, navigateToList?: boolean): void;
  abstract navigateToAppDiscoverWithFilter(filterData: PassFilterData): void;

  abstract navigateToEmbeddedPage(url: string): void;

  abstract navigateToChannelList(extras?: NavigationExtras): void;
  abstract navigateToChannelView(channelId: string, extras?: NavigationExtras, navigateToList?: boolean): void;
  abstract navigateToChannelPostEditor(channelId: string, messageId?: string, extras?: NavigationExtras): void;
  abstract navigateToChannelPostLocationSelector(channelId: string, extras?: NavigationExtras): void;
  abstract navigateToChannelFeaturedView(): void;
  abstract navigateToChannelDiscover(): void;
  abstract navigateToChannelDiscovery(filterData?: PassFilterData, extras?: NavigationExtras): void;
  abstract navigateToChannelExplore(): void;
  abstract navigateToChannelDetail(channelId: string, extras?: NavigationExtras): void;
  abstract navigateToChannelSettings(channelId: string, extras?: NavigationExtras): void;
  abstract navigateToChannelEdit(channelId: string, extras?: NavigationExtras): void;
  abstract navigateToChannelScheduledMessages(channelId: string, extras?: NavigationExtras): void;
  abstract navigateToChannelVisibilitySettings(channelId: string, extras?: NavigationExtras): void;
  abstract navigateToChannelRestrictionSettings(channelId: string, extras?: NavigationExtras): void;
  abstract navigateToChannelInteractionSettings(channelId: string, extras?: NavigationExtras): void;
  abstract navigateToChannelAddWelcome(extras?: NavigationExtras): void;
  abstract navigateToChannelMessageDetail(messageId: string): void;
  abstract navigateToAddChannelInfo(): void;
  abstract navigateToAddChannelVisibility(): void;
  abstract navigateToAddChannelRestrictions(): void;
  abstract navigateToAddChannelInteractions(): void;
  abstract navigateToPendingJoinRequestsPage(channelId: string, extras?: NavigationExtras): void;
  abstract navigateToAdminListPage(channelId: string, extras?: NavigationExtras): void;
  abstract navigateToAddAdminPage(channelId: string, extras?: NavigationExtras): void;

  abstract navigateToUserProfile(extras?: NavigationExtras): void;
  abstract navigateToUserProfileRead(showUserProfileDashboard: boolean): void;
  abstract navigateToUserProfileEdit(): void;
  abstract navigateToUserProfileEditLocationSelector(): void;
  abstract navigateToUserChannelRecommendations(): void;
  abstract navigateToUserProfileLanguage(): void;
  abstract navigateToUserProfileSettings(): void;
  abstract navigateToUserCredentialsMenu(): void;
  abstract navigateToCredentialDetails(credentialType: WalletCredentialType): void;
  abstract navigateToHelp(): void;
  abstract navigateToSettings(): void;
  abstract navigateToFAQ(): void;
  abstract navigateToMarkdown(): void;
  abstract navigateToHotline(): void;
  abstract navigateToInviteToNetwork(extras?: NavigationExtras): void;
  abstract navigateToCrmHomeBackground(extras?: NavigationExtras): void;
  abstract navigateToCrmInviteToNetwork(extras?: NavigationExtras): void;
  abstract navigateToCrmInviteToChannelSelector(extras?: NavigationExtras): void;
  abstract navigateToCrmInviteToChannel(extras?: NavigationExtras): void;
  abstract navigateToCrmCreateChat(extras?: NavigationExtras): void;
  abstract navigateToCrmActionSelector(extras?: NavigationExtras): void;
  abstract navigateToCrmDistributeChat(extras?: NavigationExtras): void;
  abstract navigateToCrmProfileReadOnlyView(extras?: NavigationExtras): void;

  abstract navigateToErrorPage(extras?: NavigationExtras): void;
  abstract navigateToEmailSettings(extras?: NavigationExtras): void;
  abstract navigateToEmailEditPage(extras?: NavigationExtras): void;
  abstract navigateToContentProvidersPage(channelId: string, extras?: NavigationExtras): void;
  abstract navigateToSubscriberListPage(channelId: string, extras?: NavigationExtras): void;
  abstract navigateToAddContentProviderPage(channelId: string, extras?: NavigationExtras): void;
  abstract navigateToReaderView(readerParams: ReaderParams, extras?: NavigationExtras): void;
  abstract navigateToTextView(readerParams: ReaderParams, extras?: NavigationExtras): void;

  abstract getNavigateToHomeLink(): any;
  abstract getNavigateToEventListLink(): any;
  abstract getNavigateToChatListLink(): any;
  abstract getNavigateToChannelListLink(): any;
  abstract getNavigateToAppListLink(): any;

  abstract navigateToRegistrationStart(isFromApp?: boolean, inviteId?: string): any;

  abstract navigateToSignIn(returnUrl?: string): void;
  abstract navigateToPopupSignIn(returnUrl?: string): void;
  abstract navigateToInviteSignIn(inviteId: string): void;

  abstract navigateToExclusiveInvite(redirectAfterCode?: string, extras?: NavigationExtras): void;

  abstract navigateToOauthLogin(extras?: NavigationExtras): void;
  abstract navigateToWalletCreateCredential(type: WalletCredentialType, extras?: NavigationExtras): void;
  abstract navigateToInitiateIssuance(returnUrl: string, credentialOffer: string, extras?: NavigationExtras): void;
  abstract getWalletInitiateIssuanceRoute(returnUrl: string): string;
  abstract doUserChannelListBackNavigation(): void;
  abstract doUserAppListBackNavigation(): void;
  abstract doUserChatListBackNavigation(): void;
  abstract navigateToCrmChatView(roomId: string): void;

  protected openNativeUrl(url: string): boolean {
    const isNative = this.wenNativeApi.isReactNativeApp();
    if (isNative) {
      const toolbarBackgroundColor = this.wenColorService.headerBackgroundColor;
      const toolbarTextColor = this.wenColorService.headerTextColor;
      this.wenNativeApi.openUrl(url, {
        toolbarBackgroundColor,
        toolbarTextColor,
      });
    }
    return isNative;
  }

  protected tryOpenUrlInNative(url: string, options?: Partial<LinkOpenerOptions>): NativeUrlOpeningResult | null {
    const isNative = this.wenNativeApi.isReactNativeApp();
    if (isNative) {
      const toolbarBackgroundColor = this.wenColorService.headerBackgroundColor;
      const toolbarTextColor = this.wenColorService.headerTextColor;
      const openUrlResult = this.wenNativeApi.openUrl(url, {
        toolbarBackgroundColor,
        toolbarTextColor,
        ...options,
      });
      return {
        canHandleNatively: true,
        openUrlResult
      };
    }
    return null;
  }

  canOpenApp(app: AppDTO) {
    const isNative = this.wenNativeApi.isReactNativeApp();
    if (isNative) {
      return Boolean(app.nativeUri || app.uri);
    }
    return Boolean(app.uri);
  }

  navigateToAppStore(options: Partial<LinkOpenerOptions>, isAndroid: boolean) {
    if (isAndroid) {
      const { androidPlayStoreId } = options;
      this.navigateToUrlWithFallback(`https://play.google.com/store/apps/details?id=${androidPlayStoreId}`, options);
      return;
    } else {
      const { iosAppName, iosAppStoreId } = options;
      this.navigateToUrlWithFallback(`https://apps.apple.com/de/app/${iosAppName}/id${iosAppStoreId}`, options);
      return;
    }
  }

  navigateToUrlWithFallback(
    url: string, options: Partial<LinkOpenerOptions> = {}, newTab: boolean = true
  ) {
    if (newTab) {
      const nativeResult = this.tryOpenUrlInNative(url, options);
      if (!nativeResult?.canHandleNatively) {
        window.open(url, '_blank');
      } else {
        return nativeResult.openUrlResult;
      }
    } else {
      location.assign(url);
    }
  }

  navigateToCallPhoneNumber(phoneNumber: string) {
    const phoneTo = `tel:${phoneNumber}`;
    if (!this.tryOpenUrlInNative('', { nativeUri: phoneTo })) {
      window.open(phoneTo);
    }
  }

  navigateToSendEmailTo(emailAddress: string) {
    const mailTo = `mailto:${emailAddress}`;
    if (!this.tryOpenUrlInNative('', { nativeUri: mailTo })) {
      window.open(mailTo);
    }
  }

  protected navigateWithHistoryStack(commands: any[], extras: NavigationExtras = {}) {
    this.appNavigator.enterStackAndNavigateByCommand(commands, extras);
  }
}
