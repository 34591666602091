import { PlatformLocation } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationExtras, Params, Router } from '@angular/router';
import { AppDTO, FaqType, WalletCredentialType } from '@portal/wen-backend-api';
import { WenRoutesProvider } from '../../../../frame/routing/routes/routes.provider';
import { WenRouteId } from '../../../../frame/routing/types';
import { isNullOrUndefined } from '../../../common/operators/null-check-util';
import { WenNativeApi } from '@portal/wen-native-api';
import { WenColorService } from '../../util/wen-color.service';
import { AppNavigator } from '../app-navigator';
import { CHAT_CREATE_FROM_FORWARD_QUERY_PARAM, CREDENTIAL_TYPE, EXCLUSIVE_INVITE_REDIRECT_AFTER_CODE, FAQ_TYPE_QUERY_PARAM, FILTER_QUERY_PARAM, INVITATION_ID_QUERY_PARAM, READER_DOC_ID_QUERY_PARAM, READER_START_PAGE_QUERY_PARAM, REGISTRATION_FROM_APP_FLAG_QUERY_PARAM, SIGNIN_RETURN_URL } from '../query-params';
import { PassFilterData, WenNavigationHelper } from '../types';
import { ReaderParams } from '../types/reader-params';
import { WenRouteUrlBuilder } from './wen-route-url-builder';
import { credentialOfferIdentifier, returnUrlIdentifier } from '../../../../views/wallet/tokens';

@Injectable()
export class MultiOutletNavigator extends WenNavigationHelper {

  constructor(
    readonly router: Router,
    readonly wenNativeApi: WenNativeApi,
    readonly wenColorService: WenColorService,
    readonly location: PlatformLocation,
    readonly routesProvider: WenRoutesProvider,
    readonly routeUrlBuilder: WenRouteUrlBuilder,
    readonly appNavigator: AppNavigator,
  ) {
    super(router, location, wenNativeApi, wenColorService, routeUrlBuilder, appNavigator);
  }

  closeDialog(extras?: NavigationExtras) {
    this.router.navigate([{
      outlets: {
        dialog: null
      }
    }], extras);
  }

  navigateToWelcomeScreen(extras?: NavigationExtras) {
    const startPage = this.routesProvider.getMultiOutletStartPage();
    this.router.navigateByUrl(startPage, extras);
  }

  navigateToChatList() {
    this.appNavigator.navigateToRoute({
      dialog: null,
      primary: WenRouteId.DASHBOARD,
      sidebar: WenRouteId.CHAT_LIST
    });
  }

  navigateToDialogChatCreation(extras?: NavigationExtras): void {
    this.navigateWithHistoryStack([{
      outlets: {
        primary: ['chat', 'create'],
        dialog: null
      }
    }], extras);
  }

  navigateToDialogChatCreationFromShare(): void {
    this.navigateToDialogChatCreation({ state: { fromDeepLink: true } });
  }

  navigateToDialogChatCreationFromForward(): void {
    const queryParams: Params = { [CHAT_CREATE_FROM_FORWARD_QUERY_PARAM]: true };
    this.navigateWithHistoryStack([{
      outlets: {
        primary: ['chat', 'create'],
        dialog: null
      }
    }], { queryParams });
  }

  navigateToGroupChatCreation(): void {
    this.navigateWithHistoryStack([{
      outlets: {
        dialog: ['chat', 'add-chat-users'],
      }
    }]);
  }

  navigateToAutoReplyEdit(): void {
    this.navigateWithHistoryStack([{
      outlets: {
        dialog: ['chat', 'auto-reply-edit'],
      }
    }]);
  }

  navigateToAddGroupChatInfo(extras?: NavigationExtras): void {
    this.router.navigate([{
      outlets: {
        dialog: ['chat', 'add-chat-info']
      }
    }], extras);
  }

  navigateToChatEdit(chatId: string, extras?: NavigationExtras) {
    this.router.navigate([{
      outlets: {
        dialog: ['chat', chatId, 'edit']
      }
    }], extras);
  }

  navigateToGroupChatDetail(chatId: string, extras?: NavigationExtras): void {
    this.router.navigate([{
      outlets: {
        dialog: ['chat', chatId, 'detail']
      }
    }], extras);
  }
  navigateToChatView(chatId: string, extras?: NavigationExtras) {
    this.router.navigate([{
      outlets: {
        primary: ['chat', chatId],
        sidebar: ['chat', 'list'],
        dialog: null
      }
    }], extras);
  }

  navigateToGroupChatAddMembers(chatId: string): void {
    this.router.navigate([{
      outlets: {
        dialog: ['chat', chatId, 'add-members']
      }
    }]);
  }

  navigateToApp(app: AppDTO) {
    const { id, nativeUri, uri, androidPlayStoreId, iosAppStoreId, iosAppName, embedded } = app;
    if (embedded) {
      this.router.navigate([{
        outlets: {
          primary: ['apps', id]
        }
      }]);
      return;
    }
    const nativeHandle = this.tryOpenUrlInNative(uri, { androidPlayStoreId, iosAppStoreId, iosAppName, nativeUri });
    if (!nativeHandle && id) {
      if (uri) {
        this.router.navigate([{
          outlets: {
            primary: ['apps', id]
          }
        }]);
      } else {
        this.navigateToAppDetail(id);
      }
    }
  }

  navigateToAppDetail(appId: string, extras?: NavigationExtras, navigateToList?: boolean) {
    const outlets = {
      dialog: ['apps', appId, 'detail'],
      sidebar: ['apps', 'list']
    };
    if (!navigateToList) {
      delete outlets.sidebar;
    }
    this.router.navigate([{ outlets }], extras);
  }

  navigateToAppDiscoverWithFilter(filterData?: PassFilterData) {
    const queryParams: Params = { [FILTER_QUERY_PARAM]: filterData };
    // TODO: Fix hacking of navigation extras: query params shouldn't be handles as part of extras
    this.router.navigate([{
      outlets: {
        sidebar: ['apps', 'discovery']
      }
    }], { queryParams });
  }

  navigateToEmbeddedPage(url: string) {
    const nativeHandle = this.openNativeUrl(url);
    if (!nativeHandle) {
      window.open(url, '_blank');
    }
  }

  navigateToChannelList(extras?: NavigationExtras) {
    this.router.navigate(this.getNavigateToChannelListLink(), extras);
  }

  navigateToChannelView(channelId: string, extras?: NavigationExtras, navigateToList?: boolean) {
    const outlets = {
      primary: ['channel', channelId],
      sidebar: ['channel', 'list'],
      dialog: null
    };
    if (!navigateToList) {
      delete outlets.sidebar;
    }
    this.router.navigate([{ outlets }], extras);
  }

  navigateToChannelPostEditor(channelId: string, messageId?: string, extras?: NavigationExtras) {
    const dialog = ['channel', channelId, 'post-editor'];
    if (messageId) {
      dialog.push(messageId);
    }
    this.router.navigate([{
      outlets: {
        dialog,
      }
    }], extras);
  }

  navigateToChannelPostLocationSelector(channelId: string, extras?: NavigationExtras) {
    this.router.navigate([{
      outlets: {
        dialog: ['channel', channelId, 'post-location-selector'],
      }
    }], extras);
  }

  navigateToChannelFeaturedView() {
    this.router.navigate([{
      outlets: {
        primary: ['channel', 'featured'],
        dialog: null
      }
    }]);
  }

  navigateToChannelDiscover() {
    this.router.navigate([{
      outlets: {
        sidebar: ['channel', 'discovery']
      }
    }]);
  }

  navigateToChannelDiscovery(filterData?: PassFilterData, extras?: NavigationExtras) {
    const queryParams: Params = { [FILTER_QUERY_PARAM]: filterData };
    this.router.navigate([{
      outlets: {
        sidebar: ['channel', 'discovery']
      }
    }], { queryParams, ...extras });
  }

  navigateToChannelExplore() {
    this.router.navigate([{
      outlets: {
        sidebar: ['channel', 'explore']
      }
    }]);
  }

  navigateToChannelDetail(channelId: string, extras?: NavigationExtras) {
    this.router.navigate([{
      outlets: {
        dialog: ['channel', channelId, 'detail']
      }
    }], extras);
  }

  navigateToChannelSettings(channelId: string, extras?: NavigationExtras) {
    this.router.navigate([{
      outlets: {
        dialog: ['channel', channelId, 'settings']
      }
    }], extras);
  }

  navigateToChannelEdit(channelId: string, extras?: NavigationExtras) {
    this.router.navigate([{
      outlets: {
        dialog: ['channel', channelId, 'edit']
      }
    }], extras);
  }

  navigateToChannelScheduledMessages(channelId: string, extras?: NavigationExtras) {
    this.router.navigate([{
      outlets: {
        primary: ['channel', channelId, 'schedules']
      }
    }], extras);
  }

  navigateToChannelVisibilitySettings(channelId: string, extras?: NavigationExtras) {
    this.router.navigate([{
      outlets: {
        dialog: ['channel', channelId, 'visibility-settings']
      }
    }], extras);
  }


  navigateToChannelRestrictionSettings(channelId: string, extras?: NavigationExtras) {
    this.router.navigate([{
      outlets: {
        dialog: ['channel', channelId, 'restriction-settings']
      }
    }], extras);
  }

  navigateToChannelInteractionSettings(channelId: string, extras?: NavigationExtras) {
    this.router.navigate([{
      outlets: {
        dialog: ['channel', channelId, 'interaction-settings']
      }
    }], extras);
  }

  navigateToChannelAddWelcome() {
    this.navigateWithHistoryStack([{
      outlets: {
        dialog: ['channel', 'add-welcome']
      }
    }]);
  }

  navigateToAddChannelInfo() {
    this.router.navigate([{
      outlets: {
        dialog: ['channel', 'add-channel-info']
      }
    }]);
  }

  navigateToAddChannelVisibility() {
    this.router.navigate([{
      outlets: {
        dialog: ['channel', 'add-channel-visibility']
      }
    }]);
  }

  navigateToAddChannelRestrictions() {
    this.router.navigate([{
      outlets: {
        dialog: ['channel', 'add-channel-restrictions']
      }
    }]);
  }

  navigateToAddChannelInteractions() {
    this.router.navigate([{
      outlets: {
        dialog: ['channel', 'add-channel-interactions']
      }
    }]);
  }

  navigateToChannelMessageDetail(messageId: string) {
    this.router.navigate([{
      outlets: {
        primary: ['message', messageId],
        sidebar: ['channel']
      }
    }]);
  }

  navigateToPendingJoinRequestsPage(channelId: string, extras?: NavigationExtras) {
    this.router.navigate([{
      outlets: {
        dialog: ['channel', channelId, 'pending-join-requests']
      }
    }], extras);
  }

  navigateToAdminListPage(channelId: string, extras?: NavigationExtras) {
    this.router.navigate([{
      outlets: {
        dialog: ['channel', channelId, 'admin-list']
      }
    }], extras);
  }

  navigateToAddAdminPage(channelId: string, extras?: NavigationExtras) {
    this.router.navigate([{
      outlets: {
        dialog: ['channel', channelId, 'add-admin']
      }
    }], extras);
  }

  navigateToContentProvidersPage(channelId: string, extras?: NavigationExtras): void {
    this.router.navigate([{
      outlets: {
        dialog: ['channel', channelId, 'content-providers']
      }
    }], extras);
  }

  navigateToAddContentProviderPage(channelId: string, extras?: NavigationExtras): void {
    this.router.navigate([{
      outlets: {
        dialog: ['channel', channelId, 'content-providers', 'add']
      }
    }], extras);
  }

  navigateToSubscriberListPage(channelId: string, extras?: NavigationExtras): void {
    this.router.navigate([{
      outlets: {
        dialog: ['channel', channelId, 'subscribers']
      }
    }], extras);
  }

  navigateToUserProfile(extras?: NavigationExtras) {
    const routeCommands = {
      primary: WenRouteId.USERPROFILE_DASHBOARD,
      sidebar: WenRouteId.USERPROFILE_VIEW,
      dialog: null
    };
    if (extras) {
      this.appNavigator.navigateToRoute(routeCommands, extras);
    } else {
      this.appNavigator.enterStackAndNavigate(routeCommands);
    }
  }

  navigateToUserProfileRead(showUserProfileDashboard: boolean) {
    if (showUserProfileDashboard) {
      this.appNavigator.navigateToRoute({
        primary: WenRouteId.USERPROFILE_DASHBOARD,
        dialog: WenRouteId.USERPROFILE_READ
      });
    } else {
      this.appNavigator.navigateToRoute({
        dialog: WenRouteId.USERPROFILE_READ
      });
    }
  }

  navigateToUserProfileEdit() {
    this.appNavigator.navigateToRoute({
      primary: WenRouteId.USERPROFILE_DASHBOARD,
      dialog: WenRouteId.USERPROFILE_EDIT
    });
  }

  navigateToUserProfileEditLocationSelector() {
    this.appNavigator.navigateToRoute({
      primary: WenRouteId.USERPROFILE_DASHBOARD,
      dialog: WenRouteId.USERPROFILE_LOCATION_SELECTOR
    });
  }

  navigateToUserChannelRecommendations() {
    this.appNavigator.navigateToRoute({
      primary: WenRouteId.USERPROFILE_DASHBOARD,
      dialog: WenRouteId.USERPROFILE_CHANNEL_RECOMMENDATIONS
    });
  }

  navigateToUserProfileLanguage() {
    this.appNavigator.navigateToRoute({
      primary: WenRouteId.USERPROFILE_LANGUAGE,
      dialog: WenRouteId.USERPROFILE_LANGUAGE
    });
  }

  navigateToUserProfileSettings() {
    this.appNavigator.navigateToRoute({
      primary: WenRouteId.USERPROFILE_DASHBOARD,
      dialog: WenRouteId.USERPROFILE_SETTINGS
    });
  }

  navigateToCredentialDetails(credentialType: WalletCredentialType) {
    if (!credentialType) {
      throw new Error('Could not navigate to credentialDetails. credentialType is missing.');
    }
    const queryParams: Params = { [CREDENTIAL_TYPE]: credentialType };
    this.appNavigator.navigateToRoute({
      primary: WenRouteId.USERPROFILE_DASHBOARD,
      dialog: WenRouteId.USERPROFILE_CREDENTIAL_DETAILS
    }, { queryParams });
  }

  navigateToUserCredentialsMenu() {
    this.appNavigator.navigateToRoute({
      primary: WenRouteId.USERPROFILE_DASHBOARD,
      dialog: WenRouteId.USERPROFILE_CREDENTIALS_MENU
    });
  }

  navigateToHelp() {
    this.appNavigator.navigateToRoute({
      primary: WenRouteId.USERPROFILE_DASHBOARD,
      sidebar: WenRouteId.USERPROFILE_HELP
    });
  }

  navigateToSettings() {
    this.appNavigator.navigateToRoute({
      primary: WenRouteId.USER_SETTINGS
    });
  }

  navigateToHotline(): void {
    this.appNavigator.navigateToRoute({
      primary: WenRouteId.USERPROFILE_DASHBOARD,
      sidebar: WenRouteId.USERPROFILE_HOTLINE
    });
  }

  navigateToMarkdown() {
    const queryParams: Params = { [FAQ_TYPE_QUERY_PARAM]: 'markdown' };
    this.router.navigate([{
      outlets: {
        primary: ['profile', 'help', 'markdown'],
        sidebar: ['profile', 'help']
      }
    }], { queryParams });
  }

  navigateToFAQ(faqType?: FaqType) {
    let queryParams = {};
    if (!isNullOrUndefined(faqType)) {
      queryParams = { [FAQ_TYPE_QUERY_PARAM]: faqType };
    }
    this.appNavigator.navigateToRoute({
      primary: WenRouteId.USERPROFILE_FAQ,
      sidebar: WenRouteId.USERPROFILE_HELP
    }, { queryParams });
  }

  navigateToInviteToNetwork(extras?: NavigationExtras): void {
    this.router.navigate([{
      outlets: {
        dialog: ['invite', 'network']
      }
    }], extras);
  }

  navigateToCrmHomeBackground(extras?: NavigationExtras): void {
    this.router.navigate([{
      outlets: {
        primary: ['crm', 'home'],
        dialog: null
      }
    }], extras);
  }

  navigateToCrmInviteToNetwork(extras?: NavigationExtras): void {
    this.router.navigate([{
      outlets: {
        primary: ['crm', 'home'],
        dialog: ['invite', 'network']
      }
    }], extras);
  }

  navigateToCrmInviteToChannelSelector(extras?: NavigationExtras): void {
    this.router.navigate([{
      outlets: {
        primary: ['crm', 'home'],
        dialog: ['crm', 'invite', 'channel', 'selector']
      }
    }], extras);
  }

  navigateToCrmInviteToChannel(extras?: NavigationExtras): void {
    this.router.navigate([{
      outlets: {
        primary: ['crm', 'home'],
        dialog: ['crm', 'invite', 'channel', 'invite']
      }
    }], extras);
  }

  navigateToCrmCreateChat(extras?: NavigationExtras): void {
    this.router.navigate([{
      outlets: {
        primary: ['crm', 'home'],
        dialog: ['crm', 'chat', 'create']
      }
    }], extras);
  }

  navigateToCrmActionSelector(extras?: NavigationExtras): void {
    this.router.navigate([{
      outlets: {
        primary: ['crm', 'home'],
        dialog: ['crm', 'action-selector']
      }
    }], extras);
  }

  navigateToCrmDistributeChat(extras?: NavigationExtras): void {
    this.router.navigate([{
      outlets: {
        primary: ['crm', 'home'],
        dialog: ['crm', 'distribute-chat']
      }
    }], extras);
  }

  navigateToCrmProfileReadOnlyView(extras?: NavigationExtras): void {
    this.router.navigate([{
      outlets: {
        primary: ['crm', 'home'],
        dialog: ['crm', 'logout']
      }
    }], extras);
  }

  navigateToErrorPage(extras?: NavigationExtras) {
    this.router.navigate([{
      outlets: {
        primary: ['error'],
        sidebar: [],
        dialog: []
      }
    }], extras);
  }

  navigateToEmailSettings(extras?: NavigationExtras): void {
    this.router.navigate([{
      outlets: {
        dialog: ['profile', 'email-settings']
      }
    }], extras);
  }

  navigateToEmailEditPage(extras?: NavigationExtras): void {
    this.router.navigate([{
      outlets: {
        dialog: ['profile', 'change-email']
      }
    }], extras);
  }

  doInitialOtherProfileViewNavigation(userId: string, extras?: NavigationExtras): void {
    this.router.navigate([{
      outlets: {
        dialog: ['profile', userId],
        sidebar: ['home'],
        primary: ['home', 'dashboard'],
      }
    }], extras);
  }

  navigateToRegistrationStart(isFromApp?: boolean, inviteId?: string) {
    let queryParams = {};
    if (!isNullOrUndefined(isFromApp)) {
      queryParams = { [REGISTRATION_FROM_APP_FLAG_QUERY_PARAM]: isFromApp };
    }
    if (!isNullOrUndefined(inviteId)) {
      queryParams = { ...queryParams, [INVITATION_ID_QUERY_PARAM]: inviteId };
    }
    this.appNavigator.navigateToRoute({
      primary: WenRouteId.REGISTRATION_START,
      dialog: null,
      sidebar: null
    }, { queryParams });
  }

  navigateToSignIn(returnUrl?: string,) {
    const queryParams: Params = { [SIGNIN_RETURN_URL]: returnUrl };
    this.appNavigator.navigateToRoute(WenRouteId.SIGN_IN_START, null, { queryParams });
  }

  navigateToPopupSignIn(returnUrl?: string): void {
    const queryParams: Params = { [SIGNIN_RETURN_URL]: returnUrl };
    this.appNavigator.navigateToRoute({
      primary: WenRouteId.SIGN_IN_HOME,
      dialog: WenRouteId.SIGN_IN_POPUP,
    }, { queryParams });
  }

  navigateToInviteSignIn(inviteId: string): void {
    let queryParams = {};
    if (!isNullOrUndefined(inviteId)) {
      queryParams = { [INVITATION_ID_QUERY_PARAM]: inviteId };
    }
    this.appNavigator.navigateToRoute({
      primary: WenRouteId.SIGN_IN_HOME,
      dialog: WenRouteId.SIGN_IN_INVITE,
    }, { queryParams });
  }

  navigateToExclusiveInvite(redirectAfterCode?: string, extras?: NavigationExtras): void {
    const queryParams: Params = { [EXCLUSIVE_INVITE_REDIRECT_AFTER_CODE]: redirectAfterCode };
    this.router.navigate([{
      outlets: {
        primary: ['exclusive-invite', 'code'],
      }
    }], { queryParams, ...extras });
  }

  navigateToOauthLogin() {
    this.router.navigateByUrl('oauth/login');
  }

  navigateToWalletCreateCredential(type: WalletCredentialType, extras?: NavigationExtras) {
    const extrasWithoutLocationChange = { ...extras };
    this.router.navigate([{
      outlets: {
        dialog: null,
        primary: ['wallet', 'credential', 'create', type]
      }
    }], extrasWithoutLocationChange);
  }

  getWalletInitiateIssuanceRoute(returnUrl: string): string {
    return `/wallet/initiate-issuance?${returnUrlIdentifier}=${returnUrl}`;
  }

  navigateToInitiateIssuance(returnUrl: string, credentialOffer: string, extras?: NavigationExtras) {
    const extrasWithQueryParams = {
      ...extras,
      queryParams: {
        [returnUrlIdentifier]: returnUrl,
        [credentialOfferIdentifier]: credentialOffer
      }
    };
    this.router.navigate([{
      outlets: {
        primary: ['wallet', 'initiate-issuance']
      }
    }], extrasWithQueryParams);
  }

  navigateToEventDiscoveryWithFilter(filterData: PassFilterData) {
    const queryParams: Params = { [FILTER_QUERY_PARAM]: filterData };
    this.router.navigate([{
      outlets: {
        sidebar: ['event', 'discovery']
      }
    }], { queryParams } as any);
  }

  navigateToExternalUrl(url: string) {
    this.navigateToEmbeddedPage(url);
  }

  getNavigateToHomeLink() {
    return [{
      outlets: {
        sidebar: ['home']
      }
    }];
  }

  getNavigateToEventListLink() {
    return [{
      outlets: {
        sidebar: ['event']
      }
    }];
  }

  getNavigateToChatListLink() {
    return [{
      outlets: {
        sidebar: ['chat']
      }
    }];
  }

  getNavigateToChannelListLink() {
    return [{
      outlets: {
        sidebar: ['channel'],
        dialog: null
      }
    }];
  }

  getNavigateToAppListLink() {
    return [{
      outlets: {
        sidebar: ['apps'],
        dialog: null
      }
    }];
  }

  navigateToReaderView(readerParams: ReaderParams, extras?: NavigationExtras) {
    if (!readerParams?.docId) {
      throw new Error('Could not navigate to reader view. docId is missing.');
    }

    const queryParams: Params =
      { [READER_DOC_ID_QUERY_PARAM]: readerParams.docId, [READER_START_PAGE_QUERY_PARAM]: readerParams.startPage };

    this.router.navigate([{
      outlets: {
        primary: ['reader'],
        dialog: null
      }
    }], { queryParams, ...extras });
  }

  navigateToTextView(readerParams: ReaderParams, extras?: NavigationExtras) {
    if (!readerParams?.articleId) {
      throw new Error('Could not navigate to text view. articleId is missing.');
    }

    this.router.navigate([{
      outlets: {
        primary: ['reader', 'textview', readerParams.articleId],
        dialog: null
      }
    }], { ...extras });

  }

  doUserChannelListBackNavigation() {
    this.router.navigate([{
      outlets: {
        primary: ['home', 'dashboard'],
        dialog: null
      }
    }], { replaceUrl: true });
  }

  doUserAppListBackNavigation() {
    this.router.navigate([{
      outlets: {
        primary: ['home', 'dashboard'],
        dialog: null
      }
    }], { replaceUrl: true });
  }

  doUserChatListBackNavigation() {
    this.router.navigate([{
      outlets: {
        primary: ['home', 'dashboard'],
        dialog: null
      }
    }], { replaceUrl: true });
  }

  navigateToCrmChatView(roomId: string): void {
    throw new Error('Method not implemented.');
  }
}
