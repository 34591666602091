import { inject, Injectable } from '@angular/core';
import { combineLatest, filter, map, pairwise, startWith, switchMap, timer } from 'rxjs';
import { mapAllBool } from '../../common/operators/map-all-bool';
import { CONNECTION_MONITORS } from './tokens';
import { CoMoState, ConnectionMonitor, ConnectionScope, isSupportsRetry } from './types';

@Injectable()
export class ConnectionMonitorService {

  private readonly connectionMonitors = inject<ConnectionMonitor[]>(CONNECTION_MONITORS);

  private ofScope(states: CoMoState<ConnectionScope>[], targetScope: ConnectionScope) {
    return states.filter(monitor => monitor.scope === targetScope);
  }

  attach() {
    const connectionStates$ = combineLatest(this.connectionMonitors.map(monitor => monitor.connectionState$));
    const isOnline$ = connectionStates$.pipe(
      map(states => states.map(state => state.isOnline)),
      mapAllBool(),
    );
    const isClientOffline$ = connectionStates$.pipe(
      map(connectionStates => this.ofScope(connectionStates, ConnectionScope.INTERNET_CONNECTION)),
      map(clientStates => clientStates.some(clientState => !clientState.isOnline)),
    );
    const isUnstable$ = isClientOffline$.pipe(
      pairwise(),
      filter(([wasOffline, isOfflineNow]) => wasOffline && !isOfflineNow),
      switchMap(() => timer(5000).pipe(
        map(() => false),
        startWith(true)
      )),
    );

    const isClientError$ = isUnstable$.pipe(
      startWith(false),
      switchMap((isUnstable) => {
        return isClientOffline$.pipe(
          map((isClientOffline) => isClientOffline || isUnstable)
        );
      }),
    );

    const offlineScopes$ = connectionStates$.pipe(
      map((connectionStates) => {
        return connectionStates
          .filter((state) => !state.isOnline)
          .map(state => state.scope);
      })
    );
    return {
      /**
       * Returns true if all the CONNECTION_MONITORS are online
       */
      isOnline$,
      /**
       * Returns true if only the BrowserConnectionMonitor or NativeConnectionMonitor is offline
       */
      isClientError$,
      /**
       * Returns a list of scopes which are not available
       */
      offlineScopes$,
    };
  }

  forceRetry() {
    this.connectionMonitors
      .filter(monitor => isSupportsRetry(monitor))
      .forEach(monitor => {
        monitor.forceRetry();
      });
  }

}
