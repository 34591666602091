import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GeoPosition } from '@portal/react-native-api';
import { SocketIoService } from '@portal/wen-backend-api';
import { ConfirmationDialogComponent, ConfirmationDialogData, OverlayManager, WenSnackbarOpener } from '@portal/wen-components';
import { Observable, catchError, from, map, throwError } from 'rxjs';
import { LocationService } from './location.service';
import { FeatureEnablementService } from '../configuration/feature-enablement';
import { NavigatorUtils } from '@portal/wen-common';

const browserLocation$ = new Observable<GeolocationPosition>(observer => {
   NavigatorUtils.getCurrentPosition().then(
    position => {
      observer.next(position);
      observer.complete();
    },
    observer.error.bind(observer)
  );
});

@Injectable()
export class BrowserLocationService extends LocationService {

  dialogData: ConfirmationDialogData;

  constructor(
    socketIoService: SocketIoService,
    snackBarOpener: WenSnackbarOpener,
    translateService: TranslateService,
    featureEnablementService: FeatureEnablementService,
    private overlayManager: OverlayManager,
  ) {
    super(socketIoService, snackBarOpener, translateService, featureEnablementService);
    this.dialogData = {
      header: translateService.instant('CHANNEL_GEO_LOCATION_PERMISSION_ERROR_HEADER'),
      content: translateService.instant('CHANNEL_GEO_LOCATION_PERMISSION_ERROR_CONTENT'),
      dismissLabel: translateService.instant('CHANNEL_GEO_LOCATION_PERMISSION_ERROR_DISMISS_BROWSER')
    };
  }

  getLocation(): Observable<GeoPosition> {
    return browserLocation$.pipe(
      catchError((err: any) => {
        if (err.code) {
          const dialog = this.overlayManager.dialog.openConfirmationDialog(ConfirmationDialogComponent, this.dialogData);
          return dialog.afterClosed().pipe(
            map(() => null)
          );
        } else {
          return throwError(() => err);
        }
      })
    );
  }

  getPermission(): Observable<string> {
    return from(NavigatorUtils.getPermissionStatus('geolocation')).pipe(
      map(status => status?.state)
    );
  }
}
