import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LegacyQuoteComponent } from './quote.component';
import { RemoveMarkdownModule, WenIconModule } from '@portal/wen-components';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [LegacyQuoteComponent],
  exports: [LegacyQuoteComponent],
  imports: [CommonModule, WenIconModule, TranslateModule, RemoveMarkdownModule]
})
export class LegacyQuoteModule { }
