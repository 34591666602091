import { ChangeDetectionStrategy, Component, inject, Input, OnDestroy } from '@angular/core';
import { PollModel } from '@portal/wen-backend-api';
import { filter, map, merge, ReplaySubject, shareReplay, Subscription, switchMap } from 'rxjs';
import { PollService } from '../../../../../../core/services/polling/poll-service';
import { EmbeddedPoll } from '../../../../../../core/store/channel/channel.state';

@Component({
  selector: 'wen-embed-poll',
  templateUrl: './embed-poll.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmbedPollComponent implements OnDestroy {

  pollModel$ = new ReplaySubject<PollModel>();
  private pollUpdatesSubscription: Subscription;

  private pollService = inject(PollService);

  @Input() set model(value: EmbeddedPoll) {
    const poll$ = this.pollService.getPoll({ id: value.pollId }).pipe(shareReplay(1));
    const updatedPoll$ = this.pollService.pollUpdates$.pipe(
      filter((pollUpdate) => pollUpdate.id === value.pollId),
      // TODO Remove this update fixed on the BE
      switchMap((pollUpdate) => poll$.pipe(map(poll => ({ ...poll, ...pollUpdate }))))
    );

    this.pollUpdatesSubscription?.unsubscribe();
    this.pollUpdatesSubscription = merge(
      poll$,
      updatedPoll$,
    ).subscribe(this.pollModel$);
  }

  ngOnDestroy(): void {
    this.pollUpdatesSubscription?.unsubscribe();
  }
}
