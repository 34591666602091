import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, skip, Subject, takeUntil } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { FormStoreService } from '../../../../../../shared/form-store/form-store.service';
import { LocationSelectorModel } from '../../../../../../shared/components/location-selector/location-selector.component';
import { Store } from '@ngrx/store';
import { headerCommandClicked } from '../../../../../../core/store/header/header.actions';
import { HeaderCommandType } from '../../../../../../core/store/header/models/HeaderCommand';
import { EmbeddedLocation } from '../../../../../../core/store/channel/channel.state';
import { EmbedDTOType } from '@portal/wen-backend-api';

@Component({
  selector: 'wen-profile-edit-location-selector',
  templateUrl: './profile-edit-location-selector.component.html',
  styleUrls: ['./profile-edit-location-selector.component.scss']
})

export class ProfileEditLocationSelectorComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  locationSelectorForm = new FormGroup({
    address: new FormControl()
  });

  constructor(
    private formStoreService: FormStoreService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.formStoreService.initializeForm();

    // workaround to accomodate difference in datatypes between dialog and profile edit
    this.locationSelectorForm.controls.address.valueChanges.subscribe(value => {
      if (value.locationData) {
        this.locationSelectorForm.controls.address.setValue(value.locationData, { emitEvent: false });
      }
      else {
        const item: EmbeddedLocation = {
          type: EmbedDTOType.LOCATION,
          locationData: value
        };
        this.locationSelectorForm.controls.address.setValue(item, { emitEvent: false });
      }
    });

    const selectionChanges$: Observable<LocationSelectorModel> = this.locationSelectorForm.controls.address.valueChanges;
    selectionChanges$.pipe(
      takeUntil(this.onDestroy$),
      skip(1),
    ).subscribe((newValue) => {
      if (newValue) {
        this.store.dispatch(headerCommandClicked({ commandType: HeaderCommandType.CANCEL_BACK_NAVIGATION }));
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
