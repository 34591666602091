@switch (errorReason) {
  @case ('noInternet') {
    <img class="wen-error-page-image" src="/assets/wen-widget/error-page/error-no-internet-robot.svg" alt="">
    <div portal-title class="wen-error-page-title">{{ "ERROR_PAGE_OFFLINE_TITLE" | translate }}</div>
    <div class="wen-error-page-content">{{ "ERROR_PAGE_OFFLINE_CONTENT" | translate }}</div>
  }
  @case ('auth') {
    <ng-container *ngTemplateOutlet="lottieTemplate"></ng-container>
    <div portal-title class="wen-error-page-title">{{ "ERROR_PAGE_AUTH_SERVER_ERROR_TITLE" | translate }}</div>
    <div class="wen-error-page-content">{{ "ERROR_PAGE_AUTH_SERVER_ERROR_CONTENT" | translate }}</div>
    <div class="wen-error-page-description">{{ "ERROR_PAGE_CONTENT_DESCRIPTION" | translate }}</div>
  }
  @case ('websocket') {
    <ng-container *ngTemplateOutlet="lottieTemplate"></ng-container>
    <div portal-title class="wen-error-page-title">{{ "ERROR_PAGE_WEBSOCKET_ERROR_TITLE" | translate }}</div>
    <div class="wen-error-page-content">{{ "ERROR_PAGE_WEBSOCKET_ERROR_CONTENT" | translate }}</div>
    <div class="wen-error-page-description">{{ "ERROR_PAGE_CONTENT_DESCRIPTION" | translate }}</div>
  }
  @case ('healthStatus') {
    <ng-container *ngTemplateOutlet="lottieTemplate"></ng-container>
    <div portal-title class="wen-error-page-title">{{ "ERROR_PAGE_HEALTHSTATUS_ERROR_TITLE" | translate }}</div>
    <div class="wen-error-page-content">{{ "ERROR_PAGE_HEALTHSTATUS_ERROR_CONTENT" | translate }}</div>
    <div class="wen-error-page-description">{{ "ERROR_PAGE_CONTENT_DESCRIPTION" | translate }}</div>
  }
  @default {
    <ng-container *ngTemplateOutlet="lottieTemplate"></ng-container>
    <div portal-title class="wen-error-page-title">{{ "ERROR_PAGE_TITLE" | translate }}</div>
    <div class="wen-error-page-content">{{ "ERROR_PAGE_CONTENT" | translate }}</div>
  }
}

<ng-template #lottieTemplate>
  <ng-lottie [options]="lottieOptions" (animationCreated)="onAnimationCreated($event)" role="presentation"></ng-lottie>
</ng-template>