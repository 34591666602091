import { CdkScrollable } from '@angular/cdk/scrolling';
import { filter, first, switchMap } from 'rxjs';
import { ListMassDataViewerDatasource } from '../../list-mass-data-viewer/providers/list-mass-data-viewer-datasource';

/**
 * Emit if the view is scrolled to the bottom
 */
export const onScrollBottom = (
  scrollable: CdkScrollable,
  datasource: ListMassDataViewerDatasource<any>
) => {
  return scrollable.elementScrolled().pipe(
    switchMap(() => {
      return datasource.isLoading().pipe(
        first(),
        filter(isLoading => !isLoading)
      );
    }),
    filter(() => {
      const bottomOffset = scrollable.measureScrollOffset('bottom');
      return bottomOffset < 500;
    }),
  );
};
