import { Inject, Injectable, Optional } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocationDTO } from '@portal/wen-backend-api';
import { YELLOWMAP_CONFIG, YellowMapConfig } from '../../../frame/api/tokens';
import { EventDatePipe } from '../../../shared/pipes/event-date-formatter.pipe';
import { isNullOrUndefined } from '../../common/operators/null-check-util';
import { EventEntity } from '../../store/events/event.state';
import { WenColorService } from '../util/wen-color.service';

type YellowMapUrlParams =
  | 'key' // The ApiKey
  | 'x'   // Latitude
  | 'y'   // Longitude
  | 'ic'  // Country iso code
  | 'zp'  // Zip code
  | 'cty' // City name
  | 'str' // Street name
  | 'hp'  // Has Popup
  | 'pt'  // Popup header
  | 'pb'  // Popup body
  | 'z'   // Zoom factor
  | 'tc'  // Activate terrain mode
  | 's';  // Style map represantation

type EventBaseParams = Pick<EventEntity, 'caption' | 'start' | 'end'>;
type EventLocationParams = Pick<LocationDTO, 'country' | 'town' | 'street' | 'zip'>;
export type EventMapParams = EventBaseParams & {
  locationContact?: EventLocationParams;
};

const addParam = (
  mapUrl: URL, key: YellowMapUrlParams, value: string
) => {
  if (!isNullOrUndefined(value)) {
    mapUrl.searchParams.append(key, value);
  }
  return mapUrl;
};

@Injectable()
export class YellowMapMapService {

  constructor(
    @Optional() @Inject(YELLOWMAP_CONFIG) private yellowMapConfig: YellowMapConfig,
    private eventDatePipe: EventDatePipe,
    private translateService: TranslateService,
    private colorService: WenColorService,
  ) { }

  private isValidYellowMapColor(color: string) {
    return /#[0-9A-F]{6}/i.test(color);
  }

  private createUrl() {
    const { yellowMapUrl, yellowMapApiKey } = this.yellowMapConfig;
    const mapUrl = new URL(`${yellowMapUrl}`);
    mapUrl.searchParams.append('key', yellowMapApiKey);
    const { primaryColor } = this.colorService;
    if (this.isValidYellowMapColor(primaryColor)) {
      mapUrl.searchParams.append('c', primaryColor);
    }
    return mapUrl;
  }

  getMapUrlFromEvent(currentEvent: EventMapParams) {
    const { yellowMapUrl, yellowMapApiKey } = this.yellowMapConfig;
    const { locationContact } = currentEvent || {};
    if (!yellowMapUrl || !yellowMapApiKey || !locationContact) {
      return null;
    }
    if (isNullOrUndefined(locationContact.street)) {
      return null;
    }
    const mapUrl = this.createUrl();
    addParam(mapUrl, 'ic', locationContact.country);
    addParam(mapUrl, 'zp', locationContact.zip);
    addParam(mapUrl, 'cty', locationContact.town);
    addParam(mapUrl, 'str', locationContact.street);
    addParam(mapUrl, 'hp', 'true');
    addParam(mapUrl, 'pt', currentEvent.caption);
    addParam(mapUrl, 'pb', this.eventDatePipe.transform(currentEvent.start, currentEvent.end));
    addParam(mapUrl, 'z', '13');
    addParam(mapUrl, 'tc', 'true');
    addParam(mapUrl, 's', 'essential');
    return mapUrl.toString();
  }

  getMapUrlFromGeoData(geoData: { latitude: number; longitude: number }) {
    const { yellowMapUrl, yellowMapApiKey } = this.yellowMapConfig;
    const hasGeoData = !isNullOrUndefined(geoData?.latitude) && !isNullOrUndefined(geoData?.longitude);
    if (!yellowMapUrl || !yellowMapApiKey || !hasGeoData ) {
      return null;
    }
    const mapUrl = this.createUrl();
    addParam(mapUrl, 'x', geoData.latitude.toString());
    addParam(mapUrl, 'y', geoData.longitude.toString());
    addParam(mapUrl, 'hp', 'true');
    addParam(mapUrl, 'pt', this.translateService.instant('GEO_LOCATION_POPUP_TITLE'));
    addParam(mapUrl, 'z', '13');
    addParam(mapUrl, 'tc', 'true');
    addParam(mapUrl, 's', 'essential');
    return mapUrl.toString();
  }
}
