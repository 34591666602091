@if (data$ | async; as data) {
  @if (contactButtonProps$ | async; as contactButtonProps) {
    <wen-action class="wen-action-rounded wen-action-animated" [altText]="contactButtonProps.altText"
      [wenVariant]="contactButtonProps.wenVariant" [iconName]="contactButtonProps.iconName" iconSize="big"
      (click)="contactButtonProps.action && handleAction($event, contactButtonProps.action, data); contactButtonProps.contextMenu && openContextMenu(contactButtonProps, data)">
      @if (!iconOnly) {
        {{ contactButtonProps.translationKey | translate }}
      }
    </wen-action>
  }
}
