import { isDevMode } from '@angular/core';

const urlRegex = /(\b(https?:\/\/|www.)[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/i;

/**
 * Util function to make it easier to reproduce url-based functionalities locally
 */
const getDevModeBaseUrl = () => {
  if (isDevMode()) {
    return 'https://client.development.we.network';
  }
  return null;
};

export const isUrl = (maybeUrl: string) => {
  return maybeUrl?.match(urlRegex);
};

export const isAbsoluteUrl = (url: string) => {
  const absoluteUrlRegexp = new RegExp('^(?:[a-z]+:)?//', 'i');
  return absoluteUrlRegexp.test(url);
};

export const isRelativeUrl = (url: string) => {
  return !isAbsoluteUrl(url) && (url.startsWith('/') || !url.includes('.'));
};

/**
 * Check if url1 contains url2
 *
 * @returns True if url2 is contained in url1 ignoring "/" at the start of the urls
 */
export const urlContains = (url1: string, url2: string) => {
  const normalizeRoute = (url: string) => {
    return url.replace(/^\//, '');
  };
  const nUrl1 = normalizeRoute(url1);
  const nUrl2 = normalizeRoute(url2);
  const equal = nUrl1 === nUrl2;
  if (equal) {
    return equal;
  }
  const contains = nUrl1.includes(nUrl2);
  return contains;
};

export const addQueryParam = (absoluteOrRelativeUrl: string, params: URLSearchParams) => {
  let urlObj: URL;
  const dummyDomain = 'https://dummy-domain.com';
  if (isAbsoluteUrl(absoluteOrRelativeUrl)) {
    urlObj = new URL(absoluteOrRelativeUrl);
  } else {
    urlObj = new URL(dummyDomain + absoluteOrRelativeUrl);
  }
  params.forEach((value, key) => {
    urlObj.searchParams.append(key, value);
  });
  const purifiedUrl = urlObj.toString().replace(dummyDomain, '');
  return purifiedUrl;
};

export const getQueryParam = <K extends string = string>(absoluteOrRelativeUrl: string, paramName: K) => {
  let urlObj: URL;
  const dummyDomain = 'https://dummy-domain.com';
  if (isAbsoluteUrl(absoluteOrRelativeUrl)) {
    urlObj = new URL(absoluteOrRelativeUrl);
  } else {
    urlObj = new URL(dummyDomain + absoluteOrRelativeUrl);
  }
  return urlObj.searchParams?.get(paramName);
};

export const getAuthQueryParam = <K extends ('code' | 'state')>(
  absoluteOrRelativeUrl: string, paramName: K
) => getQueryParam<K>(absoluteOrRelativeUrl, paramName);

export const convertToRelativeUrl = (url: string) => {
  if (!isAbsoluteUrl(url)) {
    return url;
  }
  const urlObj = new URL(url);
  return urlObj.pathname + urlObj.search;
};

export const extractOrigin = (url: string) => {
  if (!isAbsoluteUrl(url)) {
    return '';
  }
  const urlObj = new URL(url);
  return urlObj.origin;
};

export const isInternalUrl = (url: string) => {
  const rootUrl = getRootUrl();
  if (isDevMode()) {
    return url.includes(rootUrl) || url.includes(getDevModeBaseUrl());
  }
  return url.includes(rootUrl);
};

export const isMailUrl = (url: string) => {
  return url.startsWith('mailto:');
};

export const getRootUrl = () => {
  return `${location.protocol}//${location.host}`;
};

export const getAvatarUrl = (baseUrl: string, id: string) => {
  return `${baseUrl}/redirect/avatar/${id}/{size}`;
};
