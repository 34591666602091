<wen-adaptive-background>
  <wen-dialog-layout wen-adaptive-background-content [headerGravity]="headerGravity">
    <wen-dialog-layout-header [inline]="true" wenIosKeyboardSticky>
      <button headerLeft portal-button inlined (click)="onCancel()" tabindex="-1">
        <span portal-title>{{ "REGISTRATION_ABORT_LABEL" | translate }}</span>
      </button>
      <button headerRight portal-button inlined (click)="onSubmit()">
        <span portal-title>{{ "REGISTRATION_CONTINUE_LABEL" | translate }}</span>
      </button>
    </wen-dialog-layout-header>
    <div class="wen-registration-start-content" dialogLayoutContent>
      <h1 class="cdk-visually-hidden">{{ 'REGISTRATION_CONTEXT_LABEL' | translate }}</h1>
      <form wenFormStore class="wen-registration-start-form" [formGroup]="registrationStartFormGroup" (ngSubmit)="onSubmit()"
        wenFocusFirstInput>
        <div class="wen-registration-start-info">
          <h2 class="wen-registration-start-header" wen-header-big>{{ "REGISTRATION_HEADER" | translate }}</h2>
          <div class="wen-registration-start-description" tabindex="0">{{ "REGISTRATION_START_DESCRIPTION" | translate }}</div>
        </div>
        <wen-phone-number-control [placeholder]="'REGISTRATION_START_PHONE_NUMBER_LABEL' | translate"
          [formControlName]="'phoneControl'" [availableCountries]="availableCountries">
        </wen-phone-number-control>
      </form>
    </div>
  </wen-dialog-layout>
</wen-adaptive-background>
