import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreComponentsModule } from '@portal/wen-components';
import player from 'lottie-web';
import { provideLottieOptions } from 'ngx-lottie';
import { HeaderModule } from '../../frame/components/header/header.module';
import { FormProgressModule } from '../../shared/components/form-progress/form-progress.module';
import { IosUtilsModule } from '../../shared/ios-util/ios-util.module';
import { SharedModule } from '../../shared/shared.module';
import { ErrorPageReasonContentComponent } from './components/error-page/error-page-reason-content/error-page-reason-content.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { errorPageRoutes } from './error-page.routes';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(errorPageRoutes),
    HeaderModule,
    FormProgressModule,
    IosUtilsModule,
    ErrorPageReasonContentComponent,
    CoreComponentsModule,
  ],
  providers: [
    provideLottieOptions({
      player: () => player
    })
  ],
  declarations: [
    ErrorPageComponent,
  ],
  exports: []
})
export class ErrorPageModule { }
