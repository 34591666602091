import { Pipe, PipeTransform } from '@angular/core';
import { ChannelDTO } from '@portal/wen-backend-api';
import { ChannelListItemModel } from '../../views/channel/channel-list/components/channel-list/channel-list-item/models/list-item-model';
import { ChannelListItemFactory } from '../../views/channel/channel-list/components/channel-list/channel-list-item/providers/channel-list-item-factory';
import { map, Observable } from 'rxjs';
import { ChannelListItemDataSource } from '../../views/channel/channel-list/components/channel-list/channel-list-item/providers/channel-list-item-data-source';

@Pipe({
  name: 'toChannelListItem'
})
export class ChannelListItemPipe implements PipeTransform {

  constructor(
    private itemFactory: ChannelListItemFactory,
    private itemDataSource: ChannelListItemDataSource
  ) {
  }

  transform(channel: ChannelDTO): Observable<ChannelListItemModel> {
    return this.itemDataSource.getChannelListItemPropsFromChannel(channel).pipe(
      map(props => this.itemFactory.createSimpleListItem(props))
    );
  }
}
