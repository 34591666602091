import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ChannelInviteDataDTO, ChannelType, ForwardContext, RestrictionDTO, RestrictionType, SocketIoService } from '@portal/wen-backend-api';
import { Observable, Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { mapWithFirstFrom } from '../../../../../core/common/operators/map-with-first-from';
import { selectorWithParam } from '../../../../../core/common/util/selector-with-param';
import { FeatureEnablementService } from '../../../../../core/services/configuration/feature-enablement';
import { ChannelDeepLinkable } from '../../../../../core/services/navigation/deep-link/deep-linkables/channel-deep-linkable';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { selectCurrentUserData } from '../../../../../core/store/auth/auth.selectors';
import { fetchPermissionsForChannel, setSubscriptionToCurrentChannel } from '../../../../../core/store/channel/channel.actions';
import { selectChannelPermission, selectChannelRestrictions, selectCurrentChannel, selectCurrentChannelAdmins, selectCurrentChannelContentProviders, selectIsJoinRequestManager } from '../../../../../core/store/channel/channel.selectors';
import { ChannelEntity } from '../../../../../core/store/channel/channel.state';
import { removeFilter } from '../../../../../core/store/filter/filter.actions';
import { FilterEntityType } from '../../../../../core/store/filter/models/filter';
import { RootState } from '../../../../../core/store/root/public-api';
import { ChannelHeaderDataUpdater } from '../../../../../shared/channel-specific/providers/channel-view-header-data-updater';
import { ShareService } from '../../../../../shared/channel-specific/providers/share.service';
import { DetailHeaderModel } from '../../../../../shared/components/detail-header/detail-header.component';
import { ColorPalette } from '../../../../../shared/directives/colorize/palette-provider';
import { SubscribedToSource } from '../../../../../shared/directives/subscibed-to/subscribed-to-source';
import { RestrictionAutoRefresherService } from '../../../../../shared/services/restriction-auto-refresher.service';
import { ChannelSubscribedToSource } from '../../../common/providers/channel-subscribed-to-source';
import { TranslateService } from '@ngx-translate/core';

export const NOTIFICATION_CHANNEL_ID = '022c5ea8-c09e-4a47-9db9-401f0e43fc53';

// TODO: Remove when new settings page implementation is ready
const WEATHER_CHANNEL_ID = '113bfa4e-571d-4884-8d4c-9acaf60a6bd8';

@Component({
  selector: 'wen-channel-detail',
  templateUrl: './channel-detail.component.html',
  styleUrls: ['./channel-detail.component.scss'],
  providers: [
    RestrictionAutoRefresherService, ChannelHeaderDataUpdater
  ],
  viewProviders: [
    { provide: SubscribedToSource, useClass: ChannelSubscribedToSource }
  ]
})
export class ChannelDetailComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();

  @HostBinding('class.wen-channel-detail') className = true;

  currentChannel$: Observable<ChannelEntity>;
  currentChannelId$: Observable<string>;
  headerData$: Observable<DetailHeaderModel>;
  isMuted$: Observable<boolean>;
  restrictions$: Observable<RestrictionDTO[]>;
  canEdit$: Observable<boolean>;
  canHandleJoinRequests$: Observable<boolean>;
  pendingJoinRequestCount$: Observable<number>;
  adminCount$: Observable<number>;
  canListAdmins$: Observable<boolean>;
  isAdminGroupVisible$: Observable<boolean>;
  isSubscriberListVisible$: Observable<boolean>;
  isContentProviderListVisible$: Observable<boolean>;
  contentProviderCount$: Observable<number>;
  subscriberCount$: Observable<number>;
  inviteData$: Observable<ChannelInviteDataDTO>;
  infoTextForCollaborativeChannels$: Observable<string>;
  canUnsubscribe$: Observable<boolean>;
  isPersonalType$: Observable<boolean>;
  isLeaveButtonVisible$: Observable<boolean>;
  canForward$: Observable<boolean>;
  forwardContext = ForwardContext.CHANNEL;
  isSettingsVisible$: Observable<boolean>;

  get isCollaborationEnabled(): boolean {
    return this.featureEnablementService.featureFlagMethods.isEnableCollaborativeChannel();
  }

  get visibilitySettingsMenuEntryFillColor(): ColorPalette {
    return ColorPalette.PURPLE;
  }

  get adminListMenuEntryFillColor(): ColorPalette {
    return ColorPalette.DARK_GREEN;
  }

  get contentProviderMenuEntryFillColor(): ColorPalette {
    return ColorPalette.GOLD;
  }

  get channelDeepLink(): string {
    return this.deepLinkProvider.getDeepLink();
  }

  get channelSubscribersMenuEntryFillColor(): ColorPalette {
    return ColorPalette.LIGHT_BLUE;
  }

  get channelSettingsMenuEntryFillColor(): ColorPalette {
    return ColorPalette.LIGHT_BLUE;
  }

  constructor(
    private store: Store<RootState>,
    private channelHeaderDataUpdater: ChannelHeaderDataUpdater,
    private restrictionAutoRefresherService: RestrictionAutoRefresherService,
    private navigationHelper: WenNavigationHelper,
    private socketIoService: SocketIoService,
    private deepLinkProvider: ChannelDeepLinkable,
    private shareService: ShareService,
    private featureEnablementService: FeatureEnablementService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.currentChannel$ = this.store.pipe(
      select(selectCurrentChannel),
      shareReplay(1)
    );
    this.currentChannelId$ = this.currentChannel$.pipe(
      map(channel => channel?.id),
      distinctUntilChanged(),
      shareReplay(1)
    );
    const channelPermission$ = this.currentChannelId$.pipe(
      switchMap((channelId) => this.store.pipe(
        selectorWithParam(selectChannelPermission, channelId),
      )),
      shareReplay(1),
      takeUntil(this.onDestroy$),
    );
    this.isSubscriberListVisible$ = channelPermission$.pipe(
      map(channelPermission => channelPermission.canListSubscribers),
      shareReplay(1)
    );
    this.isContentProviderListVisible$ = channelPermission$.pipe(
      map(channelPermission => channelPermission.canListContentProviders),
      shareReplay(1)
    );
    this.infoTextForCollaborativeChannels$ = this.currentChannel$.pipe(
      map((channel) => this.isCollaborationEnabled && channel.collaborative ? 'CHANNEL_COLLABORATION_HINT' : '')
    );
    this.isPersonalType$ = this.currentChannel$.pipe(
      map((channel) => channel?.channelType === ChannelType.PERSONAL),
      shareReplay(1)
    );

    this.restrictionAutoRefresherService.attach();

    this.headerData$ = combineLatest([
      this.channelHeaderDataUpdater.headerData$,
      this.currentChannel$.pipe(
        filter(channel => Boolean(channel))
      )
    ]).pipe(
      map(([headerData, channel]) => {
        const { title, subTitle, prefixTitleIcon } = headerData;
        const detailHeaderModel: DetailHeaderModel = {
          title,
          description: subTitle,
          prefixTitleIcon: channel.isPrivate ?
            { name: 'lock', altText: this.translateService.instant('CHANNEL_RESTRICTED_ICON') } : prefixTitleIcon,
          imageUrl: channel.icon
        };
        return detailHeaderModel;
      })
    );

    this.isMuted$ = this.currentChannel$.pipe(
      map(channelEntity => channelEntity?.isMuted)
    );
    this.restrictions$ = this.currentChannelId$.pipe(
      switchMap((channelId) => this.store.pipe(
        select(selectChannelRestrictions),
        map((channelRestrictionFn) => channelRestrictionFn(channelId)),
      ))
    );

    this.currentChannelId$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((channelId) => this.store.dispatch(fetchPermissionsForChannel({ channelId })));

    this.canEdit$ = channelPermission$.pipe(
      map(permission => permission?.canEdit),
      takeUntil(this.onDestroy$)
    );

    this.canHandleJoinRequests$ = this.currentChannel$.pipe(
      switchMap((channel) => this.store.pipe(
        select(selectIsJoinRequestManager),
        map((joinRequestFn) => {
          const requestOnlyRestrictions = channel?.restrictions?.some(r => r.restrictionType === RestrictionType.RequestOnly);
          return requestOnlyRestrictions && joinRequestFn(channel?.id);
        }),
      )),
      takeUntil(this.onDestroy$)
    );

    this.pendingJoinRequestCount$ = this.currentChannelId$.pipe(
      mapWithFirstFrom(() => this.store.pipe(select(selectCurrentUserData))),
      switchMap(([channelId, { userId }]) => {
        this.socketIoService.channelInvitation.pendingJoinRequestList.emit({ userId, channelId });
        return this.socketIoService.channelInvitation.pendingJoinRequestList.listen.pipe(
          map(joinRequests => joinRequests?.length ?? 0),
          takeUntil(this.onDestroy$)
        );
      })
    );

    this.adminCount$ = this.store.pipe(
      select(selectCurrentChannelAdmins),
      map((users) => users?.length)
    );

    this.contentProviderCount$ = this.store.pipe(
      select(selectCurrentChannelContentProviders),
      map((users) => users?.length)
    );

    this.subscriberCount$ = this.currentChannel$.pipe(map(({ subscriptions }) => subscriptions));

    this.canListAdmins$ = channelPermission$.pipe(
      map(permission => permission?.canListAdmins),
      takeUntil(this.onDestroy$)
    );

    this.isAdminGroupVisible$ = combineLatest([
      this.canListAdmins$,
      this.canHandleJoinRequests$,
      this.isPersonalType$
    ]).pipe(
      map(([canListAdmins, canHandleJoinRequests, isPersonalType]) => {
        return (canListAdmins || canHandleJoinRequests) && !isPersonalType;
      }),
      startWith(false),
    );

    this.inviteData$ = this.currentChannel$.pipe(
      map(channelEntity => channelEntity?.invite)
    );

    this.canUnsubscribe$ = channelPermission$.pipe(
      map(permission => permission?.canUnsubscribe),
    );

    this.isLeaveButtonVisible$ = combineLatest([
      this.canUnsubscribe$,
      this.currentChannelId$,
    ]).pipe(
      map(([canUnsubscribe, currentChannelId]) => {
        return canUnsubscribe && currentChannelId !== NOTIFICATION_CHANNEL_ID;
      }),
      startWith(false),
    );

    this.canForward$ = this.featureEnablementService.appConfigObs.enableChat$.pipe(
      map(isChatEnabled => isChatEnabled && !!this.channelDeepLink)
    );

    this.isSettingsVisible$ = combineLatest([
      this.currentChannel$,
      this.isPersonalType$,
      this.featureEnablementService.featureConfigObs.enableNewChannelSettings$
    ]).pipe(
      map(([currentChannel, isPersonalType, enableNewChannelSettings]) => {
        const hasSchema = Object.keys(currentChannel?.configuration?.schema ?? {}).length > 0;
        const isWeatherChannel = WEATHER_CHANNEL_ID === currentChannel.id;
        return enableNewChannelSettings
          ? (hasSchema || isWeatherChannel) && isPersonalType
          : isWeatherChannel && isPersonalType;
      }),
      distinctUntilChanged()
    );
  }

  unSubscribeFromChannel() {
    this.store.dispatch(setSubscriptionToCurrentChannel({ setSubscribed: false }));
  }

  editChannel(channelId: string) {
    this.navigationHelper.navigateToChannelEdit(channelId);
  }

  navigateToChannelJoinRequestPage(channelId: string) {
    this.navigationHelper.navigateToPendingJoinRequestsPage(channelId);
  }

  navigateToAdminListPage(channelId: string) {
    this.store.dispatch(removeFilter({ filterEntityType: FilterEntityType.CHANNEL_LISTS }));
    this.navigationHelper.navigateToAdminListPage(channelId);
  }

  navigateToContentProviderListPage(channelId: string) {
    this.store.dispatch(removeFilter({ filterEntityType: FilterEntityType.CHANNEL_LISTS }));
    this.navigationHelper.navigateToContentProvidersPage(channelId);
  }

  navigateToSubscriberListPage(channelId: string) {
    this.navigationHelper.navigateToSubscriberListPage(channelId);
  }

  navigateToChannelSettings(channelId: string) {
    this.navigationHelper.navigateToChannelSettings(channelId);
  }

  shareDeepLink() {
    this.shareService.shareDeepLink(this.channelDeepLink);
  }

  openUrl(url: string){
    this.navigationHelper.navigateToEmbeddedPage(url);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
