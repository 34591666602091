
<wen-dialog-layout [headerGravity]="'top'">
  <h2 wen-dialog-layout-static-header class="wen-filter-selector-header">{{ 'DATE_RANGE_SELECT_LABEL' | translate }}</h2>
  <form [formGroup]="rangeFormGroup" tabindex="0" dialogLayoutContent>
    <div class="wen-filter-selector-form-group">
        <wen-date-field [placeholder]="'DATE_RANGE_FROM' | translate" [min]="startMinDate" [formControl]="rangeFormGroup.controls.start"></wen-date-field>
        <wen-date-field [placeholder]="'DATE_RANGE_UNTIL' | translate" [min]="endMinDate" [formControl]="rangeFormGroup.controls.end"></wen-date-field>
    </div>
  </form>
  <div wen-dialog-layout-static-footer>
    <mat-dialog-actions class="wen-filter-dialog-actions">
      <button [ngClass]="{ 'disabled': isFormInvalid$ | async }" class="wen-filter-dialog-button" wen-primary-color (click)="onApply()">{{ "APPLY_BUTTON" |
        translate }}</button>
      <button class="wen-filter-dialog-button" wen-primary-color (click)="onCancel()">{{ "CANCEL_BUTTON_LABEL" |
        translate }}</button>
    </mat-dialog-actions>
  </div>
</wen-dialog-layout>
