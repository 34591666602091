import { Component } from '@angular/core';

@Component({
  selector: 'wen-chat-skeleton',
  templateUrl: './chat-skeleton.component.html',
  styleUrls: ['./chat-skeleton.component.scss'],
})
export class ChatSkeletonComponent {

  public skeletonMessages = Array.from({ length: 12 }, (_, i) => {
    return {
      id: i,
      outbound: i % 3 === 0
    };
  });

}
