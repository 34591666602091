import { WenNativeApi } from '@portal/wen-native-api';
import { Observable } from 'rxjs';
import { BrowserConnectionMonitor } from './browser-connection-monitor';
import { NativeConnectionMonitor } from './native-connection-monitor';

export enum ConnectionScope {
  AUTH_SERVER = 'AUTH_SERVER',
  WEBSOCKET = 'WEBSOCKET',
  HEALTH_STATUS = 'HEALTH_STATUS',
  INTERNET_CONNECTION = 'INTERNET_CONNECTION',
}

export interface CoMoState<TSCOPE extends ConnectionScope> {
  isOnline: boolean;
  scope: TSCOPE;
}

export interface WithRetry {
  forceRetry(): void;
}

export interface WithOnlineState<TSCOPE extends ConnectionScope> {
  connectionState$: Observable<CoMoState<TSCOPE>>;
}

export const isSupportsRetry = (
  monitor: ConnectionMonitor
): monitor is ConnectionMonitor & WithRetry => {
  return Boolean(monitor && monitor.forceRetry);
};

export type ConnectionMonitor = WithOnlineState<ConnectionScope> & Partial<WithRetry>;

export const networkConnectionMonitorFactory = (
  nativeApi: WenNativeApi
) => {
  if (nativeApi.isReactNativeApp()) {
    return new NativeConnectionMonitor(nativeApi);
  }
  return new BrowserConnectionMonitor();
};
