import { inject, Injectable } from '@angular/core';
import { DocumentUtils } from '@portal/wen-common';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { Tracer } from '@portal/wen-tracer';
import { combineLatest, debounceTime, filter, map, switchMap } from 'rxjs';
import { FeatureEnablementService } from '../configuration/feature-enablement';
import { ConnectionMonitorService } from '../connection-monitors/connection-monitor.service';
import { WenNavigationHelper } from '../navigation/types';

@Injectable()
export class ConnectionErrorHandler {

  static CONNECTION_ERROR_GRACE_PERIOD = 1500;
  private readonly tracer = inject(Tracer);

  initialize() {
    const { isOnline$, offlineScopes$ } = this.connectionMonitorService.attach();
    this.featureEnablement.appConfigObs.enableDisconnectErrorPage$.pipe(
      filter(enableDisconnectErrorPage => enableDisconnectErrorPage),
      switchMap(() => combineLatest([DocumentUtils.isInBackground$, isOnline$])),
      map(([isInBackground, isOnline]) => {
        return !isOnline && !isInBackground;
      }),
      debounceTime(ConnectionErrorHandler.CONNECTION_ERROR_GRACE_PERIOD),
      filter(Boolean)
    ).subscribe(() => {
      this.navigationHelper.navigateToErrorPage();
    });
    offlineScopes$.pipe(
      filter(offlineScopes => Boolean(offlineScopes?.length)),
      smartDistinctUntilChanged()
    ).subscribe(offlineScopes => {
      const offlineScopesObj = offlineScopes.reduce((acc, scope) => {
        return { ...acc, [scope]: true };
      }, {});
      this.tracer.addBreadcrumb({ category: 'connection.offline', level: 'log', data: offlineScopesObj });
    });
    isOnline$.pipe(
      smartDistinctUntilChanged(),
      filter(isOnline => isOnline),
    ).subscribe((isOnline) => {
      this.tracer.addBreadcrumb({ category: 'connection.online', level: 'log' });
    });
  }

  constructor(
    private readonly featureEnablement: FeatureEnablementService,
    private readonly navigationHelper: WenNavigationHelper,
    private readonly connectionMonitorService: ConnectionMonitorService
  ) { }
}
