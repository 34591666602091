import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, shareReplay } from 'rxjs';
import { selectCurrentChannel } from '../../../../../../core/store/channel/channel.selectors';
import { RootState } from '../../../../../../core/store/root/public-api';

@Injectable()
export class ChannelSelector {
  private store = inject(Store<RootState>);

  currentChannel$ = this.store.pipe(
    select(selectCurrentChannel),
    distinctUntilChanged((a, b) => a?.id === b?.id),
    shareReplay(1)
  );

}
