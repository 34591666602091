import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderModule } from '../../../frame/components/header/header.module';
import { CHAT_MESSAGE_TYPE_SELECTOR_MENU_ID } from '../../../shared/components/message-type-selector/constants/type-selector-ids';
import { MessageTypeSelectorModule } from '../../../shared/components/message-type-selector/message-type-selector.module';
import { CHAT_MESSAGE_TYPE_ACTIONS } from '../../../shared/components/message-type-selector/providers/message-type-actions-provider';
import { LoadingSkeletonModule } from '../../../shared/components/loading-skeleton/loading-skeleton.module';
import { SharedModule } from '../../../shared/shared.module';
import { ChatMessagesViewModule } from '../common/components/chat-messages-view/chat-messages-view.module';
import { ChatViewComponent } from './chat-view.component';
import { ChatMessageSendFieldModule } from './components/chat-message-send-field/chat-message-send-field.module';
import { ChatViewDesktopHeaderComponent } from './components/chat-view-desktop/chat-view-desktop-header/chat-view-desktop-header.component';
import { ChatViewDesktopComponent } from './components/chat-view-desktop/chat-view-desktop.component';
import { ChatViewPreloaderGuard } from './providers/chat-view-preloader.guard';
import { ChatSkeletonComponent } from './components/chat-skeleton/chat-skeleton.component';

@NgModule({
  imports: [
    SharedModule,
    ScrollingModule,
    TranslateModule.forChild(),
    ChatMessagesViewModule,
    ChatMessageSendFieldModule,
    MessageTypeSelectorModule.forRoot(
      { menuId: CHAT_MESSAGE_TYPE_SELECTOR_MENU_ID, menuItems: CHAT_MESSAGE_TYPE_ACTIONS }
    ),
    HeaderModule,
    LoadingSkeletonModule,
  ],
  declarations: [
    ChatViewComponent,
    ChatViewDesktopComponent,
    ChatViewDesktopHeaderComponent,
    ChatSkeletonComponent
  ],
  exports: [
    ChatViewComponent
  ],
  providers: [
    ChatViewPreloaderGuard,
  ]
})
export class ChatViewModule { }
