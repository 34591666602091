@if (thumbnail$ | async; as thumbnail) {
  <div class="wen-embed-image-wrapper" (tap)="onMediaClick($event,thumbnail)">
    <img
      #image
      class="wen-embed-image"
      [wenLazyload]="thumbnail"
      [wenLazyloadDisableScaling]="true"
      (error)="handleImageError()"
      (load)="handleImageLoaded()"
      [retry-image-load]="onFinalError"
      [alt]="imageAltText"
      />
  </div>
}
@if (showLoading) {
  <div class="wen-embed-overlay">
    <wen-loading-indicator [variant]="'picture'"></wen-loading-indicator>
  </div>
}
@if (error) {
  <div
    (tap)="reloadImage()"
    class="wen-failed-image-load"
    >
    {{'RETRY_IMAGE_LOAD_TEXT' | translate}}
    <wen-icon [iconName]="'jump_forward'"/>
  </div>
}
