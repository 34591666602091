
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EmbedDTOType, EmbedDTOTypes } from '@portal/wen-backend-api';
import { getMediaTypeData } from '@portal/wen-components';

export interface EmbedDescription {
  icon?: string;
  text: string;
}

@Injectable()
export class EmbedService {

  private translateService = inject(TranslateService);

  getEmbedDescription(embed: EmbedDTOTypes): EmbedDescription {
    switch (embed.type) {
      case EmbedDTOType.DOCUMENT:
      case EmbedDTOType.LINK:
        return { text: embed.title };
      case EmbedDTOType.FORWARD:
        return { text: this.translateService.instant('FORWARDED_LABEL'), icon: 'forward' };
      case EmbedDTOType.MEDIA: {
        if (!embed?.subType) {
          return null;
        }
        const { icon, title } = getMediaTypeData(embed.subType);
        return { text: this.translateService.instant(title), icon };
      }
      case EmbedDTOType.POLL:
        return { text: this.translateService.instant('POLL_LABEL'), icon: 'chart' };
      case EmbedDTOType.NOTIFICATION:
        return { text: embed.notificationData.message };
      default:
        return null;
    }
  }
}
