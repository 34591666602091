import { selectorWithParam } from '../../../../core/common/util/selector-with-param';
import { selectChannelPermission } from '../../../../core/store/channel/channel.selectors';
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { ChannelDTO, ChannelMessagesDetailError, SocketIoService } from '@portal/wen-backend-api';
import { first, map, of, switchMap, tap } from 'rxjs';
import { fetchPermissionsForChannel, updateChannel, updateChannelMessagesDetail } from '../../../../core/store/channel/channel.actions';
import { RootState } from '../../../../core/store/root/public-api';
import { selectRouteParam } from '../../../../core/store/root/root.selectors';
import { channelMessageDetailIdentifier } from '../../tokens';
import { mapWithFirstFrom } from '../../../../core/common/operators/map-with-first-from';
import { isNullOrUndefined } from '../../../../core/common/operators/null-check-util';

export interface MessageDetailsExtras {
  error: ChannelMessagesDetailError;
  channelData: ChannelDTO;
}

export const channelMessageDetailsLoadedGuardFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(ChannelMessageDetailsLoadedGuard).canActivate(route, state);
};

@Injectable()
export class ChannelMessageDetailsLoadedGuard  {

  constructor(
    private store: Store<RootState>,
    private socketIoService: SocketIoService,
    private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.pipe(
      select(selectRouteParam(channelMessageDetailIdentifier)),
      first(),
      switchMap((messageId) => this.getDetailInfo$(messageId)),
      mapWithFirstFrom(({ channel }) => this.store.pipe(selectorWithParam(selectChannelPermission, channel?.id))),
      switchMap(([{ channel, error }, permissions]) => {
        let permissions$ = null;
        if (isNullOrUndefined(permissions)) {
          this.store.dispatch(fetchPermissionsForChannel({ channelId: channel.id, useCache: false }));
          permissions$ = this.store.pipe(
            selectorWithParam(selectChannelPermission, channel.id)
          );
        } else {
          permissions$ = of(permissions);
        }
        return permissions$.pipe(
          map(() => ({ channel, error }))
        );
      }),
      map(({ channel, error }) => {
        const currentState = this.router.getCurrentNavigation().extras.state || {};
        let newState = currentState;
        if (error) {
          const errorState: MessageDetailsExtras = { error, channelData: channel };
          newState = {
            ...newState,
            messageDetailExtras: errorState
          };
        } else {
          delete newState.messageDetailExtras;
          this.store.dispatch(updateChannel({ channel }));
        }
        this.router.getCurrentNavigation().extras.state = {
          ...newState,
        };
        return true;
      })
    );
  }

  private getDetailInfo$(messageId: string) {
    return this.socketIoService.channel.messagesDetail.acknowledgement$({ messageId }).pipe(
      tap(messageDetail => {
        this.store.dispatch(updateChannel({ channel: messageDetail.channel }));
        if (isNullOrUndefined(messageDetail.message.channelId)) {
          messageDetail.message.channelId = messageDetail.channel.id;
        }
        this.store.dispatch(updateChannelMessagesDetail({ payload: messageDetail }));
      })
    );
  }

}
