import { DateUtil } from '../../common/date/date-util';

export const base64ToFile = (fileInBase64: string): File => {
  const byteCharacters = atob(fileInBase64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'image/png' });
  return new File([blob], 'image.png', { type: 'image/png' });
};

export const blobToFile = (blob: Blob, extension: string, fileName: string = 'file' ): File => {
  const fullFileName = Boolean(extension) ? `${fileName}.${extension}` : `${fileName}`;
  return new File(
    [blob],
    fullFileName,
    {
      lastModified: DateUtil.currentDateMs(),
      type: blob.type
    }
  );
};
