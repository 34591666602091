import { Injectable } from '@angular/core';
import { VariantConfig } from '../../../directives/styling/variant-config';

/**
 * The name of the variants correspond to the name of the button organisms in Figma.
 * See https://www.figma.com/design/JiIe1xvwjUIC18l5ieeKnJ/WEN-Library?node-id=787-13054
 */
export enum ActionVariant {
  /**
   * A filled button with the primary color
   *  - Primary color background
   *  - White label
   */
  filled = 'filled',
  /**
   * An outlined button with a border and without fill
   *  - Transparent background
   *  - Primary color border
   *  - Primary color text
   */
  outlined = 'outlined',
  /**
   * A button without border and without fill with text in the primary color
   *  - Transparent background
   *  - Primary color text
   *  - No border
   */
  text = 'text',
  /**
   * A button without border and without fill with grey text
   *  - Transparent background
   *  - Grey text color
   *  - No border
   */
  textSubtle = 'textSubtle',
  /**
   * A button without border and without fill with white text
   *  - Transparent background
   *  - White text color
   *  - No border
   */
  textEmphasis = 'textEmphasis',
  /**
   * A button with emphasis background
   *  - White background
   *  - Grey text color
   */
  emphasisSubtle = 'emphasisSubtle'
}

export type IconSize = 'tiny' | 'small' | 'medium' | 'big' | 'very-big' | 'large';
export type ActionOrientation = 'horizontal' | 'vertical';

@Injectable()
export class ActionVariantConfig extends VariantConfig {
  possibleVariants = ActionVariant;
  defaultVariant = ActionVariant.text;
}
