import { Injectable } from '@angular/core';
import { Modifier } from '@popperjs/core';
import { createPreventScrollModifier } from '@portal/wen-components';
import { filter, first, Observable, of, shareReplay, Subject, switchMap, timeout } from 'rxjs';
import { ScrollerWrapper } from '../../../../core/services/scrolling/scroller-wrapper';
import { FeedDatasource } from './feed-datasource';
import { FeedLayoutCommunicator } from './feed-layout-communicator';
import { EventType, Router } from '@angular/router';
import { map, startWith } from 'rxjs/operators';
import { ExtendedScrollToOptions } from '@angular/cdk/scrolling';
import { FeedItemScroller } from './feed-item-scroller';

@Injectable()
export class FeedLayoutMediator implements FeedItemScroller {

  private scrollerWrapper: ScrollerWrapper;
  private feedLayoutCommunicator: FeedLayoutCommunicator;

  afterInitialization$ = new Subject<void>();
  /**
   * Signal for the final navigation event, used to kick-off the rendering process
   * Rendering started before this event can cause slow-down in the navigation because
   *  it can happen that at time still the previous route is shown and it will block
   *  the ui
   *
   *  Author: Zalan Flaisz
   *  Revision: ac72fa46
   */
  afterNavigationEnd$: Observable<void>;
  isFeedFullyRendered$ = this.afterInitialization$.pipe(
    switchMap(() => this.feedLayoutCommunicator.renderingInProgress$),
    map(isRendering => !isRendering),
    startWith(false)
  );

  constructor(
    private router: Router,
    private feedDatasource: FeedDatasource
  ) {
    this.afterNavigationEnd$ = this.router.events.pipe(
      filter(event => [EventType.NavigationEnd, EventType.Scroll].includes(event.type)),
      first(),
      shareReplay(1),
      timeout({
        first: 500,
        with: () => of(null),
      }),
      map(() => null)
    );
  }

  initialize(scrollerWrapper: ScrollerWrapper, feedLayoutCommunicator: FeedLayoutCommunicator) {
    this.scrollerWrapper = scrollerWrapper;
    this.feedLayoutCommunicator = feedLayoutCommunicator;
    this.afterInitialization$.next();
  }

  scrollToBottom() {
    if (!this.isInitialized()) {
      return;
    }
    this.feedLayoutCommunicator.setBottomAnchor();
    this.feedDatasource.loadFirstPage().pipe(
      first(),
      switchMap(({ hasResult }) => hasResult ? this.feedLayoutCommunicator.renderingInProgress$ : of(false))
    ).subscribe((hasActiveRendering) => {
      if (!hasActiveRendering) {
        this.scrollerWrapper.scrollTo({ bottom: 0 });
      }
    });
  }

  scrollTo(options: ExtendedScrollToOptions) {
    this.scrollerWrapper.scrollTo(options);
  }

  createPopperModifiers(): Partial<Modifier<any, any>>[] {
    return [
      createPreventScrollModifier(this.scrollerWrapper.scroller.getElementRef().nativeElement)
    ];
  }

  private isInitialized() {
    return this.feedLayoutCommunicator && this.scrollerWrapper;
  }

  getFeedItem(id: string): HTMLElement {
    return this.feedLayoutCommunicator.getRenderedItemById(id);
  }
}
