@if (model) {
  <div class="wen-channel-view-desktop-header-content" (click)="openDetail(model.id)">
    <wen-active-item-image-view [imageUrl$]="imageUrl$" [placeholder$]="placeholder$" overlaySize="medium"></wen-active-item-image-view>
    <div class="wen-channel-view-desktop-header-info">
      <div class="wen-channel-view-desktop-header-info-title">
        @if (model.isPrivate) {
          <wen-icon class="wen-icon-small" iconName="lock" wenVariant="assistiveInlined" [altText]="'CHANNEL_RESTRICTED_ICON' | translate"></wen-icon>
        }
        <h2>{{ model.title }}</h2>
        @if (model.isMuted) {
          <wen-icon class="wen-icon-small" iconName="mute" wenVariant="assistiveInlined" [altText]="'CHANNEL_MUTED_ICON' | translate"></wen-icon>
        }
      </div>
      <h3 portal-body-2>{{ subHeader }}</h3>
    </div>
  </div>
}
