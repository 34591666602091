import { Injectable } from '@angular/core';
import { SocketConnectionState, SocketIoService } from '@portal/wen-backend-api';
import { DocumentUtils } from '@portal/wen-common';
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMap } from 'rxjs';
import { ConnectionMonitor, ConnectionScope } from './types';

@Injectable()
export class SocketConnectionMonitor implements ConnectionMonitor {

  public readonly connectionState$ = this.socketIoService.isConnected$.pipe(
    distinctUntilChanged(),
    switchMap(() => {
      return DocumentUtils.isInBackground$.pipe(
        filter(isInbackground => !isInbackground),
      );
    }),
    switchMap(() => this.socketIoService.waitForAutoReconnect(3)),
    map(reconnectResult => reconnectResult.socketState === SocketConnectionState.CONNECTED),
    startWith(true),
    shareReplay(1),
    map(isOnline => {
      return {
        isOnline, scope: ConnectionScope.WEBSOCKET
      };
    })
  );

  constructor(private readonly socketIoService: SocketIoService) { }

  forceRetry() {
    this.socketIoService.reconnectIfDisconnected();
  }

}
