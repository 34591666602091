import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ActionComponent, MarkdownTextModule, WenTestIdModule, WithVariantDirective } from '@portal/wen-components';
import { CreateWelcomeComponent } from './create-welcome.component';
import { CreateWelcomeMarkdownComponent } from './create-welcome-markdown/create-welcome-markdown.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    WenTestIdModule,
    MarkdownTextModule,
    WithVariantDirective,
    ActionComponent
  ],
  declarations: [CreateWelcomeComponent, CreateWelcomeMarkdownComponent],
  exports: [CreateWelcomeComponent]
})
export class CreateWelcomeModule { }
