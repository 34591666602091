<div id="lightbox-header">
  <wen-action class="wen-action-no-padding" wenVariant="textEmphasis" (click)="close()" iconName="chevron_left">
    <span>{{ "BUTTON_BACK_LABEL" | translate }}</span>
  </wen-action>
  @if (isFileDownloadEnabled) {
    <wen-action
      class="wen-action-no-padding"
      wenVariant="textEmphasis"
      iconName="download_inactive"
      [style.visibility]="(isDownloading | async) ? 'hidden' : 'visible'"
      [altText]="'DOWNLOAD_BUTTON_LABEL' | translate"
      (click)="download()"></wen-action>
  }
</div>
