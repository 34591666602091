import { Component, Input } from '@angular/core';
import { WenIconModule } from '../wen-icon/wen-icon.module';

@Component({
  selector: 'wen-deleted-indicator',
  templateUrl: './deleted-indicator.component.html',
  styleUrl: './deleted-indicator.component.scss',
  standalone: true,
  imports: [WenIconModule]
})
export class DeletedIndicatorComponent {

  @Input() message: string;
}
