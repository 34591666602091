import { EditMessageEventDTO, MessageEvent } from '@portal/wen-backend-api';
import { hasPayload } from '../message-event-helper';

export const asEncryptedEditEvent = (event: MessageEvent): MessageEvent<EditMessageEventDTO> => {
  const isPayloadWrappedEdit = hasPayload(event.relations?.replace);
  const editMessage: EditMessageEventDTO = isPayloadWrappedEdit ? (event?.relations?.replace as any)?.payload : event?.relations?.replace;
  const editEvent: MessageEvent<EditMessageEventDTO> = {
    eventId: editMessage.relatesTo.replace.eventId,
    insertTimestamp: event.insertTimestamp,
    insertUser: event.insertUser,
    new: event.new,
    payload: editMessage,
    roomId: event.roomId,
    relations: event.relations
  };
  return editEvent;
};
