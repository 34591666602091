<div class="wen-widget-content" (contextmenu)="onContextMenu($event)" [class.wen-widget-content-wide]="isWideDevice">
  <div class="wen-banner-container" [class.wen-wide-banner-container]="isWideDevice">
    @if (isAppBannerVisible$ | async) {
      <wen-app-banner [smartStoreButtons]="isWideDevice === false"
      (closed)="onAppBannerClosed()"></wen-app-banner>
    }
    @if (showRefreshBanner$ | async) {
      <wen-refresh-banner></wen-refresh-banner>
    }
  </div>
  @if (isHeaderVisible$ | async) {
    <header>
      <ng-content select="wen-header"></ng-content>
    </header>
  }
  @if (isWideDevice) {
    <ng-container *ngTemplateOutlet="desktopContent"></ng-container>
  } @else {
    <div class="wen-mobile-content" wenIosSwipeDetector (swipeEvent)="swipeEvent($event)">
      <div [@routeAnimations]="prepareRouteForAnimation(outlet)" class="wen-mobile-primary-outlet">
        <router-outlet #outlet="outlet">
        </router-outlet>
      </div>
      @if (isNavigationBarVisible$ | async) {
        <div class="wen-mobile-navigation-bar">
          <ng-container *ngTemplateOutlet="navbarTemplate"></ng-container>
        </div>
      }
    </div>
  }
</div>

<ng-template #desktopContent>
  <div class="wen-desktop-content">
    @if (showSidebar$ | async) {
      <div class="wen-desktop-sidebar">
        @if (isNavigationBarVisible$ | async) {
          <div class="wen-desktop-sidebar-header">
            <ng-container>
              <ng-container *ngTemplateOutlet="navbarTemplate"></ng-container>
              @if (searchConfig$ | async; as searchConfig) {
                <wen-search-bar-desktop
                  [searchBarPlaceholder]="searchConfig.searchBarPlaceholder"
                  [searchEventsHandler]="searchConfig"
                  [disabled]="searchConfig?.disabled"
                  [filter-element]="searchConfig?.filterConfig"
                  [filterId]="searchConfig?.filterConfig?.filterId"
                  [filterEntityType]="searchConfig?.filterConfig?.filterEntityType"
                ></wen-search-bar-desktop>
              }
            </ng-container>
          </div>
        }
        <aside class="wen-desktop-sidebar-outlet">
          <router-outlet name="sidebar"></router-outlet>
        </aside>
      </div>
    }

    <main class="wen-desktop-primary-outlet">
      <ng-container *ngTemplateOutlet="primaryOutlet"></ng-container>
    </main>
  </div>
</ng-template>


<ng-template #navbarTemplate>
  <ng-content select="wen-navigation-bar"></ng-content>
</ng-template>

<ng-template #primaryOutlet>
  <router-outlet>
  </router-outlet>
</ng-template>
