
@switch (messageStatus) {
  @case ('SENT') {
    <wen-icon iconName="checkmark" [altText]="'MESSAGE_SENT' | translate" [className]="iconClassName"></wen-icon>
  }
  @case ('RECEIVED') {
    <wen-icon iconName="checkmark_all" [altText]="'MESSAGE_RECEIVED' | translate" [className]="iconClassName"></wen-icon>
  }
  @case ('READ') {
    <wen-icon iconName="eye_half_open" [altText]="'MESSAGE_READ' | translate" [className]="iconClassName"></wen-icon>
  }
  @case ('PENDING') {
    <wen-icon iconName="period" [altText]="'MESSAGE_PENDING' | translate" [className]="iconClassName"></wen-icon>
  }
}
