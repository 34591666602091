import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { ChannelFilter, SocketIoService } from '@portal/wen-backend-api';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { catchError, filter, first, map, merge, of, shareReplay, switchMap, withLatestFrom } from 'rxjs';
import { WenRouteId } from '../../../../../frame/routing/types';
import { LoadingState } from '../../../../common/types/store-loading-state';
import { selectorWithParam } from '../../../../common/util/selector-with-param';
import { selectActiveFiltersByEntityType } from '../../../filter/filter.selectors';
import { FilterEntityType, FilterType } from '../../../filter/models/filter';
import { SearchFilterId } from '../../../header/models/HeaderData';
import { RootState } from '../../../root/public-api';
import { selectOutletIds } from '../../../root/root.selectors';
import { reloadDiscoverChannelList, subscribeToDiscoverChannelUpdates, updateDiscoverChannelList, updateDiscoverChannelLoadingState } from '../../channel.actions';
import { Filterable } from '../../../../../shared/components/filter-view/models/filter-item';

@Injectable()
export class DiscoveryChannelListEffects {

  private readonly currentFilter$ = this.store.pipe(
    selectorWithParam(selectActiveFiltersByEntityType, FilterEntityType.CHANNEL_LISTS),
    smartDistinctUntilChanged(),
    withLatestFrom(this.store.pipe(select(selectOutletIds))),
    filter(([_, outletIds]) => {
      return WenRouteId.CHANNEL_DISCOVERY === (outletIds.sidebarId ?? outletIds.primaryId);
    }),
    map(([newFilters, _]) => {
      newFilters = newFilters.filter(f => f.filterType === FilterType.SELECT ||
        (f.filterType === FilterType.SEARCH && f.filterId === SearchFilterId.LIST_SEARCH));
        return newFilters.reduce((allFilters, currFil) => {
        let aF;
        const filterValue = currFil.filterValue as Filterable;
        switch (currFil.filterType) {
          case FilterType.SELECT:
            aF = filterValue.filter;
            break;
          case FilterType.SEARCH:
            aF = { search: filterValue };
            break;
          default:
            break;
        }
        return { ...allFilters, ...aF };
      }, {} as ChannelFilter);
    })
  );

  private readonly channelListForDiscover$ = this.socketIoService.channel.listForDiscover.listen.pipe(
    shareReplay(1)
  );

  onUpdate$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeToDiscoverChannelUpdates),
    first(),
    switchMap(() => {
      return merge(
        this.channelListForDiscover$.pipe(
          switchMap((channels) => {
            return [
              updateDiscoverChannelList({ channels: channels.content }),
              updateDiscoverChannelLoadingState({ loadingState: LoadingState.LOADED })
            ];
          })
        )
      ).pipe(
        catchError(() => of(updateDiscoverChannelLoadingState({ loadingState: LoadingState.ERROR })))
      );
    })
  ));

  reloadDiscoverChannelList$ = createEffect(() => this.actions$.pipe(
    ofType(reloadDiscoverChannelList),
    switchMap(() => this.currentFilter$.pipe(first())),
    map((currentFilter) => {
      this.socketIoService.channel.listForDiscover.emit({ ...currentFilter });
      return updateDiscoverChannelLoadingState({ loadingState: LoadingState.NOT_LOADED });
    })
  ));

  updateFilter$ = createEffect(() => this.currentFilter$.pipe(
    map((currentFilter) => {
      this.socketIoService.channel.listForDiscover.emit({ ...currentFilter });
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<RootState>,
    private socketIoService: SocketIoService,
  ) { }
}
