@if (indicatorType !== IndicatorType.PREMIUM_READING_CHANNEL) {
  <div class="wen-indicator-background"></div>
}
@if (indicatorType === IndicatorType.PREMIUM_READING_CHANNEL) {
  <img class="wen-indicator-premium-channel-background" [src]="'/assets/wen-widget/image/premium-channel.svg'" alt="">
}

<wen-icon class="wen-indicator-icon" [iconName]="indicatorIcon"
          [ngClass]="{
          'wen-indicator-premium-channel-icon': indicatorType === IndicatorType.PREMIUM_READING_CHANNEL,
          'wen-icon-small': !isLarge
          }"
          [altText]="indicatorIconAltTextKey | translate"
></wen-icon>
