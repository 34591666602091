<div class="wen-app-detail-layout" cdkScrollable>
  @if (currentApp$ | async; as currentApp) {
    @if (headerData$ | async; as headerData) {
      <wen-detail-header [model]="headerData" [coverImageStyle]="'square'">
        <h1 wen-detail-header-title>{{ headerData.title }}</h1>
        @if (canEdit$ | async) {
          <wen-action wen-detail-header-quick-actions [isNavigating]="true" class="wen-action-round wen-action-round-padding" wenVariant="filled" [wen-test-id]="'wen-app-detail-edit-app-button'" iconName="edit" [altText]="'EDIT_ACTION_LABEL' | translate" (click)="editApp(currentApp.id)"></wen-action>
        }
        @if (hasAndroidStoreInfo$ | async) {
          <wen-action wen-detail-header-quick-actions [isNavigating]="true" class="wen-action-round wen-action-no-padding" [altText]="'APP_BANNER_TO_APP_ANDROID' | translate" (click)="openAndroidStore(currentApp.androidPlayStoreId)">
            <wen-icon class="wen-icon-huge wen-icon-store wen-icon-colorful" iconName="android_store_logo"></wen-icon>
          </wen-action>
        }
        @if (hasIOSStoreInfo$ | async) {
          <wen-action wen-detail-header-quick-actions [isNavigating]="true" class="wen-action-round wen-action-no-padding" [altText]="'APP_BANNER_TO_APP_IOS' | translate" (click)="openIOSStore(currentApp.iosAppName, currentApp.iosAppStoreId)">
            <wen-icon class="wen-icon-huge wen-icon-store wen-icon-colorful" iconName="apple_store_logo"></wen-icon>
          </wen-action>
        }
        <wen-action wen-detail-header-quick-actions [isNavigating]="true" wenVariant="filled" class="wen-action-round wen-action-round-padding" *ifSubscribedTo="currentAppId$ | async; extraCondition: hasUri$ | async" iconName="open_link" (click)="navigateToApp(currentApp)"></wen-action>
        <wen-action wen-detail-header-quick-actions wenVariant="filled" class="wen-action-round wen-action-round-padding" *ifSubscribedTo="currentAppId$ | async"  iconName="delete" (click)="unSubscribeFromApp()"></wen-action>
      </wen-detail-header>
    }
    @if (currentApp.description || currentApp.categories?.length) {
      <div class="wen-app-detail-content-group">
        @if (currentApp.description) {
          <div class="wen-app-detail-field">
            <div class="wen-app-detail-info-label" portal-caption>{{ 'LABEL_INFO' | translate }}</div>
            <div class="wen-app-detail-info-text" portal-body>{{ currentApp.description }}</div>
          </div>
        }
        @if (currentApp.categories?.length) {
          <wen-read-categories-field
            class="wen-app-detail-field"
            [fieldLabel]="'APP_DETAIL_CATEGORY_DESCRIPTION' | translate"
            [categories]="currentApp.categories">
          </wen-read-categories-field>
        }
      </div>
    }
  }
</div>
<ng-container *ifNotSubscribedTo="currentAppId$ | async">
  <wen-interact-button (click)="subscribeToApp()">{{
  "APP_SUBSCRIBE_BUTTON_LABEL" | translate }}</wen-interact-button>
</ng-container>
