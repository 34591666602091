import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { DetailHeaderModule } from '../../../shared/components/detail-header/detail-header.module';
import { ReadCategoriesFieldModule } from '../../../shared/components/read-categories-field/read-categories-field.module';
import { SharedModule } from '../../../shared/shared.module';
import { EventDetailComponent } from './components/event-detail/event-detail.component';
import { EventDetailDataResolver } from './components/event-detail/providers/event-detail-data-resolver';
import { EventSubscribeButtonComponent } from './components/event-subscribe-button.component/event-subscribe-button.component';
import { EventIcalButtonComponent } from './components/event-ical-button/event-ical-button.component';


@NgModule({
  declarations: [EventDetailComponent, EventSubscribeButtonComponent],
  imports: [
    DetailHeaderModule,
    SharedModule,
    ScrollingModule,
    ReadCategoriesFieldModule,
    EventIcalButtonComponent
  ],
  providers: [
    EventDetailDataResolver,
  ],
  exports: [EventDetailComponent]
})
export class EventDetailModule { }
