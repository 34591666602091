import { WenRoutes } from '../../../frame/routing/types';
import { addChannelInfoRoute, addChannelInteractionsRoute, addChannelRestrictionsRoute, addChannelVisibilityRoute, channelAddAdminRoute, channelAddContentProviderRoute, channelAddWelcomeRoute, channelAdminListRoute, channelContentProviderListRoute, channelDetailRoute, channelDiscoveryRoute, channelEditRoute, channelExploreRoute, channelFeaturedViewRoute, channelInteractionSettingsRoute, channelListRoute, channelMessageDetailDesktopRoute, channelPostEditorRoute, channelPostLocationSelectorRoute, channelRestrictionSettingsRoute, channelSchedulesDesktopRoute, channelSettingsRoute, channelSubscriberListRoute, channelViewDesktopRoute, channelVisibilitySettingsRoute, pendingChannelJoinRequestsRoute } from './channel.routes';

export const dialogOutletChannelRoutes: WenRoutes = [
  channelDetailRoute,
  channelPostEditorRoute,
  channelPostLocationSelectorRoute,
  channelEditRoute,
  channelVisibilitySettingsRoute,
  channelInteractionSettingsRoute,
  channelRestrictionSettingsRoute,
  channelAddWelcomeRoute,
  addChannelInfoRoute,
  addChannelVisibilityRoute,
  addChannelRestrictionsRoute,
  addChannelInteractionsRoute,
  pendingChannelJoinRequestsRoute,
  channelAdminListRoute,
  channelAddAdminRoute,
  channelAddContentProviderRoute,
  channelContentProviderListRoute,
  channelSubscriberListRoute,
  channelSettingsRoute
];

export const sidebarOutletChannelRoutes: WenRoutes = [
  channelDiscoveryRoute,
  channelExploreRoute,
  channelListRoute,
  {
    path: '**',
    redirectTo: 'list'
  }
];

export const primaryOutletChannelRoutes: WenRoutes = [
  channelSchedulesDesktopRoute,
  channelViewDesktopRoute,
  channelFeaturedViewRoute,
  channelEditRoute,
  channelVisibilitySettingsRoute,
  channelInteractionSettingsRoute,
  channelRestrictionSettingsRoute,
  pendingChannelJoinRequestsRoute,
  channelAdminListRoute,
  channelAddAdminRoute,
  {
    path: '**',
    redirectTo: '/home/dashboard(sidebar:channel/list)'
  }
];

export const primaryOutletMessageRoutes: WenRoutes = [
  channelMessageDetailDesktopRoute
];
