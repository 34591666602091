@if (icon$ | async; as icon) {
  @if (icon.svgIcon) {
    <mat-icon class="wen-svg-icon" [svgIcon]="icon.svgIcon" [style.backgroundColor]="fillColor" [style.color]="color"></mat-icon>
  } @else {
    <mat-icon [style.backgroundColor]="fillColor" [style.color]="color">{{icon.fontIcon}}</mat-icon>
  }

  <!-- Alternative text for screen reader (invisible in the layout) -->
  @if (altText) {
    <span class="cdk-visually-hidden">{{ altText }}</span>
  }
}
