<div class="empty-list-header-wrapper">
  <ng-content select="[wen-empty-list-header]"></ng-content>
</div>

<img [src]="src" alt="" />

<div portal-title class="wen-empty-list-title">{{ titleLabel | translate }}</div>

<div portal-body-2 class="wen-empty-list-description">{{ textLabel | translate }}</div>

@if (buttonLabel) {
  <wen-action class="wen-action-rounded"
              wenVariant="filled"
              [isNavigating]="true"
              (click)="buttonClicked.emit()">
    {{ buttonLabel | translate }}
  </wen-action>
}
