@if (quote) {
  <div class="wen-quote-border" [ngStyle]="{'border-color': borderColor }"></div>
  <div class="wen-quote-content-wrapper">
    <div class="wen-quote-content">
      <div portal-subheading-1 class="wen-quote-author" [wenTextColorizer]="quote.author">{{ quote.author }}</div>
      <div class="wen-quote-text">
        @if (quote.isDeleted) {
          <wen-deleted-indicator [message]="quote.content"></wen-deleted-indicator>
        } @else {
          @if (quote.content) {
            <div portal-subheading-1 class="wen-quote-text-section" [innerHTML]="quote.content | removeMarkdown"></div>
          } @else {
            <wen-embed-description [embed]="(quoteMedia || quoteLink || quotePoll)"></wen-embed-description>
          }
        }
      </div>
    </div>
    <div class="wen-quote-after">
      @if (quoteMedia) {
        <wen-media-preview class="wen-media-preview" [previewSize]="'mini'" [mediaData]="quoteMedia"></wen-media-preview>
      }
      @if (quoteLink) {
        <wen-thumbnail-image class="wen-media-preview" [thumbnail]="quoteLink.thumbnail" [fixWidth]="50" [fixHeight]="50" [roundedEdges]="true"></wen-thumbnail-image>
      }
      @if (quotePoll) {
        <wen-icon class="wen-media-preview wen-icon-huge" [iconName]="'chart'"></wen-icon>
      }
    </div>
  </div>
}
