import { Inject, Injectable } from '@angular/core';
import { BaseNativeApi, CommonPermissionType, DownloadHeader, Encoding, FileMeta, LinkOpenerOptions, LinkOpenerResult, NativePlatform, ShareContent, ShareOptions, StatusBarAnimationConfig, StatusBarStyle } from '@portal/react-native-api';
import { isUrl } from '@portal/wen-common';
import { Remote } from 'comlink';
import { BehaviorSubject, Observable, combineLatest, from, of, throwError } from 'rxjs';
import { catchError, first, map, shareReplay, switchMap } from 'rxjs/operators';
import { NATIVE_API_CONFIG_TOKEN } from '../tokens';
import { IS_REACT_NATIVE_ROOT, WenNativeApiType, WenNativeInitialData, isShareContentWithUrl } from '../types/api-types';
import { NativeApiConfiguration } from '../types/configuration-types';
import { NativeNotificationPayload, NativeNotificationRegistrationPayload } from '../types/notification-types';

@Injectable()
export class WenNativeApi {

  private api: Remote<WenNativeApiType>;
  private platform: NativePlatform;

  keyboardDidShow$: Observable<void>;
  keyboardDidHide$: Observable<void>;
  initialData$: Observable<WenNativeInitialData>;
  connectivityChange$ = new BehaviorSubject<boolean>(null);

  constructor(
    @Inject(NATIVE_API_CONFIG_TOKEN) private nativeApiConfiguration: NativeApiConfiguration,
  ) { }

  init(
    api: Remote<WenNativeApiType>,
    keyboardDidShow$: Observable<void>,
    keyboardDidHide$: Observable<void>,
    connectivityChange$: Observable<boolean>
  ) {
    this.api = api;
    this.keyboardDidShow$ = keyboardDidShow$;
    this.keyboardDidHide$ = keyboardDidHide$;

    this.initialData$ = from(this.api.getPlatform()).pipe(
      switchMap((platform) => combineLatest([
        of(platform),
        from(this.api.getInitialDeepLink()).pipe(
          catchError(() => of(undefined))
        ),
        from(this.api.getInitialShareData()).pipe(
          catchError(() => of(undefined))
        ),
        from(this.getInitialNotification()).pipe(
          catchError(() => of(undefined))
        ),
      ])),
      map(([platform, initialDeepLinkData, initialShareData, initialNotificationData]) => {
        this.setPlatform(platform);
        const rootUrl = `${location.protocol}://${location.host}`;
        if (Boolean(initialDeepLinkData?.url)) {
          initialDeepLinkData.url = initialDeepLinkData.url.replace(rootUrl, '');
        }
        return { platform, initialDeepLinkData, initialNotificationData, initialShareData };
      }),
      first(),
      shareReplay(1)
    );

    connectivityChange$.subscribe(isConnected => {
      this.connectivityChange$.next(isConnected);
    });
    return this.initialData$;
  }

  openUrl(url: string, options: LinkOpenerOptions) {
    const openUrlMethod = this.api.openURL as BaseNativeApi['openURL'];
    return openUrlMethod<LinkOpenerResult, LinkOpenerOptions>(url, options) || null;
  }

  isIOS() {
    return this.platform === 'ios';
  }

  isAndroid() {
    return this.platform === 'android';
  }

  isReactNativeApp() {
    return IS_REACT_NATIVE_ROOT;
  }

  getPlatform() {
    return this.platform;
  }

  getInitialNotificationData(): Observable<NativeNotificationPayload> {
    return this.initialData$.pipe(
      map(data => data.initialNotificationData)
    );
  }

  getInitialDeepLink() {
    return this.initialData$.pipe(
      map(data => data.initialDeepLinkData)
    );
  }

  getInitialShareData() {
    return this.initialData$.pipe(
      map(data => data.initialShareData)
    );
  }

  unregisterFromNotifications() {
    if (this.isReactNativeApp() && this.api.unregisterFromNotifications) {
      this.api.unregisterFromNotifications();
    }
  }

  registerToNotifications(): Observable<NativeNotificationRegistrationPayload> {
    if (this.isReactNativeApp() && this.api.registerToNotifications) {
      return from<Promise<NativeNotificationRegistrationPayload>>(this.api.registerToNotifications()).pipe(
        catchError(() => of(null))
      );
    } else {
      return of(null);
    }
  }

  requestMultiplePermissions(permissions: CommonPermissionType[]) {
    if (this.isReactNativeApp() && this.api.requestMultiplePermissions) {
      return from(this.api.requestMultiplePermissions(permissions));
    }
    return throwError(() => 'Native permissions api not found');
  }

  checkPermissions(permissions: CommonPermissionType[]) {
    if (this.isReactNativeApp() && this.api.checkPermissions) {
      return from(this.api.checkPermissions(permissions));
    }
    return throwError(() => 'Native permissions api not found');
  }

  requestLocation() {
    return from(this.api.requestLocation({}, { authorizationLevelIos: 'whenInUse' }));
  }

  setStatusBarColor(config: StatusBarAnimationConfig) {
    if (!this.nativeApiConfiguration.enableDynamicStatusBar()) {
      return;
    }
    if (this.isReactNativeApp() && this.api.setStatusBarColor) {
      this.api.setStatusBarColor(config);
    }
  }

  setStatusBarStyle(style: StatusBarStyle) {
    if (!this.nativeApiConfiguration.enableDynamicStatusBar()) {
      return;
    }
    if (this.isReactNativeApp() && this.api.setStatusBarStyle) {
      this.api.setStatusBarStyle(style);
    }
  }

  setNavbarColor(color: string) {
    if (!this.nativeApiConfiguration.enableDynamicStatusBar()) {
      return;
    }
    if (this.isIOS() && this.isReactNativeApp() && this.api.setNavbarColor) {
      this.api.setNavbarColor(color);
    }
  }

  share(content: ShareContent, options?: Pick<ShareOptions, 'dialogTitle' | 'subject'>) {
    if (this.api.share) {
      let shareContent = { ...content };
      if (this.isIOS() && isShareContentWithUrl(shareContent) && isUrl(shareContent.url)) {
        shareContent = { url: shareContent.url };
      }
      return this.api.share(shareContent, options);
    }
    return Promise.reject(null);
  }

  storeSet(key: string, value: any) {
    this.api.storeSet(key, value);
  }

  getFileByPath(path: string, encoding: Encoding) {
    return from(this.api.getFileByPath(path, encoding));
  }

  openSettings() {
    return this.api.openAppSettings();
  }

  minimizeApp() {
    return this.api.exit();
  }

  downloadFile(url: string, fileMeta: FileMeta): Observable<boolean> {
    return from(this.api.download(url, fileMeta)).pipe(catchError(() => of(false)));
  }

  downloadFileAuthorized(url: string, header: DownloadHeader, fileName: string){
    return from(this.api.downloadFile(url, header, fileName)).pipe(catchError(() => of(false)));
  }

  private setPlatform(platform: NativePlatform) {
    this.platform = platform;
  }

  private getInitialNotification() {
    if (this.isReactNativeApp() && this.api.getInitialNotificationData) {
      return this.api.getInitialNotificationData() as Promise<NativeNotificationPayload>;
    } else {
      return Promise.resolve(undefined);
    }
  }
}
