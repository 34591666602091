<div class="wen-channel-detail-layout" cdkScrollable>
  @if (currentChannel$ | async; as currentChannel) {
    @if (headerData$ | async; as headerData) {
      <wen-detail-header [model]="headerData">
        @if (canEdit$ | async) {
          <wen-action wen-detail-header-quick-actions wenVariant="filled" [isNavigating]="true" class="wen-action-round wen-action-round-padding" iconName="edit" [altText]="'EDIT_ACTION_LABEL' | translate" [wen-test-id]="'channel-detail-edit-button'" (click)="editChannel(currentChannel.id)"></wen-action>
        }
        <wen-mute-unmute-channel-button wen-detail-header-quick-actions [channel]="currentChannel" [wen-test-id]="'channel-detail-mute-unmute-button'">
        </wen-mute-unmute-channel-button>
        <div wen-detail-header-title [class.wen-channel-detail-header-has-title-icon]="headerData.prefixTitleIcon">
          @if (headerData.prefixTitleIcon) {
            <wen-icon class="wen-app-header-title-prefix-icon wen-icon-small"
            [iconName]="headerData.prefixTitleIcon.name" [altText]="headerData.prefixTitleIcon.altText"></wen-icon>
          }
          <h1 portal-title class="wen-channel-detail-header-title-text">{{ headerData.title }}</h1>
        </div>
        @if (canForward$ | async) {
          <wen-forward-button wen-detail-header-quick-actions [deepLink]="channelDeepLink" [context]="forwardContext"></wen-forward-button>
        }
        <wen-action wenVariant="filled" wen-detail-header-quick-actions *ifSubscribedTo="currentChannelId$ | async; extraCondition: isLeaveButtonVisible$ | async"  class="wen-action-round wen-action-round-padding" iconName="logout" [altText]="'CHANNEL_UNSUBSCRIBE' | translate" [wen-test-id]="'channel-detail-unsubscribe-button'" (click)="unSubscribeFromChannel()"></wen-action>
      </wen-detail-header>
    }
    <div class="wen-channel-detail-content-group">
      @if (currentChannel.description) {
        <div class="wen-channel-detail-field">
          <div class="wen-channel-detail-field-label" portal-caption>{{ 'LABEL_INFO' | translate }}</div>
          <div class="wen-channel-detail-field-text" portal-body>{{ currentChannel.description }}</div>
        </div>
      }
      @if (currentChannel.imprint) {
        <div class="wen-channel-detail-field">
          <div class="wen-channel-detail-field-label" portal-caption>{{ 'LABEL_IMPRINT' | translate }}</div>
          <div class="wen-channel-detail-field-text" portal-body [innerText]="currentChannel.imprint"></div>
        </div>
      }
      @if (currentChannel.dataProtectionUrl) {
        <div class="wen-channel-detail-field">
          <div class="wen-channel-detail-field-label" portal-caption>{{ 'LABEL_DATA_PROTECTION' | translate }}</div>
          <div class="wen-channel-detail-field-text">
            <a (click)="openUrl(currentChannel.dataProtectionUrl)">{{ currentChannel.dataProtectionUrl }}</a>
          </div>
        </div>
      }
      @if (currentChannel.categories?.length) {
        <wen-read-categories-field
          class="wen-channel-detail-field"
          [fieldLabel]="'CHANNEL_DETAIL_CATEGORY_DESCRIPTION' | translate"
          [categories]="currentChannel.categories">
        </wen-read-categories-field>
      }
      @if (channelDeepLink) {
        <div class="wen-channel-detail-field"
          (click)="shareDeepLink()">
          <div class="wen-channel-detail-field-label" portal-caption>{{ 'LABEL_COPY_CHANNEL_LINK' | translate }}</div>
          <div class="wen-channel-detail-field-text wen-channel-detail-deep-link-container">
            <div portal-body>{{ channelDeepLink }}</div>
            <wen-icon [iconName]="'share'"></wen-icon>
          </div>
        </div>
      }
    </div>
    @if (isAdminGroupVisible$ | async) {
      <div class="wen-channel-detail-content-group wen-channel-detail-submenus">
        @if (canListAdmins$ | async) {
          <wen-menu-entry (click)="navigateToAdminListPage(currentChannel.id)"
            >
            <wen-icon [iconName]="'user_shield_on'" [fillColor]="adminListMenuEntryFillColor"
              class="wen-icon-medium" color="white" menuEntryIcon>
            </wen-icon>
            <span menuEntryTitle>{{ 'CHANNEL_ADMIN_LIST_FORM_TITLE' | translate }}</span>
            <span menuEntryDescription>{{ adminCount$ | async }}</span>
          </wen-menu-entry>
        }
        @if (canHandleJoinRequests$ | async) {
          <wen-menu-entry (click)="navigateToChannelJoinRequestPage(currentChannel.id)"
            >
            <wen-icon [iconName]="'user_raise_hand'" [fillColor]="visibilitySettingsMenuEntryFillColor" color="white"
              class="wen-icon-medium" menuEntryIcon>
            </wen-icon>
            <span menuEntryTitle>{{ 'CHANNEL_PENDING_JOIN_REQUESTS_FORM_TITLE' | translate }}</span>
            <span menuEntryDescription>{{ pendingJoinRequestCount$ | async }}</span>
          </wen-menu-entry>
        }
        @if (isContentProviderListVisible$ | async) {
          <wen-menu-entry (click)="navigateToContentProviderListPage(currentChannel.id)">
            <wen-icon iconName="author" [fillColor]="contentProviderMenuEntryFillColor" color="white"
            class="wen-icon-medium" menuEntryIcon></wen-icon>
            <span menuEntryTitle>{{ 'CHANNEL_CONTENT_PROVIDER_TITLE' | translate }}</span>
            <span menuEntryDescription>{{ contentProviderCount$ | async }}</span>
          </wen-menu-entry>
        }
        @if (isSubscriberListVisible$ | async) {
          <wen-menu-entry (click)="navigateToSubscriberListPage(currentChannel.id)">
            <wen-icon iconName="group" [fillColor]="channelSubscribersMenuEntryFillColor" color="white"
            class="wen-icon-medium" menuEntryIcon></wen-icon>
            <span menuEntryTitle>{{ 'CHANNEL_SUBSCRIBERS_TITLE' | translate }}</span>
            <span menuEntryDescription>{{ subscriberCount$ | async }}</span>
          </wen-menu-entry>
        }
        @if (infoTextForCollaborativeChannels$ | async; as infoText) {
          <div class="wen-list-info-text" portal-caption>{{ infoText | translate }}</div>
        }
      </div>
    }
    <ng-container *ifSubscribedTo="currentChannelId$ | async">
      @if (isSettingsVisible$ | async) {
        <div class="wen-channel-detail-content-group wen-channel-detail-submenus">
          <wen-menu-entry (click)="navigateToChannelSettings(currentChannel.id)">
            <wen-icon iconName="settings" [fillColor]="channelSettingsMenuEntryFillColor" color="white"
            class="wen-icon-medium" menuEntryIcon></wen-icon>
            <span menuEntryTitle>{{ 'CHANNEL_DETAIL_SETTING_TITLE' | translate }}</span>
          </wen-menu-entry>
        </div>
      }
    </ng-container>
  }
</div>
<wen-channel-subscription-controls *ifNotSubscribedTo="currentChannelId$ | async"
  [channelId]="currentChannelId$ | async"
  [restrictions]="restrictions$ | async"
  [inviteData]="inviteData$ | async"
></wen-channel-subscription-controls>
