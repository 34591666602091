import { inject, Injectable } from '@angular/core';
import { ObjectFit, ThumbnailConfig, ThumbnailCustomizations, WenBreakpointObserver } from '@portal/wen-components';

const MOBILE_ASPECT_RATIO = 2/3;
const DESKTOP_ASPECT_RATIO = 3/4;

@Injectable()
export class ChannelThumbnailCustomizations extends ThumbnailCustomizations {
  readonly breakpointObserver = inject(WenBreakpointObserver);
  getConfig(): ThumbnailConfig {
    return {
      objectFit: ObjectFit.CONTAIN,
      minAspectRatio: this.breakpointObserver.isDesktopStyleDevice ? DESKTOP_ASPECT_RATIO : MOBILE_ASPECT_RATIO,
      applyMaxWidth: false,
    };
  }
}
