import { inject, Injectable } from '@angular/core';
import { RoomType } from '@portal/wen-backend-api';
import { map, Observable, of, switchMap } from 'rxjs';
import { ForbiddenForAnonymousUserDialogService } from '../../../../shared/services/forbidden-for-anonymous-user-dialog.service';
import { ChatAvailabilityCheckerWithOverlay } from '../../../services/chat/chat-availability-checker-with-overlay';

@Injectable()
export class ChatRoomCreationValidator {

  private readonly chatAvailabilityChecker = inject(ChatAvailabilityCheckerWithOverlay);
  private readonly anonymousUserDialogService = inject(ForbiddenForAnonymousUserDialogService);

  validateRoomData(roomType: RoomType, targetUserIds: string[]): Observable<boolean> {
    if (roomType === RoomType.DIALOG && targetUserIds.length) {
      const dialogData = { errorMessageLabel: 'ERROR_ROOM_CANNOT_BE_CREATED' };
      const dialogTitle = 'ERROR_GENERIC_DIALOG_MESSAGE';
      return this.chatAvailabilityChecker.checkChatAvailabilityWithUsersWithErrorDialog(
        targetUserIds,
        dialogData,
        dialogTitle
      ).pipe(
        map(unavailableUserIds => unavailableUserIds.length === 0),
        switchMap(isValid => {
          if (isValid) {
            return of(true);
          }
          return this.anonymousUserDialogService.openChatCreationForbiddenWhenAnonymous().pipe(
            map(result => Boolean(result))
          );
        })
      );
    }
    return of(true);
  }

}
