import { PathLocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationStateTracker } from './navigation-tracker';

export interface WenHistoryState {
  /**
   * The current history stack containing the stacked routes
   */
  historyStack?: string[];
  /**
   * This property can be used to manually control of the current stack
   *  When provided aside from navigation the current history stack will not be
   *  extended with the current path, but overridden completely
   * Useful if eg. there is a need to navigate back to a known stack entry
   *  purging all the stack entries between the last entry and that one
   *
   */
  historyStackOverride?: string[];
  fromDeepLink?: boolean;
  navigationId?: number;
  fromInternalDeepLink?: boolean;
}

@Injectable()
export class WenPathLocationStrategy extends PathLocationStrategy {

  private readonly navigationTracker = new NavigationStateTracker();

  override pushState(state: WenHistoryState, title: string, url: string, queryParams: string) {
    const currentHistoryStack = this.getState()?.historyStack;
    const historyStackOverride = state?.historyStackOverride;
    if (historyStackOverride) {
      const newState: WenHistoryState = {
        historyStack: historyStackOverride
      };
      super.replaceState(newState, title, url, queryParams);
    } else if (currentHistoryStack?.length) {
      const currentPath = this.path();
      const newState: WenHistoryState = {
        ...(state || {}),
        historyStack: [...currentHistoryStack, currentPath]
      };
      this.replaceState(newState, title, url, queryParams);
    } else {
      super.pushState(state, title, url, queryParams);
      this.navigationTracker.onPathChanged(url);
    }
  }

  override back(): void {
    super.back();
  }

  override getState(): WenHistoryState {
    return super.getState();
  }

  override replaceState(state: WenHistoryState, title: string, url: string, queryParams: string): void {
    return super.replaceState(state, title, url, queryParams);
  }

  /**
   * Try to navigate back to a given url thus compressing the history to avoid loops
   *
   * @param url to navigate to
   * @returns
   *  - true the navigation succeeded and the path was in the history
   *  - false if the url was not in the history
   */
  navigateCompressed(url: string) {
    const compressToIndex = this.navigationTracker.indexOfReversed(url);
    if (compressToIndex) {
      this.historyGo(-compressToIndex);
      return true;
    }
    return false;
  }

}
