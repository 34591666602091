import { Component, inject, Input } from '@angular/core';
import { FeatureEnablementService } from '../../../core/services/configuration/feature-enablement';

@Component({
  selector: 'wen-loading-skeleton',
  templateUrl: './loading-skeleton.component.html',
  styleUrls: ['./loading-skeleton.component.scss'],
})
export class LoadingSkeletonComponent {

  private readonly featureEnablementService = inject(FeatureEnablementService);

  protected enableSkeletonLoading$ = this.featureEnablementService.featureConfigObs.enableSkeletonLoading$;

  @Input() isLoaded = false;

}
