import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { ScrollStateModule } from '../../../shared/directives/scroll-state/scroll-state.module';
import { LocationDistanceFormatterPipe } from '../../../shared/pipes/location-distance-formatter.pipe';
import { SharedModule } from '../../../shared/shared.module';
import { DiscoveryEventListItemAfterComponent } from './components/discovery-event-list/components/discovery-event-after/discovery-event-list-item-after.component';
import { DiscoveryEventListComponent } from './components/discovery-event-list/discovery-event-list.component';
import { EventListItemComponent } from './components/event-list/event-list-item/event-list-item.component';
import { EventListComponent } from './components/event-list/event-list.component';
import { UserEventListItemAfterComponent } from './components/user-event-list/components/user-event-after/user-event-list-item-after.component';
import { UserEventListComponent } from './components/user-event-list/user-event-list.component';
import { DiscoveryEventListDataResolver } from './providers/discovery-event-list-data.resolver';
import { EventListDataSource } from './providers/event-list-datasource';
import { UserEventListDataResolver } from './providers/user-event-list-data.resolver';
import { IsDesktopViewDirective } from '@portal/wen-components';

@NgModule({
  imports: [SharedModule, ScrollingModule, ScrollStateModule, IsDesktopViewDirective],
  declarations: [
    EventListComponent,
    EventListItemComponent,
    UserEventListComponent,
    DiscoveryEventListComponent,
    UserEventListItemAfterComponent,
    DiscoveryEventListItemAfterComponent,
  ],
  exports: [EventListComponent, EventListItemComponent],
  providers: [
    DiscoveryEventListDataResolver,
    EventListDataSource,
    LocationDistanceFormatterPipe,
    UserEventListDataResolver,
  ]
})
export class EventListModule { }
