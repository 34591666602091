import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { animationFrameScheduler, filter, observeOn } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { Subscription } from 'rxjs/internal/Subscription';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { isNullOrUndefined } from '../../../../../core/common/operators/null-check-util';
import { OutletHelper } from '../../../../../core/services/navigation/outlet-specific/outlet-helper';
import { selectCurrentHeaderData } from '../../../../../core/store/header/header.selectors';
import { BaseHeaderCommand, HeaderCommand } from '../../../../../core/store/header/models/HeaderCommand';
import { RootState } from '../../../../../core/store/root/public-api';
import { HeaderCommandHandler } from '../header-command/header-command-handler';
import { AppHeaderController, CommandButtonPosition } from './app-header-controller';
import { Icon } from '@portal/wen-components';

@Component({
  selector: 'wen-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    HeaderCommandHandler
  ]
})
export class AppHeaderComponent implements OnInit, OnDestroy, OnChanges {

  private onDestroy$ = new Subject<void>();
  private enablementSubscription = new Subscription();

  @HostBinding('class.wen-app-header') className = true;
  @HostBinding('class.wen-app-header-floating') isFloating = false;
  @HostBinding('class.wen-app-header-transparent') isTransparent = false;
  @HostBinding('class.wen-app-secondary-header') secondaryHeaderClassName = false;

  @Input() showLogo: boolean;
  // The context title is used as heading 1.
  @Input() contextTitle: string;
  // The title is used as heading 2.
  @Input() title: string;
  // The sub-title is not displayed as a heading.
  @Input() subTitle: string;
  @Input() logoIcon: string;
  @Input() titleIcon: Icon;
  @Input() prefixTitleIcon: Icon;
  @Input() leftCommand: HeaderCommand;
  @Input() rightCommand: HeaderCommand;
  @Input() centerCommand: BaseHeaderCommand;

  @Input() set transparent(newValue: boolean) { this.isTransparent = newValue; }
  @Input() set floating(newValue: boolean) { this.isFloating = newValue; }
  @Input() set secondaryHeader(newValue: boolean) { this.secondaryHeaderClassName = newValue; }

  @Input() listenEnablementEvents = false;

  rightCommandDisabled = false;
  leftCommandDisabled = false;

  get hasCommand() {
    return this.leftCommand || this.rightCommand;
  }

  constructor(
    private headerCommandHandler: HeaderCommandHandler,
    private appHeaderController: AppHeaderController,
    private outletHelper: OutletHelper,
    private store: Store<RootState>,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.store.pipe(
      select(selectCurrentHeaderData),
      filter((headerData) => Boolean(headerData?.commandButtonStates)),
      takeUntil(this.onDestroy$)
    ).subscribe(({ commandButtonStates }) => {
      this.appHeaderController.setLeftCommandEnablement(!commandButtonStates.leftCommandDisabled);
      this.appHeaderController.setRightCommandEnablement(!commandButtonStates.rightCommandDisabled);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!isNullOrUndefined(changes?.listenEnablementEvents?.currentValue)) {
      this.enablementSubscription.unsubscribe();
      this.enablementSubscription = this.setupEnablementEvents();
    }
  }

  private setupEnablementEvents() {
    return this.appHeaderController.enablementEvents$.pipe(
      filter(event => event.rootOutletId === this.outletHelper.resolveClosestId()),
      observeOn(animationFrameScheduler),
      takeUntil(this.onDestroy$)
    ).subscribe(({ buttonPosition, isDisabled }) => {
      switch (buttonPosition) {
        case CommandButtonPosition.LEFT:
          this.leftCommandDisabled = isDisabled;
          break;
        case CommandButtonPosition.RIGHT:
          this.rightCommandDisabled = isDisabled;
          break;
        default:
          break;
      }
      this.cdr.detectChanges();
    });
  }

  centerClicked() {
    if (!this.centerCommand) {
      return;
    }
    this.headerCommandHandler.handleCommand(this.centerCommand);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
